import styled from 'styled-components';
import Cross from '@assets/close-btn.svg';
import colors from '@config/colors';

type Props = {
  visible: boolean;
};

export const Container = styled.div<Props>`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  ${({ visible }): string => !visible && 'display: none;'};
`;

export const Modal = styled.div`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  background-color: #fff;
  margin: auto 8px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
`;

export const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // Places modal panel one-third down the page
  &:before {
    content: '';
    flex: 1;
  }

  &:after {
    content: '';
    flex: 3;
  }
`;

export const Title = styled.div`
  color: ${colors.dark.title};
  font-family: Graphie;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px;
`;

export const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  border: 0px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Cross});
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  &:hover {
    cursor: pointer;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
`;

export const Image = styled.img`
  height: 80px;
`;

export const Label = styled.div`
  font-family: proxima-soft;
  font-size: 16px;
  line-height: 19px;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const ButtonContainer = styled.div`
  width: 288px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
`;
