import React from 'react';

import { FieldContainer } from '@rentecarlo/component-library';
import RadioRow from '@molecules/inputs/RadioRow';

const MultipleChoice = ({
  value,
  onSelect,
  options,
  disabled,
  squashed,
  customButton,
  scrollable,
  name,
  selectorType,
  id,
  meta: { error, touched, active, pristine, submitFailed },
}) => (
  <FieldContainer
    id={id}
    showErrorContainer={submitFailed && error}
    showError={((touched && !pristine) || submitFailed) && error && (!active || submitFailed)}
    error={error}
  >
    <RadioRow
      value={value}
      onSelect={onSelect}
      options={options}
      disabled={disabled}
      squashed={squashed}
      customButton={customButton}
      scrollable={scrollable}
      name={name}
      selectorType={selectorType}
    />
  </FieldContainer>
);

MultipleChoice.defaultProps = {
  meta: {
    submitFailed: false,
  },
  value: null,
  disabled: false,
  squashed: false,
  scrollable: false,
  customButton: '',
  name: 'default',
};

export default MultipleChoice;
