/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import colors from '@config/colors';

import RejectionImage from '@assets/rejection.svg';
import RejectionRedImage from '@assets/rejection-red.svg';

dayjs.extend(advancedFormat);
const Text = styled.p`
  font-family: 'Proxima-Soft';
  font-size: 16px;
  line-height: 23px;
  text-align: left;

  margin-bottom: 32px;
`;

const BoldSpan = styled.span`
  font-weight: 800;
`;

const InlineText = styled.div`
  font-family: 'Proxima-Soft';
  font-size: 16px;
  line-height: 23px;
  text-align: left;

  margin-bottom: 32px;
`;

const Link = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  font-family: 'Proxima-Soft';
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  color: ${colors.blue.royal};
`;

const RestrictedPeriodMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const rejectionText = (
  <Text id='rejection-text-rejectionText'>
    <BoldSpan id='rejection-text-thankYou'>
      Thanks for taking the time to run through all the questions, we needed all the info to price
      up your insurance.
    </BoldSpan>
    <br />
    <br />
    Unfortunately, from time to time we are unable to provide insurance cover to somebody. We take a
    lot of factors into account relating to the person, vehicle and circumstance just to name a few!
    <br />
    <br />
    We know this is frustrating and we are constantly reviewing our underwriting criteria but
    unfortunately for now we can't offer a policy. In some cases, you may be able to get cover on a
    different vehicle - please feel free to find out by running another quote.
  </Text>
);

const rejectionRedText = (
  <Text id='rejection-text-rejectionRedText'>
    Unfortunately, from time to time, due to our underwriting criteria, we are unable to offer cover
    for a specific person. We take a lot of factors into account relating to the person, vehicle,
    claims, convictions, and circumstance, just to name a few!
    <br />
    <br />
    We know this is frustrating, but unfortunately, we can’t offer you a policy.
    <b id='rejection-text-paymentNotTaken'>
      We have not taken any payments from you associated with this quote.
    </b>
    <br />
    <br />
    Sorry we can't help you further and we hope you find an alternative.
  </Text>
);

const RestrictedPeriodMessage = ({ restrictedPeriod }) => {
  const { start_date, end_date, min_duration, article_link } = restrictedPeriod;

  const startDate = dayjs(start_date).format('Do of MMMM');
  const endDate = dayjs(end_date).format('Do of MMMM');

  return (
    <RestrictedPeriodMessageContainer id='rejection-container-main'>
      <Text id='rejection-text-minDuration'>
        Between the {startDate} and
        {endDate}, we are unable to offer cover for less than{min_duration} hours.
      </Text>
      <InlineText id='rejection-text-minDurationValue'>
        Please purchase a policy for {min_duration} hours or more.
        {article_link && (
          <Text id='rejection-text-moreInformation'>
            For more information, please{' '}
            <Link id='rejection-link-articleLink' href={article_link}>
              click here
            </Link>
          </Text>
        )}
      </InlineText>
    </RestrictedPeriodMessageContainer>
  );
};

const carFailureText = (
  <Text id='rejection-text-carFailure'>
    It looks like the selected vehicle does not meet our underwriting criteria.
    <br />
    <br />
    This could be due to the vehicle being high value, high insurance group or too old.
    <br />
    <br />
    We would love for you to use Veygo for your trip or journey so please feel free to quote using a
    different vehicle.
  </Text>
);

const expiredText = (motReturned, taxReturned) => {
  let subString;

  if (motReturned && taxReturned) {
    subString = 'Road Tax and MOT have';
  } else if (motReturned) {
    subString = 'MOT has';
  } else if (taxReturned) {
    subString = 'Road Tax has';
  }

  return (
    <Text id='rejection-text-expired'>
      Unfortunately we are unable to offer cover as it seems that your {subString} expired. In order
      to get covered you will need to ensure the vehicle has a valid MOT and Road Tax and then start
      a new quote.
      <br />
      <br />
      Please note our system can take a few days to update if you have recently had an MOT or
      renewed your Road Tax.
    </Text>
  );
};

const allowedErrors = ['FR21', 'FR22', 'FR23', 'FR02', 'FR07'];

const rejection = {
  getSubtitle(rejectionReasons, restrictedPeriod) {
    const returnedErrors = rejectionReasons
      ? Array.from(rejectionReasons, (reason) => reason.reason)
      : [];

    const windowReturned = returnedErrors.includes('FR21');
    const taxReturned = returnedErrors.includes('FR22');
    const motReturned = returnedErrors.includes('FR23');
    const carFailure = returnedErrors.includes('FR02') || returnedErrors.includes('FR07');
    const redFailure = returnedErrors.includes('FR27');

    if (windowReturned) {
      const isRestrictedPeriod = restrictedPeriod.length > 0;
      if (isRestrictedPeriod) {
        return <RestrictedPeriodMessage restrictedPeriod={restrictedPeriod[0]} />;
      }
    } else if (redFailure && returnedErrors.length === 1) {
      return rejectionRedText;
    } else if (
      returnedErrors.length === 0 ||
      returnedErrors.some((reason) => !allowedErrors.includes(reason))
    ) {
      return rejectionText;
    }

    if (carFailure) {
      return carFailureText;
    }

    return expiredText(motReturned, taxReturned);
  },

  getImage(rejectionReasons) {
    const returnedErrors = rejectionReasons
      ? Array.from(rejectionReasons, (reason) => reason.reason)
      : [];
    const redFailure = returnedErrors.includes('FR27');

    if (redFailure && returnedErrors.length === 1) {
      return RejectionRedImage;
    }

    return RejectionImage;
  },

  getEligibleForCrossSell(rejectionReasons) {
    const returnedErrors = rejectionReasons
      ? Array.from(rejectionReasons, (reason) => reason.reason)
      : [];
    const redFailure = returnedErrors.includes('FR27');

    if (redFailure) {
      return false;
    }

    return true;
  },
};

export default rejection;
