import styled from 'styled-components';
import colors from '@config/colors';

export const QuoteSummaryContainer = styled.div`
  align-items: center;
  border-bottom: 3px solid ${colors.blue.secondary};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
`;

export const QuoteValueHeader = styled.div`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 0;
`;

export const QuoteValueFooter = styled.div`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 12px;
  line-height: 13px;
  text-align: center;
`;

export const QuoteValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 150px;
`;

export const QuoteValue = styled.div`
  color: ${colors.grey.text.medium};
  font-family: 'gotham-rounded';
  font-size: 32px;
  font-weight: bold;
  height: 40px;
  letter-spacing: 1px;
  line-height: 38px;
  padding: 4px;
  text-align: center;
`;

export const OldValue = styled.div`
  color: ${colors.blue.important};
  text-decoration: line-through;
  font-family: 'gotham-rounded';
  font-size: 16px;
  font-weight: bold;
  height: 16px;
  width: 72px;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
`;

export const QuoteValueLoader = styled.img`
  height: 20px;
  width: 20px;
  margin: 6px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  margin-bottom: 4px;
`;

export const Text = styled.p`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 2px;
`;
