import React from 'react';
import { Panel, PageModal } from '@rentecarlo/component-library';
import styled from 'styled-components';
import { ComponentType, useComponentId } from '@hooks';
import OwnerDetailsForm from './OwnerDetailsForm.component';
import { FormPanelWrapper, FormPanelText, Title, Wrapper } from './styles';
import { ComponentProps } from './OwnerDetailsModal.container';

const PanelContainer = styled.div`
  margin: 16px 0 41px 0;
`;

const OwnerDetailsModal: React.FC<ComponentProps> = ({
  show = false,
  close,
  closeText = 'Close',
  driverDob,
  submitOwnerDetails,
  selectedRelationship,
  ownerFormFields,
  car,
  productType,
}: ComponentProps) => {
  const creator = useComponentId();
  return (
    <PageModal close={close} closeText={closeText} show={show}>
      <Wrapper>
        <Title id={creator(ComponentType.TITLE, 'ownerModal')}>Add an owner</Title>
        <PanelContainer>
          <Panel>
            <FormPanelWrapper>
              {car && (
                <FormPanelText id={creator(ComponentType.TEXT, 'ownerModalReminder')}>
                  Just as a reminder, this is the person who owns the{' '}
                  <FormPanelText
                    id={creator(ComponentType.TITLE, 'ownerModalReminderCarDetails')}
                    style={{ fontWeight: 'bold' }}
                  >
                    {car.make} {car.model} {Math.round(car.engine_size / 100) / 10}L
                  </FormPanelText>{' '}
                  you’re looking to drive.
                </FormPanelText>
              )}
            </FormPanelWrapper>
          </Panel>
        </PanelContainer>
        <OwnerDetailsForm
          close={close}
          driverDob={driverDob}
          onSubmit={submitOwnerDetails}
          selectedRelationship={selectedRelationship}
          ownerFormFields={ownerFormFields}
          productType={productType}
        />
      </Wrapper>
    </PageModal>
  );
};

export default OwnerDetailsModal;
