import { types as tempcovertypes, TempcoverActions } from './tempcover.actions';
import { types as quoteTypes, QuoteActions } from './actions';
import { types as lookupTypes } from '../lookup/actions';

export interface Tempcover {
  reasonForCover: string | null;
  otherReasonForCover: string | null;
  isNamedDriver: string | null;
}

export const initialState: Tempcover = {
  reasonForCover: null,
  otherReasonForCover: null,
  isNamedDriver: null,
};

export type TempcoverActionTypes = TempcoverActions | QuoteActions;

export default function reducer(
  state = initialState,
  action = {} as TempcoverActionTypes,
): Tempcover {
  switch (action.type) {
    case tempcovertypes.SET_REASON_FOR_COVER:
      return {
        ...state,
        reasonForCover: action.reason,
      };
    case tempcovertypes.SET_OTHER_REASON_FOR_COVER:
      return {
        ...state,
        otherReasonForCover: action.reason,
      };
    case tempcovertypes.SET_NAMED_DRIVER:
      return {
        ...state,
        isNamedDriver: action.isNamedDriver,
      };
    case quoteTypes.RESET_QUOTE:
      return initialState;
    case lookupTypes.LOOKUP_CAR_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
