import React from 'react';
import styled from 'styled-components';

import colors from '@config/colors';
import EmptyTickbox from '@assets/empty-tickbox-small.svg';
import CompletedTickbox from '@assets/completed-tickbox-small.svg';

const Button = styled.button`
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 0.5px solid ${colors.grey.lines.light};
  background-color: ${colors.white};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  padding: 16px;
  text-align: left;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 192px;
  }
`;

const Tickbox = styled.div`
  height: 32px;
  width: 32px;
  margin-bottom: 8px;
`;

const Header = styled.span`
  font-family: 'gotham-rounded-book';
  font-size: 20px;
  line-height: 23px;
  color: ${colors.outerSpace};
  margin-bottom: 24px;
`;

const BasicText = styled.span`
  font-family: 'proxima-soft';
  color: ${colors.outerSpace};
`;

const Body = styled(BasicText)`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
`;

const Footer = styled(BasicText)`
  font-size: 12px;
  line-height: 14px;
`;

const OptionButton = ({ onClick, selected, id, header, body, footer }) => (
  <Button
    onClick={onClick}
    alt={selected ? 'Button with checkbox (Checked)' : 'Button with checkbox (Unchecked)'}
    id={id}
  >
    <Tickbox>
      <img src={selected ? CompletedTickbox : EmptyTickbox} alt='tick-box' />
    </Tickbox>
    <Header id='upgradeAccount-component-header'>{header}</Header>
    <Body id='upgradeAccount-component-body'>{body}</Body>
    <Footer id='upgradeAccount-component-footer'>{footer}</Footer>
  </Button>
);

export default OptionButton;
