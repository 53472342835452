import React, { FC, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import QuoteStep from '@templates/QuoteStep';
import { ButtonCard } from '@rentecarlo/component-library';

import { useOptimizelyFlag, useOptimizelyTrack } from '@hooks';

import {
  getIPIDUrl,
  getPolicyBookletUrl,
  getBreakdownCoverFullTermsUrl,
  getBreakdownCoverIPIDUrl,
} from '@reselectors/files';

import { RootState } from '@redux/reducer';

import LearnerToNewDriverModal from '@organisms/modals/LearnerToNewDriverModal';

import { setAppField } from '@redux/reducer/app/actions';

import { RenderFullContents } from '../../../config/importantInformation';

interface AlmostThereProps {
  isMobile: boolean;
  product: string;
  registrationNumber: string;
  excess: number;
  productName: string;
  ownerName: string;
  IPIDUrl: string;
  yourAgreementWithAbleUrl: string;
  policyBookletUrl: string;
  isDriverOwner: boolean;
  hasInsurance: string;
  isSubscription: boolean;
  goToPayment: () => void;
  hasBreakdownCover: boolean;
}

const AlmostThere: FC<AlmostThereProps> = ({
  product,
  isMobile,
  registrationNumber,
  excess,
  productName,
  ownerName,
  yourAgreementWithAbleUrl,
  isDriverOwner,
  hasInsurance,
  isSubscription,
  goToPayment,
  hasBreakdownCover,
}) => {
  const dispatch = useDispatch();

  const policyBookletUrl = useSelector((state) => getPolicyBookletUrl(state));
  const IPIDUrl = useSelector((state) => getIPIDUrl(state));
  const breakdownIPIDURL = useSelector((state) => getBreakdownCoverIPIDUrl(state));
  const breakdownDetailsURL = useSelector((state) => getBreakdownCoverFullTermsUrl(state));

  const price = useSelector((state: RootState) => state.quote.save.price);
  const productType = useSelector((state: RootState) => state.quote.product.productType);
  const hasSeenLearnerNDTest = useSelector((state: RootState) => state.app.hasSeenLearnerNDTest);

  const [showLearnerToNewDriverModal, setShowLearnerToNewDriverModal] = useState(false);
  const shownLearnerNDModal = useRef(false);

  const sendEvent = useOptimizelyTrack();
  const showLearnerNewDriverModal = useOptimizelyFlag('TEMP_LEARNER_TO_NEWDRIVER_MODAL');

  useEffect(() => {
    if (
      productType === 'ldp' &&
      isSubscription &&
      !hasSeenLearnerNDTest &&
      showLearnerNewDriverModal.enabled &&
      showLearnerNewDriverModal.variationKey === 'pre_purchase' &&
      !shownLearnerNDModal.current
    ) {
      setTimeout(() => {
        setShowLearnerToNewDriverModal(true);
        sendEvent('learner_to_newdriver_show');
        shownLearnerNDModal.current = true;
      }, 2000);
    }
  }, [sendEvent, showLearnerNewDriverModal, productType, isSubscription, hasSeenLearnerNDTest]);

  const closeLearnerToNewDriverModal = () => {
    setShowLearnerToNewDriverModal(false);
    dispatch(setAppField('hasSeenLearnerNDTest', true));
  };
  const renderStepSubtitle = () =>
    'We know there is a lot of text, ' +
    'but we need you to read through all the information below, ' +
    'and confirm you are happy with it before we take payment.';

  const contents = RenderFullContents({
    registrationNumber,
    product,
    excess,
    productName,
    ownerName,
    IPIDUrl,
    yourAgreementWithAbleUrl,
    policyBookletUrl,
    isDriverOwner,
    hasInsurance,
    isSubscription,
    hasBreakdownCover,
    breakdownDetailsURL,
    breakdownIPIDURL,
  });

  return (
    <>
      <QuoteStep
        id='almostThere-component-quoteStep'
        stepTitle='About your cover'
        stepSubtitle={renderStepSubtitle()}
        form
      >
        <ButtonCard
          id='almostThere-component-singleTermsConfirmation'
          contents={contents}
          buttonText='Accept terms and purchase'
          onClick={() => goToPayment()}
          isMobile={isMobile}
        />
      </QuoteStep>
      <LearnerToNewDriverModal
        show={showLearnerToNewDriverModal}
        onClose={closeLearnerToNewDriverModal}
        price={Number(price)}
      />
    </>
  );
};

export default AlmostThere;
