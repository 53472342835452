import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  width: number;
  justification: string;
};

const ModalPanel = styled.div`
  background-color: #fff;
  border-radius: 5px;
  max-width: ${(props: Props): number => props.width}px;
  min-height: 150px;
  margin: 50px auto;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props: Props): string => props.justification};
`;

const ModalPanelComponent: React.FC<Props> = ({ width, justification, children }) => (
  <ModalPanel justification={justification} width={width}>
    {children}
  </ModalPanel>
);

ModalPanelComponent.defaultProps = {
  width: 500,
  justification: 'flex-start',
};

export default ModalPanelComponent;
