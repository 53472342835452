import styled from 'styled-components';
import colors from '@config/colors';

interface DurationSummaryProps {
  displayColumn?: boolean;
}

interface DateSummaryProps {
  fullWidth?: boolean;
}

export const QuoteRowContainer = styled.div`
  padding: 25px 0px;
`;

export const DurationSummary = styled.div<DurationSummaryProps>`
  display: flex;
  flex-direction: ${({ displayColumn }): string => (displayColumn ? 'column' : 'row')};
  width: 100%;
`;

export const DateSummary = styled.div<DateSummaryProps>`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  width: ${({ fullWidth }): number => (fullWidth ? 100 : 50)}%;
  ${({ fullWidth }) => fullWidth && 'align-items: center;'}
`;

export const DateLabel = styled.p`
  color: ${colors.blue.important};
  font-family: 'gotham-rounded';
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
`;

export const DateCalendarValue = styled.p`
  color: ${colors.black};
  font-family: 'proxima-soft';
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
`;

export const DateTimeValue = styled.p`
  color: ${colors.black};
  font-family: 'proxima-soft';
  font-size: 14px;
  line-height: 17px;
`;
