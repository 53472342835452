// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';
import styled from 'styled-components';

import { TextLink } from '@rentecarlo/component-library';

import colors from '@config/colors';

export const Text = styled.p`
  color: ${colors.outerSpace};
  padding-bottom: 20px;
  font-family: 'proxima-soft';
  font-size: 14px;
  line-height: 19px;
`;

export const StrongText = styled(Text)`
  font-weight: bold;
`;

export const SectionTitle = styled.h3`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.32px;
  margin-bottom: 8px;
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

const Uppercase = styled.span`
  text-transform: uppercase;
`;

interface RenderFullContentsProps {
  registrationNumber: string;
  excess: number;
  product: string;
  ownerName: string;
  IPIDUrl: string;
  yourAgreementWithAbleUrl: string;
  policyBookletUrl: string;
  isDriverOwner: boolean;
  hasInsurance: string;
  isSubscription: boolean;
  hasBreakdownCover: boolean;
  breakdownDetailsURL: string;
  breakdownIPIDURL: string;
}
export const RenderFullContents = ({
  registrationNumber,
  excess,
  product,
  ownerName,
  IPIDUrl,
  yourAgreementWithAbleUrl,
  policyBookletUrl,
  isDriverOwner,
  hasInsurance,
  isSubscription,
  hasBreakdownCover,
  breakdownDetailsURL,
  breakdownIPIDURL,
}: RenderFullContentsProps) => {
  const contents = [];
  switch (product) {
    case 'tc':
      contents.push(renderTemporaryCoverAboutCover(excess, isSubscription));
      break;
    case 'ldp':
      contents.push(renderLearnerDriverTerms(registrationNumber));
      if (isDriverOwner) {
        contents.push(renderOwnerLearnerDriverAboutCover(hasInsurance, isSubscription));
      } else {
        contents.push(renderCsiOrLdpAboutCover(ownerName, isSubscription));
      }
      break;
    case 'csi':
    default:
      contents.push(renderCsiOrLdpAboutCover(ownerName, isSubscription));
      break;
    case 'newdriver':
      contents.push(renderNewDriverAboutCover(isSubscription));
      contents.push(renderTelematicsAboutCover());
      break;
  }
  contents.push(
    renderAboutFalseInfo(),
    renderTermsAndConditions(IPIDUrl, yourAgreementWithAbleUrl, policyBookletUrl, isSubscription),
  );
  if (hasBreakdownCover) contents.push(renderBreakdownCover(breakdownDetailsURL, breakdownIPIDURL));
  return contents;
};

const renderOwnerLearnerDriverAboutCover = (hasInsurance: string, isSubscription: boolean) => {
  return (
    <>
      <SectionTitle id='almostThere-title-aboutCoverOwnerLeanerDriver'>About the cover</SectionTitle>
      {confirmationOpeningText(isSubscription)}
      {hasInsurance !== 'false' && (
        <Text id='almostThere-text-aboutCoverOwnerLeanerDriverOne'>
          I understand that the vehicle has to remain insured elsewhere for the duration of this
          policy and that this policy is not suitable as the only insurance for the vehicle.
        </Text>
      )}
      {duiAndMedicalText()}
      <Text id='almostThere-text-aboutCoverOwnerLeanerDriverTwo'>I understand about the excesses on this policy.</Text>
      {territorialLimitsText()}
      <Text id='almostThere-text-aboutCoverOwnerLeanerDriverThree'>
        I confirm the vehicle is not a hire or rental vehicle and I am or will be the registered
        keeper.
      </Text>
      <Text id='almostThere-text-aboutCoverOwnerLeanerDriverFour'>I confirm the vehicle is owned, leased or contracted to a private individual.</Text>
      <Text id='almostThere-text-aboutCoverOwnerLeanerDriverFive'>
        I understand this cover is only valid for social, domestic and commuting use. I confirm I
        will not use the vehicle for anything other than this and I will not use the insurance to
        release the vehicle from an impound.
      </Text>
    </>
  );
};

const renderTemporaryCoverAboutCover = (excess: number, isSubscription: boolean) => {
  return (
    <>
      <SectionTitle id='almostThere-title-aboutCoverTemp'>About the cover</SectionTitle>
      {confirmationOpeningText(isSubscription)}
      {duiAndMedicalText()}
      <Text id='almostThere-text-aboutCoverTempOne'>
        I understand the information about the policy excesses and that my excess is set at £
        {excess} on this policy.
      </Text>
      <Text id='almostThere-text-aboutCoverTempTwo'>
        I understand this cover is only valid for social, domestic and commuting use. I confirm that
        I will not use the vehicle for anything other than this.
      </Text>
      {territorialLimitsText()}
      <Text id='almostThere-text-aboutCoverTempThree'>
        I confirm the vehicle is not a hire or rental vehicle and I am or will be the registered
        keeper.
      </Text>
      <Text id='almostThere-text-aboutCoverTempFour'>I confirm the vehicle is owned, leased or contracted to a private individual.</Text>
      <Text id='almostThere-text-aboutCoverTempFive'>I will not use the insurance to release the vehicle from an impound.</Text>
    </>
  );
};

const renderCsiOrLdpAboutCover = (ownerName: string, isSubscription: boolean) => {
  return (
    <>
      <SectionTitle id='almostThere-title-aboutCoverCSIOrLDP'>About the cover</SectionTitle>
      {confirmationOpeningText(isSubscription)}
      <Text id='almostThere-text-aboutCoverCSIOrLDPOne'>
        I confirm I have <Capitalize id='ownerNamePermission'>{ownerName}</Capitalize>
        's permission to purchase cover on their vehicle and provide their personal information.
      </Text>
      <Text id='almostThere-text-aboutCoverCSIOrLDPTwo'>
        I understand that the vehicle has to remain insured elsewhere for the duration of this
        policy and that this policy is not suitable as the only insurance for the vehicle.
      </Text>
      <Text id='almostThere-text-aboutCoverCSIOrLDPThree'>
        I confirm that I am not providing a financial benefit to{' '}
        <Capitalize id='almostThere-text-ownerNameFinancial'>{ownerName}</Capitalize> by using this vehicle, to do so
        could result in a claim not being paid or my policy being cancelled or declared void.
      </Text>
      {duiAndMedicalText()}
      <Text id='almostThere-text-aboutCoverCSIOrLDPFour'>I understand about the excesses on this policy.</Text>
      {territorialLimitsText()}
      <Text id='almostThere-text-aboutCoverCSIOrLDPFive'>
        I confirm the vehicle is not a hire or rental vehicle and I confirm the vehicle is owned,
        leased or contracted to a private individual.
      </Text>
      <Text id='almostThere-text-aboutCoverCSIOrLDPSix'>
        I understand this cover is only valid for social, domestic and commuting use. I confirm I
        will not use the vehicle for anything other than this and I will not use the insurance to
        release the vehicle from an impound.
      </Text>
    </>
  );
};


const renderNewDriverAboutCover = (isSubscription: boolean) => {
  return (
    <>
      <SectionTitle id='almostThere-title-aboutCoverNewDriver'>New Driver Terms</SectionTitle>
      {confirmationOpeningText(isSubscription)} 
      <Text id='almostThere-text-aboutCoverNewDriverOne'>
        I understand that I am not covered if: I am driving under the influence of drink and/or drugs, or if I have not told the DVLA about any notifiable medical conditions which could affect my licence validity.
      </Text>
      <Text id='almostThere-text-aboutCoverNewDriverTwo'>
        I understand the information about the policy excesses and that my excess is set at £750 on this policy.
      </Text>
      <Text id='almostThere-text-aboutCoverNewDriverThree'>
        I understand this cover is only valid for social, domestic and commuting use. I confirm that I will not use the vehicle for anything other than this.
        I understand that this cover is only valid within the territorial limits of England, Northern Ireland, Wales and Scotland, and that if I am driving in the Channel Islands, Isle of Man or in the EEA/EU, my cover is limited to the minimum level required in that country.
      </Text>
      <Text id='almostThere-text-aboutCoverNewDriverFour'>
        I confirm the vehicle is not a hire or rental vehicle.
      </Text>
      <Text id='almostThere-text-aboutCoverNewDriverFive'>
        I confirm the vehicle is owned, leased or contracted to a private individual. 
      </Text>
      <Text id='almostThere-text-aboutCoverNewDriverSix'>
        I will not use the insurance to release the vehicle from an impound. 
      </Text>
    </>
  );
};

const renderTelematicsAboutCover = () => {
  return (
    <>
      <SectionTitle id='almostThere-title-telematicsAboutCover'>Telematics</SectionTitle>
      <Text id='almostThere-text-telematicsAboutCoverOne'>
        By choosing this insurance product, I understand and agree to the use of telematics technology provided by a third-party service provider. Telematics involves using devices and systems to collect and transmit data about my driving behaviour, including but not limited to, speed, distance, and driving patterns.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverTwo'>
        I am aware that the third-party telematics provider will collect and process this data on behalf of the insurance company. The information gathered through telematics will be used to assess my driving habits, calculate insurance premiums, and enhance the services provided.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverThree'>
        As the insurance provider, we reserve the right to collect and analyse this data to assess my driving habits, determine insurance premiums, and improve our services. The information collected through telematics might also be used to offer personalised discounts, rewards, and incentives based on my driving behaviour.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverFour'>
        I acknowledge that my policy may be cancelled if my driving score falls below the acceptable threshold, and I agree to maintain a good driving record to prevent cancellation.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverFive'>
        Upon accepting this insurance, I must activate the provided telematics app within 14 days from policy issuance. Failure to do so will result in automatic policy cancellation. I understand the significance of timely activation for accurate data collection, premium assessment, and service enhancement. By accepting this policy, I agree to adhere to this activation requirement.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverSix'>
        I agree that if I am unable to complete these steps (Install and activate telematics app) within the 14 days, my telematics policy is at risk of being cancelled, and I may be unable to purchase future Veygo policies.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverSeven'>
        I understand that my policy may be cancelled if there is inactivity for 30 days, and I agree to promptly respond to any required actions or communications to maintain the policy.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverEight'>
        I accept that this telematics-based product is suitable for my driving habits, and I agree to ensure I drive a minimum of 100 miles each month as a requirement of the policy.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverNine'>
        I understand that I must not tamper with the telematics app data, and I agree to use the app honestly and accurately, refraining from any attempts to manipulate or alter the information it records.
      </Text>
      <Text id='almostThere-text-telematicsAboutCoverTen'>
        I want to emphasise that my privacy and data security are extremely important. I understand that all data collected will be handled in line with the privacy policy and relevant data protection laws. By proceeding with the purchase of this insurance product, I acknowledge and consent to the use of telematics technology for the duration of my policy term.
      </Text>
    </>
  );
};

const renderLearnerDriverTerms = (registrationNumber: string) => {
  return (
    <>
      <SectionTitle id='almostThere-title-learnerDriverTerms'>Learner Driver Terms</SectionTitle>
      <Text id='almostThere-text-learnerDriverTermsOne'>
        I confirm that when driving the vehicle with registration{' '}
        <Uppercase id='almostThere-text-registrationNumber'>{registrationNumber}</Uppercase> under this policy of
        insurance, I will be supervised by someone that has had their licence for 3+ years and is 21
        or older.
      </Text>
      <Text id='almostThere-text-learnerDriverTermsTwo'>
        I also understand that I will not be covered for any claims immediately after passing my
        practical driving test. This is because only provisional licence holders are able to use
        this type of insurance.
      </Text>
    </>
  );
};

const renderTermsAndConditions = (
  IPIDUrl: string,
  yourAgreementWithAbleUrl: string,
  policyBookletUrl: string,
  isSubscription: boolean,
) => {
  return (
    <>
      <SectionTitle id='almostThere-title-termsAndConditions'>Terms and conditions</SectionTitle>
      <Text id='almostThere-text-termsAndConditionsOne'>
        You have had access to and have read the{' '}
        <TextLink  id='almostThere-link-termsAndConditionsIPID' href={IPIDUrl} fontSize={14} bold={false}>
          Insurance Product Information
        </TextLink>{' '}
        document, the{' '}
        <TextLink  id='almostThere-link-termsAndConditionsAbleAgreement' href={yourAgreementWithAbleUrl} fontSize={14} bold={false}>
          Your Agreement with Able Insurance Services Limited
        </TextLink>{' '}
        document and full details of our policy in our{' '}
        <TextLink  id='almostThere-link-termsAndConditionsPolicyBooklet' href={policyBookletUrl} fontSize={14} bold={false}>
          Policy Booklet
        </TextLink>
        .
      </Text>
      <Text id='almostThere-text-termsAndConditionsTwo'>
        You will check your Proposal Confirmation and tell us immediately if any details are
        incorrect.
      </Text>
      <Text id='almostThere-text-termsAndConditionsThree'>
        You agree to inform us of any changes detailed in 'Keeping your policy up to date' section
        of the policy booklet for the duration of this policy.
      </Text>
      <Text id='almostThere-text-termsAndConditionsFour'>I understand that if I don't do the above, it may mean that a claim is not paid.</Text>
      {isSubscription && (
        <StrongText id='almostThere-text-termsAndConditionsFive'>
          I understand I am no longer covered if I fail to make the payment by the due date.
        </StrongText>
      )}
    </>
  );
};

const renderAboutFalseInfo = () => {
  return (
    <>
      <SectionTitle id='almostThere-title-aboutFalseInfo'>About providing false information</SectionTitle>
      <Text id='almostThere-text-aboutFalseInfoOne'>
        It is an offence under the Road Traffic Act to make a false statement or withhold any
        material information to gain issue of a Motor Insurance Certificate.
      </Text>
      <Text id='almostThere-text-aboutFalseInfoTwo'>
        If at ANY time we find that the details you give us are inaccurate or incomplete then this
        could affect the validity of your insurance cover with us. You run the risk that we may
        cancel your policy (and we could do this effective from the start of your policy) or refuse
        to pay all or part of any claim you make.
      </Text>
    </>
  );
};

const territorialLimitsText = () => (
  <Text id='almostThere-text-territorialLimits'>
    I understand that this cover is only valid within the territorial limits of England, Northern
    Ireland, Wales and Scotland, and that if I am driving in the Channel Islands, Isle of Man or in
    the EEA/EU, my cover is limited to the minimum level required in that country.
  </Text>
);

const confirmationOpeningText = (isSubscription: boolean) => (
  <Text id='almostThere-text-confirmationOpening'>
    I confirm that all of the information is correct and that I wish to proceed with the purchase of
    my {isSubscription ? 'monthly insurance subscription' : 'temporary car insurance policy'} and I
    understand that I will need to be able to produce my certificate of motor insurance when
    requested.
  </Text>
);

const duiAndMedicalText = () => (
  <Text id='almostThere-text-duiAndMedical'>
    I understand that I am not covered if: I am driving under the influence of drink and/or drugs,
    or if I have not told the DVLA about any notifiable medical conditions which could affect my
    licence validity.
  </Text>
);

const renderBreakdownCover = (breakdownDetailsURL: string, breakdownIPIDURL: string) => {
  return (
    <>
      <SectionTitle id='almostThere-title-breakdownCover'>Breakdown cover</SectionTitle>
      <Text id='almostThere-text-breakdownCover'>
        You have had access to and have read the{' '}
        <TextLink id='almostThere-link-breakdownCoverDetails' href={breakdownDetailsURL} fontSize={14} bold={false}>
          Breakdown Cover Full Terms
        </TextLink>{' '}
        and{' '}
        <TextLink id='almostThere-link-breakdownCoverIPID' href={breakdownIPIDURL} fontSize={14} bold={false}>
        Breakdown Insurance Product Information Document
        </TextLink>
        .
      </Text>
    </>
  );
};
