import React from 'react';
import styled from 'styled-components';
import colors from '@config/colors';
import { FieldLock, Tick, ErrorForwardIcon } from '@rentecarlo/component-library';
import { ComponentType, useComponentId } from '@hooks';

type StylePropsType = {
  Icon: React.FC | null;
  textColor: string;
  borderBottomColor: string;
};

const FieldIconWithImage = styled.img`
  width: 16px;
`;

export default (
  disabled: boolean,
  active: boolean,
  valid: boolean,
  error: boolean,
): StylePropsType => {
  const creator = useComponentId();
  if (disabled) {
    return {
      Icon: FieldLock,
      textColor: colors.grey.text.light,
      borderBottomColor: colors.grey.text.light,
    };
  }
  if (active) {
    return { Icon: null, textColor: colors.outerSpace, borderBottomColor: colors.blue.important };
  }

  if (valid) {
    return {
      Icon: () => <Tick status='active' size={16} />,
      textColor: colors.black,
      borderBottomColor: colors.caribbeanGreen,
    };
  }
  if (error) {
    return {
      Icon: () => (
        <FieldIconWithImage
          id={creator(ComponentType.ICON, 'errorForward')}
          src={ErrorForwardIcon}
        />
      ),
      textColor: colors.blazeOrange,
      borderBottomColor: colors.blazeOrange,
    };
  }
  return { Icon: null, textColor: colors.outerSpace, borderBottomColor: 'none' };
};
