import Debug from 'debug';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  setAlternativeProduct,
  types,
  MakeAlternativeProductCheck,
} from '@redux/reducer/quote/alternativeProducts.actions';
import quoteApi from '@services/api/quote';

const debug = Debug('veygo:sagas:alternativeProducts');

export function* makeAlternativeProductsCheckSaga(
  action: MakeAlternativeProductCheck,
): SagaIterator {
  const debugSave = debug.extend('saveQuoteSaga');
  try {
    const quoteUuid = yield select((state) => state.quote.save.uuid);

    const { passed_uw: passedUW, price } = yield call(
      quoteApi.getAltProduct,
      quoteUuid,
      action.productType,
    );

    yield put(
      setAlternativeProduct(action.productType, {
        passedUW,
        price,
      }),
    );
  } catch (error) {
    debugSave('error', error);
  }
}

export function* watchAlternativeProducts(): SagaIterator {
  yield takeEvery(types.MAKE_ALTERNATIVE_PRODUCT_CHECK, makeAlternativeProductsCheckSaga);
}
