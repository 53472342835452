export const addressToPreview = (address) => {
  const flatNumber = address.flatNumber && `${address.flatNumber}, `;
  const county = address.county && `${address.county}, `;
  const houseName = address.houseName && `${address.houseName}, `;
  const houseNumber = address.houseNumber && `${address.houseNumber}, `;
  const line1 = address.line1 && `${address.line1}, `;
  const line2 = address.line2 && `${address.line2}, `;
  const postcode = address.postcode && address.postcode;
  const road = address.road && `${address.road}, `;
  const town = address.town && `${address.town}, `;

  return (
    flatNumber + (houseName || houseNumber) + (road || line1 || line2) + (town || county) + postcode
  );
};

export const addressToList = (address) => {
  const addressList = [];
  if (address.flatNumber) addressList.push(address.flatNumber);

  if (address.houseName) addressList.push(address.houseName);

  const houseNumber = address.houseNumber ? `${address.houseNumber}, ` : '';

  if (address.road) {
    addressList.push(houseNumber + address.road);
  } else if (address.line1) {
    addressList.push(houseNumber + address.line1);
  } else if (address.line2) {
    addressList.push(houseNumber + address.line2);
  }

  if (address.town) {
    addressList.push(address.town);
  } else if (address.county) {
    addressList.push(address.county);
  }

  if (address.postcode) addressList.push(address.postcode);

  return addressList;
};

export const getAddressFromLookup = (addressList, value) =>
  addressList.find((a) => a.key === parseInt(value));

export const addressActionCreator = (address) => ({
  isManual: false,
  county: address.County,
  country: 'GBR',
  flatNumber: address.FlatNumber,
  houseName: address.HouseName,
  houseNumber: address.HouseNumber,
  line1: address.AddressLine1,
  line2: address.AddressLine2,
  postcode: address.Postcode,
  road: address.Road,
  town: address.Town,
  lookupId: address.addressID,
  key: `${address.key}`,
});
