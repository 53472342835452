import React from 'react';

import { WrappedFieldProps } from 'redux-form';
import getTempcoverReasonText from '@services/getTempcoverReasonText';
import reasonForBuyingTempcover from '@config/reasonForBuyingTempcover';
import QuestionModal from '@molecules/blocks/QuestionModal';
import SelectItemButton from '@atoms/buttons/SelectItemButton';
import styled from 'styled-components';

const SelectItemButtonContainer = styled.div`
  margin-top: 16px;
`;

const Container = styled.div`
  pointer-events: auto;
`;

interface Props extends WrappedFieldProps {
  closeModel: () => void;
  disabled: boolean;
  openModal: () => void;
  modalOpen: boolean;
  productType: string;
  id: string;
  changeToCsi: () => void;
  onOpen: () => void;
  onClose: () => void;
  isQuoteForMyself: boolean;
}

export const TempcoverReasonField: React.FC<Props> = ({
  input: { value, onChange },
  onClose,
  modalOpen,
  productType,
  id,
  onOpen,
  disabled,
  changeToCsi,
  isQuoteForMyself,
}) => (
  <Container>
    <QuestionModal
      onClose={onClose}
      onSelectedQuesiton={onChange}
      persistedSelectedQuestion={value}
      display={modalOpen ? '' : 'none'}
      productType={productType}
      questions={reasonForBuyingTempcover(changeToCsi, isQuoteForMyself)}
      title={
        isQuoteForMyself
          ? 'What is the reason you need temporary cover?'
          : 'What is the reason they need temporary cover?'
      }
    />
    <SelectItemButtonContainer>
      <SelectItemButton
        id={id}
        label={getTempcoverReasonText(value, isQuoteForMyself)}
        onClick={onOpen}
        disabled={disabled}
      />
    </SelectItemButtonContainer>
  </Container>
);
