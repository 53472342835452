export default [
  {
    title: 'Parent/Child',
    description: 'Means your father, mother, son, daughter or legal guardian.',
    value: 'pa',
    id: "parent-ChildSelector",
  },
  {
    title: 'Partner',
    description: 'Means your husband, wife, civil partner or a person with whom you live on a permanent basis at the same address, sharing financial responsibilities, as if you were married to them. This does not include business partners, unless you also have a relationship with them as described in the first sentence.',
    value: 'sp',
    id: "partnerSelector",
  },
  {
    title: 'Friend',
    description: 'A sustained social relationship, a person (not a company or other business entity).',
    value: 'fr',
    id: "friendSelector",
  },
  {
    title: 'Colleague',
    description: 'Any person (not a company or other business entity) employed by the same company or business which employs you.',
    value: 'co',
    id: "colleagueSelector",
  },
  {
    title: 'Other family',
    description: 'Means your brother, sister, uncle, aunt, nephew, niece or first cousin, grandfather, grandmother, grandchild, in-law and legal step-family.',
    value: 'fa',
    id: "otherFamilySelector",
  },
  {
    title: 'Other',
    description: 'Any relationship that does not fit into the other categories.',
    value: 'ot',
    id: "otherSelector",
  },
];
