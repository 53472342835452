import React from 'react';
import OccupationSelect from '@molecules/selects/OccupationSelect';
import { FieldContainer } from '@rentecarlo/component-library';

const OccupationSelectField = (props) => {
  const {
    id,
    input: { onChange, value },
    meta: { error, touched, active, pristine, submitFailed },
    occupations,
    occupationType,
    disabled,
    isQuoteForMyself,
  } = props;
  return (
    <FieldContainer
      id={id}
      showErrorContainer={submitFailed && error}
      showError={((touched && !pristine) || submitFailed) && error && (!active || submitFailed)}
      error={error}
    >
      <OccupationSelect
        onChange={onChange}
        value={value}
        occupations={occupations}
        occupationType={occupationType}
        disabled={disabled || !occupationType}
        isQuoteForMyself={isQuoteForMyself}
      />
    </FieldContainer>
  );
};

export default OccupationSelectField;
