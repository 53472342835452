import React from 'react';
import styled from 'styled-components';

import RadioButton from '@atoms/inputs/RadioButton';

import { FieldContainer, InformationBox } from '@rentecarlo/component-library';

const ToggleButtonContainer = styled.div`
  display: flex;
  & > * {
    flex: 1; /* Each child of ToggleButtonContainer takes up equal space */
  }
`;

export const errors = {
  ERROR_RIGHT: 'invalid_choice_right',
  ERROR_LEFT: 'invalid_choice_left',
};

const ToggleButtonWithNoTicker = ({
  value,
  onSelect,
  leftLabel,
  rightLabel,
  groupName,
  error,
  errorMessage,
  displayLeftError,
  hasValidationError,
  validationErrorMessage,
  id,
  page,
}) => (
  <div>
    <FieldContainer
      id={id}
      showErrorContainer={hasValidationError}
      showError={hasValidationError}
      error={validationErrorMessage}
    >
      <ToggleButtonContainer>
        <RadioButton
          id={`${page}-button-${groupName}Yes`}
          checked={value === 'true'}
          onSelect={onSelect}
          text={leftLabel}
          value='true'
          displayError={error === 'invalid_choice_left' || displayLeftError}
          tickSize={0}
        />
        <RadioButton
          id={`${page}-button-${groupName}No`}
          checked={value === 'false'}
          onSelect={onSelect}
          text={rightLabel}
          value='false'
          displayError={error === 'invalid_choice_right'}
          tickSize={0}
        />
      </ToggleButtonContainer>
      {errorMessage !== undefined && error !== undefined && error !== 'Required' && (
        <InformationBox id={`${page}-text-${groupName}ErrorMessage`} type='error'>
          {errorMessage}
        </InformationBox>
      )}
    </FieldContainer>
  </div>
);

ToggleButtonWithNoTicker.defaultProps = {
  leftLabel: 'YES',
  rightLabel: 'NO',
  displayLeftError: false,
};

export default ToggleButtonWithNoTicker;
