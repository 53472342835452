import React from 'react';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

type StripeLoadingContainerProps = {
  amount: number;
  isSubscription: boolean;
  children?: React.ReactNode | undefined;
};

const StripeLoadingContainer: React.FC<StripeLoadingContainerProps> = ({
  amount,
  isSubscription,
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  const options = {
    mode: isSubscription ? 'subscription' : 'payment',
    currency: 'gbp',
    amount,
    paymentMethodCreation: 'manual',
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimary: '#000000',
        colorBackground: '#ffffff',
        colorText: '#000000',
        colorDanger: '#df1b41',
        borderRadius: '4px',
      },
      rules: {
        '.Input': {
          border: '1px solid #CBCBCB',
        },
      },
    },
  };

  return (
    <Elements stripe={stripe} options={options as StripeElementsOptions}>
      {children}
    </Elements>
  );
};

export default StripeLoadingContainer;
