/* eslint-disable camelcase, @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs';
import { buildDateForApi } from '@services/date';
import { licenceTypeMap, remoteToLocalLicenceTypeMap } from '@services/transformers/maps';

type AddressLocal = {
  flatNumber: string;
  houseNumber: string;
  houseName: string;
  line1: string;
  line2: string;
  road: string;
  town: string;
  county: string;
  country: string;
  postcode: string;
};

export type CustomerCreateLocalV3 = {
  title: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  occupationType: string;
  occupation: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  birthdate: any;
  user: {
    username: string;
  };
  address: AddressLocal;
  licence: {
    number: string;
    type: string;
  };
  contactPreferences: {
    email: boolean;
    telephone: boolean;
  };
};

type PersonalDetailsRemote = {
  title: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  occupation_type: string;
  occupation: string;
  date_of_birth: string;
};

type DrivingLicenceRemoteV3 = {
  licence_number: string;
  licence_type: string;
};

type MarketingPreferencesRemote = {
  email_consent: boolean;
  phone_consent: boolean;
};

type AddressRemoteV3 = {
  flat_no: string;
  house_no: string;
  house_name: string;
  line1: string;
  line2: string;
  road: string;
  town: string;
  county: string;
  country: string;
  postcode: string;
};

export type CreateCustomersRequestV3 = {
  user: {
    username: string;
  };
  personal_details: PersonalDetailsRemote;
  address: AddressRemoteV3;
  driving_licence: DrivingLicenceRemoteV3;
  marketing_preferences: MarketingPreferencesRemote;
};

export type UpdateCustomersRequestV3 = {
  personal_details: {
    phone_number: string;
    occupation_type: string;
    occupation: string;
  };
  address: AddressRemoteV3;
};

export type CustomerResponseV3 = {
  email: string;
  title: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_of_birth: string;
  age: number;
  occupation: string;
  occupation_type: string;
  phone_number: string;
  postcode: string;
  licence_number: string;
  licence_type: string;
  email_consent: boolean;
  phone_consent: boolean;
  can_edit: boolean;
  cassie_licence_type: string;
  full_provisional_confirmed: boolean | null;
  full_provisional_date_confirmed: string | null;
  addresses: Array<AddressRemoteV3>;
};

export interface CustomerResponseV3Additional extends CustomerResponseV3 {
  has_conversion_bonus: boolean;
  crossale_discount_factor: number | null;
}

export type CustomerV3LicenceData = {
  licence_number: string;
  licence_type: string;
  cassie_licence_type: string;
  full_provisional_confirmed: boolean | null;
  full_provisional_date_confirmed: string | null;
  has_conversion_bonus: boolean;
  crossale_discount_factor: number | null;
};

export const localFieldsToCreateRemoteV3 = (
  data: CustomerCreateLocalV3,
): CreateCustomersRequestV3 => ({
  user: {
    username: data.user.username,
  },
  personal_details: {
    title: data.title,
    first_name: data.firstName,
    last_name: data.lastName,
    phone_number: data.phoneNumber,
    occupation_type: data.occupationType,
    occupation: data.occupation,
    date_of_birth: buildDateForApi(data.birthdate),
  },
  address: {
    flat_no: data.address.flatNumber,
    house_no: data.address.houseNumber,
    house_name: data.address.houseName,
    line1: data.address.line1,
    line2: data.address.line2,
    road: data.address.road,
    town: data.address.town,
    county: data.address.county,
    country: data.address.country,
    postcode: data.address.postcode,
  },
  driving_licence: {
    licence_number: data.licence.number,
    licence_type: licenceTypeMap[data.licence.type],
  },
  marketing_preferences: {
    email_consent: data.contactPreferences.email,
    phone_consent: data.contactPreferences.telephone,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localFieldsToUpdateRemoteV3 = (data: any): UpdateCustomersRequestV3 => ({
  personal_details: {
    phone_number: data.phoneNumber,
    occupation_type: data.occupationType,
    occupation: data.occupation,
  },
  address: {
    flat_no: data.address.flatNumber,
    house_no: data.address.houseNumber,
    house_name: data.address.houseName,
    line1: data.address.line1,
    line2: data.address.line2,
    road: data.address.road,
    town: data.address.town,
    county: data.address.county,
    country: data.address.country,
    postcode: data.address.postcode,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const remoteToLocalLicenceFields = (data: CustomerV3LicenceData): any => ({
  licence: {
    cassieType: data.cassie_licence_type || '',
    fullLicenceConfirmed: data.full_provisional_confirmed || null,
    fullLicenceDate: data.full_provisional_date_confirmed
      ? dayjs(data.full_provisional_date_confirmed)
      : null,
    type: data.licence_type,
  },
  crossaleDiscountFactor: data.crossale_discount_factor,
  hasConversionBonus: data.has_conversion_bonus,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const remoteToLocalFields = (data: CustomerResponseV3Additional, state: any): any => ({
  email: data.email,
  title: data.title.toLowerCase(),
  firstName: data.first_name,
  lastName: data.last_name,
  birthdate: dayjs(data.date_of_birth),
  age: data.age,
  occupation: data.occupation,
  occupationType: data.occupation_type,
  phoneNumber: data.phone_number,
  postcode: data.postcode,
  licence: {
    ...state.licence,
    number: data.licence_number,
    type: remoteToLocalLicenceTypeMap[data.licence_type],
    fullLicenceConfirmed: data.full_provisional_confirmed || null,
    fullLicenceDate: data.full_provisional_date_confirmed
      ? dayjs(data.full_provisional_date_confirmed)
      : null,
  },
  contactPreferences: {
    email: data.email_consent,
    telephone: data.phone_consent,
  },
  canEdit: data.can_edit,
  hasConversionBonus: data.has_conversion_bonus,
  crossaleDiscountFactor: data.crossale_discount_factor,
});

export const remoteToLocalAddress = (data: AddressRemoteV3): AddressLocal => ({
  flatNumber: data.flat_no,
  houseNumber: data.house_no,
  houseName: data.house_name,
  line1: data.line1,
  line2: data.line2,
  road: data.road,
  town: data.town,
  county: data.county,
  country: data.country,
  postcode: data.postcode,
});
