import { combineReducers } from 'redux';

import address from './address';
import connections from './connections';
import customer from './customer';
import login from './login';
import register from './register';

const AccountReducer = combineReducers({
  address,
  connections,
  customer,
  login,
  register,
});

export default AccountReducer;

export interface AccountState extends ReturnType<typeof AccountReducer> {}
