import { SetStateFieldActionCreator } from '@utils/types';
import { PaymentMethod, StripeError } from '@stripe/stripe-js';
import { Payment, BillingAddress } from './paymentTypes';
import { PurchaseQuoteData } from './purchasedQuoteTypes';

export enum types {
  SET_PAYMENT_FIELD = 'SET_PAYMENT_FIELD',
  PAYMENT_REQUEST_STRIPE_PSD2 = 'PAYMENT.REQUEST_STRIPE_PSD2',
  PAYMENT_REQUEST_STRIPE_POLL = 'PAYMENT.REQUEST_STRIPE_POLL',
  PAYMENT_REQUEST_STRIPE_SUBSCRIPTION = 'PAYMENT.REQUEST_STRIPE_SUBSCRIPTION',
  PAYMENT_SUCCESS = 'PAYMENT.SUCCESS',
  PAYMENT_FAILURE = 'PAYMENT.FAILURE',
  PAYMENT_STRIPE_ERROR = 'PAYMENT.STRIPE.ERROR',
  SET_BILLING_ADDRESS_FIELDS = 'SET_BILLING_ADDRESS_FIELDS',
  SET_REUSE_DRIVER_ADDRESS = 'SET_REUSE_DRIVER_ADDRESS',
  SET_PAPERLESS_DELIVERY = 'SET_PAPERLESS_DELIVERY',
  SET_DELIVERY_PREFERENCE = 'SET_DELIVERY_PREFERENCE',
  SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS',
  DOCUMENT_DELIVERY_PREFERENCES_SET = 'DOCUMENT_DELIVERY_PREFERENCES_SET',
  RESET_PAYMENT_ERROR = 'RESET_PAYMENT_ERROR',
  PAYMENT_REQUEST_FINAL_CHECKS = 'PAYMENT.REQUEST_FINAL_CHECKS',
  PAYMENT_FINAL_CHECKS_SUCCESS = 'PAYMENT.FINAL_CHECKS_SUCCESS',
  PAYMENT_FINAL_CHECKS_FAILED = 'PAYMENT.FINAL_CHECKS_FAILED',
}

export type SetPaymentField = ReturnType<typeof setPaymentField>;

export interface SetBillingAddressFields {
  type: typeof types.SET_BILLING_ADDRESS_FIELDS;
  data: BillingAddress;
}

export interface SetReuseDriverAddress {
  type: typeof types.SET_REUSE_DRIVER_ADDRESS;
  value: boolean;
}

export interface SetPaperlessDelivery {
  type: typeof types.SET_PAPERLESS_DELIVERY;
  value: boolean;
}

export interface SetDeliveryPreference {
  type: typeof types.SET_DELIVERY_PREFERENCE;
  value: string;
}

export interface PaymentSuccess {
  type: typeof types.PAYMENT_SUCCESS;
  data: PurchaseQuoteData;
}

export interface PayFailure {
  type: typeof types.PAYMENT_FAILURE;
  error: string;
  errorCode: string | null;
  declinedCode: string | null;
}

export interface HandleStripeError {
  type: typeof types.PAYMENT_STRIPE_ERROR;
  error: StripeError;
}

export interface PollForQuoteStatus {
  type: typeof types.PAYMENT_REQUEST_STRIPE_POLL;
}

export interface CompleteFinalCheck {
  type: typeof types.PAYMENT_REQUEST_FINAL_CHECKS;
  paymentMethod: PaymentMethod;
}

export interface FinalCheckPassed {
  type: typeof types.PAYMENT_FINAL_CHECKS_SUCCESS;
}

export interface FinalChecksFailed {
  type: typeof types.PAYMENT_FINAL_CHECKS_FAILED;
}

export interface TermsAndConditionsAccepted {
  type: typeof types.SET_TERMS_AND_CONDITIONS;
}

export interface DocumentDeliveryPreferencesSet {
  type: typeof types.DOCUMENT_DELIVERY_PREFERENCES_SET;
  payload: Payment;
}

export interface ResetPaymentError {
  type: typeof types.RESET_PAYMENT_ERROR;
}

export interface PaymentRequestStripeSubscription {
  type: typeof types.PAYMENT_REQUEST_STRIPE_SUBSCRIPTION;
}

export type PaymentActions =
  | SetPaymentField
  | SetBillingAddressFields
  | SetReuseDriverAddress
  | SetPaperlessDelivery
  | SetDeliveryPreference
  | PaymentSuccess
  | PayFailure
  | HandleStripeError
  | PollForQuoteStatus
  | CompleteFinalCheck
  | FinalCheckPassed
  | FinalChecksFailed
  | TermsAndConditionsAccepted
  | DocumentDeliveryPreferencesSet
  | ResetPaymentError
  | PaymentRequestStripeSubscription;

// Actions
export const setPaymentField: SetStateFieldActionCreator<
  Payment,
  typeof types.SET_PAYMENT_FIELD
> = (field, value) => ({
  type: types.SET_PAYMENT_FIELD,
  field,
  value,
});

export const setBillingAddressFields = (data: BillingAddress): SetBillingAddressFields => ({
  type: types.SET_BILLING_ADDRESS_FIELDS,
  data,
});

export const setReuseDriverAddress = (value: boolean): SetReuseDriverAddress => ({
  type: types.SET_REUSE_DRIVER_ADDRESS,
  value,
});

export const setPaperlessDelivery = (value: boolean): SetPaperlessDelivery => ({
  type: types.SET_PAPERLESS_DELIVERY,
  value,
});

export const setDeliveryPreference = (value: string): SetDeliveryPreference => ({
  type: types.SET_DELIVERY_PREFERENCE,
  value,
});

export const paymentSuccess = (data: PurchaseQuoteData): PaymentSuccess => ({
  type: types.PAYMENT_SUCCESS,
  data,
});

export const payFailure = (error: string, errorCode: string, declinedCode: string): PayFailure => ({
  type: types.PAYMENT_FAILURE,
  error: error || 'Your payment failed',
  errorCode,
  declinedCode,
});

export const stripeError = (error: StripeError): HandleStripeError => ({
  type: types.PAYMENT_STRIPE_ERROR,
  error,
});

export const pollForQuoteStatus = (): PollForQuoteStatus => ({
  type: types.PAYMENT_REQUEST_STRIPE_POLL,
});

export const completeFinalCheck = (paymentMethod: PaymentMethod): CompleteFinalCheck => ({
  type: types.PAYMENT_REQUEST_FINAL_CHECKS,
  paymentMethod,
});

export const finalChecksPassed = (): FinalCheckPassed => ({
  type: types.PAYMENT_FINAL_CHECKS_SUCCESS,
});

export const finalChecksFailed = (): FinalChecksFailed => ({
  type: types.PAYMENT_FINAL_CHECKS_FAILED,
});

export const termsAndConditionsAccepted = (): TermsAndConditionsAccepted => ({
  type: types.SET_TERMS_AND_CONDITIONS,
});

export const documentDeliveryPreferencesSet = (
  payload: Payment,
): DocumentDeliveryPreferencesSet => ({
  type: types.DOCUMENT_DELIVERY_PREFERENCES_SET,
  payload,
});

export const resetPaymentError = (): ResetPaymentError => ({
  type: types.RESET_PAYMENT_ERROR,
});

export const createSubscriptionPayment = (): PaymentRequestStripeSubscription => ({
  type: types.PAYMENT_REQUEST_STRIPE_SUBSCRIPTION,
});
