import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

const calculateSubscriptionEndDate = (): Dayjs => {
  const start = dayjs().tz('Europe/London');
  const startDay = start.date();
  const numDaysNextMonth = dayjs().add(1, 'M').daysInMonth();
  const startPlusOneMonth = start.add(1, 'M').hour(23).minute(59).second(59);

  if (startDay > numDaysNextMonth) {
    return startPlusOneMonth.date(numDaysNextMonth);
  }

  return startPlusOneMonth.subtract(1, 'd');
};

export default calculateSubscriptionEndDate;
