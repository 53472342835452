/* eslint-disable camelcase */
import { UnknownAction } from 'redux';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

// Types

export enum ActionTypes {
  IMMEDIATE_START = 'IMMEDIATE_START',
  RESET_DURATION = 'RESET_DURATION',
  SET_DURATION_START = 'SET_DURATION_START',
  SET_DURATION_END = 'SET_DURATION_END',
  SAVE_QUOTE_SUCCESS = 'SAVE_QUOTE.SUCCESS',
}

export interface ImmediateStartAction {
  type: ActionTypes.IMMEDIATE_START;
  value: boolean;
}

export interface ResetDurationAction extends UnknownAction {
  type: ActionTypes.RESET_DURATION;
}

export interface SetDurationStartAction {
  type: ActionTypes.SET_DURATION_START;
  value: Dayjs | null;
}

export interface SetDurationEndAction {
  type: ActionTypes.SET_DURATION_END;

  value: Dayjs | null;
}

export interface SaveQuoteSuccess {
  type: ActionTypes.SAVE_QUOTE_SUCCESS;
  data: {
    duration_start: Dayjs;
    duration_end: Dayjs;
  };
}

// Actions
export const isStartImmediate = (value: boolean): ImmediateStartAction => ({
  type: ActionTypes.IMMEDIATE_START,
  value,
});

export const resetDuration = (): ResetDurationAction => ({
  type: ActionTypes.RESET_DURATION,
});

export const setDurationStart = (value: Dayjs | null): SetDurationStartAction => ({
  type: ActionTypes.SET_DURATION_START,
  value: value ? value.clone() : null,
});

export const setDurationEnd = (value: Dayjs | null): SetDurationEndAction => ({
  type: ActionTypes.SET_DURATION_END,
  value: value ? value.clone() : null,
});

export type DurationAction =
  | ImmediateStartAction
  | ResetDurationAction
  | SetDurationStartAction
  | SetDurationEndAction
  | SaveQuoteSuccess;
