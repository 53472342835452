import { ownerDetails, aboutYou } from './actions';

interface CompletedPages {
  carDetails: boolean;
  drivingHistory: boolean;
}

export type PagesState = Readonly<{
  error: null | string;
  completed: CompletedPages;
}>;

export const MARK_PAGE_AS_RESET_SUCCESS = 'PAGES.RESET.SUCCESS';
export const MARK_PAGE_AS_COMPLETE_SUCCESS = 'PAGES.COMPLETED.SUCCESS';
export const MARK_PAGE_AS_COMPLETE_FAILURE = 'PAGES.COMPLETED.FAILURE';

export interface MarkPageAsComplete {
  type: typeof MARK_PAGE_AS_COMPLETE_SUCCESS;
  pageName: string;
}

export interface MarkPageAsCompleteFailure {
  type: typeof MARK_PAGE_AS_COMPLETE_FAILURE;
  pageName: string;
  error: string;
}

export interface MarkPageAsReset {
  type: typeof MARK_PAGE_AS_RESET_SUCCESS;
  pageName: string;
}

export interface OwnerDetailsPageSubmitted {
  type: typeof ownerDetails.SUBMITTED;
  payload: ownerDetailsPayload;
}

export interface AboutYouPageSubmitted {
  type: typeof aboutYou.SUBMITTED;
  payload: aboutYouPayload;
}

export type ownerDetailsPayload = {
  firstName: string;
  surname: string;
  birthdate: Date;
  relationship: 'pa' | 'sp' | 'fr' | 'co' | 'fa' | 'ot';
  save: boolean;
};

export type aboutYouPayload = {
  firstName: string;
  middleNames: string;
  lastName: string;
  title: 'mr' | 'miss' | 'mrs' | 'ms';
  occupationType: string;
  occupation: string;
  phoneNumber: string;
  email: string;
  birthdate: Date;
  address: {
    isManual: boolean;
    county: string;
    country: string;
    flatNumber: string;
    houseName: string;
    houseNumber: string;
    line1: string;
    line2: string;
    postcode: string;
    road: string;
    town: string;
    lookupId?: string;
    key?: string;
  };
  source: string;
};

export type PageActions =
  | MarkPageAsComplete
  | MarkPageAsCompleteFailure
  | MarkPageAsReset
  | OwnerDetailsPageSubmitted
  | AboutYouPageSubmitted;
