import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import referralReducer from '@redux/features/referral/referralSlice';
import { quoteApi } from '@services/api/quoteRtk';
import { history } from '../../router';
import account from './account';
import analytics from './analytics';
import app from './app';
import config from './config';
import lookup from './lookup';
import quote from './quote';
import pages from './pages';

const rootReducer = (previousHistory: History) =>
  combineReducers({
    router: connectRouter(previousHistory),
    account,
    analytics,
    app,
    config,
    lookup,
    quote,
    form: formReducer,
    pages,
    referral: referralReducer,
    [quoteApi.reducerPath]: quoteApi.reducer,
  });

export default rootReducer(history);

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;
