export const csiExcessData = [
  {
    title: 'Compulsory Excess',
    value: '£750',
  },
  {
    title: 'Windscreen replacement excess',
    value: '£115',
  },
  {
    title: 'Windscreen repair excess',
    value: '£25',
  },
];

export const ldpExcessData = [
  {
    title: 'Compulsory Excess',
    value: '£250',
  },
  {
    title: 'Windscreen replacement excess',
    value: '£115',
  },
  {
    title: 'Windscreen repair excess',
    value: '£25',
  },
];

export const tempcoverExcessData = [
  {
    title: 'Compulsory Excess',
    value: '£750',
  },
  {
    title: 'Windscreen replacement excess',
    value: '£115',
  },
  {
    title: 'Windscreen repair excess',
    value: '£25',
  },
];

export const newDriverExcessData = [
  {
    title: 'Compulsory Excess',
    value: '£750',
  },
  {
    title: 'Windscreen replacement excess',
    value: '£115',
  },
  {
    title: 'Windscreen repair excess',
    value: '£25',
  },
];

export const csiConfirmations = [
  {
    key: 'confirmExcesses',
    value: 'I understand the information about the policy excesses above.',
  },
  {
    key: 'confirmSDC',
    value:
      'I understand this cover is only valid for social, domestic and commuting use.\
   I confirm that I will not use the vehicle for anything other than this.',
  },
  {
    key: 'confirmImpound',
    value: 'I will not use the insurance to release a vehicle from an impound.',
  },
  {
    key: 'confirmNonRental',
    value:
      'I confirm the vehicle is not a hire or rental vehicle and it is owned, leased or contracted to a private individual.',
  },
];

export const ldpConfirmations = [
  {
    key: 'confirmExcesses',
    value: 'I understand the information about the policy excesses above.',
  },
  {
    key: 'confirmB2C',
    value:
      'I understand this cover is only valid for social, domestic and commuting use.\
   I confirm that I will not use the vehicle for anything other than this and I will not use the insurance to release a vehicle from an impound.',
  },
  {
    key: 'confirmNonRental',
    value:
      'I confirm the vehicle is not a hire or rental vehicle and it is owned, leased or contracted to a private individual.',
  },
];

export const subsConfirmations = [
  {
    key: 'confirmExcesses',
    value: 'I understand the information about the policy excesses above.',
  },
  {
    key: 'confirmSDC',
    value:
      'I understand this cover is only valid for social, domestic and commuting use.\
   I confirm that I will not use the vehicle for anything other than this.',
  },
  {
    key: 'confirmImpound',
    value: 'I will not use the insurance to release a vehicle from an impound.',
  },
  {
    key: 'confirmNonRental',
    value:
      'I confirm the vehicle is not a hire or rental vehicle and it is owned, leased or contracted to a private individual.',
  },
  {
    key: 'confirmMonthlyPayment',
    value:
      'I understand that if I don\'t make my payment by the due date then I will no longer be insured.',
  },
];
