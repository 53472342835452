import deepUpdate from '@services/deepUpdate';
import { types as newdrivertypes, NewDriverActions } from './newdriver.actions';
import { types as quoteTypes, QuoteActions } from './actions';

export interface NewDriver {
  expectedMileage: string | null;
  overnightLocation: string | null;
  hasAlternateVehicle: string | null;
  typeOfUsage: string | null;
}

export const initialState: NewDriver = {
  expectedMileage: null,
  overnightLocation: null,
  hasAlternateVehicle: null,
  typeOfUsage: null,
};

export type NewDriverActionTypes = NewDriverActions | QuoteActions;

export default function reducer(
  state = initialState,
  action = {} as NewDriverActionTypes,
): NewDriver {
  switch (action.type) {
    case newdrivertypes.SET_NEWDRIVER_FIELD:
      return deepUpdate(state, action.field, action.value);
    case quoteTypes.RESET_QUOTE:
      return initialState;
    default:
      return state;
  }
}
