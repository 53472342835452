import styled, { css } from 'styled-components';
import colors from '@config/colors';
import DownArrow from '@assets/down-arrow-dark.svg';

type IconProps = {
  isOpen: boolean;
};

const arrowIconStyles = (isOpen: boolean) => {
  if (isOpen) {
    return css`
      transform: rotate(180deg);
    `;
  }
  return css``;
};

export const Icon = styled.img.attrs<IconProps>(() => {
  return {
    src: DownArrow,
    alt: 'Click for options',
  };
})<IconProps>`
  ${({ isOpen }) => arrowIconStyles(isOpen)}
`;
export const DropDown = styled.div`
  min-height: 64px;
  border-radius: 8px;
  background-color: ${colors.grey.background.light};
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const DropDownTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
  width: 100%;
  font-family: 'proxima-soft';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  font-color: ${colors.grey.dark};
`;

export const DropDownContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;
