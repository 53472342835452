import { call, put, select, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import { setUserField } from '../reducer/quote/user.actions';
import { getQuoteUUID } from './prefill';

export function* getSeonSessionSaga() {
  // eslint-disable-next-line no-undef
  if (typeof getSeonSession === 'function') {
    const quoteUuid = yield select(getQuoteUUID) || uuidv4();

    // eslint-disable-next-line no-undef
    const session = yield call(getSeonSession, quoteUuid);

    if (session !== '' && session !== null && session !== undefined) {
      yield put(setUserField('seonsession', session));
    }
  }
}

export function* watchRouting() {
  yield takeEvery(LOCATION_CHANGE, getSeonSessionSaga);
}
