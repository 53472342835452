/* eslint-disable */

import dayjs from 'dayjs';
import factory from './factory';
import { validationTests } from '../formValidation';


const buildDate = dateObject => {
  if (!dateObject) return null;
  return dateObject.format('YYYY-MM-DD');
};

const reverse = input => ({
  car: {
    owner: {
      birthdate: input.car.owner.date_of_birth ? dayjs(input.car.owner.date_of_birth) : null,
    },
  },
});

const direct = input => ({
  car: {
    owner: {
      date_of_birth: buildDate(input.car.owner.birthdate),
      phone_number: validationTests.phonenumber(input.car.owner.phoneNumber) ? input.car.owner.phoneNumber : '',
      email: validationTests.email(input.car.owner.email) ? input.car.owner.email : '',
    },
  },
});

export default factory({
  'uuid': 'uuid',
  'car.uuid': 'car.uuid',
  'car.owner.uuid': 'car.owner.uuid',
  'car.owner.birthdate': 'car.owner.date_of_birth',
  'car.owner.email': 'car.owner.email',
  'car.owner.firstName': 'car.owner.first_name',
  'car.owner.lastName': 'car.owner.last_name',
  'car.owner.occupation': 'car.owner.occupation',
  'car.owner.occupationType': 'car.owner.occupation_type',
  'car.owner.phoneNumber': 'car.owner.phone_number',
  'car.owner.postcode': 'car.owner.postcode',
  'car.abiCode': 'car.abi_code',
  'car.engineSize': 'car.engine_size',
  'car.make': 'car.make',
  'car.model': 'car.model',
  'car.registrationNumber': 'car.registration_number',
  'car.transmission': 'car.transmission',
  'car.year': 'car.year',
  'customerUuid': 'customer',
  'relationship': 'relationship',
  'mainInsurer': 'main_insurer',
}, direct, reverse);
