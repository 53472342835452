import { connect } from 'react-redux';
import getRelationshipText from '@services/getRelationshipText';
import { formatDate } from '@services/date';
import OwnerDetailsCard from './OwnerDetailsCard.component';

export interface StateProps {
  firstName: string;
  surname: string;
  ownerEmail: string;
  ownerRelationship: string;
  ownerBirthdate: string;
  isMobile: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): StateProps => ({
  firstName: state.quote.owner.firstName,
  surname: state.quote.owner.surname,
  ownerEmail: state.quote.owner.email,
  ownerRelationship: getRelationshipText(state.quote.owner.relationship, null),
  ownerBirthdate: formatDate(state.quote.owner.birthdate),
  isMobile: state.app.isMobile,
});

export default connect(mapStateToProps)(OwnerDetailsCard);
