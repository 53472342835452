import React from 'react';
import { Link } from 'react-router-dom';
import { CardModal } from '@rentecarlo/component-library';
import PillButton from '@atoms/buttons/PillButton';
import PortalImage from '@assets/portal-exit.svg';
import { ButtonContainer, ImageContainer, Image, Title, Label } from './styles';

type Props = {
  visible: boolean;
  path: string;
  onClose: () => void;
};

const ClearDataModal: React.FC<Props> = ({ path, visible, onClose }) => {
  return (
    <CardModal visible={visible} close={onClose} maxWidth='320px'>
      <>
        <ImageContainer>
          <Image src={PortalImage} />
        </ImageContainer>
        <Title id='quote-title-clearDataModal'>Leaving so soon...</Title>
        <Label id='quote-text-clearDataModal'>
          You're about to leave this section. Leaving now might wipe some of your answers and your
          details won't be saved.
        </Label>
        <ButtonContainer>
          <Link to={path}>
            <PillButton
              id='quote-button-clearDataModalLeave'
              width='100%'
              background='rgba(246, 123, 160, 0.3)'
              textColor='#DB347F'
              onClick={onClose}
            >
              Wipe answers and leave
            </PillButton>
          </Link>
        </ButtonContainer>
        <ButtonContainer>
          <PillButton id='quote-button-clearDataModalStay' width='100%' onClick={onClose}>
            Close one! Stay on this page
          </PillButton>
        </ButtonContainer>
      </>
    </CardModal>
  );
};

export default ClearDataModal;
