import React from 'react';
import { SelectableOptionBox } from '@rentecarlo/component-library';

import {
  getCurrentPrice,
  getPriceDifference,
  appendDummyExcessIfRequired,
} from '@services/variableExcess';

const VariableExcessOptions = ({
  excessSelected,
  finalPricesByExcess,
  showUnavailableExcess,
  setVariableExcess,
}) => {
  const currentPrice = getCurrentPrice(excessSelected, finalPricesByExcess);
  const setFinalPricesByExcess = appendDummyExcessIfRequired(
    finalPricesByExcess,
    showUnavailableExcess,
  );

  return (
    <>
      {setFinalPricesByExcess.map(({ price, excess }) => (
        <SelectableOptionBox
          key={excess}
          onClick={() => (price ? setVariableExcess(excess) : null)}
          label={`£${excess}`}
          selected={excessSelected === excess}
          infoText={getPriceDifference(price, currentPrice, excessSelected === excess)}
          enabled={price !== null}
        />
      ))}
    </>
  );
};

export default VariableExcessOptions;
