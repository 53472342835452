import deepUpdate from '@services/deepUpdate';
import { types as ancillarytypes, AncillaryActions } from './ancillary.actions';
import { types as quoteTypes, QuoteActions } from './actions';

export interface Ancillary {
  breakdownCover: boolean;
  initialPriceWithBreakdownCover: string | null;
}

export const initialState: Ancillary = {
  breakdownCover: false,
  initialPriceWithBreakdownCover: null,
};

export type AncillaryActionTypes = AncillaryActions | QuoteActions;

export default function ancillary(
  state = initialState,
  action = {} as AncillaryActionTypes,
): Ancillary {
  switch (action.type) {
    case ancillarytypes.SET_ANCILLARY_FIELD:
      return deepUpdate(state, action.field, action.value);
    case quoteTypes.RESET_QUOTE:
      return initialState;
    case ancillarytypes.UPDATE_INITIAL_PRICE_WITH_BREAKDOWN_COVER:
      return {
        ...state,
        initialPriceWithBreakdownCover: action.price,
      };
    default:
      return state;
  }
}
