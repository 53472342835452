import styled from 'styled-components';

import colors from '@config/colors';

export const Container = styled.li`
  color: ${colors.outerSpace};
  cursor: pointer;
  display: flex;
  font-family: 'proxima-soft';
  font-size: 16px;
  padding: 16px 0px;
  list-style-type: none;
  width: 100%;
  padding: 0px;
`;

export const QuestionType = styled.div`
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 4px;
  padding-top: 32px;
  font-size: 16px;
`;

export const QuestionDescription = styled.div`
  font-weight: normal;
  line-height: 24px;
  text-transform: initial;
  padding-bottom: 32px;
  font-size: 16px;
`;

export const TickboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div<{ applyTransparency: boolean }>`
  opacity: ${({ applyTransparency }): string => (applyTransparency ? '0.4' : '1.0')};
  margin-right: 16px;
  flex-grow: 1;
  border-bottom: 1px solid ${colors.grey.lines.light};
  min-height: 124px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
