import React from 'react';
import VipVeypal from '@assets/vip-veypal.svg';
import QuoteStep from '@templates/QuoteStep';
import PrivacyPolicyInfo from '@atoms/PrivacyPolicyInfo';
import { useOptimizelyFlag } from '@hooks';
import { Container, Image, Text } from './styles';

interface Props {
  firstName: string;
  licenceType: string;
  productType: string;
  submission: (productType: string) => void;
}
const LoggedInStart: React.FC<Props> = ({ firstName, licenceType, productType, submission }) => {
  const fastRepurchaseEnabled = useOptimizelyFlag('TEMP_WEB_FAST_REPURCHASE').enabled;

  const handleLoggedInSubmission = (): void => {
    if (!fastRepurchaseEnabled) {
      sessionStorage.setItem('quoteJourneyStart', Date.now().toString());
    }
    submission(productType);
  };
  return (
    <QuoteStep
      id='start-component-quoteStepLoggedIn'
      stepTitle={`Welcome back ${firstName}!`}
      nextAction={() => handleLoggedInSubmission()}
      showButton
      nextLabel='Next'
      submitError={null}
    >
      <Container>
        <Image src={VipVeypal} />
      </Container>
      <Text id='start-text-remembered'>
        To help speed up your quote, we've remembered your personal info from last time.
      </Text>
      {productType === 'newdriver' && licenceType === 'uk_prov' && (
        <Text id='start-text-eligibility'>
          To be eligible for New Driver insurance we will need to check that you have passed your
          driving test. You don't have to do anything, just click the next button below.
        </Text>
      )}
      <PrivacyPolicyInfo />
    </QuoteStep>
  );
};

export default LoggedInStart;
