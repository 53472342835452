import { put, select, takeEvery } from 'redux-saga/effects';
import { replace, LOCATION_CHANGE } from 'connected-react-router';
import getPages from '@services/pageOrderValidation';
import { RootState } from '@redux/reducer';

type Action = {
  payload: { location: { pathname: string } };
};

function* handleLocationChange({
  payload: {
    location: { pathname },
  },
}: Action): Generator {
  const state: RootState = (yield select()) as RootState;

  const pages = getPages(state);
  if (pages.findIndex((value) => value.url === pathname) >= 0) {
    let index = 0;

    while (index < pages.length) {
      if (pathname === pages[index].url) {
        return;
      }

      if (!pages[index].hasRequiredData) {
        const { url } = pages[index];
        yield put(replace(url));
        return;
      }

      index += 1;
    }
  }
}

export default function* pageOrderSaga(): Generator {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
}
