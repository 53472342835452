import React, { FC } from 'react';
import styled from 'styled-components';
import { Card } from '@rentecarlo/component-library';
import colors from '@config/colors';
import { ComponentType, useComponentId } from '@hooks';

import DetailedDln from '@assets/dln-detailed-teal.svg';
import Car from '@assets/car-detailed.svg';
import CarOwner from '@assets/car-with-owner.svg';

const WhatYouNeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'proxima-soft';
  font-weight: bold;
`;
const Image = styled.img`
  padding-right: 16px;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${colors.grey.lines.light};
  margin: 0;
  align-self: center;
`;

const CardItem: FC<{ imageSrc: string; text: string }> = ({ imageSrc, text }) => {
  const creator = useComponentId();
  return (
    <Card padding='16px' radius={8}>
      <Content id={creator(ComponentType.CONTAINER, `whatYouNeedItem${imageSrc}`)}>
        <Image src={imageSrc} />
        {text}
      </Content>
    </Card>
  );
};

const WhatYouNeed: FC = () => {
  const creator = useComponentId();
  return (
    <WhatYouNeedContainer id={creator(ComponentType.COMPONENT, 'whatsNeededForQuote')}>
      <CardItem imageSrc={DetailedDln} text='Driving licence' />
      <VerticalLine />
      <CardItem imageSrc={Car} text='Car registration' />
      <VerticalLine />
      <CardItem imageSrc={CarOwner} text='Car owner details' />
    </WhatYouNeedContainer>
  );
};

export default WhatYouNeed;
