import HttpClient from './httpClient';

import { customerPreferredCar as customerPreferredCarTransformer } from '../transformers';

const customerPreferredCar = {
  list() {
    return new HttpClient.Builder().get(`/network/customer-preferred-car/`).execute();
  },

  get(uuid) {
    return new HttpClient.Builder().get(`/network/customer-preferred-car/${uuid}/`).execute();
  },

  update(uuid, data) {
    return new HttpClient.Builder()
      .put(`/network/customer-preferred-car/${uuid}/`)
      .data(customerPreferredCarTransformer.reduxToAPI(data))
      .execute();
  },

  delete(uuid) {
    return new HttpClient.Builder().delete(`/network/customer-preferred-car/${uuid}/`).execute();
  },
};

export default customerPreferredCar;
