/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '@config/colors';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/London');

const DateFieldContainer = styled.div`
  display: flex;
  flex: 1;
  height: 65px;
  margin: 0 -4px 16px;
`;

const DateField = styled.input`
  color: ${colors.outerSpace};
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  flex-grow: 1;
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  height: 65px;
  margin: 0 4px 0;
  position: relative;
  text-align: center;
  width: 0;
`;

interface Props {
  date: Dayjs;
  setDate: (date: Dayjs | null) => void;
  onBlur: (date: Dayjs | null) => void;
}

interface StringDict {
  [key: string]: string;
}

type DayMonthYearType = 'day' | 'month' | 'year';

const DateInput: React.FC<Props> = ({ date, setDate, onBlur }) => {
  const [day, setDay] = useState(isDayjs(date) ? date.format('DD') : '');
  const [month, setMonth] = useState(isDayjs(date) ? date.format('MM') : '');
  const [year, setYear] = useState(isDayjs(date) ? date.format('YYYY') : '');

  const changeDate = ({ day, month, year }: StringDict) => {
    const correctLength = day.length + month.length + year.length === 8;
    const dateAsDayjs = dayjs.utc(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    if (correctLength && dateAsDayjs.isValid()) {
      setDate(dateAsDayjs);
      onBlur(dateAsDayjs);
    } else {
      setDate(null);
      onBlur(null);
    }
  };
  const onChange = (field: DayMonthYearType, value: string): void => {
    const validateRegex = field === 'year' ? /^[0-9]{0,4}$/ : /^[0-9]{0,2}$/;
    if (validateRegex.test(value)) {
      switch (field) {
        case 'day':
          setDay(value);
          changeDate({ day: value, month, year });
          break;
        case 'month':
          setMonth(value);
          changeDate({ day, month: value, year });
          break;
        case 'year':
          setYear(value);
          changeDate({ day, month, year: value });
          break;
        default:
          break;
      }
    }
  };

  return (
    <DateFieldContainer>
      <DateField
        id='dayField'
        placeholder='DD'
        value={day}
        onChange={(event) => onChange('day', event.target.value)}
        type='text'
      />
      <DateField
        id='monthField'
        placeholder='MM'
        value={month}
        onChange={(event) => onChange('month', event.target.value)}
        type='text'
      />
      <DateField
        id='yearField'
        placeholder='YYYY'
        value={year}
        onChange={(event) => onChange('year', event.target.value)}
        type='text'
      />
    </DateFieldContainer>
  );
};

export default DateInput;
