/* eslint-disable camelcase */
import HttpClient from './httpClient';

function lookupLicenceType(licence_number) {
  return new HttpClient.Builder()
    .post('/accnt/lookup-licence-type')
    .data({ licence_number })
    .execute();
}

export default lookupLicenceType;
