import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const SET_HAS_PASSED = 'SET_HAS_PASSED';
export const SET_DRIVING_TEST_DATE = 'SET_DRIVING_TEST_DATE';

export interface SetHasPassed {
  type: typeof SET_HAS_PASSED;
  hasPassed: boolean;
}

export interface SetDrivingTestDate {
  type: typeof SET_DRIVING_TEST_DATE;
  drivingTestDate: Dayjs | null;
}

export type DrivingTestActions = SetHasPassed | SetDrivingTestDate;

export const setHasPassed = (hasPassed: boolean): SetHasPassed => ({
  type: SET_HAS_PASSED,
  hasPassed,
});

export const setDrivingTestDate = (drivingTestDate: Dayjs | null): SetDrivingTestDate => ({
  type: SET_DRIVING_TEST_DATE,
  drivingTestDate,
});
