export enum types {
  RESET_QUOTE = 'RESET_QUOTE',
  START_QUOTE = 'START_QUOTE',
  USER_RESET_QUOTE = 'USER_RESET_QUOTE',
  QUOTE_FROM_CONNECTION = 'QUOTE_FROM_CONNECTION',
  SET_POSTCODE = 'SET_DRIVER_ADDRESS_FIELDS',
  PARTIAL_UPDATE_SUCCESS = 'QUOTE_PARTIAL_UPDATE.SUCCESS',
  PARTIAL_UPDATE_FAILURE = 'QUOTE_PARTIAL_UPDATE.FAILURE',
}

export interface ResetQuote {
  type: typeof types.RESET_QUOTE;
}

export interface UserResetQuote {
  type: typeof types.USER_RESET_QUOTE;
}

export interface PartialUpdateSuccess {
  type: typeof types.PARTIAL_UPDATE_SUCCESS;
}

export interface PartialUpdateFailure {
  type: typeof types.PARTIAL_UPDATE_FAILURE;
}

export interface StartQuote {
  type: typeof types.START_QUOTE;
}

export type QuoteActions =
  | ResetQuote
  | UserResetQuote
  | PartialUpdateSuccess
  | PartialUpdateFailure
  | StartQuote;

export const resetQuote = (): ResetQuote => ({
  type: types.RESET_QUOTE,
});

export const userResetQuote = (): UserResetQuote => ({
  type: types.USER_RESET_QUOTE,
});

export const partialUpdateSuccess = (): PartialUpdateSuccess => ({
  type: types.PARTIAL_UPDATE_SUCCESS,
});

export const partialUpdateFailure = (): PartialUpdateFailure => ({
  type: types.PARTIAL_UPDATE_FAILURE,
});
