import styled from 'styled-components';
import colors from '@config/colors';

export const CarValueWrapper = styled.div`
  margin-top: 32px;
`;

export const Divider = styled.div`
  background-color: ${colors.grey.lines.light};
  height: 1px;
  margin: 24px 0px 20px 0px;
`;

export const List = styled.ul`
  list-style-type: disc;
  padding-left: 32px;
  font-size: 14px;
`;
