import {
  FETCH_CONNECTIONS_REQUEST,
  FETCH_CONNECTIONS_SUCCESS,
  FETCH_CONNECTIONS_FAILURE,
  PreferredCar,
  ConnectionActionTypes,
} from './connections.actions';
import { types as loginTypes, LoginActiontypes } from './login.actions';
import customerPreferredCarTransformer from '../../../services/transformers/customerPreferredCar';

export interface ConnectionState {
  loading?: boolean;
  editingPhoneNumber: string;
  editingEmail: string;
  editingUuid: string;
  error: Error | null;
  preferredCars: PreferredCar[];
}

// Reducer
export const initialState: ConnectionState = {
  error: null,
  loading: false,
  editingPhoneNumber: '',
  editingEmail: '',
  editingUuid: '',
  preferredCars: [],
};

const getAllPreferredCars = (data: PreferredCar[]) =>
  data.map((cpc) => customerPreferredCarTransformer.apiToRedux(cpc));

export default function connectionsReducer(
  state = initialState,
  action: ConnectionActionTypes | LoginActiontypes,
): ConnectionState {
  switch (action.type) {
    case FETCH_CONNECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONNECTIONS_SUCCESS:
      return {
        ...state,
        preferredCars: getAllPreferredCars(action.data),
        loading: false,
      };
    case FETCH_CONNECTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case loginTypes.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
