import React from 'react';
import styled from 'styled-components';
import { RoundedGreyBox } from '@rentecarlo/component-library';

import QuoteStep from '@templates/QuoteStep';
import colors from '@config/colors';
import Cake from '@assets/licence-change-cake.svg';

import { ComponentProps } from './UpgradeAccount.container';

const CakeContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PromotionalTextContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`;

const PromotionalText = styled.span`
  color: ${colors.outerSpace};
  font-family: 'gotham-rounded';
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`;

const PromotionalTextBold = styled.span`
  font-weight: bold;
`;

const InfoText = styled.div`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 16px;
  text-align: center;
  line-height: 19px;
`;

const UpgradeAccount: React.FC<ComponentProps> = ({
  hasBoughtBefore,
  continue: continueQuote,
  productType,
}) => {
  return (
    <form>
      <QuoteStep
        id='licenceChangeSuccess-component-quoteStep'
        stepTitle='WOOHOO!'
        stepSubtitle='You did it! Congratulations on passing your driving test. We think that deserves some cake...'
        includeStepPrefix={false}
        nextLabel='UPGRADE MY ACCOUNT'
        nextAction={() => continueQuote()}
        displaySummaryFooter={false}
      >
        <CakeContainer>
          <img src={Cake} alt='congratulations!' />
        </CakeContainer>
        <PromotionalTextContainer id='licenceChangeSuccess-container-promotionalText'>
          {hasBoughtBefore && productType !== 'newdriver' ? (
            <PromotionalText id='licenceChangeSuccess-text-promotionalText'>
              <PromotionalTextBold id='licenceChangeSuccess-text-promotionalTextTenPercent'>
                Get 10% off{' '}
              </PromotionalTextBold>
              your insurance policies for{' '}
              <PromotionalTextBold id='licenceChangeSuccess-text-promotionalTextTwelveMonths'>
                12 months!
              </PromotionalTextBold>
            </PromotionalText>
          ) : (
            <PromotionalText id='licenceChangeSuccess-text-promotionalTextUpgrade'>
              We will now upgrade your account to a Full GB Licence Account
            </PromotionalText>
          )}
        </PromotionalTextContainer>
        {hasBoughtBefore && productType !== 'newdriver' && (
          <RoundedGreyBox padding={16} margin={[0, 0, 60, 0]}>
            <InfoText id='licenceChangeSuccess-text-infoTextDiscount'>
              You will now receive 10% off all insurance policies bought within the next 12 months
              from today.
            </InfoText>
          </RoundedGreyBox>
        )}
      </QuoteStep>
    </form>
  );
};

export default UpgradeAccount;
