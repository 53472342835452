import React from 'react';
import styled, { css } from 'styled-components';
import colors from '@config/colors';

import size from '@config/size';

const Container = styled.div`
  position: fixed;
  display: ${(props) => props.display};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  ${(props) =>
    !props.fullscreenOnDesktop &&
    css`
      @media (min-width: ${props.desktopMinWidth}) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        &:after {
          background-color: ${colors.grey.background.lighter};
          position: absolute;
          right: -80px;
          width: 80px;
          content: '';
          top: 0;
          height: 100%;
        }
        &:before {
          background-color: ${colors.grey.background.lighter};
          position: absolute;
          left: -2px;
          width: 2px;
          content: '';
          top: 0;
          height: 100%;
        }
      }
    `}
`;

const Modal = (props) => {
  const { desktopMinWidth, fullscreenOnDesktop, display, children } = props;
  return (
    <Container
      desktopMinWidth={desktopMinWidth}
      fullscreenOnDesktop={fullscreenOnDesktop}
      display={display}
    >
      {children}
    </Container>
  );
};

Modal.defaultProps = {
  desktopMinWidth: size.desktopWidth,
  fullscreenOnDesktop: true,
  display: '',
};

export default Modal;
