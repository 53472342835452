import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps, Field, formValueSelector } from 'redux-form';

import { saveAndGoTo } from '@redux/reducer/quote/save.actions';
import QuoteStep from '@templates/QuoteStep';
import MainChoicePricingElement from '@molecules/blocks/MainChoicePricingElement.container';
import { makeAlternativeProductCheck } from '@redux/reducer/quote/alternativeProducts.actions';

import { RootState } from '@redux/reducer';
import { setProduct } from '@redux/reducer/quote/product.actions';
import { useOptimizelyTrack } from '@hooks';
import { ProductType } from '@utils/types';

import TelematicsField from './TelematicsField';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin-bottom: 32px;
`;

const ProductChoice: React.FC<InjectedFormProps> = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const telematicsValue = useSelector((state: RootState) =>
    formValueSelector('productChoiceForm')(state, 'telematics'),
  );
  const productType = useSelector((state: RootState) => state.quote.product.productType);
  const previousProductType = useSelector(
    (state: RootState) => state.quote.product.previousProductType,
  );
  const altProduct = useSelector((state: RootState) => state.quote.alternativeProducts);
  const quotePrice = useSelector((state: RootState) => state.quote.save.price);
  const isFastRepurchase = useSelector((state: RootState) => state.quote.save.isFastRepurchase);

  const pricesSet = useRef(false);

  const [telematicsPrice, setTelematicsPrice] = useState('');
  const [nonTelematicsPrice, setNonTelematicsPrice] = useState('');

  const sendEvent = useOptimizelyTrack();
  useEffect(() => {
    sendEvent('newdriver_cross_sell_subs_init');
  }, [sendEvent]);

  const altProductType =
    productType === 'newdriver' ? (previousProductType as ProductType) : 'newdriver';

  useEffect(() => {
    if (altProduct.get(altProductType) === undefined) {
      dispatch(makeAlternativeProductCheck(altProductType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Need to wait for product check result
    if (pricesSet.current || altProduct.get(altProductType) === undefined) return;

    const altProductPrice = (altProduct.get(altProductType)?.price as number).toFixed(2).toString();

    setTelematicsPrice(productType === 'newdriver' ? quotePrice : altProductPrice);
    setNonTelematicsPrice(productType === 'newdriver' ? altProductPrice : quotePrice);
    pricesSet.current = true;
  }, [altProduct, altProductType, productType, quotePrice]);

  return (
    <QuoteStep
      id='productChoice-component-quoteStep'
      stepTitle='Product Choice'
      showButton
      nextLabel='Continue'
      nextAction={handleSubmit((values: Record<string, string>) => {
        const newProductType = values.telematics ? 'newdriver' : 'tc';
        if (newProductType !== productType) {
          dispatch(setProduct(newProductType));
        }
        if (isFastRepurchase) {
          dispatch(saveAndGoTo('confirm-details'));
        } else {
          dispatch(saveAndGoTo('important-information'));
        }
        sendEvent(`newdriver_cross_sell_subs_selected_${newProductType}`);
      })}
    >
      <Container>
        <MainChoicePricingElement
          telematicsSelected={telematicsValue}
          telematicsPrice={telematicsPrice}
          nonTelematicsPrice={nonTelematicsPrice}
        />
        <Field
          id='productChoice-field-telematics'
          name='telematics'
          component={TelematicsField}
          telematicsPrice={telematicsPrice}
          nonTelematicsPrice={nonTelematicsPrice}
        />
      </Container>
    </QuoteStep>
  );
};

export default reduxForm({
  form: 'productChoiceForm',
})(ProductChoice);
