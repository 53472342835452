import React from 'react';
import styled from 'styled-components';

import Select from '@molecules/selects/Select';

const Container = styled.div`
  margin-bottom: 16px;
`;

const getOccupationOptions = (occupationType, occupations) => {
  if (occupationType) {
    return occupations[occupationType].map((occupation) => ({
      name: occupation,
      value: occupation,
    }));
  }
  return [];
};

const OccupationSelect = ({
  disabled,
  occupations,
  occupationType,
  onChange,
  value,
  isQuoteForMyself,
}) => (
  <Container>
    <Select
      id='occupation_select'
      value={value}
      defaultValue={value}
      placeholder={
        occupationType === 'student'
          ? `Find ${isQuoteForMyself ? 'your' : 'their'} subject`
          : `Find ${isQuoteForMyself ? 'your' : 'their'} occupation`
      }
      options={getOccupationOptions(occupationType, occupations)}
      onChange={onChange}
      disabled={disabled}
    />
  </Container>
);

OccupationSelect.defaultProps = {
  occupations: [],
};

export default OccupationSelect;
