import styled, { css } from 'styled-components';
import colors from '@config/colors';
import media from '@config/media';
import size from '@config/size';

export const MainContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  place-content: start;

  ${css`
    @media (min-width: ${size.desktopWidth}) {
      background-color: ${colors.grey.background.lighter};
      justify-content: center;
      place-content: start;
    }
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

export const Content = styled.div.attrs(() => ({
  id: 'content',
}))`
  background-color: ${colors.grey.background.lighter};
  flex: 1;
  padding: 0 16px;
  min-width: 320px;
  ${css`
    @media (min-width: ${size.desktopWidth}) {
      min-width: ${({ showFullWidthPage }) => (showFullWidthPage ? '720px' : '400px')};
      max-width: 100%;
      padding-left: ${({ showFullWidthPage }) => (showFullWidthPage ? '150px' : '40px')};
      padding-right: 0px;
      display: flex;
      justify-content: center;
    }
  `}
`;

export const InnerContent = styled.main`
  margin: 0;
  ${css`
    @media (min-width: ${size.desktopWidth}) {
      width: ${({ showFullWidthPage }) => (showFullWidthPage ? '720px' : '400px')};
    }
  `}
`;

export const SidebarContainer = styled.div`
  min-width: 340px;
  padding: 0px;
  width: 320px;

  ${media.tablet`
      display: none;
  `};

  ${({ mobileDockLocation }) => css`
    padding: 0px;
    min-width: 320px;
    width: 320px;
    background: white;
    box-sizing: border-box;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);

    ${media.tablet`
      height: ${({ displayMobileSummary }) => (displayMobileSummary ? '100%' : '0%')};
      transition: height 0.4s;
      display: flex;
      justify-content: center;
      top: ${mobileDockLocation};
      bottom: 0;
      position: fixed;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      z-index: 1;
      flex: 1;

      & > * {
        flex-shrink: 0;
      }
    `}
  `}

  ${css`
    @media (min-width: ${size.desktopWidth}) {
      display: block;
    }
  `}
`;
