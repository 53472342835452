import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import fieldInformationStyleProps from '@services/fieldInformationStyleProps';
import { PasswordInput, FieldContainer } from '@rentecarlo/component-library';
import FieldInformation from '@atoms/inputs/FieldInformation';

interface Props {
  placeholder: string;
  textStyle: string;
  desktopRightMargin: string;
  id: string;
  name: string;
  type: string;
}

const PasswordInputField: React.FC<Props & WrappedFieldProps> = ({
  input: { onChange, onBlur, value, name },
  meta: { valid, error, touched, active, pristine, submitFailed },
  placeholder = 'Add Password',
  id,
}) => {
  const { Icon, textColor } = fieldInformationStyleProps((active && valid) as boolean, false);

  let message = '';

  if (active && valid) {
    message = 'Your password is safe';
  }

  return (
    <FieldContainer>
      <PasswordInput
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        name={name}
        errorText={
          ((touched && !pristine) || submitFailed) && error && (!active || submitFailed)
            ? error
            : undefined
        }
      />
      <FieldInformation icon={Icon} textColor={textColor} message={message} />
    </FieldContainer>
  );
};

export default PasswordInputField;
