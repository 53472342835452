import { takeEvery, put, select, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { types as appTypes, changeProduct, ChangeProduct } from '@reducer/app/actions';
import { setProduct } from '@reducer/quote/product.actions';
import { userResetQuote } from '@reducer/quote/actions';
import { ChangeLicenceType, types as licenceTypes } from '@reducer/quote/licence.actions';
import {} from '@reducer/quote/save';
import { getCurrentPath } from '@services/path';
import { RootState } from '@redux/reducer';

/**
 * getCurrentProductType
 * @param {state} - RootState to get product type
 * @returns {type} - productType
 */
export const getCurrentProductType = (state: RootState): string => state.quote.product.productType;

const ALLOWED_LICENCE_PRODUCTS: Record<string, string[]> = {
  uk_auto: ['csi', 'tc', 'newdriver'],
  uk_manual: ['csi', 'tc', 'newdriver'],
  uk_prov: ['ldp', 'newdriver'],
};

const CONTACT_US_URL = '/car-sharing/contact-us';

/**
 * checkLocationShouldNotRedirect
 * @param {path} - url path
 * @returns {type} - matches with path or not
 */
const checkLocationShouldNotRedirect = (path: string) => path.match(CONTACT_US_URL);

/**
 * getLicenceProduct
 * @param {licenceType} - license type
 * @param {currentProductType} - current product type
 * @returns {licenceProduct}
 */
const getLicenceProduct = (licenceType: string, currentProductType: string) => {
  const allowedProducts = ALLOWED_LICENCE_PRODUCTS[licenceType];

  let licenceProduct;
  if (licenceType === 'uk_manual' || licenceType === 'uk_auto') {
    licenceProduct = allowedProducts.find((product) => product === currentProductType) || 'csi';
  }

  if (licenceType === 'uk_prov') {
    licenceProduct = allowedProducts.find((product) => product === currentProductType) || 'ldp';
  }

  return licenceProduct;
};

/**
 * checkLicenceSaga
 * @generator
 * @param {licenceType} - LicenseType
 * @param {resetQuote} - reset quote or not
 * @yields {getLicenceProduct} - gets the current license product type
 * @yields {changeProduct} - change the product type
 * @returns {SagaIterator}
 */
export function* checkLicence(licenceType: string, resetQuote = false): SagaIterator {
  if (checkLocationShouldNotRedirect(window.location.pathname)) {
    return;
  }

  const currentProductType = yield select(getCurrentProductType);

  if (resetQuote) {
    yield put(userResetQuote());
  }

  const licenceProduct = getLicenceProduct(licenceType, currentProductType);

  if (!licenceProduct) {
    return;
  }

  yield put(changeProduct(licenceProduct));
}

/**
 * licenceTypeChangeSaga
 * @generator
 * @param {action} - action of the type ChangeLicenceType containe LicenseType
 * @yields {checkLicence} - checkes the LicenseType and change product
 * @returns {SagaIterator}
 */
export function* licenceTypeChangeSaga(action: ChangeLicenceType): SagaIterator {
  yield call(checkLicence, action.value);
}

/**
 * changeProductSaga
 * @generator
 * @param {action} - action of the type ChangeProduct and containe Product
 * @yields {setProduct} - sets the product
 * @returns {SagaIterator}
 */
export function* changeProductSaga(action: ChangeProduct): SagaIterator {
  let path = getCurrentPath();
  // 😢
  if (['/licence-change/error', '/licence-change/success'].includes(path)) {
    path = '/start';
  }
  if (path === '/licence-change') {
    return;
  }

  yield put(setProduct(action.product));
}

/**
 * watchChangeProduct
 * @generator
 * @yields {changeProductSaga}
 * @yields {licenceTypeChangeSaga}
 * @returns {SagaIterator}
 * */
export function* watchChangeProduct(): SagaIterator {
  yield takeEvery(appTypes.CHANGE_PRODUCT, changeProductSaga);
  yield takeEvery(licenceTypes.CHANGE_LICENCE_TYPE, licenceTypeChangeSaga);
}
