import styled from 'styled-components';

export const InfoImage = styled.img`
  margin-right: 8px;
  width: 16px;
  height: 16px;
`;

interface ContainerProps {
  textColor?: string;
}

export const InfoContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${(props): string | undefined => props.textColor};
  font-family: proxima-soft;
  font-size: 14px;
  letter-spacing: 0;
  margin: 8px 0 4px 16px;
`;
