import React from 'react';
import styled from 'styled-components';
import { LinkButton, PillButton, SeparationSpace, TextLink } from '@rentecarlo/component-library';

const Container = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
`;

const RejectionFooter = ({ faqUrl }) => {
  const handleRouteToHome = () => (window.location = 'https://www.veygo.com');

  return (
    <Container>
      <PillButton id='rejection-button-homeScreen' width='100%' onClick={handleRouteToHome}>
        Go to home screen
      </PillButton>
      <SeparationSpace marginTop='1rem' drawSeparator={false} />
      <TextLink id='rejection-button-helpfulFAQs' bold fontSize={16} href={faqUrl}>
        Helpful FAQs
      </TextLink>
    </Container>
  );
};

export default RejectionFooter;
