/* eslint-disable */

import { default as _invert } from 'lodash/invert';
import { default as _merge } from 'lodash/merge';

import { mapKeys } from './tools';

const noop = () => null;

const applyTransform = transform => (input, output) =>
  _merge(output, transform(input, output));

/* `mapping` should be like:
 * {
 *   reduxKey1: 'apiKey1',
 *   reduxKey2: 'apiKey2',
 *   ...
 * }
 */
export default (keyMapping, direct=noop, reverse=noop) => {
  const directMapper = mapKeys(keyMapping);
  const reverseMapper = mapKeys(_invert(keyMapping));

  const directTransformer = applyTransform(direct);
  const reverseTransformer = applyTransform(reverse);

  return {
    apiToRedux(apiData) {
      const reduxData = reverseMapper(apiData);
      return reverseTransformer(apiData, reduxData);
    },
    reduxToAPI(reduxData) {
      const apiData = directMapper(reduxData);
      return directTransformer(reduxData, apiData);
    },
  };
};
