import React from 'react';

import Page from '../Page';

import AllSet from './assets/all-set.svg';
import * as styles from './assets/styles';

const AlreadySet: React.FC = () => {
  return (
    <Page
      title="It looks like you're already set..."
      titleCentered
      buttonText='Back to homepage'
      buttonAction={(): void => {
        window.location.href = 'https://www.veygo.com/';
      }}
    >
      <styles.Container>
        <styles.Image src={AllSet} />
        <styles.Text id='alreadySet-text-greatNews'>
          Great news! Based on the information you've given us (you're a named driver on another
          policy for this car), it looks like you're already covered. We don't want to charge you
          for something you don't need. But we'd love to be there for you in the future!
        </styles.Text>
        <styles.Text id='alreadySet-text-missedSomething'>
          If you think we've missed something and you still need insurance, please{' '}
          <styles.Link id='alreadySet-link-missedSomething' href='https://www.veygo.com/contact/'>
            contact us
          </styles.Link>{' '}
          so we can chat about what you need.
        </styles.Text>
      </styles.Container>
    </Page>
  );
};

export default AlreadySet;
