import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 8px;
`;

export const TextContainer = styled.div`
  padding: 32px 0;
  overflow-wrap: break-word;
`;

export const Spacer = styled.div`
  height: 16px;
`;
