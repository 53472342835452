import { SetStateFieldActionCreator } from '@utils/types';
import { State } from './claims';

export const SET_CLAIMS_FIELD = 'SET_CLAIMS_FIELD';

// Actions
export const setClaimsField: SetStateFieldActionCreator<State, typeof SET_CLAIMS_FIELD> = (
  field,
  value,
) => ({
  type: SET_CLAIMS_FIELD,
  field,
  value,
});

export type SetClaimsField = ReturnType<typeof setClaimsField>;

export type ClaimsAction = SetClaimsField;
