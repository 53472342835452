import React from 'react';
import styled, { css } from 'styled-components';
import GreenTick from '@atoms/inputs/GreenTick';
import arrow from '@assets/select-arrow.svg';
import disabledArrow from '@assets/select-arrow-disabled.svg';
import colors from '@config/colors';

const StyledSelect = styled.select`
  appearance: none;
  background: ${(props) => (props.disabled ? `url(${disabledArrow})` : `url(${arrow})`)} no-repeat
    right;
  background-color: ${(props) => (props.disabled ? colors.grey.background.disabled : colors.white)};
  border: 1px solid ${colors.grey.lines.light};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: ${(props) => (props.hasValue ? 'bold' : '')};
  text-transform: none;
  line-height: 19px;
  padding: 22px 16px;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  ::-ms-expand {
    display: none;
  }
  ${(props) => (props.showTick ? 'padding-right: 85px' : 'padding-right: 40px')};
  ${(props) =>
    props.type === 'compact' &&
    css`
      max-height: 40px;
      padding: 10px 26px 10px 16px;
      color: colors.outerSpace;
      font-family: 'gotham-rounded';
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      background-color: white;
    `}
`;

// eslint-disable-next-line react/destructuring-assignment
const Option = ({ key, value, name }) => (
  <option id={key} value={value}>
    {name}
  </option>
);

const Select = (props) => {
  const {
    disabled,
    id,
    onChange,
    options,
    placeholder,
    selectorType,
    showValidateTick,
    type,
    value,
    desktopRightMargin,
  } = props;
  return (
    <GreenTick
      showTick={showValidateTick === null ? !!value : showValidateTick}
      mobileRightMargin={`${50}px`}
      desktopRightMargin={desktopRightMargin}
    >
      <StyledSelect
        data-test={selectorType}
        id={id}
        onChange={onChange}
        type={type}
        disabled={disabled}
        value={value}
        hasValue={value}
        showTick={showValidateTick === null ? !!value : showValidateTick}
      >
        {placeholder && (
          <option id='quote-component-option' key='default' value='' disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <Option key={option.value} {...option} />
        ))}
      </StyledSelect>
    </GreenTick>
  );
};

Select.defaultProps = {
  type: 'default',
  disabled: false,
  value: '',
  placeholder: '',
  showValidateTick: null,
};

export default Select;
