import React, { FC } from 'react';
import { SeparationSpace } from '@rentecarlo/component-library';
import { Subtitle, InfoText } from './assets/BreakdownTerms';

type Props = {
  id: string;
  title: string;
  showSeparator?: boolean;
  children?: React.ReactNode;
};

const BreakdownTerms: FC<Props> = ({ id, title, children, showSeparator = false }) => (
  <div id={id}>
    <Subtitle id='importantInformation-title-breakdownTerms'>{title}</Subtitle>
    <InfoText>{children}</InfoText>
    {showSeparator && <SeparationSpace marginBottom='24px' />}
  </div>
);

export default BreakdownTerms;
