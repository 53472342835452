import styled from 'styled-components';
import colors from '@config/colors';
import media from '@config/media';

export const Container = styled.div`
  margin-left: 48px;
`;

export const Subtitle = styled.span`
  ${media.tablet`
    height: 54px;
    width: 256px;
  `}
  height: 38px;
  width: 304px;
  color: ${colors.outerSpace};
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 19px;
  font-family: 'proxima-soft';
`;
