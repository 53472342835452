import React from 'react';
import styled, { css } from 'styled-components';
import { Tick } from '@rentecarlo/component-library';
import colors from '@config/colors';
import CompleteTickIcon from '@assets/tick.svg';
import HoverButton from '@assets/button-hover.svg';
import EmptyTickIcon from '@assets/checkbox-empty.svg';

import { capitaliseWords } from '@services/formatString';

const getColor = (props, normalColor, selectedColor, selectedErrorColor, disabledColor) => {
  if (props.selectedStyle) {
    return props.displayError ? selectedErrorColor : selectedColor;
  }
  return props.disabled ? disabledColor : normalColor;
};

const getBorder = (props) =>
  getColor(
    props,
    colors.grey.lines.light,
    colors.grey.lines.light,
    colors.orange,
    colors.grey.lines.light,
  );

const getIcon = (checked, hovered, tickSize) => {
  let status = 'empty';
  if (hovered) status = 'hover';
  if (checked) status = 'active';

  const size = Number(tickSize.replace('px', ''));

  return <Tick status={status} size={size} />;
};

const TitleRadioContainer = styled.div`
  margin: 0 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    getColor(
      props,
      colors.white,
      colors.white.selected,
      colors.orangeLight,
      colors.grey.background.disabled,
    )};
  border: 1px solid ${(props) => getBorder(props)};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  color: ${(props) => getColor(props, colors.dark.title, colors.black.selected, colors.orange)};
  flex-grow: 1;
  height: 64px;
  position: relative;
  transition: all 0.4s ease-in-out;
  opacity: ${(props) =>
    (props.otherItemSelected && !props.hovered) || (props.otherItemHovered && !props.checked)
      ? 0.5
      : 1};
  &:hover {
    box-shadow: 0 2px 0 0 ${colors.shadow};
  }
  ${(props) =>
    props.squashed
      ? `
    height: 40px;
    border-radius: 0;
    margin: 0 0px;
    border-left-width: 0px;
    ${css`
      :first-child {
        border-left-width: 1px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      :last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    `}`
      : null};
`;

const StyledRadio = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
`;

const IconContainer = styled.div`
  height: ${(props) => props.tickSize};
  position: absolute;
  right: ${(props) => props.tickPosition};
  top: ${(props) => props.tickPosition};
  width: ${(props) => props.tickSize};
  pointer-events: none;
`;

const StyledLabel = styled.label`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const TitleRadioButton = ({
  onFocus,
  onBlur,
  checked,
  displayError,
  disabled,
  locked,
  squashed,
  id,
  selectorType,
  text,
  tickSize,
  tickPosition,
  value,
  onSelect,
  otherItemSelected,
  hovered,
  otherItemHovered,
}) => (
  <TitleRadioContainer
    selectedStyle={checked}
    displayError={displayError}
    disabled={disabled || locked}
    squashed={squashed}
    id={id}
    onMouseOver={() => onFocus()}
    onMouseOut={() => onBlur()}
    onFocus={() => onFocus()}
    onBlur={() => onBlur()}
    otherItemSelected={otherItemSelected}
    hovered={hovered}
    otherItemHovered={otherItemHovered}
    checked={checked}
  >
    <StyledRadio
      data-test={selectorType}
      type='radio'
      checked={checked}
      value={value}
      onChange={(data) => (disabled || locked ? null : onSelect(data))}
    />
    <StyledLabel id={`${id}-radioLabel`} htmlFor={id} disabled={disabled || locked}>
      {capitaliseWords(text)}
    </StyledLabel>
    {!squashed && (
      <IconContainer
        tickSize={tickSize}
        tickPosition={tickPosition}
        id={`${id}-radioIconContainer`}
      >
        {getIcon(checked, hovered, tickSize)}
      </IconContainer>
    )}
  </TitleRadioContainer>
);

TitleRadioButton.defaultProps = {
  checked: null,
  disabled: false,
  displayError: false,
  tickPosition: '10px',
  squashed: false,
  tickSize: '16px',
  locked: false,
};

export default TitleRadioButton;
