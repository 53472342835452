import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { OptimizelyProvider } from '@optimizely/react-sdk';
import { optimizelyClient } from '@services/clients/optimizely';

import './services/sentry';

import getRouter from './router';
import store from './redux/store';
import rootSaga from './redux/sagas';

import 'whatwg-fetch';

import './index.css';

store.runSaga(rootSaga);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <OptimizelyProvider
      id='quote-provider-optimizely'
      optimizely={optimizelyClient}
      user={{ id: 'tempcover_user' }}
    >
      {getRouter()}
    </OptimizelyProvider>
  </Provider>,
);

// Needed to allow hot module reloading for development.
if (module.hot) {
  module.hot.accept();
}
