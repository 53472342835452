import { connect } from 'react-redux';
import { getFaqUrl } from '@reselectors/links';
import RejectionSidebar from './RejectionSidebar';

const mapStateToProps = (state) => ({
  faqUrl: getFaqUrl(state),
});

const mapDispatchToProps = {};

const RejectionSidebarContainer = connect(mapStateToProps, mapDispatchToProps)(RejectionSidebar);

export default RejectionSidebarContainer;
