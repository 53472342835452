import React from 'react';
import MultipleChoice from '@molecules/inputs/MultipleChoice';

const MultipleChoiceField = ({ id, input, meta, withChange, selectorType, options, disabled }) => {
  const { value, onChange, name } = input;
  const changeHandler = (newValue) => {
    withChange();
    onChange(newValue);
  };

  return (
    <MultipleChoice
      id={id}
      onSelect={changeHandler}
      name={name}
      value={value}
      data-test={selectorType}
      options={options}
      disabled={disabled}
      meta={meta}
    />
  );
};

MultipleChoiceField.defaultProps = {
  disabled: false,
  withChange: () => {},
};

export default MultipleChoiceField;
