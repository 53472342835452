import styled from 'styled-components';
import colors from '@config/colors';

interface SectionTitleProps {
  hideMargin?: boolean;
}

const SectionTitle = styled.h3<SectionTitleProps>`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.32px;
  ${(props) => (props.hideMargin ? '' : 'margin-bottom: 8px;')}
`;

SectionTitle.defaultProps = {
  hideMargin: false,
};

export default SectionTitle;
