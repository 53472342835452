import styled from 'styled-components';
import colors from '@config/colors';

export const StyledContainer = styled.div`
  position: relative;
  background-color: ${colors.grey.background.light};
  border-radius: 8px;
  padding: 24px 24px 14px 24px;
  @media only screen and (max-width: 1024px) {
    padding: 24px 16px 14px 16px;
  }
`;

export interface StyledNotchProps {
  value: string;
}

export const StyledNotch = styled.b`
  position: absolute;
  left: ${({ value }: StyledNotchProps): string =>
    value === 'true' ? 'calc(25% - 10px)' : 'calc(75% - 10px)'};
  top: -8px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid ${colors.grey.background.light};
  /* ie6 height fix */
  font-size: 0;
  line-height: 0;
  /* ie6 transparent fix */
  _border-right-color: pink;
  _border-left-color: pink;
  _filter: chroma(color=pink);
`;
