// Types
import { SetStateFieldActionCreator } from '@utils/types';
import { PurchasedQuote } from './purchasedQuoteTypes';

export enum types {
  SET_PURCHASED_QUOTE_FIELD = 'SET_PURCHASED_QUOTE_FIELD',
}

export type SetPurchasedQuoteField = ReturnType<typeof setPurchasedQuoteField>;

export type PurchaseQuoteActions = SetPurchasedQuoteField;

// Actions creators
export const setPurchasedQuoteField: SetStateFieldActionCreator<
  PurchasedQuote,
  typeof types.SET_PURCHASED_QUOTE_FIELD
> = (field, value) => ({
  type: types.SET_PURCHASED_QUOTE_FIELD,
  field,
  value,
});
