import React, { useLayoutEffect, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';

import {
  MainContainer,
  ContentContainer,
  Content,
  SidebarContainer,
  InnerContent,
} from '@templates/Main';

import Header from '@organisms/header/Header';
import ViewQuoteSummaryBanner from '@atoms/blocks/ViewQuoteSummaryBanner';
import QuoteSummarySidebar from '@molecules/asides/QuoteSummarySidebar';
import { useAppSelector } from '@hooks';
import { Page } from '../../ui-tools';

// Calculate where the mobile quote summary should dock.
// Due to the stick elements, we have to constantly recalculate this
const calculateDockLocation = () => {
  let summaryBannerElement = '0px';
  const element = document.getElementById('quote-container-summaryBanner');
  if (element) {
    const summaryElementBounds = element.getBoundingClientRect();
    return (summaryBannerElement = `${summaryElementBounds.y + summaryElementBounds.height}px`);
  }

  return summaryBannerElement;
};

const SidebarLayout = ({ displayMobileSummary, location, router, onHandleSummaryClose }) => {
  const [dockLocation, setDockLocation] = useState('0px');
  const pixelLocation = calculateDockLocation();

  // Listen to any changes to the layout
  // Update position after the DOM has changed, not before.
  useLayoutEffect(() => {
    setDockLocation(pixelLocation);
  }, [pixelLocation]);

  return (
    <SidebarContainer
      mobileDockLocation={dockLocation}
      id='sidebar-container'
      displayMobileSummary={displayMobileSummary}
    >
      <QuoteSummarySidebar
        displayMobileSummary={displayMobileSummary}
        router={router}
        pathname={location.pathname}
        onHandleSummaryClose={onHandleSummaryClose}
      />
    </SidebarContainer>
  );
};

const Quote = (props) => {
  const { children, location, router } = props;
  const CandisplaySideBar = useAppSelector((state) => state.account.customer.displaySideBar);
  const [displayMobileSummary, setDisplayMobileSummary] = useState(false);

  useLayoutEffect(() => {
    // CSIs scroll handling is appaling, so this allows fixed elements
    // to scroll without scrolling the background
    document.body.style.overflow = displayMobileSummary ? 'hidden' : 'scroll';
  }, [displayMobileSummary]);

  const displayFullWidthPage = ['/almost-there'].includes(window.location.pathname);
  const shouldShowSidebar = !['/start', '/licence-switch', '/licence-switch/error'].includes(
    location.pathname,
  );

  return (
    <MainContainer id='quote-container-main'>
      <Header pathname={location.pathname} />
      {CandisplaySideBar && shouldShowSidebar && (
        <Sticky enabled top={22} innerZ={1}>
          <ViewQuoteSummaryBanner
            visible={displayMobileSummary}
            onClick={() => setDisplayMobileSummary(!displayMobileSummary)}
            useExcessPrice={location.pathname.includes('important-information')}
          />
        </Sticky>
      )}
      <ContentContainer id='quote-container-content'>
        <Content className='page' showFullWidthPage={displayFullWidthPage}>
          <InnerContent showFullWidthPage={displayFullWidthPage}>
            <Page borderTop={false}>{children}</Page>
          </InnerContent>
        </Content>
        {CandisplaySideBar && shouldShowSidebar && (
          <SidebarLayout
            displayMobileSummary={displayMobileSummary}
            router={router}
            location={location}
            onHandleSummaryClose={() => setDisplayMobileSummary(!displayMobileSummary)}
          />
        )}
      </ContentContainer>
    </MainContainer>
  );
};

Quote.defaultProps = {};

export default Quote;

const mapStateToProps = (state) => ({
  apiError: state.quote.save.error,
});
const mapDispatchToProps = {};

export const QuoteContainer = connect(mapStateToProps, mapDispatchToProps)(Quote);
