import React from 'react';
import styled from 'styled-components';

import TableRow from '@atoms/layout/TableRow';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowContainer = styled.div`
  margin-bottom: 26px;
`;

const Table = ({ data, id }) => (
  <Column id={id}>
    {data.map((rowData) => (
      <RowContainer key={rowData.title}>
        <TableRow title={rowData.title} value={rowData.value} />
      </RowContainer>
    ))}
  </Column>
);

export default Table;
