import { connect } from 'react-redux';
import OwnerSummary from './OwnerSummary.component';

// Props passed in from the parent
interface ParentProps {
  setOwnerModalVisibility: () => void;
}

export interface StateProps extends ParentProps {
  hasOwner: boolean;
}

const hasOwnerDetails = (owner: Owner): boolean =>
  !!(owner.firstName && owner.surname && owner.birthdate && owner.email && owner.relationship);

interface Owner {
  firstName: string;
  surname: string;
  birthdate: string;
  email: string;
  relationship: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any, ownProps: ParentProps): StateProps => ({
  hasOwner: hasOwnerDetails(state.quote.owner),
  setOwnerModalVisibility: ownProps.setOwnerModalVisibility,
});

export default connect(mapStateToProps)(OwnerSummary);
