import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, InformationBox, P } from '@rentecarlo/component-library';

import QuoteStep from '@templates/QuoteStep';
import PricingElement from '@molecules/blocks/PricingComponent.payment';
import PillButton from '@atoms/buttons/PillButton';
import { getIPIDUrl, getBreakdownCoverIPIDUrl } from '@reselectors/files';
import VoucherCode from '@organisms/blocks/VoucherCode';

import UpdateMobileNumberModal from '@organisms/modals/UpdateMobileNumberModal';

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const AuthenticationButtons = styled.div`
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
`;

const ButtonWrapper = styled.div`
  padding: 4px;
  width: 100%;
`;

const IPIDButtonContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

const BreakdownIPIDButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  margin: 0 16px;
  font-weight: bold;
`;

const NumberedList = styled.ol`
  list-style-type: decimal;
  margin-top: 32px;
  margin-left: 0.75em;
  color: ${({ theme }) => theme.textPrimary};
`;

const NumberedListItems = styled.li``;

ButtonContainer.displayName = 'ButtonContainer';
AuthenticationButtons.displayName = 'AuthenticationButtons';

interface FinalQuoteProps {
  loggedIn: boolean;
  discountCode: string;
  validDiscountCode: boolean | null;
  hasConversionBonus: boolean;
  register: () => void;
  continue: () => void;
  saveQuote: () => void;
  setDiscountCode: (discountCode: string) => void;
  utmSource: string;
  productType: string;
  isSubscription: boolean;
  breakdownCover: boolean;
  isMobileNumber: boolean;
  loading: boolean;
  isQuoteForMyself: boolean;
}

export const buildTitleByProduct = (
  productType: string,
  isSubscription: boolean,
  isQuoteForMyself: boolean,
): string => {
  if (isSubscription) {
    return `${isQuoteForMyself ? 'Your' : 'Their'} final quote`;
  }
  if (productType === 'ldp') {
    return `${isQuoteForMyself ? 'Your' : 'Their'} final quote for learner driver insurance`;
  }
  if (productType === 'tc') {
    return `${isQuoteForMyself ? 'Your' : 'Their'} final quote for temporary insurance`;
  }
  return `${isQuoteForMyself ? 'Your' : 'Their'} final quote for car sharing insurance`;
};

const FinalQuote: React.FC<FinalQuoteProps> = ({
  validDiscountCode,
  discountCode,
  hasConversionBonus,
  saveQuote,
  setDiscountCode,
  isSubscription,
  productType,
  loggedIn,
  continue: continueQuote,
  register,
  breakdownCover,
  isMobileNumber,
  loading,
  isQuoteForMyself,
}) => {
  const TermsAndConditionsRef = useRef<HTMLOListElement | null>(null);

  const [showVoucherInput, setShowVoucherInput] = useState(false);
  const [hasSubmittedVoucher, setHasSubmittedVoucher] = useState(false);

  const [showMobileNumberModal, setShowMobileNumberModal] = useState(false);

  const scrollToTermsAndConditions = () => TermsAndConditionsRef.current?.scrollIntoView();

  const IPIDUrl = useSelector((state) => getIPIDUrl(state));
  const breakdownIPIDURL = useSelector((state) => getBreakdownCoverIPIDUrl(state));

  useEffect(() => {
    if (productType === 'newdriver' && !isMobileNumber) {
      setShowMobileNumberModal(true);
    }
  }, [productType, isMobileNumber]);

  const renderPromotionTermsAndConditions = (): React.ReactElement | null => {
    if (hasConversionBonus) {
      return (
        <NumberedList ref={TermsAndConditionsRef}>
          <NumberedListItems id='finalQuote-text-promotionTermsItemOne'>
            We reserve the right to remove this discount at any time without notice.
          </NumberedListItems>
          <NumberedListItems id='finalQuote-text-promotionTermsItemTwo'>
            This promotion only applies for UK Motor Policy Holders.
          </NumberedListItems>
          <NumberedListItems id='finalQuote-text-promotionTermsItemThree'>
            This promotion only applies to the final quote and will not apply to ancillary costs,
            such as lower excess and breakdown.
          </NumberedListItems>
        </NumberedList>
      );
    }
    return null;
  };

  const renderVoucherInput = (): React.ReactElement => {
    return (
      <div id='finalQuote-container-discount' style={{ padding: '8px' }}>
        {hasSubmittedVoucher &&
          !loading &&
          (!validDiscountCode ? (
            <InformationBox id='finalQuote-text-discountInvalidCode' type='error'>
              This is not a valid voucher code.
            </InformationBox>
          ) : (
            <InformationBox id='finalQuote-text-discountValidCode' type='important'>
              Your voucher code has been applied.
            </InformationBox>
          ))}
      </div>
    );
  };

  const renderSummaryFooter = (): React.ReactElement => {
    return (
      <>
        {isSubscription && (
          <TextContainer>
            <P fontSize={14} id='finalQuote-text-noFeeCancelText'>
              Cancel anytime with no fees ✅
            </P>
          </TextContainer>
        )}
        <VoucherCode
          breakdownCover={breakdownCover}
          discountCode={discountCode}
          hasConversionBonus={hasConversionBonus}
          saveQuote={saveQuote}
          setDiscountCode={setDiscountCode}
          validDiscountCode={validDiscountCode}
          showVoucherInput={showVoucherInput}
          setShowVoucherInput={setShowVoucherInput}
          setHasSubmittedVoucher={setHasSubmittedVoucher}
          scrollToTermsAndConditions={scrollToTermsAndConditions}
        />
      </>
    );
  };

  return (
    <>
      <UpdateMobileNumberModal
        visible={showMobileNumberModal}
        close={() => setShowMobileNumberModal(false)}
        isQuoteForMyself={isQuoteForMyself}
      />
      <QuoteStep
        id='finalQuote-component-quoteStep'
        stepTitle={buildTitleByProduct(productType, isSubscription, isQuoteForMyself)}
        stepSubtitle={`Based on the details you have provided, here is the final price for ${
          isQuoteForMyself ? 'your' : 'their'
        } ${isSubscription ? 'monthly subscription' : 'policy'}.`}
      >
        <PricingElement
          quoteSummaryHeader={
            isSubscription
              ? `${isQuoteForMyself ? 'Your' : 'Their'} monthly subscription`
              : `${isQuoteForMyself ? 'Your' : 'Their'} quote`
          }
          quoteSummaryFooter={renderSummaryFooter()}
          showNextPayment={false}
          isTelematics={productType === 'newdriver'}
        />
        {showVoucherInput || validDiscountCode ? renderVoucherInput() : null}
        <ButtonContainer id='finalQuote-container'>
          {loggedIn ? (
            <Button
              id='finalQuote-button-continueToPayment'
              onClick={() => continueQuote()}
              height={64}
              borderWidth={0}
            >
              Continue to payment
            </Button>
          ) : (
            <ButtonWrapper id='finalQuote-container-registerButton'>
              <Button
                id='finalQuote-button-register'
                onClick={register}
                height={64}
                borderWidth={0}
              >
                Register
              </Button>
            </ButtonWrapper>
          )}
        </ButtonContainer>
        <IPIDButtonContainer>
          <PillButton
            id='finalQuote-button-IPIDPill'
            onClick={() => {
              window.open(IPIDUrl, '_blank');
            }}
          >
            Read our Product Information Guide
          </PillButton>
        </IPIDButtonContainer>
        {breakdownCover && (
          <BreakdownIPIDButtonContainer>
            <PillButton
              id='finalQuote-button-breakdownIPID'
              onClick={() => {
                window.open(breakdownIPIDURL, '_blank');
              }}
            >
              Breakdown Cover Information Guide
            </PillButton>
          </BreakdownIPIDButtonContainer>
        )}
        {renderPromotionTermsAndConditions()}
      </QuoteStep>
    </>
  );
};

export default FinalQuote;
