/* eslint-disable camelcase, @typescript-eslint/explicit-module-boundary-types */
import HttpClient from '@services/api/httpClient';

import {
  CustomerCreateLocalV3,
  localFieldsToCreateRemoteV3,
  localFieldsToUpdateRemoteV3,
} from '@services/transformers/customers';

const customerApiUrlV3 = `${process.env.REACT_APP_API_URL}/customers/v3`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postV3 = (data: CustomerCreateLocalV3): Promise<any> => {
  const transformedData = localFieldsToCreateRemoteV3(data);
  const request = new HttpClient.Builder().post('', customerApiUrlV3).data(transformedData);

  return request.execute();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getV3 = async (): Promise<any> => {
  return new HttpClient.Builder().get(`/me`, customerApiUrlV3).execute();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const putV3 = async (data: any): Promise<any> => {
  const transformedData = localFieldsToUpdateRemoteV3(data);
  return new HttpClient.Builder().put(`/me`, customerApiUrlV3).data(transformedData).execute();
};

export default {
  postV3,
  getV3,
  putV3,
};
