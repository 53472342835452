import { LOCATION_CHANGE } from 'connected-react-router';
import _isFunction from 'lodash/isFunction';
import _isRegExp from 'lodash/isRegExp';

export const selectLocation = (location) => {
  // eslint-disable-next-line no-nested-ternary
  const locationCheck = _isFunction(location)
    ? (path) => location(path)
    : _isRegExp(location)
    ? (path) => location.test(path)
    : (path) => path === location;

  return (action) =>
    action.type === LOCATION_CHANGE && locationCheck(action.payload.location.pathname);
};
