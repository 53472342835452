import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';

import styled from 'styled-components';
import { LinkButton, RoundedGreyBox } from '@rentecarlo/component-library';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import AALogo from '@assets/aa-logo.png';
import ToggleSelector from '@molecules/inputs/ToggleSelectorField';
import BreakdownModal from '@organisms/modals/BreakdownModal';

const InfoText = styled.div`
  font-family: 'proxima-soft';
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: ${({ theme }): string => theme.primary};
  background-color: rgba(48, 198, 226, 0.2);
  padding: 8px;
  border-radius: 8px;
  display: inline-block;
`;

interface Props {
  breakdownCoverPrice: string;
}

const AALogoImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  float: left;
  margin-right: 16px;
  margin-top: 8px;
`;

enum ShowModal {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

const AncillaryField: React.FC<Props & WrappedFieldProps> = ({
  input,
  meta,
  breakdownCoverPrice,
}) => {
  const [showBreakdownModal, setshowBreakdownModal] = useState<ShowModal>(ShowModal.CLOSED);
  return (
    <>
      <BreakdownModal
        show={showBreakdownModal === ShowModal.OPEN}
        close={() => setshowBreakdownModal(ShowModal.CLOSED)}
      />
      <SectionTitle id='importantInformation-title-ancillaryAddBreakdown'>
        Add breakdown cover for{' '}
        <InfoText id='importantInformation-text-ancillaryAddBreakdownPrice'>
          £{breakdownCoverPrice}?
        </InfoText>
      </SectionTitle>
      <RoundedGreyBox
        id='importantInformation-container-ancillary'
        margin={[0, 0, 8, 0]}
        padding={[16, 16, 16, 16]}
      >
        <AALogoImage src={AALogo} />
        Includes roadside repair, home start, and national recovery - delivered by{' '}
        <b id='importantInformation-title-ancillaryProvider'>The AA</b>
        .
        <br />
        <LinkButton
          id='importantInformation-button-ancillaryDetails'
          bold
          action={() => setshowBreakdownModal(ShowModal.OPEN)}
        >
          See full details
        </LinkButton>
      </RoundedGreyBox>
      <ToggleSelector input={input} meta={meta} groupName='breakdownCover' />
    </>
  );
};

export default AncillaryField;
