import styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 14px;
`;

export const NewDriverInfoContainer = styled.div`
  padding-bottom: 20px;
`;

export const Image = styled.img`
  flex: 1;
`;

export const Text = styled.div`
  font-family: proxima-soft;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 24px;
`;

export const SeparatorContainer = styled.div`
  width: 100%;
  display: none;
  margin-top: 16px;

  ${MediaQuery.mobile(css`
    display: block;
  `)};
`;
