import React from 'react';
import { useSelector } from 'react-redux';
import { Button, PageModal } from '@rentecarlo/component-library';
import { ButtonWrapper, Title, TitleSpacer, Wrapper } from './styles';

interface Props {
  show: boolean;
  close: () => void;
  children: JSX.Element;
}

const CarModal: React.FC<Props> = ({ show, close, children }) => {
  const closeText = 'Back';
  const haveCar = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => !!state.lookup.car?.make,
  );
  return (
    <PageModal close={close} closeText={closeText} show={show}>
      <Wrapper>
        <Title id='quoteStart-title-carModal'>Add a car</Title>
        <TitleSpacer />
        {children}
      </Wrapper>

      <ButtonWrapper visible={haveCar}>
        <Button
          id='quoteStart-button-carModal'
          data-testid='car-modal-add-car'
          borderWidth={0}
          onClick={close}
          height={64}
        >
          That's the car
        </Button>
      </ButtonWrapper>
    </PageModal>
  );
};

export default CarModal;
