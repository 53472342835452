/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
import { ConnectedComponent, useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { RootState } from '@redux/reducer';

export default (Component: ConnectedComponent<any, any>, allowedProducts: string[] | undefined) => (
  props: any,
): React.ReactElement => {
  const dispatch = useDispatch();
  const productType = useSelector((state: RootState) => state.quote.product.productType);

  useEffect(() => {
    if (allowedProducts && !allowedProducts.includes(productType)) {
      dispatch(push('/start'));
    }
  }, [productType, dispatch]);

  return <Component {...props} />;
};
