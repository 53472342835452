import React from 'react';

import fieldStyleProps from '@services/fieldStyleProps';

import { Container, Input, IconContainer } from './assets/styles';

interface Props {
  active?: boolean;
  alwaysCapitalised?: boolean;
  alwaysLowerCase?: boolean;
  disabled?: boolean;
  showError: boolean;
  id: string;
  isValid?: boolean;
  maxLength?: number;
  onBlur?: () => void;
  onChange: React.ChangeEventHandler;
  onFocus?: () => void;
  pasteDisabled?: boolean;
  placeholder?: string;
  placeholderStyle?: string;
  selectorType?: string;
  type?: string;
  textStyle?: string;
  value: string;
}
const TextInput: React.FC<Props> = ({
  isValid = false,
  selectorType = null,
  id,
  disabled = false,
  type = 'text',
  onChange,
  onBlur = () => {
    /* default to noop */
  },
  onFocus = () => {
    /* default to noop */
  },
  textStyle = '',
  pasteDisabled = false,
  placeholderStyle = '',
  value,
  alwaysCapitalised = false,
  alwaysLowerCase = false,
  placeholder = '',
  maxLength = 100,
  active = false,
  showError,
}) => {
  const { Icon, textColor, borderBottomColor } = fieldStyleProps(
    disabled,
    active,
    isValid,
    showError,
  );
  return (
    <Container>
      <Input
        data-test={selectorType}
        id={id}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={(e): false | void => pasteDisabled && e.preventDefault()}
        textStyle={textStyle}
        placeholderStyle={placeholderStyle}
        applyPlaceholderStyle={value === ''}
        alwaysCapitalised={alwaysCapitalised}
        alwaysLowerCase={alwaysLowerCase}
        borderBottomColor={borderBottomColor}
        textColor={textColor}
      />
      <IconContainer>{Icon && <Icon />}</IconContainer>
    </Container>
  );
};

export default TextInput;
