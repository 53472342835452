/* eslint-disable custom-rules/id-formats */
import React, { useState } from 'react';
import styled from 'styled-components';

import { LinkButton, IconList, TickIcon } from '@rentecarlo/component-library';

import { ComponentType, useComponentId, useOptimizelyTrack } from '@hooks';

import Arrow from './assets/arrow.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Text = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.standard};
  color: ${({ theme }) => theme.textPrimary};

  margin: -3px 0;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ArrowIcon = styled.img.attrs({ src: Arrow })``;

interface LearnMoreProps {
  savingNumber: string;
  savingPercentage: string;
}

const LearnMore: React.FC<LearnMoreProps> = ({ savingNumber, savingPercentage }) => {
  const creator = useComponentId();
  const sendEvent = useOptimizelyTrack();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <LinkButton
        id={creator(ComponentType.BUTTON, 'learnerToNewDriverModalLearnMore')}
        action={() => {
          sendEvent('learner_to_newdriver_learn_more');
          setIsOpen((value) => !value);
        }}
        bold
      >
        <ButtonContainer
          id={creator(ComponentType.BUTTON, 'learnerToNewDriverModalLearnMore-text')}
        >
          Learn More
          <ArrowIcon style={{ transform: `scaleY(${isOpen ? '-1' : '1'})` }} />
        </ButtonContainer>
      </LinkButton>
      {isOpen && (
        <IconList
          id={creator(ComponentType.CONTAINER, 'learnerToNewDriverModalLearnMore-list')}
          icon={TickIcon}
          items={[
            <Text id={creator(ComponentType.TEXT, 'learnerToNewDriverModalLearnMore-listItem1')}>
              <BoldText>Instant Coverage:</BoldText> Get covered immediately after passing your
              test. No need to shop around!
            </Text>,
            <Text id={creator(ComponentType.TEXT, 'learnerToNewDriverModalLearnMore-listItem2')}>
              <BoldText>Price Guarantee:</BoldText> Secure your discount for 6 months.
            </Text>,
            <Text id={creator(ComponentType.TEXT, 'learnerToNewDriverModalLearnMore-listItem3')}>
              <BoldText>Big Savings:</BoldText> Enjoy a {savingPercentage}% discount for the first
              month—save £{savingNumber}!
            </Text>,
            <Text id={creator(ComponentType.TEXT, 'learnerToNewDriverModalLearnMore-listItem4')}>
              <BoldText>No Strings Attached:</BoldText> Secure your price now, with no obligation to
              commit.
            </Text>,
            <Text id={creator(ComponentType.TEXT, 'learnerToNewDriverModalLearnMore-listItem5')}>
              <BoldText>No upfront costs:</BoldText> Only pay once you've passed your test.
            </Text>,
          ]}
        />
      )}
    </Container>
  );
};

export default LearnMore;
