import { VeygoResponseError } from '@utils/types';
import { RegisterActiontypes, types as registerTypes } from './register.actions';
import { types as loginTypes, LoginActiontypes } from './login.actions';

export interface RegisterState {
  customerUuid: string;
  email: string;
  errors: VeygoResponseError | null;
  loading: boolean;
  password: string;
  registrationToken: string | null;
}

// Reducer
export const initialState: RegisterState = {
  customerUuid: '',
  email: '',
  errors: null,
  loading: false,
  password: '',
  registrationToken: null,
};

export type RegisterTypes = RegisterActiontypes | LoginActiontypes;

export default function registerReducer(
  state = initialState,
  action: RegisterTypes,
): RegisterState {
  switch (action.type) {
    case registerTypes.SET_REGISTER_FIELDS:
      return {
        ...state,
        ...action.obj,
      };
    case registerTypes.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case registerTypes.REGISTER_SUCCESS:
      return {
        ...state,
        email: '',
        password: '',
        loading: false,
        registrationToken: action.token,
      };
    case registerTypes.REGISTER_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    case loginTypes.LOGOUT_SUCCESS:
      return initialState;
    case loginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case loginTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
