import { IndicativePrices } from '@redux/types';
import { DefaultDuration } from '@services/getDefaultDuration';

import { EndChoiceTypes } from './duration';

export enum types {
  INDICATIVE_PRICE_QUICK_SELECT = 'INDICATIVE_PRICE.QUICK_SELECT',
  QUICK_SELECT_PRICES_REQUEST = 'QUICK_SELECT_PRICES.REQUEST',
  QUICK_SELECT_PRICES_SUCCESS = 'QUICK_SELECT_PRICES.SUCCESS',
  QUICK_SELECT_PRICES_FAILURE = 'QUICK_SELECT_PRICES.FAILURE',
  SUBSCRIPTION_PRICES_SUCCESS = 'SUBSCRIPTION_PRICES_SUCCESS',
  SUBSCRIPTION_PRICES_FAILURE = 'SUBSCRIPTION_PRICES_FAILURE',
}

export interface QuickSelectIndicativePrice {
  type: typeof types.INDICATIVE_PRICE_QUICK_SELECT;
  duration: EndChoiceTypes;
}

export interface GetQuickSelectPrices {
  type: typeof types.QUICK_SELECT_PRICES_REQUEST;
  isSubscription: boolean;
  defaultDuration: DefaultDuration;
}

export interface GetQuickSelectPricesSuccess {
  type: typeof types.QUICK_SELECT_PRICES_SUCCESS;
  prices: IndicativePrices;
}

export interface GetQuickSelectPricesFailure {
  type: typeof types.QUICK_SELECT_PRICES_FAILURE;
}

export const quickSelectIndicativePrice = (
  duration: EndChoiceTypes,
): QuickSelectIndicativePrice => ({
  type: types.INDICATIVE_PRICE_QUICK_SELECT,
  duration,
});

export const getQuickSelectPrices = (
  isSubscription: boolean,
  defaultDuration: DefaultDuration,
): GetQuickSelectPrices => ({
  type: types.QUICK_SELECT_PRICES_REQUEST,
  isSubscription,
  defaultDuration,
});

export const getQuickSelectPricesSuccess = (
  prices: IndicativePrices,
): GetQuickSelectPricesSuccess => ({
  type: types.QUICK_SELECT_PRICES_SUCCESS,
  prices,
});

export const getQuickSelectPricesFailure = (): GetQuickSelectPricesFailure => ({
  type: types.QUICK_SELECT_PRICES_FAILURE,
});

export type IndicativePriceActions =
  | QuickSelectIndicativePrice
  | GetQuickSelectPrices
  | GetQuickSelectPricesSuccess
  | GetQuickSelectPricesFailure;
