import React, { FC } from 'react';

import {
  DetailRow,
  DetailRowHeader,
  QuoteRowContainer,
  DetailRowValue,
} from './assets/QuoteRowDetail';

type Props = {
  large?: boolean;
  label?: string;
  id?: string;
  className?: string;
  value?: string;
};

const QuoteRowDetail: FC<Props> = ({ large, label, id, className = '', value }) => (
  <QuoteRowContainer>
    <DetailRow>
      <DetailRowHeader id={`${id}Header`} large={large}>
        {label}
      </DetailRowHeader>
      <DetailRowValue id={id} large={large} className={className}>
        {value}
      </DetailRowValue>
    </DetailRow>
  </QuoteRowContainer>
);

export default QuoteRowDetail;
