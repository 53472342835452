import { connect } from 'react-redux';
import { addressToList } from '@services/address';
import {
  setPaymentField,
  stripeError,
  pollForQuoteStatus,
  resetPaymentError,
  completeFinalCheck,
} from '@reducer/quote/payment.actions';

import CardPaymentForm from '@organisms/forms/CardPaymentForm/CardPaymentForm';
import { Dispatch } from 'redux';
import { ConversionProject } from '@config/constants';
import { sendEvent } from '@reducer/analytics/actions';
import { PaymentMethod } from '@stripe/stripe-js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  return {
    cardHolderEmail: state.quote.driver.email,
    stripeClientSecret: state.quote.payment.paymentIntent.clientSecret,
    stripeClientStatus: state.quote.payment.paymentIntent.status,
    billingAddress: state.quote.payment.billingAddress,
    billingAddressPopulated: addressToList(state.quote.payment.billingAddress).length > 0,
    paymentError: state.quote.payment.error,
    errorCode: state.quote.payment.errorCode,
    declinedCode: state.quote.payment.declinedCode,
    paymentLoading: state.quote.payment.loading,
    enableCompletePurchase: state.config.enableCompletePurchase,
    isSubscription: state.quote.product.isSubscription,
    userIdentity: state.config.optimizelyAttributes.user_identity,
    productType: state.quote.product.productType,
    checksPassed: state.quote.payment.checksPassed,
    amount: Number(state.quote.save.price),
    stateConfirmationToken: state.quote.payment.confirmationToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  stripeError: (error: Error) => dispatch(stripeError(error)),
  pollForQuoteStatus: () => dispatch(pollForQuoteStatus()),
  setPaymentLoading: (loading: boolean) => dispatch(setPaymentField('loading', loading)),
  resetPaymentError: () => dispatch(resetPaymentError()),
  sendGAEventForPaymentFlow: (name: string, value: string) => {
    return dispatch(
      sendEvent(ConversionProject.ConversionOptimization, {
        featureFlagName: name,
        featureFlagValue: value,
      }),
    );
  },
  completeFinalCheck: (paymentMethod: PaymentMethod) => dispatch(completeFinalCheck(paymentMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentForm);
