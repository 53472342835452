export const types = {
  INIT: 'INIT_ADMIRAL_CSI',
  CHECK_SESSION: 'CHECK_SESSION',
};

export const initStore = () => ({
  type: types.INIT,
});

export const checkSession = () => ({
  type: types.CHECK_SESSION,
});
