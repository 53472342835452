import { PaymentMethod } from '@utils/types';

export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

export interface SetProduct {
  type: typeof SET_PRODUCT;
  product: string;
}

export interface SetSubscription {
  type: typeof SET_SUBSCRIPTION;
  isSubscription: boolean;
}

export interface SetPaymentMethod {
  type: typeof SET_PAYMENT_METHOD;
  paymentMethod: PaymentMethod | null;
}

export type ProductActions = SetProduct | SetSubscription | SetPaymentMethod;

export const setProduct = (product: string): SetProduct => ({
  type: SET_PRODUCT,
  product,
});

export const setSubscription = (isSubscription: boolean): SetSubscription => ({
  type: SET_SUBSCRIPTION,
  isSubscription,
});

export const setPaymentMethod = (paymentMethod: PaymentMethod | null): SetPaymentMethod => ({
  type: SET_PAYMENT_METHOD,
  paymentMethod,
});
