import React from 'react';

import CarReg from '@organisms/blocks/CarReg';
import CardCarIcon from '@assets/car-icn-tc.svg';
import { SeparationSpace, P, LinkButton, Card, CardUtils } from '@rentecarlo/component-library';
import colors from '@config/colors';
import PillButton from '@atoms/buttons/PillButton';
import { CarMakeAndModel, CarInfo, Footer, Icon } from './assets/styles';

export type Car = {
  make: string;
  model: string;
  transmission: string;
  // eslint-disable-next-line camelcase
  engine_size: number;
};

type Props = {
  title?: string;
  car: Car;
  registrationNumber: string;
  showNotch?: boolean;
  onEdit?: () => void;
  showFooter?: boolean;
};

const CarDetailsCard: React.FC<Props> = ({
  title = 'Car details',
  car,
  registrationNumber,
  showNotch = true,
  onEdit,
  children,
  showFooter = false,
}) => {
  const showEditButton = !!onEdit;
  return (
    <Card id='quoteStart-container-carDetails'>
      <CardUtils.CardWrapper>
        {showNotch && <CardUtils.StyledNotch />}
        <CardUtils.StyledCard>
          <CardUtils.Wrapper>
            <CardUtils.CardTitleWrapper>
              <CardUtils.CardTitleIcon icon={CardCarIcon} />
              <CardUtils.CardTitleTitle id='quoteStart-title-carDetails' fontSize={18}>
                {title}
              </CardUtils.CardTitleTitle>
            </CardUtils.CardTitleWrapper>
            {showEditButton && (
              <CardUtils.PillButtonWrapper>
                <PillButton
                  id='quoteStart-button-carDetailsEditCar'
                  onClick={onEdit}
                  padding='2px 16px'
                >
                  Edit
                </PillButton>
              </CardUtils.PillButtonWrapper>
            )}
          </CardUtils.Wrapper>
          <CardUtils.ChildrenWrapper id='quoteStart-container-carDetailsMakeAndModel'>
            <CarMakeAndModel id='quoteStart-text-carDetailsMakeAndModel'>
              {car.make} {car.model}
            </CarMakeAndModel>
            <CarReg reg={registrationNumber} />
            <CarInfo id='quoteStart-text-carDetailsCarInfo'>
              {car.transmission === 'm' ? 'Manual' : 'Automatic'} Transmission
              <br />
              {Math.round(car.engine_size / 100) / 10}L engine size
            </CarInfo>
            {children}
            {showFooter && showEditButton && (
              <>
                <SeparationSpace marginTop='24px' marginBottom='16px' />
                <Footer>
                  <Icon />
                  <P
                    id='quoteStart-paragraph-carDetailsFooter'
                    color={colors.grey.text.light}
                    fontSize={14}
                  >
                    If something isn't right, you can
                  </P>
                  <LinkButton id='quoteStart-button-carDetailsEditCar' action={onEdit} bold>
                    edit it
                  </LinkButton>
                </Footer>
              </>
            )}
          </CardUtils.ChildrenWrapper>
        </CardUtils.StyledCard>
      </CardUtils.CardWrapper>
    </Card>
  );
};

export default CarDetailsCard;
