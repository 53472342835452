import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  padding: 8px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textPrimary};
  font-family: Graphie;
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 40px;
  text-align: left;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
