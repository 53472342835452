export const capitaliseFirstLetter = (name: string): string =>
  name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

export const capitaliseFirstLetterText = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const capitaliseWords = (text: string): string => {
  if (!text) return '';

  const capitalizedWords: Array<string> = [];

  text
    .toLowerCase()
    .split(' ')
    .map((word) => capitalizedWords.push(word[0].toUpperCase() + word.substr(1)));

  return capitalizedWords.join(' ');
};
