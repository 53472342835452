import { FastRepurchaseType } from './fastRepurchaseTypes';

export enum types {
  SET_FAST_REPURCHASE_DATA = 'SET_FAST_REPURCHASE_DATA',
}

export interface SetFastRepurchaseData {
  type: typeof types.SET_FAST_REPURCHASE_DATA;
  data: FastRepurchaseType;
}

export type FastRepurchaseActions = SetFastRepurchaseData;

export const setFastRepurchaseData = (data: FastRepurchaseType): SetFastRepurchaseData => ({
  type: types.SET_FAST_REPURCHASE_DATA,
  data,
});
