import React from 'react';
import { Button, InformationBox } from '@rentecarlo/component-library';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';

import TextInputField from '@atoms/inputs/TextInputField';
import DateInputField from '@molecules/DateInputField';
import RelationshipSlide from '@molecules/slides/RelationshipSlide';
import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import { setUserField } from '@reducer/quote/user.actions';
import ReduxFieldValidation from '@services/formValidation';
import { FormContainer } from './styles';

const maxLength50 = ReduxFieldValidation.maxLength(50);

interface Props {
  close: () => void;
  driverDob: Date;
  onSubmit: (values: Record<string, unknown>) => void;
  ownerFormFields: Record<string, unknown>;
  productType: string;
}

const OwnerDetailsForm: React.FC<Props & InjectedFormProps> = ({
  close,
  driverDob,
  handleSubmit,
  onSubmit = (): void => {
    /* Explicitly empty for default value */
  },
  ownerFormFields,
  productType,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitForm = handleSubmit((values: any, dispatch: any) => {
    onSubmit(values);
    close();
    dispatch(setUserField('relationship', values.relationship));
  });

  return (
    <FormContainer>
      <Group>
        <SectionTitle id='quote-title-ownerDetailsOwnersName'>The car owner’s name</SectionTitle>
        <Field
          name='firstName'
          placeholder='First name'
          component={TextInputField}
          validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isName, maxLength50]}
          id='quote-field-ownerDetailsFirstName'
        />
        <Field
          name='surname'
          placeholder='Surname'
          component={TextInputField}
          validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isName, maxLength50]}
          id='quote-field-ownerDetailsSurname'
        />
      </Group>
      <Group>
        <SectionTitle id='quote-title-ownerDetailsRelationship'>Relationship</SectionTitle>
        <Field
          name='relationship'
          placeholder='select relationship'
          validate={[ReduxFieldValidation.isRequired]}
          component={RelationshipSlide}
          id='quote-field-ownerDetailsRelationshipSelector'
          productType={productType}
        />
      </Group>
      {ownerFormFields && ownerFormFields.relationship && (
        <Group>
          <SectionTitle id='quote-title-ownerDetailsDOBQuestion'>
            What is the owner's date of birth?
          </SectionTitle>
          <Field
            name='birthdate'
            id='quote-field-ownerDetailsBirthDate'
            driverDob={driverDob}
            component={DateInputField}
            validate={[
              ReduxFieldValidation.isValidDOB,
              ReduxFieldValidation.isOwnerOver16,
              ReduxFieldValidation.validateRelationshipAgainstAge,
            ]}
          />
        </Group>
      )}
      <Group>
        <SectionTitle id='quote-title-ownerDetailsEmailAddress'>
          The car owner's email address
        </SectionTitle>
        <Field
          name='email'
          type='email'
          placeholder='Email address'
          component={TextInputField}
          validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isEmail]}
          id='quote-field-ownerDetailsEmailAddress'
        />
        <InformationBox id='quote-text-ownerDetailsInformationBox' type='important'>
          We will send a confirmation of purchase to the owner once payment is taken. The above
          e-mail address will not be used for marketing purposes.
        </InformationBox>
      </Group>
      <Group>
        {/* This button has always used the default theme (no product type), for some reason */}
        <Button id='quote-button-ownerDetailsNext' height={56} onClick={submitForm}>
          Add owner
        </Button>
      </Group>
    </FormContainer>
  );
};

export default reduxForm({
  form: 'ownerDetailsForm',
})(OwnerDetailsForm);
