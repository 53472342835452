import styled from 'styled-components';
import colors from '@config/colors';

const ModalTitle = styled.h1`
  color: ${colors.grey.text.medium};
  font-family: 'gotham-rounded';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 38px;
  margin-bottom: 16px;
  text-transform: ${(props) => (props.isUpperCase ? 'uppercase' : 'none')};
`;

export default ModalTitle;
