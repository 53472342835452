import styled from 'styled-components';
import colors from '@config/colors';
import InfoIcon from '@assets/info-icon-sm.svg';

export const CarMakeAndModel = styled.h4`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 20px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
`;

export const CarInfo = styled.div`
  height: 38px;
  width: 224px;
  color: ${colors.dark.title};
  font-family: 'proxima-soft';
  font-size: 16px;
  letter-spacing: -0.22px;
  line-height: 19px;
`;

export const Footer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img.attrs({
  src: InfoIcon,
})`
  height: 14px;
  width: 14px;
`;
