export enum types {
  SELECT_VARIABLE_EXCESS = 'SELECT_VARIABLE_EXCESS',
}

export interface SetVariableExcess {
  type: types.SELECT_VARIABLE_EXCESS;
  excess: number;
}

export type ImportantInformationActions = SetVariableExcess;

export const setVariableExcess = (excess: number): SetVariableExcess => ({
  type: types.SELECT_VARIABLE_EXCESS,
  excess,
});
