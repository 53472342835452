import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import ToggleSelector from '@molecules/inputs/ToggleSelectorField';
import ReduxFieldValidation from '@services/formValidation';
import DropDownField from '@atoms/inputs/DropDownField/DropDownField.component';
import alternateVehicleOptions from '@config/alternateVehicleOptions';

interface Props {
  input: {
    onChange: (val: string) => void;
    value: string;
  };
  meta: {
    error?: string;
  };
  otherInsuranceSelected: boolean;
  getInsurersName: string;
  isDriverOwner: boolean;
}

const TypeOfUsageField: React.FC<Props & WrappedFieldProps> = ({ input, meta }: Props) => (
  <ToggleSelector
    input={input}
    meta={meta}
    groupName='hasAlternateVehicle'
    yesSubquestion={
      <>
        <SectionTitle id='drivingProfile-title-typeOfUsage'>What type of usage?</SectionTitle>
        <Field
          name='typeOfUsage'
          id='drivingProfile-field-typeOfUsageDropdown'
          component={DropDownField}
          placeholder='Select type of usage'
          options={alternateVehicleOptions}
          errorText='Type of usage is required.'
          validate={[ReduxFieldValidation.isRequired]}
        />
      </>
    }
  />
);

export default TypeOfUsageField;
