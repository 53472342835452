import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { RootState } from '@redux/reducer';

import { resetQuote } from '@reducer/quote/actions';
import { changeLicenceType } from '@reducer/quote/licence.actions';
import { LicenceTypes } from '@reducer/quote/licence';

import { isCassieProvLicence } from '@services/licence';
import { setProduct } from '@redux/reducer/quote/product.actions';
import LicenceError from './LicenceError';

interface StateProps {
  cassieLicenceType: string;
  productType: string;
}

interface DispatchProps {
  onExitQuote: (cassieLicenceType: string, productType: string) => void;
}

export interface ComponentProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: RootState): StateProps => ({
  cassieLicenceType: state.quote.licence.cassieType,
  productType: state.quote.product.productType,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onExitQuote: (cassieLicenceType: string, productType: string) => {
    if (isCassieProvLicence(cassieLicenceType)) {
      if (productType === 'newdriver') {
        dispatch(setProduct('ldp'));
      }
      dispatch(resetQuote());
      dispatch(push('/start'));
    } else {
      dispatch(changeLicenceType(LicenceTypes.uk_manual));
      dispatch(push('/start'));
    }
  },
});

const LicenceErrorContainer = connect(mapStateToProps, mapDispatchToProps)(LicenceError);

export default LicenceErrorContainer;
