const getUtmCookieValue = (cookieName: string): string => {
  const utmCookieMatch = document.cookie.match(new RegExp(`${cookieName}=([^;]+)`));
  if (!utmCookieMatch) {
    return '';
  }
  const utmCookieValue = utmCookieMatch[1];
  return utmCookieValue;
};

export default getUtmCookieValue;
