import deepUpdate from '../../../services/deepUpdate';
import { types as quoteTypes, QuoteActions } from './actions';
import { types as userTypes, UserActions } from './user.actions';
import { types as loginTypes } from '../account/login.actions';
import { types as lookupType } from '../lookup/actions';

import { ActionTypes, DriverActions } from './driver.actions';
import { DriverState } from './driverTypes';
import { types as fastRepurchasetypes } from './fastRepurchase.actions';

// Reducer
export const initialState: DriverState = {
  address: {
    isManual: false,
    country: 'GBR',
    county: '',
    flatNumber: '',
    houseName: '',
    houseNumber: '',
    line1: '',
    line2: '',
    postcode: '',
    road: '',
    town: '',
    lookupId: '',
    udprn: '',
    lookedUp: false,
    key: '',
  },
  birthdate: null,
  email: '',
  emailConfirmation: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  title: '',
  occupationType: '',
  occupation: '',
  isDriverOwner: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DriverActionTypes = DriverActions | UserActions | QuoteActions | any;
export default function driver(state = initialState, action: DriverActionTypes): DriverState {
  switch (action.type) {
    case ActionTypes.SET_DRIVER_FIELDS:
      return {
        ...state,
        ...action.obj,
      };
    case ActionTypes.SET_DRIVER_FIELD:
      return deepUpdate(state, action.field, action.value);
    case ActionTypes.SET_DRIVER_OWNER:
      return {
        ...state,
        isDriverOwner: action.driver,
      };
    case ActionTypes.SET_DRIVER_ADDRESS_FIELDS:
      return {
        ...state,
        address: {
          ...state.address,
          ...action.data,
          postcode:
            action.data.postcode !== undefined
              ? action.data.postcode.trim().toUpperCase()
              : state.address.postcode.trim(),
        },
      };
    case userTypes.PREFILL_CONNECTION:
    case userTypes.PREFILL_DRIVER:
    case userTypes.PREFILL_DRIVER_ON_EDIT:
      return {
        ...state,
        address:
          action.data.address.length > 0
            ? {
                ...state.address,
                isManual: action.data.address[0].isManual,
                country: action.data.address[0].country,
                county: action.data.address[0].county,
                flatNumber: action.data.address[0].flatNumber,
                houseName: action.data.address[0].houseName,
                houseNumber: action.data.address[0].houseNumber,
                line1: action.data.address[0].line1,
                line2: action.data.address[0].line2,
                postcode: action.data.address[0].postcode,
                road: action.data.address[0].road,
                town: action.data.address[0].town,
                lookupId: action.data.address[0].lookupId,
                udprn: action.data.address[0].udprn,
                key: null,
              }
            : state.address,
        birthdate: action.data.customer.birthdate,
        email: action.data.customer.email,
        emailConfirmation: action.data.customer.email,
        firstName: action.data.customer.firstName,
        lastName: action.data.customer.lastName,
        occupation: action.data.customer.occupation,
        occupationType: action.data.customer.occupationType,
        phoneNumber: action.data.customer.phoneNumber,
        title: action.data.customer.title,
      };
    case userTypes.PREFILL_OWNER:
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return initialState;
    case lookupType.LOOKUP_ADDRESS_FAILURE:
      return action.code === 'POSTCODE_NOT_FOUND'
        ? {
            ...state,
            address: {
              ...state.address,
              isManual: true,
            },
          }
        : state;
    case fastRepurchasetypes.SET_FAST_REPURCHASE_DATA:
      return {
        ...state,
        ...action.data.driver,
      };
    default:
      return state;
  }
}
