import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface ReferralState {
  /** The `bp_e` cookie value generated by Buyapowa when a friend is referred to us. Encoded in base64.
   *
   * Normally this is a URL parameter with the same name, but we wanted a newly-referred friend
   * to land on the CMS first to browse around. Instead of having to send the `?bp_e` URL parameter
   * on every link to the quote frontend, we decided to store it as a cookie and pull it out when they started
   * the quote.
   *
   * {@link https://docs.buyapowa.com/buyapowa-documentation/developer-resources-1/referral-metadata-in-url-parameter | Buyapowa Docs > Referral metadata in URL parameter}
   */
  buyapowaEncryptedValue: string | null;
}

export const initialState: ReferralState = {
  buyapowaEncryptedValue: null,
};

const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    buyapowaCookieFound: (state, action: PayloadAction<string>) => {
      state.buyapowaEncryptedValue = action.payload;
    },
  },
});

const { actions, reducer } = referralSlice;
export const { buyapowaCookieFound } = actions;

export default reducer;
