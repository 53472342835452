import React from 'react';

import styled from 'styled-components';
import colors from '@config/colors';

export const Input = styled.input`
  background-color: ${(props) => (props.disabled ? colors.grey.background.disabled : colors.white)};
  border: 1px solid ${colors.grey.lines.light};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  color: ${colors.grey.text.light};
  font-family: 'proxima-soft';
  font-size: 14px;
  height: 35px;
  padding: 8px 30px 8px 15px;
  line-height: 16px;
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'inherit')};
`;

const TextInput = ({
  disabled,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  textStyle,
  placeholderStyle,
  id,
}) => (
  <Input
    disabled={disabled}
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    textStyle={textStyle}
    placeholderStyle={placeholderStyle}
    applyPlaceholderStyle={value === ''}
    id={id}
  />
);

TextInput.defaultProps = {
  disabled: false,
  isValid: false,
  placeholder: '',
  onBlur: () => {},
  type: 'text',
};

export default TextInput;
