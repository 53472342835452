import React from 'react';
import styled from 'styled-components';
import { WrappedFieldProps } from 'redux-form';
import { FieldContainer, Tick } from '@rentecarlo/component-library';

import colors from '@config/colors';

interface ConfirmationBoxProps {
  disabled?: boolean;
}

const ConfirmationBox = styled.div<ConfirmationBoxProps>`
  display: flex;
  align-items: center;
  height: 75px;
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 3px 0 ${colors.shadow};
  opacity: ${(props) => (props.disabled ? '0.5' : '1.0')};
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  background-color: ${colors.white};
`;

const ConfirmationBody = styled.span`
  font-family: 'Proxima-Soft';
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-right: 16px;
`;

export const TickBoxContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-left: 16px;
  margin-right: 16px;
`;

interface Props extends WrappedFieldProps {
  body: React.ReactNode;
  id: string;
  disabled?: boolean;
  selectorType: string;
}

const FlatButton: React.FC<Props> = ({
  disabled = false,
  input: { onChange, value },
  selectorType,
  id,
  body,
  meta: { error, submitFailed },
}) => (
  <FieldContainer id={id} showErrorContainer={submitFailed && error}>
    <ConfirmationBox
      id='quote-container-confirmationBox'
      onClick={() => {
        if (!disabled) {
          onChange(!value);
        }
      }}
      disabled={disabled}
    >
      <TickBoxContainer>
        <Tick data-test={selectorType} status={value ? 'active' : 'empty'} />
      </TickBoxContainer>
      <ConfirmationBody id='quote-component-confirmationBody'>{body}</ConfirmationBody>
    </ConfirmationBox>
  </FieldContainer>
);

export default FlatButton;
