import styled from 'styled-components';
import colors from '@config/colors';
import GBLicenceIcon from '@assets/gb-licence-img-small.png';

type RegProps = {
  marginTop?: string;
};

export const RegContainer = styled.div<RegProps>`
  box-sizing: border-box;
  height: 32px;
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: ${({ marginTop }: RegProps): string => marginTop || ''};
`;

export const LicenceIconWrapper = styled.div`
  width: 21px;
  height: 32px;
  background-color: ${colors.teal};
`;

export const LicenceIcon = styled.img.attrs(() => ({
  src: GBLicenceIcon,
}))`
  width: 21px;
  height: 32px;
`;

export const LicenceText = styled.div`
  font-family: UKNumberPlate;
  font-size: 16px;
  line-height: 16px;
  margin: 0 8px 0 8px;
  color: ${colors.outerSpace};
`;
