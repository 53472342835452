import React from 'react';
import DateInput from '@atoms/inputs/DateInput';

const DateField = (props) => {
  const {
    input: { value, onChange, onBlur },
    meta: { valid, error, touched },
    prefilled,
  } = props;

  return (
    <DateInput
      date={value}
      prefilled={prefilled}
      setDate={onChange}
      onBlur={onBlur}
      valid={valid}
      touched={touched}
      error={error}
    />
  );
};

export default DateField;
