import { ROUTES } from '../../../../router';

const checkPath = (pathname: string, currentRoute: string): boolean =>
  [pathname, `/${pathname}`].includes(`/${currentRoute}`);

export function getProgress(pathname: string, productType: string): number {
  const routes = ROUTES.filter(
    (route) => !(route.products && !route.products.includes(productType)),
  );

  if (routes === undefined) return 0;

  let index;
  let i;
  for (i = 0; i < routes.length; i += 1) {
    if (checkPath(pathname, routes[i].path)) {
      index = i;
      break;
    }
  }

  if (index !== undefined) {
    const percentage = Math.floor((100 / (routes.length - 1)) * index);
    return percentage;
  }

  return 0;
}
