import React from 'react';
import Select from '@molecules/selects/Select';

const SelectField = (props) => {
  const {
    disabled,
    id,
    input: { value, onChange },
    meta: { valid },
    options,
    placeholder,
    type,
    desktopRightMargin,
  } = props;
  return (
    <Select
      id={id}
      onChange={onChange}
      value={value}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      options={options}
      showValidateTick={value && valid}
      desktopRightMargin={desktopRightMargin}
    />
  );
};

SelectField.defaultProps = {
  options: [],
  type: 'default',
};

export default SelectField;
