// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */

import { css } from 'styled-components';

export const desktopWidth = 1024;
export const tabletWidth = 480;
export const formPadding = 16;
const mobileXS = 320

const media = {
  desktop: (...args: any) => css`
    @media (min-width: ${desktopWidth}px) {
      ${css(...args)}
    }
  `,
  ie11: (...args: any) => css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      ${css(...args)}
    }
  `,
  mobileXS: (...args: any) => css`
    @media (max-width: ${mobileXS}px) {
      ${css(...args)}
    }
  `,
  mobile: (...args: any) => css`
    @media (max-width: ${tabletWidth}px) {
      ${css(...args)}
    }
  `,
  tablet: (...args: any): any => css`
    @media (max-width: ${desktopWidth - 1}px) {
      ${css(...args)}
    };
  `,
};

export default media;
