import React, { FC } from 'react';
import styled from 'styled-components';

import InfoIconSvg from '@assets/info-icon-sm.svg';
import colors from '@config/colors';

const Container = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  margin-right: 4px;
`;

const Text = styled.div`
  color: ${colors.grey.text.light};
  font-family: proxima-soft;
  font-size: 14px;
  line-height: 17px;
  width: 350px;
`;

type Props = {
  text?: string;
  id?: string;
  children?: React.ReactNode;
};

const InfoTag: FC<Props> = ({ children, text, id }) => (
  <Container id={id}>
    <IconContainer>
      <img src={InfoIconSvg} alt='info-icon' />
    </IconContainer>
    {text ? <Text id='aboutYou-text-infoTag'>{text}</Text> : children}
  </Container>
);

export default InfoTag;
