import { SetStateFieldActionCreator } from '@utils/types';
import { NewDriver } from './newdriver';

export enum types {
  SET_NEWDRIVER_FIELD = 'SET_NEWDRIVER_FIELD',
}

type SetNewDriverField = ReturnType<typeof setNewDriverField>;

export type NewDriverActions = SetNewDriverField;

export const setNewDriverField: SetStateFieldActionCreator<
  NewDriver,
  typeof types.SET_NEWDRIVER_FIELD
> = (field, value) => ({
  type: types.SET_NEWDRIVER_FIELD,
  field,
  value,
});
