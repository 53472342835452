import factory from './factory';

export default factory({
  isManual: 'is_manual',
  country: 'country',
  county: 'county',
  flatNumber: 'flat_no',
  houseName: 'house_name',
  houseNumber: 'house_no',
  line1: 'line1',
  line2: 'line2',
  postcode: 'postcode',
  road: 'road',
  town: 'town',
  udprn: 'udprn',
  uuid: 'uuid',
  lookupId: 'lookup_id',
});
