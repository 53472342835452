import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { logout } from '@reducer/account/login.actions';
import NavBar from './NavBar';

export type Props = {
  productType: string;
  loggedIn: boolean;
};

export type DispatchProps = {
  logout: () => void;
  goToAccount: () => void;
  goToDocuments: () => void;
};

// eslint-disable-next-line
const mapStateToProps = ({ quote, account }: any): Props => ({
  productType: quote.product.productType,
  loggedIn: account.login.loggedIn,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  logout: (): void => dispatch(logout()),
  goToAccount: (): void => dispatch(push('account')),
  goToDocuments: (): void => dispatch(push('account/documents')),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
