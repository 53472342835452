import styled, { css } from 'styled-components';

import colors from '@config/colors';

interface CardProps {
  checked: boolean;
}

export const IconContainer = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  right: 5px;
  top: 3px;
`;
export const Container = styled.div`
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  position: relative;
`;

export const Card = styled.div<CardProps>`
  align-items: center;
  background: ${({ checked }) => (checked ? colors.green.selected : colors.white)};
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  color: ${({ checked }) => (checked ? colors.white : colors.outerSpace)};
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: center;
  width: 100%;

  ${({ checked }) => css`
    &:hover {
      background-color: ${!checked && colors.grey.background.hover};
    }
  `}
`;

export const Title = styled.div`
  font-family: proxima-soft;
  font-size: 26px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  padding-bottom: 5px;
`;

export const SubTitle = styled.div`
  font-family: proxima-soft;
  font-size: 12px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
`;
