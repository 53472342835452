import deepUpdate from '../../../services/deepUpdate';

import { PurchasedQuote } from './purchasedQuoteTypes';

import { types as paymentTypes, PaymentActions } from './payment.actions';
import { types as purchasedQuoteTypes, PurchaseQuoteActions } from './purchasedQuote.actions';
import { types as quoteTypes, QuoteActions } from './actions';

// Reducer
export const initialState: PurchasedQuote = {
  uuid: '',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PurchaseQuoteActionTypes = PurchaseQuoteActions | PaymentActions | QuoteActions;

export default function payment(
  state: PurchasedQuote = initialState,
  action = {} as PurchaseQuoteActionTypes,
): PurchasedQuote {
  switch (action.type) {
    case paymentTypes.PAYMENT_SUCCESS:
      return {
        uuid: action.data.uuid,
      };
    case purchasedQuoteTypes.SET_PURCHASED_QUOTE_FIELD:
      return deepUpdate(state, action.field, action.value);
    case quoteTypes.START_QUOTE:
      return initialState;
    default:
      return state;
  }
}
