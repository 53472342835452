import React from 'react';
import styled from 'styled-components';
import SidebarTitle from '@atoms/titles/componentTitles/SidebarTitle';
import HelpfullLinkRow from '@atoms/links/HelpfullLinkRow';
import colors from '@config/colors';

const Container = styled.aside`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
`;

const HelpBox = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.grey.lines.light};
  border-top-left-radius: ${(props) => (props.fullscreen ? '0' : '4px')};
  border-top-right-radius: ${(props) => (props.fullscreen ? '0' : '4px')};
  height: 480px;
  padding: 32px 24px;
`;

const AltSidebarTitle = styled(SidebarTitle)`
  margin-bottom: 20px;
`;

const LightParagraph = styled.p`
  color: ${colors.grey.paragraphs.light};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  padding-bottom: 24px;
`;

const RejectionSidebar = ({ fullscreen, faqUrl }) => (
  <Container>
    <HelpBox fullscreen={fullscreen}>
      <AltSidebarTitle id='rejection-title-helpfulLinksSidebar'>HELPFUL LINKS</AltSidebarTitle>
      <LightParagraph id='rejection-paragraph-furtherQuestions'>
        If you have any further questions about our products or services please visit the links
        below.
      </LightParagraph>
      <HelpfullLinkRow text='FAQs' url={faqUrl} />
      <HelpfullLinkRow text='Help Centre' url='https://www.veygo.com/help' />
      <HelpfullLinkRow text='Homepage' url='https://www.veygo.com' />
    </HelpBox>
  </Container>
);

RejectionSidebar.defaultProps = {
  fullscreen: false,
  title: undefined,
};

export default RejectionSidebar;
