import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import { hideAlertBanner } from '@redux/reducer/app/actions';

import { useOptimizelyFlag } from '@hooks';

import ChristmasIcon from '@assets/tree-decorated.svg';
import ErrorIcon from '@assets/error-triangle.svg';
import { RootState } from '@redux/reducer';

import { SiteAlertBanner } from '@rentecarlo/component-library';

const AlertContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 50px;
`;

const AlertsBanner: React.FC = () => {
  const showAlertBanner = useOptimizelyFlag('PERM_SHOW_ALERT_BANNER');

  const showAlert = useSelector((state: RootState) => state.app.showAlertBanner);
  const dispatch = useDispatch();

  return showAlertBanner.enabled && showAlert ? (
    <div id='alerts-placeholder'>
      <AlertContainer>
        <SiteAlertBanner
          onClose={
            showAlertBanner.variables.show_close_button
              ? () => dispatch(hideAlertBanner())
              : undefined
          }
          message={showAlertBanner.variables.message}
          icon={showAlertBanner.variables.show_christmas_tree ? ChristmasIcon : ErrorIcon}
        />
      </AlertContainer>
    </div>
  ) : null;
};

export default AlertsBanner;
