import React from 'react';
import { Field } from 'redux-form';

import styled from 'styled-components';
import colors from '@config/colors';

import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import ToggleButtons from '@molecules/inputs/ToggleButtonsField';
import ncbValues from '@config/NCBValues';
import MultipleChoiceField from '@molecules/inputs/MultipleChoiceField';
import ReduxFieldValidation from '@services/formValidation';
import ReduxFieldParsers from '@services/formParsers';

const ToggleButtonField = ({
  input,
  name,
  meta,
  displayLeftError,
  id,
  hasValidationError,
  validationErrorMessage,
}) => (
  <ToggleButtons
    value={input.value}
    onSelect={input.onChange}
    error={meta.error}
    groupName={name}
    displayLeftError={displayLeftError}
    id={id}
    hasValidationError={hasValidationError}
    validationErrorMessage={validationErrorMessage}
    page='drivingHistory'
  />
);

const questionText = (isQuoteForMyself) => {
  return `Do ${isQuoteForMyself ? 'you' : 'they'} have a valid no claims bonus certificate (NCB)?`;
};

const Box = styled.div`
  border-radius: 4px;
  background-color: ${colors.grey.background.light};
  padding: 30px 24px;
`;

const BoxTitleContainer = styled.div`
  margin-right: 64px;
`;

const NCBForm = ({ haveNCB, submitFailed, formErrors, isQuoteForMyself }) => (
  <Group>
    <SectionTitle id='drivingHistory-title-NCB'>{questionText(isQuoteForMyself)}</SectionTitle>
    <Field
      props={{ name: 'haveNCB' }}
      name='haveNCB'
      component={ToggleButtonField}
      validate={[ReduxFieldValidation.required]}
      id='drivingHistory-field-haveNCB'
      hasValidationError={submitFailed && formErrors && formErrors.ncb && formErrors.ncb.haveNCB}
      validationErrorMessage='No claims bonus certificate required'
    />
    {haveNCB && (
      <Box>
        <BoxTitleContainer>
          <SectionTitle id='drivingHistory-title-NCBYears'>
            {`How many years no claims do ${isQuoteForMyself ? 'you' : 'they'} have?`}
          </SectionTitle>
        </BoxTitleContainer>
        <Field
          name='level'
          id='drivingHistory-field-NCBLevel'
          component={MultipleChoiceField}
          options={ncbValues}
          validate={[ReduxFieldValidation.isRequired]}
          parse={ReduxFieldParsers.toInteger}
        />
      </Box>
    )}
  </Group>
);

NCBForm.defaultProps = {
  haveNCB: null,
  level: null,
};

export default NCBForm;
