import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children?: ReactNode;
  useFlex?: boolean;
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 1;
  backdrop-filter: blur(2px);
  overflow: scroll;
  ${(props: Props): string =>
    props.useFlex
      ? `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Places modal panel one-third down the page
    &:before {
      content: '';
      flex: 1;
    }

    &:after {
      content: '';
      flex: 3;
    }
  `
      : ''}
  }
`;

const BackdropComponent: React.FC<Props> = ({ children, useFlex }) => (
  <Backdrop id='modal-backdrop' useFlex={useFlex}>
    {children}
  </Backdrop>
);

Backdrop.defaultProps = {
  useFlex: false,
};

export default BackdropComponent;
