export default [
    {
        name: 'Policyholder on another car',
        value: 'ph_car'
    },
    {
        name: 'Named driver on another car',
        value: 'nd_car'
    },
    {
        name: 'Company car including personal use',
        value: 'cc_inc'
    },
    {
        name: 'Company car excluding personal use',
        value: 'cc_exc'
    },
    {
        name: 'Policyholder on a van',
        value: 'ph_van'
    },
    {
        name: 'Named driver on a van',
        value: 'nd_van'
    },
    {
        name: 'Company van including personal use',
        value: 'cv_inc'
    },
    {
        name: 'Company van excluding personal use',
        value: 'cv_exc'
    },
    {
        name: 'Motorcycle',
        value: 'Motorcycle'
    },
]