import deepUpdate from '../../../services/deepUpdate';
import { types as quoteTypes, QuoteActions } from './actions';
import { FastRepurchaseActions, types as fastRepurchasetypes } from './fastRepurchase.actions';
import { types, LegalTypes } from './legal.actions';

export interface State {
  sameNameConfirmed: boolean;
  loginPoint: string;
  privacyPolicyConfirmed: boolean;
}

// Reducer
export const initialState: State = {
  sameNameConfirmed: false,
  loginPoint: '', // represents when the customer logged in during the quote
  privacyPolicyConfirmed: false,
};

type LegalActionTypes = LegalTypes | QuoteActions | FastRepurchaseActions;
export default function legal(state = initialState, action: LegalActionTypes): State {
  switch (action.type) {
    case types.SET_LEGAL_FIELD:
      return deepUpdate(state, action.field, action.value);
    case types.TOGGLE_PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicyConfirmed: !state.privacyPolicyConfirmed,
      };
    case quoteTypes.RESET_QUOTE:
      return initialState;
    case fastRepurchasetypes.SET_FAST_REPURCHASE_DATA:
      return {
        ...state,
        ...action.data.legal,
      };
    default:
      return state;
  }
}
