import React from 'react';

import TickIcon from '@assets/tick-radio-button.svg';
import { DurationOptions } from '@config/indicativeDurations';
import { IconContainer, Container, Card, Title, SubTitle } from './assets/styles';

interface Props {
  checked: boolean;
  option: DurationOptions;
  onSelect: (durationObject: DurationOptions) => void;
  id: string;
  text: string;
  sub: string;
}

const DurationRadioButton: React.FC<Props> = ({ checked, option, onSelect, id, text, sub }) => (
  <Container>
    <Card onClick={() => onSelect(option)} checked={checked} id={id}>
      <Title id='indicativePrice-title-durationRadioButton'>{text}</Title>
      <SubTitle id='indicativePrice-subtitle-durationRadioButton'>{sub}</SubTitle>
      {checked && <IconContainer src={TickIcon} />}
    </Card>
  </Container>
);

export default DurationRadioButton;
