import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { ANALYTICS_SEND_EVENT, SendEvent } from '@redux/reducer/analytics/actions';

export function* sendGAEvent({ event, data }: SendEvent): Generator<boolean> {
  window.dataLayer.push({
    event,
    ...data,
  });
  yield true;
}

export function* watchAnalytics(): SagaIterator {
  yield takeEvery(ANALYTICS_SEND_EVENT, sendGAEvent);
}
