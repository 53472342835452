/* eslint-disable */

import { default as _isObject } from 'lodash/isObject';
import { default as _mapKeys } from 'lodash/mapKeys';
import { default as _merge } from 'lodash/merge';

/* Given:
 *   (['a', 'b', 'c'], 1)
 * Returns:
 *   { a: { b: { c: 1 } } }
 */
export const buildTree = (keys, value) => {
  if (keys.length == 0) return value;

  const root = keys.shift();
  return {
    [root]: buildTree(keys, value),
  };
};

/* Given:
 *   {
 *     'a.b': 1,
 *     'a.c': 2,
 *     'd.e': 3,
 *     'f': 4
 *   }
 * Returns:
 *   {
 *     a: {
 *       b: 1,
 *       c: 2
 *     },
 *     d: {
 *       e: 3
 *     },
 *     f: 4
 *   }
 */
export const expandKeys = obj => {
  const forest = Object.keys(obj).map(key => {
    const value = obj[key];
    const tree = buildTree(key.split('.'), value);

    return tree;
  });

  return _merge(...forest);
};

/* Inverse of `expandKeys`.
 * Given:
 *   {
 *     a: {
 *       b: 1,
 *       c: 2
 *     },
 *     d: {
 *       e: 3
 *     },
 *     f: 4
 *   }
 * Returns:
 *   {
 *     'a.b': 1,
 *     'a.c': 2,
 *     'd.e': 3,
 *     'f': 4
 *   }
 */
export const retractKeys = obj => {
  if (!_isObject(obj)) return obj;

  return Object.keys(obj).reduce((acc, key) => {
    const child = retractKeys(obj[key]);

    const newObj = _isObject(child)
      ? _mapKeys(child, (v, subKey) => `${key}.${subKey}`)
      : { [key]: child };

    return {
      ...acc,
      ...newObj,
    };
  }, {});
};

export const mapKeys = mapping =>  data => {
  const retractedData = retractKeys(data);
  const retractedResult = _mapKeys(
    retractedData,
    (v, key) => mapping[key],
  );

  if (retractedResult.undefined !== undefined) {
    delete retractedResult.undefined;
  }

  return expandKeys(retractedResult);
};
