import React from 'react';
import { useSelector } from 'react-redux';
import { PageModal, RoundedGreyBox, TextLink } from '@rentecarlo/component-library';
import styled from 'styled-components';

import BreakdownTerms from '@atoms/blocks/BreakdownTerms';
import { getBreakdownCoverIPIDUrl } from '@redux/reselectors/files';
import { Title, TitleSpacer, Wrapper } from './styles';

interface Props {
  show: boolean;
  close: () => void;
}

const BreakdownTermsContainer = styled.div`
  margin: 60px 0 65px;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
`;

const BreakdownModal: React.FC<Props> = ({ show, close }) => {
  const closeText = 'Close';
  const breakdownIPIDURL = useSelector((state) => getBreakdownCoverIPIDUrl(state));

  return (
    <PageModal close={close} closeText={closeText} show={show}>
      <Wrapper>
        <Title id='importantInformation-title-breakdownModal'>Breakdown cover</Title>
        <TitleSpacer />
        <RoundedGreyBox id='importantInformation-text-breakdownModalProvider'>
          Your breakdown cover will be provided by our partner The AA. Here's what's included.
        </RoundedGreyBox>
        <BreakdownTermsContainer>
          <BreakdownTerms
            id='importantInformation-container-breakdownModalRoadsideRepair'
            title='Roadside repair'
            showSeparator
          >
            The AA will try to fix your vehicle at the scene in under 60 minutes.
          </BreakdownTerms>
          <BreakdownTerms
            id='importantInformation-container-breakdownModalHomeStart'
            title='Home start'
            showSeparator
          >
            Get roadside assistance if you can't get going within a 1/4 mile of your home.
          </BreakdownTerms>
          <BreakdownTerms
            id='importantInformation-container-breakdownModalNationwideRecovery'
            title='Nationwide recovery'
            showSeparator
          >
            If The AA are unable to repair your vehicle, The AA will help you finish your journey by
            towing you, your vehicle and up to 7 passengers wherever you need to go.
          </BreakdownTerms>
          <BreakdownTerms
            id='importantInformation-text-breakdownModalTerms'
            title='Cancellation'
            showSeparator
          >
            Your breakdown cover is tied to your car insurance policy. Therefore in order to cancel
            one, you will need to cancel the other.
            <br />
            <br /> Your breakdown cover is also non-refundable. Therefore if you cancel your car
            insurance policy, you will receive a refund for the unused part of your car insurance
            policy, but not for your breakdown cover.
          </BreakdownTerms>
          <BreakdownTerms
            id='importantInformation-text-breakdownModalLink'
            title='View product summary'
          >
            View{' '}
            <TextLink
              id='importantInformation-link-breakdownModalTerms'
              bold={false}
              fontSize={14}
              href={breakdownIPIDURL}
            >
              Breakdown Insurance Product Information Document
            </TextLink>
          </BreakdownTerms>
        </BreakdownTermsContainer>
      </Wrapper>
    </PageModal>
  );
};

export default BreakdownModal;
