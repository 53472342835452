import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '@redux/reducer';
import { upgradeCustomerRequest } from '@reducer/account/customer.actions';
import { hasBoughtBeforeSelector } from '@reselectors/quote';
import UpgradeAccount from './UpgradeAccount';

interface StateProps {
  hasBoughtBefore: boolean;
  productType: string;
}

interface DispatchProps {
  continue: () => void;
}

export interface ComponentProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: RootState): StateProps => ({
  hasBoughtBefore: hasBoughtBeforeSelector(state),
  productType: state.quote.product.productType,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  continue: () => {
    dispatch(upgradeCustomerRequest());
  },
});

const UpgradeAccountContainer = connect(mapStateToProps, mapDispatchToProps)(UpgradeAccount);

export default UpgradeAccountContainer;
