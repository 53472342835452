import React, { useState } from 'react';
import { ComponentType, useComponentId } from '@hooks';
import { DropDown, Icon, DropDownTitle, DropDownContent } from './assets/styles';

type Props = {
  children: React.ReactNode;
  id?: string;
  title: string;
  onClick?: () => void;
};

const DropDownDisplay: React.FC<Props> = ({ children, title, onClick }) => {
  const creator = useComponentId();
  const [isOpen, setIsOpen] = useState(false);
  const onDropDownClick = () => {
    setIsOpen(!isOpen);
    if (onClick) {
      onClick();
    }
  };
  return (
    <DropDown onClick={onDropDownClick} id={creator(ComponentType.CONTAINER, 'dropdownDisplay')}>
      <DropDownTitle id={creator(ComponentType.CONTAINER, 'dropdownTitle')}>
        {title}
        <Icon isOpen={isOpen} />
      </DropDownTitle>
      {isOpen && <DropDownContent>{children}</DropDownContent>}
    </DropDown>
  );
};

export default DropDownDisplay;
