import React, { FC } from 'react';
import { Tick } from '@rentecarlo/component-library';

import { ComponentType, useComponentId } from '@hooks';
import {
  Container,
  TextContainer,
  QuestionType,
  QuestionDescription,
  TickBoxContainer,
} from './assets/QuestionItem';

type Props = {
  value: string;
  title?: string;
  id?: string;
  description?: string;
  applyTransparency: boolean;
  selectQuestion: (value: string) => void;
  isSelected?: boolean;
};

const QuestionItem: FC<Props> = ({
  selectQuestion,
  value,
  title,
  id,
  description,
  applyTransparency,
  isSelected,
}) => {
  const creator = useComponentId();
  return (
    <Container onClick={() => selectQuestion(value)}>
      <TextContainer applyTransparency={applyTransparency}>
        <QuestionType id={id}>{title}</QuestionType>
        <QuestionDescription id={creator(ComponentType.TEXT, 'questionDescription')}>
          {description}
        </QuestionDescription>
      </TextContainer>
      <TickBoxContainer applyTransparency={applyTransparency}>
        <Tick status={isSelected ? 'active' : 'inactive'} size={31} />
      </TickBoxContainer>
    </Container>
  );
};

export default QuestionItem;
