import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation, useHistory } from 'react-router-dom';
import {
  WrappedFieldProps,
  Field,
  reduxForm,
  InjectedFormProps,
  formValueSelector,
  destroy,
} from 'redux-form';
import { RootState } from '@redux/reducer';

import styled from 'styled-components';
import { saveAndGoTo, setIsFastPurchase } from '@redux/reducer/quote/save.actions';
import QuoteStep from '@templates/QuoteStep';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import ToggleSelector from '@molecules/inputs/ToggleSelectorField';
import ReduxFieldValidation from '@services/formValidation';
import SingleDateCalendar from '@molecules/calendars/SingleDateCalendar';
import { FieldContainer } from '@rentecarlo/component-library';
import { setHasPassed, setDrivingTestDate } from '@redux/reducer/quote/drivingTest.actions';
import { setShouldShowSideBar } from '@redux/reducer/account/customer.actions';
import { resetQuote } from '@redux/reducer/quote/actions';
import { togglePrivacyPolicy } from '@redux/reducer/quote/legal.actions';

dayjs.extend(utc);
dayjs.extend(timezone);

const DateContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 8px;
  padding: 16px 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
`;

const CalendarField: React.FC<WrappedFieldProps> = ({ input, meta }) => (
  <FieldContainer
    showErrorContainer={meta.submitFailed && meta.error}
    showError={meta.submitFailed && meta.error}
    error={meta.submitFailed && meta.error}
    showErrorImage={meta.submitFailed && meta.error}
  >
    <DateContainer>
      <SingleDateCalendar
        date={input.value || undefined}
        onChange={(date: Dayjs) => input.onChange(date)}
      />
    </DateContainer>
  </FieldContainer>
);

const PassedTestField: React.FC<WrappedFieldProps> = ({ input, meta }) => (
  <FieldContainer
    showErrorContainer={meta.submitFailed && meta.error}
    showError={meta.submitFailed && meta.error}
    error={meta.submitFailed && meta.error}
    showErrorImage={meta.submitFailed && meta.error}
  >
    <ToggleSelector
      leftLabel='Yes'
      rightLabel='Not yet'
      groupName='hasPassedTest'
      input={input}
      meta={meta}
    />
  </FieldContainer>
);

interface LocationState {
  destination: string;
  checkProductUw: boolean;
}

const DrivingTest: React.FC<InjectedFormProps> = ({ handleSubmit, submitFailed, valid }) => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const dispatch = useDispatch();

  const { destination = '/important-information', checkProductUw = true } = location.state || {};

  const hasPassedValue = useSelector((state: RootState) =>
    formValueSelector('drivingTestForm')(state, 'hasPassedTest'),
  );
  const isFastRepurchase = useSelector((state: RootState) => state.quote.save.isFastRepurchase);

  useEffect(() => {
    return history.listen((newLocation, action) => {
      if (action === 'POP' && isFastRepurchase && newLocation.pathname === '/quote-start') {
        dispatch(setIsFastPurchase(false));
        dispatch(setShouldShowSideBar(true));
        dispatch(resetQuote());
        dispatch(destroy('quoteStartForm'));
        dispatch(togglePrivacyPolicy());
      }
    });
  }, [dispatch, isFastRepurchase, history]);

  return (
    <QuoteStep
      id='drivingTest-component-quoteStep'
      stepTitle='Driving test'
      showButton
      nextLabel='Continue'
      nextAction={handleSubmit((values: Record<string, unknown>) => {
        if (values.drivingTestDate) {
          if (dayjs().diff(values.drivingTestDate as string, 'days') > 90) {
            dispatch(push('licence-switch/error'));
            return;
          }

          dispatch(setDrivingTestDate(values.drivingTestDate as Dayjs));
        }
        dispatch(setHasPassed(values.hasPassedTest === 'true'));
        dispatch(saveAndGoTo(destination, checkProductUw));
      })}
      submitError={submitFailed && !valid}
    >
      <Group id='drivingTest-group-group'>
        <SectionTitle id='drivingTest-field-title'>Have you passed your driving test?</SectionTitle>
        <Field
          name='hasPassedTest'
          id='drivingTest-field-hasPassedTest'
          component={PassedTestField}
          validate={[ReduxFieldValidation.required]}
        />
        {hasPassedValue === 'true' && (
          <>
            <SectionTitle id='drivingTest-title-drivingTestDate'>
              What date did you pass your driving test?
            </SectionTitle>
            <Field
              name='drivingTestDate'
              component={CalendarField}
              id='drivingTest-field-drivingTestDate'
              validate={ReduxFieldValidation.required}
            />
          </>
        )}
      </Group>
    </QuoteStep>
  );
};

export default reduxForm({
  form: 'drivingTestForm',
})(DrivingTest);
