import React, { ReactElement } from 'react';
import { SectionTitleWithTooltipContainer, SectionTitle, IconContainer } from './assets/styles';

interface Props {
  title: string;
  tooltip: ReactElement;
}

const SectionTitleWithTooltip = ({ title, tooltip }: Props): ReactElement => (
  <SectionTitleWithTooltipContainer>
    <SectionTitle id='car-title-sectionTitleWithToolTip'>{title}</SectionTitle>
    <IconContainer id='car-icon-sectionTitleWithToolTip'>{tooltip}</IconContainer>
  </SectionTitleWithTooltipContainer>
);

export default SectionTitleWithTooltip;
