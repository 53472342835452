import { push } from 'connected-react-router';
import { takeEvery, call, select, put } from 'redux-saga/effects';

import { lookupLicenceType } from '@services/api';
import { isCassieFullLicence } from '@services/licence';
import handleError from '@redux/utils/errorHandler';
import { getProductType } from '@reselectors/quote';
import { types, setLicenceField } from '../reducer/quote/licence.actions';

export const getLicenceNumber = (state) => state.account.customer.licence.number;

export function* goToUpgrade() {
  try {
    const productType = yield select(getProductType);
    if (productType !== 'newdriver') {
      yield put(setLicenceField('upgradeFromStart', true));
    }
    const licenceNumber = yield select(getLicenceNumber);
    const cassieLicenceType = yield call(lookupLicenceType, licenceNumber);
    if (isCassieFullLicence(cassieLicenceType)) {
      yield put(push('/licence-change/success'));
    } else {
      yield put(push('/licence-change'));
    }
  } catch (error) {
    handleError(error);
  }
}

export function* watchLicence() {
  yield takeEvery(types.FETCH_LICENCE_TYPE, goToUpgrade);
}
