import styled from 'styled-components';
import colors from '@config/colors';

interface IProps {
  large?: boolean;
}

export const DetailRowHeader = styled.div<IProps>`
  color: ${colors.blue.important};
  font-family: 'gotham-rounded';
  font-size: ${(props) => (props.large ? '16px' : '12px')};
  font-weight: 300;
  line-height: 14px;
  text-align: center;
  margin-bottom: ${(props) => (props.large ? '5px' : '0')};
`;

export const DetailRowValue = styled.div<IProps>`
  color: ${colors.black};
  font-family: 'proxima-soft';
  font-size: ${(props) => (props.large ? '32px' : '14px')};
  font-weight: bold;
  line-height: ${(props) => (props.large ? '40px' : '17px')};
  text-align: center;
`;

export const DetailRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
`;

export const QuoteRowContainer = styled.div`
  padding: 25px 0px;
`;
