import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { addressToList, addressActionCreator, getAddressFromLookup } from '@services/address';
import { setBillingAddressFields, setReuseDriverAddress } from '@reducer/quote/payment.actions';
import { lookupBillingAddress, setLookupField } from '@reducer/lookup/actions';
import { BillingAddress as BillingAddressValue } from '@redux/reducer/quote/paymentTypes';
import BillingAddress from './BillingAddress';

const parseStringToBoolean = (value: string) => value === 'true';

interface StateProps {
  driverAddress: string[];
  billingAddress: string[];
  billingAddressLookupPostcode: string;
  reuseDriverAddress: boolean;
  addressResultKey: string;
  addressResults: string[];
}

interface DispatchProps {
  setReuseDriverAddress: (value: boolean) => void;
  setBillingAddressFields: (data: BillingAddressValue) => void;
  setBillingAddressLookupPostcode: (e: Record<string, unknown>) => void;
  lookupBillingAddress: (postcode: string) => void;
}

interface MergeProps {
  billingAddressOnChange: (event: Record<string, unknown>) => void;
}

export interface ComponentProps extends StateProps, DispatchProps, MergeProps {}

const mapStateToProps = ({
  quote: {
    driver: { address },
    payment: { billingAddress },
  },
  lookup,
}: Record<string, unknown>): StateProps => ({
  driverAddress: addressToList(address),
  billingAddress: addressToList(billingAddress),
  billingAddressLookupPostcode: lookup.billingAddressPostcode,
  reuseDriverAddress: billingAddress.reuseDriverAddress,
  addressResultKey: billingAddress.key,
  addressResults: lookup.billingAddresses,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setReuseDriverAddress: (value) => dispatch(setReuseDriverAddress(parseStringToBoolean(value))),
  setBillingAddressFields: (data) => dispatch(setBillingAddressFields(data)),
  setBillingAddressLookupPostcode: (e) =>
    dispatch(setLookupField('billingAddressPostcode', e.target.value.toUpperCase())),
  lookupBillingAddress: (postcode) => dispatch(lookupBillingAddress(postcode)),
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Record<string, unknown>,
): MergeProps => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  billingAddressOnChange: (event) => {
    const addressKey = event.target.value;
    const address = getAddressFromLookup(stateProps.addressResults, addressKey);
    dispatchProps.setBillingAddressFields(addressActionCreator(address));
  },
});

const BillingAddressContainer = reduxForm({
  form: 'billingAddressForm',
})(BillingAddress);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BillingAddressContainer);
