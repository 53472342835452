import Debug from 'debug';
import React, { useEffect, useState } from 'react';
import { InjectedFormProps, Field } from 'redux-form';
import styled from 'styled-components';

import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import TextInput from '@atoms/inputs/TextInputField';
import InfoTag from '@atoms/paragraphs/InfoTag';
import QuoteStep from '@templates/QuoteStep';
import EmailAlreadyExistsModal from '@organisms/modals/EmailAlreadyExistsModal';
import accountApi from '@services/api/customer';
import ReduxFieldValidation from '@services/formValidation';

import UpgradeLicenceBackground from '@assets/prompt-box-background-uml.svg';
import PlateIcon from '@assets/p-plate-v2.svg';
import { Button, FieldContainer, PromptBox } from '@rentecarlo/component-library';

import {
  PreviousCustomerCar,
  useGetRecentCustomerCarRegsQuery,
  useLazyQuoteCopyQuery,
} from '@services/api/quoteRtk';
import { useAppDispatch, useAppSelector, useOptimizelyFlag, useOptimizelyTrack } from '@hooks';
import { goToUpgrade } from '@reducer/quote/licence.actions';
import { setUserField } from '@reducer/quote/user.actions';

import RepurchaseLoader from '@atoms/layout/RepurchaseLoader';
import { useHistory } from 'react-router-dom';
import { saveAndGoTo, setIsFastPurchase } from '@redux/reducer/quote/save.actions';
import PostcodeAndDoB from './PostcodeAndDob';
import AboutCar from './AboutCar';
import { ComponentProps } from './QuoteStart.container';
import PreviousVehicles from './PreviousVehicles';

const InfoTagContainer = styled.div`
  margin: -7px 16px 0 16px;
`;

const debug = Debug('veygo:pages:quoteStart');

const getSubtitleText = (
  isLoggedIn: boolean,
  fastRepurchaseEnabled: boolean,
  fetchedData: PreviousCustomerCar[] | undefined,
  isLoading: boolean,
) => {
  if (isLoggedIn && fastRepurchaseEnabled) {
    if (isLoading) {
      return '';
    }
    if (fetchedData && fetchedData.length) {
      return (
        <div id='quoteStart-subtitle-repurchase'>
          Simply select a vehicle below to re-use a previous quote or tap <b>‘Start a new quote’</b>{' '}
          if you’re insuring a new vehicle.
        </div>
      );
    }
  }
  return "Just complete the below and we'll have your guide price waiting on the next page.";
};

const QuoteStart: React.FC<ComponentProps & InjectedFormProps> = ({
  licenceType,
  productType,
  setDriverOwner,
  setProduct,
  submission,
  submitAddressAndDob,
  // formErrors isn't injected by redux-form, but it's a part of it
  formErrors,
  // Redux-Form injected props
  formValues,
  handleSubmit,
  initialize,
  submitFailed,
  valid,
  clearEmailField,
  email,
  isQuoteForMyself,
  userIdentity,
  saveError,
}) => {
  // Selectors and Dispatch
  const dispatch = useAppDispatch();

  const birthdate = useAppSelector((state) => state.quote.driver.birthdate);
  const car = useAppSelector((state) => state.lookup.car);
  const haveCar = useAppSelector((state) => Boolean(state.lookup.car?.make));
  const isLoggedIn = useAppSelector((state) => state.account.login.loggedIn);
  const postcode = useAppSelector((state) => state.account.customer.postcode);
  const amplifyLoaded = useAppSelector((state) => state.config.amplifyLoaded);
  const [startNewQuote, setStartNewQuote] = useState(false);
  const history = useHistory();

  const { data: fetchedData, isLoading, error: getCarRegError } = useGetRecentCustomerCarRegsQuery(
    null,
    {
      skip: !isLoggedIn || !amplifyLoaded,
    },
  );

  const [
    triggerCopyQuery,
    { isFetching: isLoadingCopyQuery, isSuccess, error: copyQuoteError },
  ] = useLazyQuoteCopyQuery();

  const [showEmailExistsModal, toggleEmailExistsModal] = useState(false);

  const redesignedUMLEnabled = useOptimizelyFlag('TEMP_REDESIGNED_UML').enabled;
  const showEmailInput = useOptimizelyFlag('TEMP_EMAIL_ON_QUOTE_START').enabled;
  const fastRepurchaseEnabled =
    useOptimizelyFlag('TEMP_WEB_FAST_REPURCHASE').enabled && productType !== 'newdriver';

  const checkEmailExists = async (): Promise<void> => {
    try {
      if (!formErrors.email) {
        await accountApi.checkEmail(email);

        // Hide any onscreen options/keyboards if user has focused another field after inputting email
        (document.activeElement as HTMLElement).blur();

        toggleEmailExistsModal(true);
      }
    } catch (exception) {
      // Email wasn't found
    }
  };
  const handleErrorCode = (error: FetchBaseQueryError | SerializedError | undefined) => {
    if (error?.data?.code === 'VEHICLEFAILEDCHECKS') {
      return `Unfortunately, we can't insure this vehicle. If you want to try another, Press "Start a new quote" and just enter a different registration.`;
    }
    if (error?.data?.code === 'VEHICLENOVALUE') {
      return 'We need some more information about your vehicle. Please start a new quote instead.';
    }

    if (saveError) {
      switch (saveError) {
        case 'LICENCENOTFOUND':
          return `The driving licence number on your account is not valid. Please check your details are correct before trying again`;
        case 'LICENCEEXPIRED':
          return `The driving licence on your account has expired. Please check your details are correct before trying again`;
        default:
          break;
      }
    }

    return `We’re really sorry, but something has gone wrong. It looks like we weren’t able to complete your request. Please try again.`;
  };

  const sendEvent = useOptimizelyTrack();

  useEffect(() => {
    if (isLoggedIn) {
      const values = Object.assign(formValues, { postcode, birthdate });
      debug('initializing form with values', values);
      initialize(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, postcode, birthdate]);

  useEffect(() => {
    if (isSuccess && !isLoadingCopyQuery) {
      dispatch(saveAndGoTo('/choose-your-cover', false, true));
      dispatch(setIsFastPurchase(true));
    }
  }, [isSuccess, history, dispatch, isLoadingCopyQuery]);

  const loggedInCustomerHasNoPreviousCars =
    isLoggedIn && amplifyLoaded && !isLoading && fetchedData && !fetchedData.length;

  const displayCarousel = fetchedData && fetchedData.length > 0;
  const showForm =
    !fastRepurchaseEnabled ||
    !isLoggedIn ||
    loggedInCustomerHasNoPreviousCars ||
    startNewQuote ||
    getCarRegError ||
    haveCar;

  const subtitleText = getSubtitleText(isLoggedIn, fastRepurchaseEnabled, fetchedData, isLoading);

  return fastRepurchaseEnabled && displayCarousel && isLoadingCopyQuery ? (
    <RepurchaseLoader
      primaryText="We're building your quote"
      secondaryText="This won't take long"
    />
  ) : (
    <>
      {isLoggedIn &&
        !redesignedUMLEnabled &&
        (productType === 'ldp' || (productType === 'newdriver' && licenceType === 'uk_prov')) && (
          <PromptBox
            id='upgradeLicencePromptbox'
            title='Passed your test?'
            text='If you’re no longer learning, congrats! 🎉 You need to update your licence.'
            buttonText='Change to full licence'
            icon={PlateIcon}
            marginTop='48px'
            marginBottom='0px'
            textFontSize='16px'
            background={UpgradeLicenceBackground}
            onClick={() => dispatch(goToUpgrade())}
          />
        )}
      <QuoteStep
        id='quoteStart-component-quoteStep'
        displaySummaryFooter={false}
        form
        formErrors={formErrors}
        nextAction={handleSubmit((values: Record<string, unknown>) => {
          const valuesWithCar = {
            ...values,
            car,
          };
          sendEvent('save_product_selected');
          submission(valuesWithCar, productType, isLoggedIn, userIdentity);
        })}
        nextLabel='Next'
        nextDisabled={!haveCar}
        stepTitle='Start your quote'
        stepSubtitle={subtitleText}
        submitError={submitFailed && !valid}
        showButton={haveCar}
      >
        {fastRepurchaseEnabled && displayCarousel && (copyQuoteError || saveError) && (
          <FieldContainer
            id='fastRepurchase'
            data-testid='carRepurchase'
            showErrorContainer
            showError
            error={handleErrorCode(copyQuoteError)}
            showErrorImage
          />
        )}
        {fastRepurchaseEnabled && displayCarousel && (
          <PreviousVehicles cars={fetchedData} triggerFunction={triggerCopyQuery} />
        )}
        {fastRepurchaseEnabled && displayCarousel && !showForm && (
          <div style={{ paddingTop: '14px' }}>
            <SectionTitle id='quoteStart-title-coverDifferentVehicle'>
              Covering a different vehicle?
            </SectionTitle>
            <div style={{ paddingTop: '8px' }}>
              <Button
                id='quoteStart-button-startNewQuote'
                data-testid='quoteStart-button-startNewQuote'
                onClick={() => setStartNewQuote(true)}
              >
                Start New Quote
              </Button>
            </div>
          </div>
        )}
        {showForm && (
          <>
            <form
              onSubmit={handleSubmit((values: Record<string, unknown>) => {
                submission(values, productType, isLoggedIn, userIdentity);
              })}
            />
            <PostcodeAndDoB />
            {showEmailInput && !isLoggedIn && (
              <Group>
                <SectionTitle id='aboutYou-title-email'>
                  {isQuoteForMyself
                    ? 'What is your email address?'
                    : 'What is their email address?'}
                </SectionTitle>
                <Field
                  data-testId='quoteStartForm-field-email'
                  name='email'
                  type='email'
                  placeholder='Email address'
                  maxLength={254}
                  component={TextInput}
                  validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isEmail]}
                  disabled={isLoggedIn}
                  onBlur={() => checkEmailExists()}
                />
                <InfoTagContainer>
                  <InfoTag
                    id='quoteStartForm-text-infoTag'
                    text={`This email address is really important - it's where we'll send ${
                      isQuoteForMyself ? 'your' : 'their'
                    } policy documents
                and also the magic link to recover ${
                  isQuoteForMyself ? 'your' : 'their'
                } account if the password is forgotten. We'll
                send marketing updates here too. You can change ${
                  isQuoteForMyself ? 'your' : 'their'
                } preferences at any time in your
                account or by clicking the unsubscribe link in marketing emails.`}
                  />
                </InfoTagContainer>
              </Group>
            )}
            <AboutCar
              toggleStatus={(status: boolean) => dispatch(setUserField('status', status))}
              setProduct={setProduct}
              setDriverOwner={setDriverOwner}
              submitFailed={submitFailed}
              onModalOpen={handleSubmit((values: Record<string, unknown>) => {
                submitAddressAndDob(values, licenceType);
              })}
              licenceType={licenceType}
              isQuoteForMyself={isQuoteForMyself}
            />
          </>
        )}
      </QuoteStep>
      <EmailAlreadyExistsModal
        visible={showEmailExistsModal}
        close={() => {
          clearEmailField();
          toggleEmailExistsModal(false);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }}
        email={email}
      />
    </>
  );
};

export default QuoteStart;
