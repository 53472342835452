export default {
  athensGray: '#D6DADB',
  charcoal: "#323232",
  easternBlue: '#249CB5',
  robinsEggBlue: '#00dccd',
  whiteIce: '#F2FDFD',
  dark: {
    title: '#364344',
  },
  grey: {
    dark: '#273132',
    faded: '#C7C7C7',
    lines: {
      light: '#CBCBCB',
    },
    paragraphs: {
      light: '#969696',
    },
    text: {
      light: '#757575',
      medium: '#4D4D4D',

    },
    background: {
      light: '#F3F4F7',
      medium: '#06E9BB',
      disabled: '#EEEEEE',
      hover: '#F2F9FA',
      lighter: '#FBFBFC',
    },
  },
  outerSpace: '#364344',
  shadow: 'rgba(0, 0, 0, 0.08)',
  blue: {
    primary: '#30C6E2',
    important: '#249CB5',
    secondary: '#54ABBA',
    steel: '#49727B',
    royal: '#0B7BFB',
    facebook: '#3B5998',
    background: '#F2F9FA',
  },
  white: '#FFFFFF',
  black: '#000000',
  orange: '#FF6000',
  yellow: '#FFC439',
  orangeLight: '#FFECE1',
  green: {
    selected: '#01D9CB',
    mint: '#01D9CB',
    light: '#D3FFEF',
    dark: '#008D59',
  },
  teal: '#005465',
  charcoalGrey: '#364344',
  blazeOrange: '#FF6000',
  caribbeanGreen: '#01D9CB',
  peachCream: '#FFEDE0',
  backdrop: 'rgba(0, 0, 0, 0.3)',
  frostedMint: '#D3FFEF',
  errorRed: '#FF0000'
};
