import React from 'react';
import styled from 'styled-components';
import colors from '@config/colors';

import { QuoteRowDetail, QuoteDurationSummary, QuoteSummary } from './PricingComponents';

const QuoteContainer = styled.div`
  border: 1px solid ${colors.grey.lines.light};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const QuoteDetails = styled.div`
  background-color: ${colors.grey.background.light};
  display: flex;
  flex-direction: column;
  padding: 0px 70px;
`;

const Divider = styled.div`
  background-color: ${colors.grey.lines.light};
  height: 1px;
`;

export const PricingElement = (props) => {
  const {
    carDetails,
    crossSaleInitialPrice,
    duration,
    initialPrice,
    loading,
    policyHolderName,
    price,
    quoteSummaryFooter,
    quoteSummaryHeader,
    referenceNumber,
    validDiscountCode,
    isSubscription,
    subscriptionReferenceNumber,
    breakdownCover,
    isTelematics,
  } = props;

  const renderCarDetails = () => (
    <QuoteRowDetail id='quote-text-carName' label='We will insure a' value={carDetails} />
  );

  const renderPolicyHolderName = () => (
    <QuoteRowDetail
      id='quote-text-policyHoldersName'
      label='The policy holder will be'
      value={policyHolderName}
    />
  );

  const renderPolicyReferenceNumber = () => (
    <QuoteRowDetail
      label='REF NO'
      value={isSubscription ? subscriptionReferenceNumber : referenceNumber}
      large
      className='quote-reference-analytics'
      id='quote-text-refNoPolicy'
    />
  );
  const renderAncillaries = () => (
    <QuoteRowDetail id='quote-text-ancillaries' label='add-ons' value='UK BREAKDOWN COVER' />
  );

  return (
    <QuoteContainer>
      <QuoteSummary
        id='quote-component-quoteSummary'
        header={quoteSummaryHeader}
        footer={quoteSummaryFooter}
        loading={loading}
        price={price}
        initialPrice={initialPrice}
        validDiscountCode={validDiscountCode}
        crossSaleInitialPrice={crossSaleInitialPrice}
        isSubscription={isSubscription}
        isTelematics={isTelematics}
      />
      <QuoteDetails id='quote-component-quoteDetails'>
        {carDetails && renderCarDetails()}
        {carDetails && (duration || policyHolderName || referenceNumber) && <Divider />}
        {duration && <QuoteDurationSummary duration={duration} isSubscription={isSubscription} />}
        {duration && (policyHolderName || referenceNumber) && <Divider />}
        {policyHolderName && renderPolicyHolderName()}
        {policyHolderName && referenceNumber && <Divider />}
        {(referenceNumber || subscriptionReferenceNumber) && renderPolicyReferenceNumber()}
        {breakdownCover && <Divider />}
        {breakdownCover && renderAncillaries()}
      </QuoteDetails>
    </QuoteContainer>
  );
};

export default PricingElement;
