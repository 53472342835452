import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Car } from '@pages/quote/CarDetails/CarDetails.container';
import OwnerDetailsModal from './OwnerDetailsModal.component';

export interface StateProps {
  car: Car;
  productType: string;
  selectedRelationship: string;
  driverDob: Date;
  ownerFormFields: Record<string, unknown>;
}

export interface ComponentProps extends StateProps {
  show: boolean;
  close: () => void;
  closeText?: string;
  submitOwnerDetails: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): StateProps => ({
  car: state.lookup.car,
  productType: state.quote.product.productType,
  selectedRelationship: state.quote.user.relationship,
  driverDob: state.quote.driver.birthdate,
  ownerFormFields: getFormValues('ownerDetailsForm')(state),
});

export default connect(mapStateToProps)(OwnerDetailsModal);
