import { FinalPriceExcess } from '@utils/types';

export const getBreakdownCoverPrice = (finalPricesByExcess: FinalPriceExcess[]): string => {
  if (finalPricesByExcess.length) {
    // Breakdown cover is the same price across all excesses, so just get the 1st one
    return finalPricesByExcess[0].breakdown_price?.toFixed(2) || '';
  }

  return '';
};
