import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as appActions from '../redux/reducer/app/actions';

export default (Component) => {
  /*
   * The size guard wraps the whole application and updates whenever the size of
   * the browser window changes, providing the exact size of the window and
   * whether or not the application is mobile size.
   */
  const SizeGuard = ({ setSize, setIsMobile, ...componentProps }) => {
    const [windowDimentions, updateWindowDimensions] = useState();
    useEffect(() => {
      window.addEventListener('resize', updateWindowDimensions);
      return () => {
        window.removeEventListener('resize', updateWindowDimensions);
      };
    }, []);

    useEffect(() => {
      setSize(window.innerWidth, window.innerWidth);
      setIsMobile(window.innerWidth < 768);
    }, [windowDimentions, setSize, setIsMobile]);

    return <Component {...componentProps} />;
  };

  const mapDispatchToProps = {
    setIsMobile: appActions.setIsMobile,
    setSize: appActions.setSize,
  };

  const connected = connect(null, mapDispatchToProps)(SizeGuard);
  connected.displayName = `Guarded(${Component.name})`;

  return connected;
};
