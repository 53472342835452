import React from 'react';
import styled from 'styled-components';
import AddressSelectInner from '@molecules/selects/AddressSelect';

const Container = styled.div`
  margin-top: 16px;
`;

const AddressSelect = (props) => (
  <Container>
    <AddressSelectInner {...props} />
  </Container>
);

AddressSelect.defaultProps = {
  ...AddressSelectInner.defaultProps,
};

export default AddressSelect;
