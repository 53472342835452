import React from 'react';
import { Panel, P, TextLink } from '@rentecarlo/component-library';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/reducer';
import { Container } from './assets/styles';

const PrivacyPolicyInfo: React.FC = () => {
  const loggedIn = useSelector((state: RootState) => state.account.login.loggedIn);
  return (
    <Container>
      <Panel id='start-component-panel'>
        <P id='start-paragraph-privacyPolicy' fontSize={14}>
          By hitting {!loggedIn ? "'Continue As Guest'" : "'Next'"} below, you confirm you're happy
          for Veygo to use your data as outlined in our{' '}
          <TextLink
            id='start-link-privacyPolicy'
            fontSize={14}
            href='https://www.veygo.com/privacy-policy/'
          >
            Privacy Policy
          </TextLink>
        </P>
      </Panel>
    </Container>
  );
};

export default PrivacyPolicyInfo;
