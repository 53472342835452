import React from 'react';
import Select from '@molecules/selects/Select';

const AddressSelect = ({ setAddressFields, addresses, value, disabled }) => (
  <Select
    id='address_select'
    selectorType='addressSelect'
    onChange={setAddressFields}
    options={addresses.map((a) => ({ name: a.Preview, value: a.key.toString() }))}
    placeholder='Select your address'
    value={value}
    disabled={disabled}
  />
);

AddressSelect.defaultProps = {
  disabled: false,
};

export default AddressSelect;
