import React, { ReactElement } from 'react';
import { WrappedFieldProps } from 'redux-form';

import { DropDown } from '@rentecarlo/component-library';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import { ComponentType, useComponentId } from '@hooks';

type Option = {
  value: string;
  name: string;
  child?: ReactElement;
};

interface Props {
  id?: string;
  label?: string;
  placeholder: string;
  options: Array<Option>;
  disabled?: boolean;
  errorText?: string;
  containerError?: boolean;
}

const DropDownField: React.FC<Props & WrappedFieldProps> = ({
  input: { onChange, value, name },
  meta: { touched, error, active, pristine, submitFailed },
  placeholder = 'Please Select',
  label,
  options,
  errorText,
}) => {
  const creator = useComponentId();
  return (
    <>
      <SectionTitle id={creator(ComponentType.TITLE, 'dropdownFieldLabel')}>{label}</SectionTitle>
      <DropDown
        id={name}
        value={value}
        onClick={onChange}
        placeholder={placeholder}
        options={options}
        errorText={
          ((touched && !pristine) || submitFailed) && error && (!active || submitFailed)
            ? errorText
            : undefined
        }
        containerError={
          !!(((touched && !pristine) || submitFailed) && error && (!active || submitFailed))
        }
      />
    </>
  );
};

export default DropDownField;
