import { QuoteState } from '@redux/reducer/quote';
import { isEqual } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

// This type is incomplete
interface ApiResponse {
  underwriting_criteria: boolean;
  price: string;
  excess: string;
  isLoggedIn: boolean;
  isNewCar: boolean;
  ancillary_id: string;
  licence_type: string;
  cassie_licence_type: string;
}

export function roundToNearestMinutes(date: Dayjs, minutes: number): Dayjs {
  const roundedMinutes = Math.floor(dayjs(date).minute() / minutes) * minutes;
  return dayjs(date).clone().startOf('hour').add(roundedMinutes, 'minutes');
}

function quoteIsOutOfDate(date: Dayjs): boolean {
  const fiveMinutesAgo = dayjs().subtract(5, 'minutes');
  return date.isBefore(fiveMinutesAgo);
}

function getDate(date: Dayjs, isImmediateStart: boolean | null) {
  if (!isImmediateStart) return date.toISOString();
  return quoteIsOutOfDate(date) ? date.toISOString() : roundToNearestMinutes(date, 5).toISOString();
}

function convertFields(quote: QuoteState) {
  return {
    ...quote,
    user: { ...quote.user, seonsession: null },
    car: { ...quote.car, valueBand: null },
    duration: {
      ...quote.duration,
      endDateTime: quote.duration.endDateTime
        ? getDate(dayjs(quote.duration.endDateTime), quote.duration.immediateStart)
        : null,
      startDateTime: quote.duration.startDateTime
        ? getDate(dayjs(quote.duration.startDateTime), quote.duration.immediateStart)
        : null,
    },
    licence: { ...quote.licence, cassieType: !!quote.licence.cassieType },
    save: { ...quote.save, expiry: undefined },
  };
}

/**
 * Singleton class to manage the state of the last quote and response.
 * This class ensures that only one instance of the state is maintained
 * and provides methods to determine if a new API call should be made
 * based on the changes in the quote state.
 */
export class DirtyState {
  private static instance: DirtyState | null = null;

  private lastQuote: QuoteState | null;

  private lastResponse: ApiResponse | null;

  private constructor() {
    this.lastQuote = null;
    this.lastResponse = null;
  }

  static getInstance(): DirtyState {
    if (DirtyState.instance === null) {
      DirtyState.instance = new DirtyState();
    }
    return DirtyState.instance;
  }

  /**
   * Determines if an API call should be made based on the current quote state
   * or if the current URL is one before final quote.
   *
   * @param {QuoteState} quote - The current quote state.
   * @param {string} currentURL - The current URL.
   * @returns {boolean} True if an API call should be made, false otherwise.
   */
  shouldCall(quote: QuoteState, currentURL: string): boolean {
    if (this.lastResponse === null || this.lastQuote === null) {
      // first call
      return true;
    }
    if (currentURL === '/important-information') {
      // forcing refresh before final quote
      return true;
    }
    if (isEqual(convertFields(quote), convertFields(this.lastQuote))) {
      // nothing changed
      return false;
    }
    // something changed
    return true;
  }

  /**
   * Returns the stored API response.
   *
   * @returns {ApiResponse | null} The stored API response or null if no response is stored.
   */
  getStoredResponse(): ApiResponse | null {
    return this.lastResponse;
  }

  /**
   * Stores the given quote state and API response.
   *
   * @param {QuoteState} quote - The quote state to store.
   * @param {ApiResponse} response - The API response to store.
   */
  setStoredResponse(quote: QuoteState, response: ApiResponse): void {
    this.lastQuote = quote;
    this.lastResponse = response;
  }

  /**
   * Resets the stored quote state and API response to null.
   */
  resetStoredResponse(): void {
    this.lastQuote = null;
    this.lastResponse = null;
  }
}
