// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("UKNumberPlate.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'UKNumberPlate';\n  font-weight: normal;\n  font-style: normal;\n  src: local('UKNumberPlate'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n", "",{"version":3,"sources":["webpack://./src/assets/fonts/UKNumberPlateFont.less"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,mBAAA;EACA,kBAAA;EACA,uFAAA;AACJ","sourcesContent":["@font-face {\n    font-family: 'UKNumberPlate';\n    font-weight: normal;\n    font-style: normal;\n    src: local('UKNumberPlate'), url('UKNumberPlate.ttf') format('truetype'),;\n  }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
