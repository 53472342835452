import styled from 'styled-components';
import colors from '@config/colors';
import media from '@config/media';

export const Container = styled.aside`
  position: sticky;
  top: 0;
  padding: 32px;
  ${media.tablet`
    width: 256px;
    padding: 16px 0px 16px 0px;
  `};

  ${media.mobile`
    height: 135vh;
  `};

  ${media.mobileXS`
    height: 175vh;
  `};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  padding-bottom: 30px;
`;

export const Title = styled.span`
  font-family: Graphie;
  font-weight: 600;
  font-size: 28px;
  color: ${colors.outerSpace};
`;

export const QuoteSummaryDetailsContainer = styled.ul`
  margin-top: -24px;
  padding: 0;
`;

// Pricing Widget

export const PriceContainer = styled.div`
  position: relative;
  background: ${colors.grey.background.light};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.grey.background.light};
  margin-top: 32px;
  display: flex;
`;
PriceContainer.displayName = 'PriceContainer';

type PriceWrapper = {
  discount: boolean;
  crossSaleInitialPrice: boolean;
};

export const PriceLabelWrapper = styled.div<PriceWrapper>`
  padding: ${({ discount, crossSaleInitialPrice }): string =>
    discount || crossSaleInitialPrice ? '24px' : '16px'};
  flex: 0.5;
`;

export const PriceLabel = styled.span`
  color: ${colors.grey.text.light};
  font-family: Graphie;
  font-size: 16px;
  font-weight: 600;
`;
PriceLabel.displayName = 'PriceLabel';

export const PriceValueWrapper = styled.span`
  flex: 0.5;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const PriceValue = styled.span`
  color: ${colors.outerSpace};
  font-size: 25px;
  font-family: Graphie;
  font-weight: 700;
`;
PriceValue.displayName = 'PriceValue';

export const HistoricPriceWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 56;
`;
HistoricPriceWrapper.displayName = 'HistoricPriceWrapper';

export const HistoricPriceValue = styled.span`
  color: {colors.outerSpace};
  font-size: 12px;
  font-weight: 600;
  margin-left: 25px;
  text-decoration: line-through;
`;
HistoricPriceValue.displayName = 'HistoricPriceValue';

export const NumberPlate = styled.div`
  box-sizing: border-box;
  border: 1px solid ${colors.grey.lines.light};
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: inline-block;
  margin-top: 8px;
`;

export const LightParagraph = styled.p`
  color: ${colors.grey.paragraphs.light};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  padding-bottom: 24px;
`;
