export enum types {
  SET_REASON_FOR_COVER = 'SET_REASON_FOR_COVER',
  SET_OTHER_REASON_FOR_COVER = 'SET_OTHER_REASON_FOR_COVER',
  SET_NAMED_DRIVER = 'SET_NAMED_DRIVER',
}

export interface SetReasonForCover {
  type: typeof types.SET_REASON_FOR_COVER;
  reason: string;
}

export interface SetOtherReasonForCover {
  type: typeof types.SET_OTHER_REASON_FOR_COVER;
  reason: string;
}

export interface SetNamedDriver {
  type: typeof types.SET_NAMED_DRIVER;
  isNamedDriver: string;
}

export type TempcoverActions = SetReasonForCover | SetOtherReasonForCover | SetNamedDriver;

export const setReasonForCover = (reason: string): SetReasonForCover => ({
  type: types.SET_REASON_FOR_COVER,
  reason,
});

export const setOtherReasonForCover = (reason: string): SetOtherReasonForCover => ({
  type: types.SET_OTHER_REASON_FOR_COVER,
  reason,
});

export const setNamedDriver = (isNamedDriver: string): SetNamedDriver => ({
  type: types.SET_NAMED_DRIVER,
  isNamedDriver,
});
