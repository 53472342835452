import styled from 'styled-components';
import colors from '@config/colors';
import PriceTag from '@assets/price-tag.svg';

export const Container = styled.div`
  border: 2px solid ${colors.robinsEggBlue};
  border-radius: 4px;
  margin: 4px;
  display: flex;
  line-height: 20px;
  padding: 4px;
  background-color: #ccf8f5;
`;

interface IImage {
  align?: string;
}

export const PriceImage = styled.img.attrs({ src: PriceTag })<IImage>`
  color: ${({ theme }) => theme.textPrimaryAlt};
  align-self: ${(props) => props.align};
  height: 24px;
  width: auto;
`;

export const Body = styled.span`
  color: ${({ theme }) => theme.textPrimaryAlt};
  padding-left: 8px;
  align-self: stretch;
  text-align: left;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 250px;
  }
`;
