import React from 'react';
import Ticket from '@assets/ticket-icn.svg';
import Minus from '@assets/minus.svg';
import Plus from '@assets/add-icn.svg';
import BluePlus from '@atoms/inputs/BluePlus';
import VoucherCodeInput from '@atoms/inputs/VoucherCodeInput';
import {
  InputRow,
  Icon,
  AddLink,
  LinkContainer,
  RemoveLink,
  CodeContainer,
  CodeArea,
  CodeInputContainer,
  AppliedText,
  Divider,
  DiscountMessageContainer,
  TermsAndConditionsLink,
} from '@organisms/blocks/VoucherCode/assets/styles';
import PriceTagBox from '@atoms/blocks/PriceTagBox';

interface Props {
  validDiscountCode: boolean | null;
  discountCode: string;
  setDiscountCode: (discountCode: string) => void;
  hasConversionBonus: boolean;
  breakdownCover: boolean;
  saveQuote: () => void;
  showVoucherInput: boolean;
  setShowVoucherInput: (showVoucherInput: boolean) => void;
  setHasSubmittedVoucher: (hasSubmittedVoucher: boolean) => void;
  scrollToTermsAndConditions: () => void;
}

export const VoucherCode: React.FC<Props> = ({
  validDiscountCode,
  hasConversionBonus,
  discountCode,
  setDiscountCode,
  breakdownCover,
  saveQuote,
  showVoucherInput,
  setShowVoucherInput,
  setHasSubmittedVoucher,
  scrollToTermsAndConditions,
}) => {
  const renderDiscountMessaging = (): React.ReactElement => (
    <DiscountMessageContainer>
      <PriceTagBox id='voucherCode-text-priceTagBox'>
        You passed! 🥳 We have applied a 10% discount to your policy.{' '}
        <TermsAndConditionsLink id='voucherCode-link-terms' onClick={scrollToTermsAndConditions}>
          T&Cs apply
        </TermsAndConditionsLink>
      </PriceTagBox>
    </DiscountMessageContainer>
  );

  const getVoucherAppliedText = () => {
    let text = `${discountCode} applied`;

    if (breakdownCover) {
      text += ' to eligible products';
    }

    return text;
  };

  return (
    <CodeContainer id='finalQuote-container-voucher'>
      <LinkContainer onClick={() => setShowVoucherInput(!showVoucherInput)}>
        <Icon src={showVoucherInput ? Minus : Plus} />
        <AddLink id='finalQuote-text-voucherAddCode'>Add a promotional code</AddLink>
      </LinkContainer>
      {showVoucherInput && (
        <>
          <Divider />
          <CodeArea id='finalQuote-container-voucherDisplay'>
            {validDiscountCode ? (
              <InputRow>
                <Icon src={Ticket} />
                <AppliedText id='finalQuote-text-voucherAppliedText'>
                  {getVoucherAppliedText()}
                </AppliedText>
                <RemoveLink
                  id='finalQuote-link-voucherRemove'
                  onClick={() => {
                    setDiscountCode('');
                    saveQuote();
                    setHasSubmittedVoucher(false);
                  }}
                >
                  Remove
                </RemoveLink>
              </InputRow>
            ) : (
              <CodeInputContainer>
                <BluePlus
                  onClick={() => {
                    saveQuote();
                    setHasSubmittedVoucher(true);
                  }}
                />
                <VoucherCodeInput
                  placeholder='Add your code'
                  id='finalQuote-input-voucherCode'
                  value={discountCode}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setDiscountCode(event.target.value)
                  }
                  disabled={validDiscountCode}
                  textStyle={undefined}
                  placeholderStyle={undefined}
                />
              </CodeInputContainer>
            )}
          </CodeArea>
        </>
      )}
      {hasConversionBonus && renderDiscountMessaging()}
    </CodeContainer>
  );
};

export default VoucherCode;
