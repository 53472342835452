import styled from 'styled-components';
import colors from '@config/colors';

const SectionSubTitle = styled.h4`
  color: ${colors.grey.text.light};
  font-family: 'proxima-soft';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
  margin-bottom: 15px;
`;

export const SectionSubTitleImage = styled.img`
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
`;

export default SectionSubTitle;
