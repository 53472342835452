export default [
  {
    text: 'STUDENT',
    value: 'student',
  },
  {
    text: 'EMPLOYED',
    value: 'employed',
  },
  {
    text: 'OTHER',
    value: 'other',
  },
];
