import { DurationOptions, Durations } from '@config/indicativeDurations';
import { RootState } from '@redux/reducer';
import { IndicativePrices } from '@redux/types';
import { shallowEqual, useSelector } from 'react-redux';

interface DisplayValues {
  displayPrice: string | null;
  displayHours: number | null;
  durationToUpSell: DurationOptions | null;
  displayUpSell: boolean;
}

export const getDurationToUpSell = (
  currentHours: number,
  options: DurationOptions[],
): DurationOptions | null => {
  const increment = Math.floor(currentHours / 6);
  const possibleHours = [6, 12, 18, 24];

  const validUpSellHours = possibleHours.slice(increment);
  const upSellDuration = validUpSellHours.reduce<DurationOptions | null>((result, option) => {
    if (result) return result;
    const foundOption = options.find((o) => o.inHours === option);
    return foundOption ?? result;
  }, null);
  return upSellDuration;
};

export const getDisplayValues = (
  hours: number,
  currentHours: number,
  prices: IndicativePrices,
  currentPrice: string,
): { displayPrice: string | null; displayHours: number | null } => {
  if (!prices[hours]) return { displayPrice: null, displayHours: null };

  const { price } = prices[hours];
  const displayPrice = (parseFloat(price) - parseFloat(currentPrice)).toFixed(2);
  const displayHours = hours - currentHours;

  return { displayPrice, displayHours };
};

const twentyFourHoursNotDisplayed = (currentTabOptions: DurationOptions[]) =>
  currentTabOptions.find((options) => options.inHours === 24) === undefined;

export const getValidDurations = (durations: Durations, selectedTab: string): DurationOptions[] => {
  const selectedDuration = durations[selectedTab as keyof Durations];

  if (selectedDuration && 'options' in selectedDuration) {
    const currentTabOptions = selectedDuration.options.filter((option) => option.validate);

    if (twentyFourHoursNotDisplayed(currentTabOptions)) {
      const oneDayOption = durations.days.options.find((option) => option.inHours === 24);
      if (oneDayOption) {
        return [...currentTabOptions, oneDayOption];
      }
    }

    return currentTabOptions;
  }

  return [];
};

export const UseUpSellComponentHook = (
  durations: Durations,
  selectedTab: string,
): DisplayValues => {
  const currentPrice = useSelector((state: RootState) => state.quote.indicativePrice.price ?? '0');
  const endChoice = useSelector((state: RootState) => state.quote.duration.endChoiceSelected);
  const prices = useSelector(
    (state: RootState) => state.quote.indicativePrice.prices,
    shallowEqual,
  );

  const currentHours = endChoice?.inHours ?? 0;
  const durationToUpSell = getDurationToUpSell(
    currentHours,
    getValidDurations(durations, selectedTab),
  );

  if (!durationToUpSell) {
    return {
      durationToUpSell,
      displayPrice: null,
      displayHours: null,
      displayUpSell: !(currentHours === 0 || durationToUpSell === null),
    };
  }

  const { displayPrice, displayHours } = getDisplayValues(
    durationToUpSell.inHours,
    currentHours,
    prices,
    currentPrice,
  );

  return {
    durationToUpSell,
    displayPrice,
    displayHours,
    displayUpSell: !(currentHours === 0 || displayPrice === null || durationToUpSell === null),
  } as const;
};
