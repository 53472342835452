import { createSelector } from 'reselect';
import dayjs from 'dayjs';

const getRestrictedPeriods = ({ config }) => config.restrictedPeriods;

export const getCurrentRestrictedPeriod = createSelector([getRestrictedPeriods], (periods) => {
  const now = dayjs();
  return periods.filter(
    (period) => dayjs(period.start_date).isBefore(now) && dayjs(period.end_date).isAfter(now),
  );
});

export const isRestrictedPeriod = createSelector(
  [getCurrentRestrictedPeriod],
  (withinPeriod) => withinPeriod.length > 0,
);
