// eslint-disable-next-line import/no-unresolved
import Config from 'Config';
import * as Sentry from '@sentry/react';
import { ExtraErrorData } from '@sentry/integrations';

const serializeDepth = 5;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_PLATFORM,
  release: Config.version,
  ignoreErrors: [
    /null is not an object (evaluating 'elt\.parentNode')/,
    /undefined is not an object (evaluating '__gCrWeb\.autofill\.extractForms')/,
    /DOM Exception 18: An attempt was made to break through the security policy of the user agent\./,
    /Blocked a frame with origin "https:\/\/car-sharing.*.com" from accessing a frame with origin "https:\/\/js.stripe.com". Protocols, domains, and ports must match./,
    /_promiseRejectionHandler([native code])/,
    /EXISTINGDETAILSNLIC/,
  ],
  normalizeDepth: serializeDepth + 1,
  integrations: [
    new ExtraErrorData({
      depth: serializeDepth,
    }),
  ],
  beforeSend(event, hint) {
    if (
      event.exception &&
      event.exception.values &&
      hint.originalException &&
      hint.originalException.message &&
      event.exception.values[0].value.startsWith('Non-Error exception captured')
    ) {
      const newEvent = event;
      newEvent.message = hint.originalException.message;
      newEvent.exception.values[0].value = hint.originalException.message;
      return newEvent;
    }
    return event;
  },
});

Sentry.setTag('side', 'frontend');
