/* eslint-disable */

import { default as _invert } from 'lodash/invert';
import dayjs from 'dayjs';
import { buildDateForApi } from '@services/date';
import factory from './factory';

import { validationTests } from '../formValidation';

import {
  licenceTypeMap,
} from './maps';

const licenceTypeRevertMap = _invert(licenceTypeMap);

const buildLicenceNumber = (licenceNumber, dob) => validationTests.licenceDobValid({ number: licenceNumber.replace(/ /g, ''), dob }) ? licenceNumber.replace(/ /g, '') : '';

const direct = (input) => ({
  phone_number: validationTests.phonenumber(input.phoneNumber) ? input.phoneNumber : '',
  email: validationTests.email(input.email) ? input.email : '',
  date_of_birth: buildDateForApi(input.birthdate),
  licence_number: buildLicenceNumber(input.licence.number, input.birthdate),
  full_provisional_date_confirmed: buildDateForApi(input.licence.fullLicenceDate),
  licence_type: licenceTypeMap[input.licence.type],
  ncb_years: input.ncb.haveNCB
    ? input.ncb.level
    : 0,
  customer_stated_test_pass_date: buildDateForApi(input.statedTestPassDate),
});

const reverse = (input, output) => ({
  birthdate: input.date_of_birth ? dayjs(input.date_of_birth) : null,
  licence: {
    ...output.licence,
    type: licenceTypeRevertMap[input.licence_type],
    fullLicenceDate: input.full_provisional_date_confirmed ? dayjs(input.full_provisional_date_confirmed) : null,
  },
  ncb: {
    ...output.ncb,
    level: input.ncb_years === 0 || input.ncb_years > 0 ? input.ncb_years : '',
  },
  statedTestPassDate: input.customer_stated_test_pass_date ? dayjs(input.customer_stated_test_pass_date) : null,
});

export default factory({
  'uuid': 'uuid',
  'birthdate': 'date_of_birth',
  'age': 'age',
  'firstName': 'first_name',
  'lastName': 'last_name',
  'email': 'email',
  'middleNames': 'middle_name',
  'ncb.haveNCB': 'ncb',
  'ncb.level': 'ncb_years',
  'occupation': 'occupation',
  'occupationType': 'occupation_type',
  'phoneNumber': 'phone_number',
  'postcode': 'postcode',
  'title': 'title',
  'licence.number': 'licence_number',
  'licence.type': 'licence_type',
  'licence.country': 'licence_country',
  'licence.cassieType': 'cassie_licence_type',
  'licence.fullLicenceConfirmed': 'full_provisional_confirmed',
  'licence.fullLicenceDate': 'full_provisional_date_confirmed',
  'insuredHours': 'csi_insured_hours',
  'clearanceLevel': 'clearance_level',
  'csiNCB': 'csi_ncb',
  'migratedLDP': 'migrated_from_ldp',
  'canEdit': 'can_edit',
  'source': 'source',
  'statedTestPassDate': 'customer_stated_test_pass_date',
  'hasConversionBonus': 'has_conversion_bonus',
  'crossaleDiscountFactor': 'crossale_discount_factor',
  'contactPreferences.email': 'email_consent',
  'contactPreferences.telephone': 'phone_consent',
}, direct, reverse);
