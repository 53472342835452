import React from 'react';
import { SummaryLine } from '@rentecarlo/component-library';
import SummaryBox from '@molecules/blocks/SummaryBox';
import { ComponentType, useComponentId } from '@hooks';
import styled from 'styled-components';
import { toCamelCase } from '@utils/toCamelCase';

export interface FastRepurchaseSummaryProps {
  title: string;
  pillButtonAction: () => void;
  hidePillButton: boolean;
  icon?: string;
  data: { label: string; value: string | string[]; id: string }[];
  children?: React.ReactNode;
}

const ChildContainer = styled.div`
  margin-top: 36px;
`;

const FastRepurchaseSummary: React.FC<FastRepurchaseSummaryProps> = (props) => {
  const creator = useComponentId();
  const { title, pillButtonAction, hidePillButton = false, icon, data, children } = props;

  return (
    <SummaryBox
      title={title}
      pillButtonAction={pillButtonAction}
      id={creator(ComponentType.CONTAINER, toCamelCase(title))}
      hidePillButton={hidePillButton}
      icon={icon}
    >
      {data.map((item) => {
        const values =
          typeof item.value === 'object' && item.value !== null ? [...item.value] : [item.value];
        return (
          <SummaryLine
            label={item.label}
            values={values}
            id={creator(ComponentType.TEXT, item.id)}
          />
        );
      })}
      {children && <ChildContainer>{children}</ChildContainer>}
    </SummaryBox>
  );
};

export default FastRepurchaseSummary;
