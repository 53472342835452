import React from 'react';

import { ComponentType, useComponentId } from '@hooks';
import { InfoContainer, InfoImage } from './assets/styles';

interface Props {
  icon?: string | undefined;
  message?: string;
  textColor?: string;
}

const FieldInformation: React.FC<Props> = ({ icon = '', message = '', textColor = '' }) => {
  const creator = useComponentId();
  return (
    <InfoContainer id={creator(ComponentType.CONTAINER, 'fieldInformation')} textColor={textColor}>
      {icon && <InfoImage src={icon} />}
      {message}
    </InfoContainer>
  );
};

export default FieldInformation;
