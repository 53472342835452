import React from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Button, OrSeparator } from '@rentecarlo/component-library';

import { useOptimizelyTrack, useOptimizelyFlag } from '@hooks';

import QuoteStep from '@templates/QuoteStep';
import OutlineButton from '@atoms/buttons/OutlineButton';
import IndicativePriceInput from '@molecules/inputs/IndicativePriceInput';
import { PaymentMethod } from '@utils/types';

import indicativeDurations, { durationLabelMap } from '@config/indicativeDurations';
import getDefaultDuration from '@services/getDefaultDuration';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Europe/London');

interface StyledProps {
  borderTop?: boolean;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 0px 64px 0px;
  max-width: 400px;
`;

const OrContainer = styled.div`
  margin: 0 32px;
  max-width: 336px;
`;

const DurationCoverButtonContainer = styled.div<StyledProps>`
  max-width: 400px;
  margin: ${({ borderTop }) => (borderTop ? 60 : 24)}px 0 16px;
`;

const getIndicativeDefaultByProduct = (product: string) => {
  if (product === 'ldp') return indicativeDurations.ldp.default;

  return indicativeDurations.csi.default;
};

const getDurationButtonLabel = (
  endChoiceSelected: string,
  defaultIndicativeDuration: string,
): string => {
  const durationValue = endChoiceSelected || defaultIndicativeDuration;
  return `Buy ${durationLabelMap[durationValue]} of cover`;
};

const subsDefaultDuration = indicativeDurations.getSubs().default;

export interface Props {
  productType: string;
  resetAndGoToDuration: () => void;
  loading: boolean;
  nextAction: (isFastRepurchase: boolean, shouldShowDriverProfile: boolean) => void;
  disableQuickDateSelectionButton: boolean;
  paymentMethod: PaymentMethod;
  endChoiceSelectedValue: string;
  birthdate: Dayjs | null;
  isQuoteForMyself: boolean;
  isFastRepurchase: boolean;
}

const IndicativePrice: React.FC<Props> = ({
  productType,
  resetAndGoToDuration,
  loading,
  nextAction,
  disableQuickDateSelectionButton,
  paymentMethod,
  endChoiceSelectedValue,
  birthdate,
  isQuoteForMyself,
  isFastRepurchase,
}) => {
  const sendEvent = useOptimizelyTrack();

  const sendEventAndSubmit = (): void => {
    let shouldShowDriverProfile = false;
    if (productType === 'newdriver' || (productType === 'tc' && paymentMethod === 'subscription')) {
      shouldShowDriverProfile = true;
    }

    if (!isFastRepurchase) {
      shouldShowDriverProfile = false;
    }

    sendEvent('indicative_continue');
    nextAction(isFastRepurchase, shouldShowDriverProfile);
  };

  const personalisedDurationsFlag = useOptimizelyFlag('TEMP_PERSONALISED_DEFAULT_DURATIONS');

  const defaultDuration = personalisedDurationsFlag.enabled
    ? getDefaultDuration(productType, birthdate)
    : getIndicativeDefaultByProduct(productType);

  return (
    <QuoteStep
      id='indicativePrice-component-quoteStep'
      displaySummaryFooter={false}
      stepTitle={`The estimated quote for ${isQuoteForMyself ? 'your' : 'their'} insurance`}
      stepSubtitle={`Based on the information provided, this is an estimated cost for ${
        isQuoteForMyself ? 'your' : 'their'
      } cover. ${
        paymentMethod !== PaymentMethod.subscription
          ? `Please tell us how long ${isQuoteForMyself ? 'you' : 'they'} want cover for.`
          : ''
      }`}
    >
      {paymentMethod === PaymentMethod.subscription ? (
        <>
          <IndicativePriceInput
            loading={loading}
            isSubscription
            defaultDuration={subsDefaultDuration}
          />
          <DurationCoverButtonContainer borderTop>
            <Button
              id='indicativePrice-button-buySubscription'
              height={64}
              onClick={() => sendEventAndSubmit()}
              disabled={disableQuickDateSelectionButton || loading}
            >
              Buy subscription
            </Button>
          </DurationCoverButtonContainer>
        </>
      ) : (
        <>
          <IndicativePriceInput loading={loading} defaultDuration={defaultDuration} />
          <DurationCoverButtonContainer>
            <Button
              id='indicativePrice-button-buy1DayOfCover'
              height={64}
              borderWidth={0}
              onClick={() => sendEventAndSubmit()}
              disabled={loading}
            >
              {getDurationButtonLabel(endChoiceSelectedValue, defaultDuration.value)}
            </Button>
          </DurationCoverButtonContainer>
          <OrContainer>
            <OrSeparator />
          </OrContainer>
          <ButtonContainer>
            <OutlineButton
              id='indicativePrice-button-selectOwnDuration'
              testId='custom-button'
              onClick={resetAndGoToDuration}
            >
              Select your own duration
            </OutlineButton>
          </ButtonContainer>
        </>
      )}
    </QuoteStep>
  );
};

export default IndicativePrice;
