import React from 'react';
import styled from 'styled-components';

import tickIcon from '@assets/tick-mobile.svg';

const Tick = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 0;
`;

const TickInputContainer = styled.div`
  position: relative;
`;

const MobileGreenTick = ({ children, showTick, tickImgAlt }) => (
  <TickInputContainer>
    {children}
    {showTick && <Tick src={tickIcon} alt={tickImgAlt} />}
  </TickInputContainer>
);

MobileGreenTick.defaultProps = {
  tickImgAlt: 'Input okay',
};

export default MobileGreenTick;
