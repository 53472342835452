import * as Sentry from '@sentry/react';
import { StripeError } from '@stripe/stripe-js';

const handleError = (
  error: Error | StripeError | null,
  extra: Record<string, unknown> = {},
): void => {
  Sentry.captureException(error, { extra });

  if (process.env.REACT_APP_PLATFORM !== 'PRODUCTION') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default handleError;
