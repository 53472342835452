/*
 * Reselectors for static files.
 */
import { createSelector } from 'reselect';

const getQuoteSaveError = (state) => state.quote.save.error;
const getLookupError = (state) => state.lookup.error;

export const dlnAlreadyUsed = createSelector(
  [getQuoteSaveError],
  (quoteSaveError) => quoteSaveError === 'EXISTINGDETAILSNLIC',
);

export const postcodeNotFound = createSelector(
  [getLookupError],
  (lookupError) =>
    lookupError === "This postcode hasn't been found please enter your address manually below.",
);
