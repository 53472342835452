import styled from 'styled-components';
import colors from '@config/colors';

const SecondaryTitle = styled.h3`
  color: ${colors.grey.text.light};
  font-family: 'proxima-soft';
  font-weight: normal;
  font-size: 18px;
  line-height: 17px;
  text-transform: uppercase;
`;

export default SecondaryTitle;
