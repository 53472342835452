import styled from 'styled-components';
import colors from '@config/colors';

import { ThemeUtils } from '@rentecarlo/component-library';

interface Props {
  theme: ThemeUtils.Theme;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  flex: 1;
`;

export const Text = styled.div`
  margin-top: 24px;
  font-family: proxima-soft;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: ${colors.dark.title};
`;

export const Link = styled.a`
  font-family: proxima-soft;
  font-weight: bold;
  color: ${({ theme }: Props): string => theme.linkPrimary};
  text-decoration: none;
`;
