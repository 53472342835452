import React from 'react';

import AlertsBanner from '@organisms/banners/AlertsBanner';

import { connect } from 'react-redux';
import NavBarContainer from '../../molecules/navbar';

type Props = {
  screenWidth: string;
  loggedIn: boolean;
};

type OwnProps = {
  pathname: string;
};

const Header: React.FC<Props & OwnProps> = ({ pathname }) => (
  <div id='header'>
    <div className='full-width-header'>
      <div className='outer-wrap'>
        <header>
          <NavBarContainer pathname={pathname} />
        </header>
      </div>
    </div>
    <AlertsBanner />
  </div>
);

// Unsure how to type the state argument...
// eslint-disable-next-line
const mapStateToProps = (state): Props => ({
  screenWidth: state.app.size.width,
  loggedIn: state.account.login.loggedIn,
});

export default connect(mapStateToProps)(Header);
