/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { connect } from 'react-redux';
import { RootState } from '@redux/reducer';
import { Dispatch } from 'redux';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { getCurrentRestrictedPeriod } from '@reselectors/restrictedPeriod';
import {
  isStartImmediate,
  setDurationStart,
  setDurationEnd,
} from '@reducer/quote/duration.actions';
import {
  isCoverEndTimeAfterCoverStartTime,
  isCoverLongerThanMinimumDuration,
  isCoverShorterThanMaximumDuration,
  isCoverStartTimeInFuture,
  isCoverStartBefore60Days,
  isCoverDurationTimeValid,
  isCoverDurationInRestrictedPeriod,
} from '@services/cover';
import { isBetween22and5 } from '@services/date';
import indicativeDurations from '@config/indicativeDurations';
import DurationOfCover from './DurationOfCover';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Europe/London');

export interface CurrentRestrictedPeriod {
  end_date: string;
  min_duration: number;
}

interface StateProps {
  immediateStart: boolean | null;
  startDateTime: Dayjs | null;
  endDateTime: Dayjs | null;
  productType: string;
  isSubscription: boolean;
  isCoverStartTimeInFuture: boolean;
  isCoverDurationTimeValid: boolean;
  isCoverStartBefore60Days: boolean;
  isCoverEndTimeAfterCoverStartTime: boolean;
  isCoverLongerThanMinimumDuration: boolean;
  isCoverShorterThanMaximumDuration: boolean;
  isCoverDurationInRestrictedPeriod: boolean;
  currentRestrictedPeriod: CurrentRestrictedPeriod[];
  loggedIn: boolean;
  isStartDateTimeBetween22and5: boolean | null;
  userIdentity: string;
  driverDOB: Dayjs | null;
  isFastRepurchase: boolean;
  paymentMethod: string | null;
}

const getPage = (): [string, boolean] => {
  return ['car', false];
};

const mapStateToProps = (state: RootState): StateProps => {
  const {
    quote: {
      duration: { immediateStart, startDateTime, endDateTime },
      product: { productType, isSubscription, paymentMethod },
      driver: { birthdate },
      save: { isFastRepurchase },
    },
    config: {
      optimizelyAttributes: { user_identity: userIdentity },
    },
  } = state;

  return {
    immediateStart,
    paymentMethod,
    startDateTime: startDateTime ? startDateTime.clone() : null,
    endDateTime: endDateTime ? endDateTime.clone() : null,
    productType,
    isSubscription,
    isCoverStartTimeInFuture: isCoverStartTimeInFuture(startDateTime, immediateStart),
    isCoverDurationTimeValid: isCoverDurationTimeValid(startDateTime, endDateTime),
    isCoverStartBefore60Days: isCoverStartBefore60Days(startDateTime),
    isCoverEndTimeAfterCoverStartTime: isCoverEndTimeAfterCoverStartTime(
      startDateTime,
      endDateTime,
    ),
    isCoverLongerThanMinimumDuration: isCoverLongerThanMinimumDuration(startDateTime, endDateTime),
    isCoverShorterThanMaximumDuration: isCoverShorterThanMaximumDuration(
      startDateTime,
      endDateTime,
      productType,
    ),
    isCoverDurationInRestrictedPeriod: isCoverDurationInRestrictedPeriod(
      startDateTime,
      endDateTime,
      getCurrentRestrictedPeriod(state),
    ),
    currentRestrictedPeriod: getCurrentRestrictedPeriod(state),
    loggedIn: state.account.login.loggedIn,
    isStartDateTimeBetween22and5: isBetween22and5(startDateTime),
    userIdentity,
    driverDOB: birthdate,
    isFastRepurchase,
  };
};

interface DispatchProps {
  setImmediateStart: (immediateStart: boolean, isSubscription: boolean) => void;
  setStartDateTime: (date: Dayjs, isSubscription: boolean) => void;
  setEndDateTime: (date: Dayjs) => void;
  nextAction: (
    action: string | boolean,
    isFastRepurchase: boolean,
    paymentMethod: string | null,
    productType: string,
  ) => void;
  resetEndDate: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setImmediateStart: (immediateStart: boolean, isSubscription: boolean) => {
    dispatch(isStartImmediate(immediateStart));
    if (immediateStart && isSubscription)
      dispatch(
        setDurationEnd(
          dayjs
            .tz(dayjs(), 'Europe/London')
            .add(indicativeDurations.getSubs().default.inHours, 'hour'),
        ),
      );
  },
  setStartDateTime: (date: Dayjs, isSubscription = false) => {
    dispatch(setDurationStart(dayjs.tz(date, 'Europe/London')));
    if (isSubscription)
      dispatch(
        setDurationEnd(
          dayjs
            .tz(date, 'Europe/London')
            .add(indicativeDurations.getSubs().default.inHours, 'hour'),
        ),
      );
  },
  setEndDateTime: (date: Dayjs) => {
    dispatch(setDurationEnd(dayjs.tz(date, 'Europe/London')));
  },
  nextAction: (
    action: string | boolean,
    isFastRepurchase: boolean,
    paymentMethod: string,
    productType: string,
  ) => {
    if (isFastRepurchase) {
      if (
        productType === 'newdriver' ||
        (productType === 'tc' && paymentMethod === 'subscription')
      ) {
        return dispatch(saveAndGoTo('driving-profile', false));
      }

      return dispatch(saveAndGoTo('confirm-details'));
    }

    const [page, checkProductUw] = getPage();
    return dispatch(saveAndGoTo(page, checkProductUw));
  },
  resetEndDate: () => dispatch(setDurationEnd(null)),
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
  const {
    userIdentity,
    isFastRepurchase,
    paymentMethod,
    productType,
    ...otherStateProps
  } = stateProps;
  const { nextAction, ...otherDispatchProps } = dispatchProps;
  return {
    ...otherStateProps,
    ...otherDispatchProps,
    nextAction: () => nextAction(userIdentity, isFastRepurchase, paymentMethod, productType),
  };
};

const DurationOfCoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DurationOfCover);

export default DurationOfCoverContainer;
