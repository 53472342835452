import React, { useState } from 'react';
import styled from 'styled-components';

import RadioButton from '@atoms/inputs/RadioButton';
import TitleRadioButton from '@atoms/inputs/TitleRadioButton';
import { ComponentType, useComponentId } from '@hooks';

const TitleChoiceContainer = styled.div`
  display: flex;
  margin: ${(props) => (props.squashed ? null : '0 -4px 16px')};
  ${(props) => props.scrollable && 'overflow-x: scroll;'}
`;

const toCamelCase = (str) =>
  typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;

const RadioRow = ({
  customButton,
  squashed,
  scrollable,
  options,
  name,
  value,
  onSelect,
  disabled,
}) => {
  const creator = useComponentId();
  const [hovered, setHovered] = useState(null);
  if (name === 'title') {
    const Button = TitleRadioButton;
    return (
      <TitleChoiceContainer
        id={creator(ComponentType.CONTAINER, 'titleChoice')}
        squashed={squashed}
        scrollable={scrollable}
      >
        {options.map((option) => (
          <Button
            id={creator(ComponentType.BUTTON, `${name}${toCamelCase(option.value)}`)}
            checked={value === option.value}
            key={option.value}
            onSelect={onSelect}
            tickPosition='5px'
            tickSize='16px'
            disabled={disabled}
            option={option}
            squashed={squashed}
            onFocus={() => setHovered(option.value)}
            onBlur={() => setHovered(null)}
            otherItemSelected={value !== '' && option.value !== value}
            hovered={hovered === option.value}
            otherItemHovered={hovered !== null && hovered !== option.value}
            {...option}
          />
        ))}
      </TitleChoiceContainer>
    );
  }
  const Button = customButton || RadioButton;
  return (
    <TitleChoiceContainer
      id={creator(ComponentType.CONTAINER, 'titleChoice')}
      squashed={squashed}
      scrollable={scrollable}
    >
      {options.map((option) => (
        <Button
          id={creator(ComponentType.BUTTON, `${name}${toCamelCase(option.value)}`)}
          checked={value === option.value}
          key={option.value}
          onSelect={onSelect}
          tickPosition='5px'
          tickSize='10px'
          disabled={disabled}
          option={option}
          squashed={squashed}
          {...option}
        />
      ))}
    </TitleChoiceContainer>
  );
};

RadioRow.defaultProps = {
  disabled: false,
  squashed: false,
  scrollable: false,
  customButton: '',
  name: 'default',
};

export default RadioRow;
