import colors from '@config/colors';

import infoSVG from '@assets/info-icon-sm.svg';
import successSVG from '@assets/complete-tick-variable.svg';

type StylePropsType = {
  Icon: string | undefined;
  textColor: string;
};

export default (valid: boolean, info: boolean): StylePropsType => {
  if (valid) {
    return { Icon: successSVG, textColor: colors.caribbeanGreen };
  }
  if (info) {
    return { Icon: infoSVG, textColor: colors.outerSpace };
  }
  return { Icon: undefined, textColor: colors.outerSpace };
};
