import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { productEmail } from '@reselectors/quote';
import { pollForQuoteStatus, setPaymentField } from '@reducer/quote/payment.actions';
import { sendEvent } from '@reducer/analytics/actions';
import { ConversionProject } from '@config/constants';

import { RootState } from '@redux/reducer';

import Payment from './Payment';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Europe/London');
interface StateProps {
  cardHolderName: string;
  isImmediateStart: boolean | null;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  paymentMethod: string;
  paymentError: string | null;
  productEmail: string;
  isSubscription: boolean;
  userIdentity: string;
  productType: string;
}

interface DispatchProps {
  setCardHolderName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPaymentWithCard: () => void;
  sendGAEventForPaymentFlow: () => void;
  pollForQuoteStatus: () => void;
  setPaymentLoader: (loading: boolean) => void;
}

export interface ComponentProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: RootState): StateProps => {
  const {
    quote: { payment, duration, product },
  } = state;
  return {
    cardHolderName: payment.cardHolderName,
    isImmediateStart: duration.immediateStart,
    startDate: duration.startDateTime,
    endDate: duration.endDateTime,
    paymentMethod: payment.paymentMethod,
    paymentError: payment.error,
    productEmail,
    isSubscription: product.isSubscription,
    userIdentity: state.config.optimizelyAttributes.user_identity,
    productType: product.productType,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setCardHolderName: (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setPaymentField('cardHolderName', event.target.value)),
  setPaymentWithCard: () => {
    dispatch(setPaymentField('error', null));
    dispatch(setPaymentField('paymentMethod', 'card'));
  },
  sendGAEventForPaymentFlow: () =>
    dispatch(
      sendEvent(ConversionProject.ConversionOptimization, {
        featureFlagName: ConversionProject.PAYMENT_START,
        featureFlagValue: 'Apple / Google Pay',
      }),
    ),
  pollForQuoteStatus: () => dispatch(pollForQuoteStatus()),
  setPaymentLoader: (loading: boolean) => dispatch(setPaymentField('loading', loading)),
});

const PaymentForm = reduxForm({
  form: 'paymentForm',
})(Payment);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
