import React, { ReactElement, KeyboardEvent } from 'react';
import { FieldProps } from 'redux-form';
import { Car } from '@pages/quote/CarDetails/CarDetails.container';

import {
  RegInputContainer,
  RegistrationInput,
  RegistrationInputWrapper,
  LicenceIcon,
  LicenceIconWrapper,
} from './styles';
import { cleanedValue } from './VehicleLookupForm.component';

interface InputProps {
  onSearch: (reg: string) => void;
  car: Car;
  resetLookupCar: () => void;
  onRegUpdate: () => void;
  disabled: boolean;
}

const VehicleLookupSearch = ({
  input,
  meta,
  onSearch,
  car,
  resetLookupCar,
  onRegUpdate,
  disabled,
}: FieldProps | InputProps): ReactElement => {
  const { onChange, onBlur, value } = input;
  const { error } = meta;
  const newValue = value;

  const handleOnChange = (e) => {
    if (car && car?.make) {
      resetLookupCar();
    }
    onRegUpdate();
    onChange(e);
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !disabled) {
      onSearch(cleanedValue(newValue.toUpperCase()));
    }
  };
  return (
    <RegInputContainer hasError={error}>
      <LicenceIconWrapper>
        <LicenceIcon />
      </LicenceIconWrapper>
      <RegistrationInputWrapper>
        <RegistrationInput
          id='car_form_car_details'
          onChange={handleOnChange}
          onBlur={onBlur}
          value={newValue}
          placeholder='CAR REG'
          maxLength={8}
          type='text'
          onKeyPress={handleEnter}
        />
      </RegistrationInputWrapper>
    </RegInputContainer>
  );
};

export default VehicleLookupSearch;
