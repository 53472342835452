import deepUpdate from '@services/deepUpdate';
import { types, UserActions } from '@redux/reducer/quote/user.actions';
import { types as loginTypes, LoginActiontypes } from '@redux/reducer/account/login.actions';
import { types as quoteTypes, QuoteActions } from '@redux/reducer/quote/actions';
import { types as paymentTypes } from '@redux/reducer/quote/payment.actions';
import { aboutYou } from '@redux/reducer/pages/actions';
import { PageActions } from '@redux/reducer/pages/types';
import { FastRepurchaseActions, types as fastRepurchasetypes } from './fastRepurchase.actions';

export interface User {
  connection: string;
  prefilledDriver: boolean;
  prefilledOwner: boolean;
  quoteSummaryModalOpen: boolean;
  referrer: string;
  relationship: string | null;
  relationshipModalOpen: boolean;
  tempcoverReason: string;
  tempcoverAltReason: string;
  rejectionEmail: string;
  status: string;
  version: string;
  source: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  affiliateId: string;
  awc: string;
  seonsession: string | undefined;
}

export const initialState: User = {
  connection: '',
  prefilledDriver: false,
  prefilledOwner: false,
  quoteSummaryModalOpen: false,
  referrer: '',
  relationship: null,
  relationshipModalOpen: false,
  tempcoverReason: '',
  tempcoverAltReason: '',
  rejectionEmail: '',
  status: '',
  version: 'CLASSIC',
  source: '',
  utmSource: '',
  utmMedium: '',
  utmCampaign: '',
  affiliateId: '',
  awc: '',
  seonsession: undefined,
};

export type UserActionTypes =
  | UserActions
  | QuoteActions
  | PageActions
  | LoginActiontypes
  | FastRepurchaseActions;

export default function user(state: User = initialState, action = {} as UserActionTypes): User {
  switch (action.type) {
    case types.SET_USER_FIELD:
      return deepUpdate(state, action.field, action.value);
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return {
        ...initialState,
        referrer: state.referrer,
        utmSource: state.utmSource,
        utmMedium: state.utmMedium,
        utmCampaign: state.utmCampaign,
        awc: state.awc,
      };
    // this used to be `case quoteTypes.RESET_REFERRER`, but that was dumb, since the only
    // thing that dispatched that action was...the payment success saga. So we just
    // listen for payment success instead.
    case paymentTypes.PAYMENT_SUCCESS:
      return {
        ...state,
        referrer: '',
        utmSource: '',
        utmMedium: '',
        utmCampaign: '',
        awc: '',
      };
    case types.PREFILL_DRIVER_ON_EDIT:
      return {
        ...state,
        prefilledDriver: true,
        prefilledOwner: false,
      };
    case types.PREFILL_DRIVER:
      return {
        ...state,
        prefilledDriver: true,
        prefilledOwner: false,
      };
    case types.PREFILL_OWNER:
      return {
        ...state,
        prefilledDriver: false,
        prefilledOwner: true,
      };
    case types.PREFILL_OWNER_ON_EDIT:
      return {
        ...state,
        prefilledDriver: false,
        prefilledOwner: true,
      };
    case types.PREFILL_CONNECTION:
      return {
        ...state,
        status: 'driver',
        relationship: action.preferredCar.relationship,
        prefilledDriver: true,
        prefilledOwner: true,
      };
    case aboutYou.SUBMITTED:
      return {
        ...state,
        source: action.payload.source,
      };
    case fastRepurchasetypes.SET_FAST_REPURCHASE_DATA:
      return {
        ...state,
        ...action.data.user,
      };
    default:
      return state;
  }
}
