import { SetStateFieldActionCreator } from '@utils/types';
import { State } from '.';

// Types
export enum types {
  SET_IS_MOBILE = 'SET_IS_MOBILE',
  SET_SIZE = 'SET_SIZE',
  CHANGE_PRODUCT = 'CHANGE_PRODUCT',
  SET_APP_FIELD = 'SET_APP_FIELD',
  HIDE_ALERT_BANNER = 'HIDE_ALERT_BANNER',
}

// Actions
export const setSize = (width: number, height: number): SetSize => ({
  type: types.SET_SIZE,
  width,
  height,
});

export const setIsMobile = (isMobile: boolean): SetIsMobile => ({
  type: types.SET_IS_MOBILE,
  isMobile,
});

export const hideAlertBanner = (): HideAlertBanner => ({
  type: types.HIDE_ALERT_BANNER,
});

export const changeProduct = (product: string): ChangeProduct => ({
  type: types.CHANGE_PRODUCT,
  product,
});

export const setAppField: SetStateFieldActionCreator<State, typeof types.SET_APP_FIELD> = (
  field,
  value,
) => ({
  type: types.SET_APP_FIELD,
  field,
  value,
});

export interface SetSize {
  type: typeof types.SET_SIZE;
  width: number;
  height: number;
}

export interface SetIsMobile {
  type: typeof types.SET_IS_MOBILE;
  isMobile: boolean;
}

export interface HideAlertBanner {
  type: typeof types.HIDE_ALERT_BANNER;
}

export interface ChangeProduct {
  type: typeof types.CHANGE_PRODUCT;
  product: string;
}

export type SetAppField = ReturnType<typeof setAppField>;

export type AppTypeActions = SetSize | SetIsMobile | ChangeProduct | SetAppField | HideAlertBanner;
