export default function getRelationshipText(
  relationship,
  defaultValue = 'Select your relationship',
) {
  switch (relationship) {
    case 'pa':
      return 'Parent/child';
    case 'sp':
      return 'Partner';
    case 'fa':
      return 'Other Family';
    case 'fr':
      return 'Friend';
    case 'co':
      return 'Colleague';
    case 'ot':
      return 'Other';
    default:
      return defaultValue;
  }
}
