import HttpClient from './httpClient';

const transform = (data) => ({
  is_manual: data.isManual,
  country: data.country,
  county: data.county,
  flat_no: data.flatNumber,
  house_name: data.houseName,
  house_no: data.houseNumber,
  line1: data.line1,
  line2: data.line2,
  postcode: data.postcode,
  road: data.road,
  town: data.town,
  udprn: data.udprn,
  lookup_id: data.lookupId,
});

const buildError = (message, redirect, code) => ({ message, redirect, code });

const address = {
  create(data) {
    return new HttpClient.Builder().post('/accnt/addresses/').data(transform(data)).execute();
  },

  update(uuid, data) {
    return new HttpClient.Builder()
      .put(`/accnt/addresses/${uuid}/`)
      .data(transform(data))
      .execute();
  },

  save(data) {
    return data.uuid ? address.update(data.uuid, data) : address.create(data);
  },

  getList(postcode) {
    const postcodeNoSpaces = postcode.replace(' ', '');
    try {
      return new HttpClient.Builder().get(`/lookup/address/${postcodeNoSpaces}`).execute();
    } catch (error) {
      switch (error.status) {
        case 404:
          throw buildError(
            "This postcode hasn't been found please enter your address manually below.",
            false,
            'POSTCODE_NOT_FOUND',
          );
        case 500:
          throw buildError(
            'Sorry, the service is not available at the moment.',
            false,
            'ADDRESS_LOOKUP_NOT_AVAILABLE',
          );
        default:
          return error.json;
      }
    }
  },
};

export default address;
