import React from 'react';

import { Container, Body, PriceImage } from './assets/styles';

type Props = {
  id: string;
  children?: React.ReactNode;
  alignIcon?: string;
};

const PriceTagBox: React.FC<Props> = ({ id, children, alignIcon = 'center' }) => {
  return (
    <Container id={id}>
      <PriceImage align={alignIcon} />
      <Body>{children}</Body>
    </Container>
  );
};

export default PriceTagBox;
