import styled from 'styled-components';

export const SectionTitleWithTooltipContainer = styled.div`
  display: flex;
`;

export const SectionTitle = styled.h3`
  flex: 1;
  color: #364344;
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 8px;
`;

export const IconContainer = styled.div`
  display: flex;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-top: 2px;
`;
