import { connect } from 'react-redux';
import PricingElement from '@molecules/blocks/PricingElement';
import { crossSaleInitialPriceSelector } from '@reselectors/quote';

const mapStateToProps = (state) => {
  const {
    quote: { driver, duration, save, car, product, ancillary },
  } = state;

  const getInitialPrice = () => {
    return ancillary.breakdownCover ? ancillary.initialPriceWithBreakdownCover : save.initialPrice;
  };

  const { price } = save;

  return {
    duration,
    price,
    initialPrice: getInitialPrice(),
    validDiscountCode: save.validDiscountCode,
    carDetails: `${car.make} ${car.model}`,
    policyHolderName: `${driver.firstName} ${driver.lastName}`,
    crossSaleInitialPrice: crossSaleInitialPriceSelector(
      state,
      getInitialPrice(),
      price,
      ancillary.breakdownCover,
    ),
    isSubscription: product.isSubscription,
    breakdownCover: ancillary.breakdownCover,
  };
};

const ConnectedPricingElement = connect(mapStateToProps)(PricingElement);

export default ConnectedPricingElement;
