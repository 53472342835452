import Debug from 'debug';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { types as initTypes } from '@redux/init';
import type { RootState } from '@redux/reducer';
import type { AppDispatch } from '@redux/store';
import handleError from '@redux/utils/errorHandler';
import { quoteApi } from '@services/api/quoteRtk';
import { getCookie } from '@services/cookies';
import { buyapowaCookieFound } from './referralSlice';

const debugListeners = Debug('veygo:features:referral:listeners');

/** {@link https://redux-toolkit.js.org/api/createListenerMiddleware | API Reference > createListenerMiddleware } */
const referralListenerMiddleware = createListenerMiddleware({
  onError: handleError,
});

/**
 * Typed version of `startListening`.
 *
 * {@link https://redux-toolkit.js.org/api/createListenerMiddleware#startlistening | API Reference > startListening }
 * */
const startAppListening = referralListenerMiddleware.startListening.withTypes<
  RootState,
  AppDispatch
>();

// Find Buyapowa Cookie listener
const findBuyapowaCookieListener = () => {
  const debug = debugListeners.extend('findBuyapowaCookie');
  startAppListening({
    type: initTypes.INIT,
    effect: (_action, listenerApi) => {
      const { dispatch } = listenerApi;

      const cookieName = 'bp_e';
      const cookieValue = getCookie(cookieName);
      if (cookieValue !== undefined) {
        debug(`Found '${cookieName}' cookie with value: ${cookieValue}`);
        dispatch(buyapowaCookieFound(cookieValue));
      }
    },
  });
};
findBuyapowaCookieListener();

// Create Referral listener
const createReferralListener = () => {
  const debug = debugListeners.extend('createReferral');
  startAppListening({
    predicate: (_action, currentState, previousState) => {
      // Fire this listener's effect if:
      //   1. The Buyapowa encrypted value (from the `bp_e` cookie) exists
      //   2. A new quote has been created
      const hasBuyapowaEncryptedValue = !!currentState.referral.buyapowaEncryptedValue;
      const hasQuoteUuidChanged = currentState.quote.save.uuid !== previousState.quote.save.uuid;
      const hasQuoteUuid = !!currentState.quote.save.uuid;

      const result = hasBuyapowaEncryptedValue && hasQuoteUuidChanged && hasQuoteUuid;
      return !!result;
    },
    effect: async (_action, listenerApi) => {
      debug('Creating referral...');
      const state = listenerApi.getState();
      const quoteUuid = state.quote.save.uuid;
      const { buyapowaEncryptedValue } = state.referral;

      const promise = listenerApi.dispatch(
        quoteApi.endpoints.createReferral.initiate({ quoteUuid, buyapowaEncryptedValue }),
      );

      // You use `.unwrap()` if you want the result right now.
      const result = await promise.unwrap();
      debug('Referral created: ', result);
    },
  });
};
createReferralListener();

export default referralListenerMiddleware;
