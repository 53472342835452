import React from 'react';
import styled from 'styled-components';

import { Button } from '@rentecarlo/component-library';
import Backdrop from '@atoms/layout/modal/Backdrop';
import ModalPanel from '@atoms/layout/modal/ModalPanel';

const ButtonContainer = styled.div`
  width: 150px;
`;

const FooterRow = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Modal = (props) => {
  const {
    children,
    cancelLabel,
    canConfirm,
    okLabel,
    onAccept,
    onCancel,
    displayButtons,
    show,
    justification,
    width,
  } = props;

  return show ? (
    <Backdrop>
      <ModalPanel justification={justification} width={width}>
        <div>
          {children}
          {displayButtons && (
            <FooterRow>
              <ButtonContainer>
                <Button id='quote-button-driverDetailsSummaryModalCancel' onClick={onCancel}>
                  {cancelLabel}
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  id='quote-button-driverDetailsSummaryModalAccept'
                  onClick={onAccept}
                  disabled={!canConfirm}
                >
                  {okLabel}
                </Button>
              </ButtonContainer>
            </FooterRow>
          )}
        </div>
      </ModalPanel>
    </Backdrop>
  ) : null;
};

Modal.defaultProps = {
  cancelLabel: 'Cancel',
  canConfirm: true,
  displayButtons: true,
  justification: 'center',
  okLabel: 'Ok',
  width: 500,
};

export default Modal;
