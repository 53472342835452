import styled from 'styled-components';
import media from '@config/media';
import {
  getProductColour,
  getSecondaryColour,
  getActiveColour,
} from '@services/productSettingsHelpers';

type NavigationBarProps = {
  productColour: ReturnType<typeof getProductColour>;
  secondaryColour: ReturnType<typeof getSecondaryColour>;
  activeColour: ReturnType<typeof getActiveColour>;
};

type DropdownInterface = {
  open: boolean;
};

interface ProgressBarMobileContainerProps {
  productColour: string;
}

type DropMenuProps = DropdownInterface & Pick<NavigationBarProps, 'secondaryColour'>;

export const NavBarContainer = styled.div`
  width: 100%;
  min-height: 60px;
  *:focus {
    outline: none;
  }
  position: sticky;
  z-index: 2;
`;

export const Container = styled.div`
  width: 100%;
  min-height: 60px;
  *:focus {
    outline: none;
  }
`;

export const NavigationBar = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0px 56px;
  background-color: ${(props: NavigationBarProps): string => props.productColour};
  ${media.mobile`
    padding: 8px 16px 0 16px;
    height: 44px;
  `}
`;

export const Logo = styled.img`
  ${media.mobile`
    height: 30px;
  `}
  &:hover {
    cursor: pointer;
  }
`;

export const RightContainer = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: flex-end;
  flex-basis: auto;
  ${media.mobile`
    flex: 1;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SignInButton = styled.button`
  height: 30px;
  border-radius: 17px;
  border-width: 0;
  padding: 2px 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props: NavigationBarProps): string => props.secondaryColour};
  transition: 0.5s;
  &:hover {
    background-color: ${(props: NavigationBarProps): string => props.productColour};
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  }
  &:active {
    background-color: ${(props: NavigationBarProps): string => props.activeColour};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  }
  ${media.mobile`
    height: 24px;
  `}
`;

export const NavBarText = styled.span`
  font-family: 'proxima-soft';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: white;
`;

export const AccountIconContainer = styled.button`
  display: flex;
  min-height: 40px;
  min-width: 40px;
  border: 0px;
  border-radius: 20px;
  background-color: #044656;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  &:hover {
    background-color: #0d7a86;
    box-shadow: inset 0 -2px 0 0 #044e56, 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  }
  &:active {
    background-color: #0d7a86;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  }
  ${media.mobile`
    min-height: 32px;
    min-width: 32px;
  `}
`;

export const AccountButton = styled.img`
  width: 24px;
  ${media.mobile`
    width: 20px;
  `}
`;

export const DropMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props: DropMenuProps): string => (props.open ? '80' : '0')}px;
  width: 100%
  padding: 0px 377px;
  transition: 0.4s all ease-in-out;
  overflow: hidden;
  background-color: ${(props: DropMenuProps): string => props.secondaryColour};
  @media only screen and (max-width: 690px) {
    height: ${(props: DropMenuProps): string => (props.open ? '209' : '0')}px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const DropButtonWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 690px) {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const DropButtonContainer = styled.button`
  display: ${(props: DropdownInterface): string => (props.open ? 'flex' : 'none')};
  height: 48px;
  border-width: 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  width: 215px;
  @media only screen and (max-width: 690px) {
    align-items: center;
    justify-content: flex-start;
    height: 68px;
    min-width: 100%;
    padding: 16px;
  }
`;

export const DropText = styled.span`
  font-family: 'proxima-soft';
  font-size: ${(props: DropdownInterface): string => (props.open ? '16' : '0')}px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: white;
  padding-left: 15px;
`;

export const Account = styled.img`
  padding: 9px;
`;

export const Purchases = styled.img`
  padding: 9px;
`;

export const SignOutContainer = styled.div`
  display: ${(props: DropdownInterface): string => (props.open ? 'flex' : 'none')};
  height: ${(props: DropdownInterface): string => (props.open ? '34' : '0')}px;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 690px) {
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const SignOutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${(props: DropdownInterface): string => (props.open ? '34' : '0')}px;
  min-width: ${(props: DropdownInterface): string => (props.open ? '257' : '0')}px;
  border-width: ${(props: DropdownInterface): string => (props.open ? '1' : '0')}px;
  border-color: white;
  border-style: solid;
  border-radius: 17px;
  background-color: transparent;
  font-family: 'proxima-soft';
  font-size: ${(props: DropdownInterface): string => (props.open ? '14' : '0')}px;
  font-weight: 500;
  text-align: center;
  color: white;
  &:hover {
    background-color: white;
    color: #43d3b1;
  }
  &:active {
    background-color: white;
    color: #43d3b1;
  }
  @media only screen and (max-width: 690px) {
    margin: 16px 16px;
    justify-content: center;
    align-items: center;
  }
`;

export const ProgressBarDesktopContainer = styled.div`
  flex-grow: 1;
  padding: 0 215px 0 100px;
  ${media.tablet`
    padding: 0 30px 0 30px;
  `}
  ${media.mobile`
    display: none;
  `}
`;

export const ProgressBarMobileContainer = styled.div`
  display: none;
  background-color: ${(props: ProgressBarMobileContainerProps): string => props.productColour};
  ${media.mobile`
    display: block;
    align-items: center;
    padding: 8px 16px;
    height: 22px;
  `}
`;
