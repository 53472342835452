import { types as loginTypes, LoginActiontypes } from '../account/login.actions';
import { types as quoteTypes, QuoteActions } from '../quote/actions';

import {
  MARK_PAGE_AS_COMPLETE_SUCCESS,
  MARK_PAGE_AS_COMPLETE_FAILURE,
  MARK_PAGE_AS_RESET_SUCCESS,
  PagesState,
  PageActions,
} from './types';

// Reducer
export const initialState: PagesState = {
  error: null,
  completed: {
    carDetails: false,
    drivingHistory: false,
  },
};

export type PageActionTypes = PageActions | QuoteActions | LoginActiontypes;

export default function pages(
  state: PagesState = initialState,
  action: PageActionTypes,
): PagesState {
  switch (action.type) {
    case MARK_PAGE_AS_COMPLETE_SUCCESS:
      return {
        ...state,
        completed: {
          ...state.completed,
          [action.pageName]: true,
        },
      };
    case MARK_PAGE_AS_RESET_SUCCESS:
      return {
        ...state,
        completed: {
          ...state.completed,
          [action.pageName]: false,
        },
      };
    case MARK_PAGE_AS_COMPLETE_FAILURE:
      return {
        ...state,
        completed: {
          ...state.completed,
          [action.pageName]: false,
        },
        error: action.error,
      };
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return initialState;
    default:
      return state;
  }
}
