import styled from 'styled-components';

import colors from '@config/colors';
import fonts from '@config/fonts';

export const Title = styled.h1`
  font-family: ${fonts.title};
  font-weight: bold;
  line-height: 36px;
  font-size: 32px;
  color: ${colors.dark.title};
`;

export const TitleSpacer = styled.div`
  width: 100%;
  height: 40px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

type Hidable = {
  visible: boolean;
};

export const ButtonWrapper = styled.div<Hidable>`
  ${({ visible }): string | null => (visible ? null : 'display: none;')};
  width: 100%;
  margin-bottom: 16px;
`;
