import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { RoundedGreyBox } from '@rentecarlo/component-library';

import colors from '@config/colors';
import Address from '@atoms/paragraphs/Address';
import PostcodeSearchBox from '@molecules/searchBoxes/PostcodeSearchBox';
import ReduxFieldValidation from '@services/formValidation';
import AddressSelect from '@molecules/blocks/AddressSelect';
import ToggleButtons from '@molecules/inputs/ToggleButtons';
import { ComponentProps } from './BillingAddress.container';

const TitleRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${colors.outerSpace};
  flex: 1;
  font-family: proxima-soft;
  font-size: 16px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
`;

interface ToggleButtonProps {
  displayLeftError: boolean;
  reuseDriverAddress: boolean;
  meta: Record<string, unknown>;
  setReuseDriverAddress: (value: boolean) => void;
}

const ToggleButtonField = ({
  displayLeftError,
  reuseDriverAddress,
  meta,
  setReuseDriverAddress,
}: ToggleButtonProps) => (
  <ToggleButtons
    value={reuseDriverAddress}
    onSelect={(event: Record<string, unknown>) => setReuseDriverAddress(event.target.value)}
    error={meta.error}
    groupName='useThisAddress'
    displayLeftError={displayLeftError}
    page='payment'
  />
);

const PostcodeSearchContainer = styled.div`
  margin-bottom: 32px;
`;

const BillingAddress: React.FC<ComponentProps> = ({
  driverAddress,
  setReuseDriverAddress,
  reuseDriverAddress = null,
  lookupBillingAddress,
  setBillingAddressLookupPostcode,
  addressResultKey = '',
  addressResults = [],
  billingAddressOnChange,
  billingAddress = [],
}) => {
  return (
    <>
      <RoundedGreyBox>
        <TitleRow>
          <Title id='payment-title-billingAddress'>USE THIS ADDRESS?</Title>
        </TitleRow>
        <Address address={driverAddress} />
      </RoundedGreyBox>
      <ButtonContainer>
        <Field
          id='payment-field-reuseDriverAddress'
          name='reuseDriverAddress'
          component={ToggleButtonField}
          setReuseDriverAddress={setReuseDriverAddress}
          reuseDriverAddress={reuseDriverAddress}
        />
      </ButtonContainer>
      {reuseDriverAddress === false && (
        <>
          <PostcodeSearchContainer>
            <Field
              name='postcode'
              placeholder='Add your postcode'
              component={PostcodeSearchBox}
              validate={[ReduxFieldValidation.required, ReduxFieldValidation.isCoveredPostcode]}
              id='payment-field-postcodeSearchBox'
              buttonId='searchButton'
              onSearch={lookupBillingAddress}
              onChange={setBillingAddressLookupPostcode}
            />
            <AddressSelect
              value={addressResultKey}
              addresses={addressResults}
              disabled={addressResults.length <= 0}
              setAddressFields={billingAddressOnChange}
            />
          </PostcodeSearchContainer>
          {billingAddress.length > 0 && (
            <RoundedGreyBox>
              <TitleRow>
                <Title id='payment-title-billingAddressFound'>ADDRESS FOUND</Title>
              </TitleRow>
              <Address address={billingAddress} />
            </RoundedGreyBox>
          )}
        </>
      )}
    </>
  );
};

export default BillingAddress;
