import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';

import styled from 'styled-components';
import loaderIcon from '@assets/loading.gif';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img``;
const LoadingTextContainer = styled.div`
  margin-left: 15px;
`;
const MainLoadingText = styled.p``;
const SecondaryLoadingText = styled.p`
  font-size: 10px;
`;

type Props = {
  id: string;
  primaryText?: string;
  secondaryText?: string;
};

export const Loader: React.FC<Props> = ({ id, primaryText, secondaryText }) => {
  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(
      () => Sentry.captureMessage('Loader timed out after 60 seconds'),
      60000,
    );

    return () => clearTimeout(timer);
  }, []);

  return (
    <Wrapper>
      <Icon src={loaderIcon} id={id} />
      {primaryText && (
        <LoadingTextContainer>
          <MainLoadingText id='quote-text-loaderPrimary'>{primaryText}</MainLoadingText>
          <SecondaryLoadingText id='quote-text-loaderSecondary'>
            {secondaryText}
          </SecondaryLoadingText>
        </LoadingTextContainer>
      )}
    </Wrapper>
  );
};

export default Loader;
