import { connect } from 'react-redux';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import { Dispatch } from 'redux';

import { setRegisterFields, registerRequest } from '@reducer/account/register.actions';
import { setDriverField } from '@redux/reducer/quote/driver.actions';

import QuoteRegister from './QuoteRegister';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  const { account } = state;
  return {
    contactPreferences: account.customer.contactPreferences,
    formErrors: getFormSyncErrors('quoteRegisterForm')(state),
    submitting: account.register.loading,
    loggedIn: account.login.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // Unfortunately this is typed as `object` in redux too.
  // eslint-disable-next-line @typescript-eslint/ban-types
  submitRegistration: (values: object) => {
    dispatch(setRegisterFields(values));
    dispatch(setDriverField('email', values.email));
    return dispatch(registerRequest('almost-there'));
  },
});

const QuoteRegisterContainer = connect(mapStateToProps, mapDispatchToProps)(QuoteRegister);

export default reduxForm({
  form: 'quoteRegisterForm',
})(QuoteRegisterContainer);
