import styled from 'styled-components';
import { Button, ThemeUtils } from '@rentecarlo/component-library';

export const FormErrorContainer = styled.div`
  margin-bottom: 20px;
`;

export const PaymentOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -4px 16px;
`;

export const PaymentOption = styled.div<{ fadeOut?: boolean }>`
  margin: 10px 4px;
  opacity: ${(props) => (props.fadeOut ? 0.35 : 1.0)};
`;

export const TextContainer = styled.div`
  margin: 0 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19.42px;
`;

export const PayByCardButton = styled(Button).withConfig({
  displayName: 'PayByCardButton',
})`
  && {
    border-radius: 4px;
    }
  }
`;

PayByCardButton.defaultProps = {
  theme: ThemeUtils.GenericTheme,
};
