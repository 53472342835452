import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';

import { resetQuote } from '@redux/reducer/quote/actions';

import LicenceImage from '@assets/licence-conflict.svg';

import QuoteStep from '@templates/QuoteStep';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const LicenceSwitchError: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetQuote());
  }, [dispatch]);

  return (
    <QuoteStep
      id='licenceSwitchError-component-quoteStep'
      stepTitle='Please try again later'
      stepSubtitle='You told us you have passed your test (congratulations!) but your driving licence is still showing as a provisional licence in the DVLA’s database. Unfortunately we can’t sell you insurance as a full licence holder until the DVLA have updated their database to show your licence as a full licence. Please try again later.'
    >
      <Container>
        <img src={LicenceImage} alt='licence switch error' />
      </Container>
    </QuoteStep>
  );
};

export default LicenceSwitchError;
