import { AnalyticsState } from './analyticTypes';

export const ANALYTICS_SEND_EVENT = 'ANALYTICS.SEND_EVENT';

export interface SendEvent {
  type: typeof ANALYTICS_SEND_EVENT;
  event: string;
  data: AnalyticsState;
}

// Actions
export const sendEvent = (event: string, data: AnalyticsState = {}): SendEvent => ({
  type: ANALYTICS_SEND_EVENT,
  event,
  data,
});

export type AnalyticsActionTypes = SendEvent;
