import styled from 'styled-components';
import colors from '@config/colors';

export const Title = styled.div`
  color: ${colors.dark.title};
  font-family: Graphie;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  padding: 24px 8px 0;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img``;

export const Label = styled.div`
  font-family: proxima-soft;
  font-size: 16px;
  line-height: 19px;
  padding: 16px 24px;
`;

export const ButtonContainer = styled.div`
  margin: 16px;
  min-width: 250px;
`;
