import React from 'react';

const ChevronLeft = () => (
  <svg
    version='1.1'
    id='Capa_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 256 256'
    style={{ enableBackground: 'new 0 0 256 256' }}
  >
    <g>
      <g>
        <polygon points='207.093,30.187 176.907,0 48.907,128 176.907,256 207.093,225.813 109.28,128 		' />
      </g>
    </g>
  </svg>
);

export default ChevronLeft;
