import { call, put, take, takeEvery, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  lookupAddressFailure,
  lookupAddressSuccess,
  lookupBillingAddressFailure,
  lookupBillingAddressSuccess,
  lookupCarFailure,
  lookupCarSuccess,
  setLookupField,
  types,
} from '@reducer/lookup/actions';
import { selectConnection, FETCH_CONNECTIONS_SUCCESS } from '@reducer/account/connections.actions';
import { setCarField } from '@reducer/quote/car.actions';
import address from '@services/api/address';
import car from '@services/api/car';
import { matchingCarInPreferences } from '@services/connections';
import { getDriverOwner } from '@reselectors/quote';

export function* fetchAddressSaga({ postcode }) {
  try {
    const data = yield call(address.getList, postcode);
    yield put(lookupAddressSuccess(data));
  } catch (error) {
    yield put(lookupAddressFailure(error.message, error.redirect, error.code));
  }
}

export function* fetchBillingAddressSaga({ postcode }) {
  try {
    const data = yield call(address.getList, postcode);
    yield put(lookupBillingAddressSuccess(data));
  } catch (error) {
    yield put(lookupBillingAddressFailure(error.message, error.redirect, error.code));
  }
}

export const getLoginStatus = (state) => state.account.login.loggedIn;
export const getPreferredCars = (state) => state.account.connections.preferredCars;

export function* fetchCarSaga({ registrationNumber }) {
  try {
    const loggedIn = yield select(getLoginStatus);
    // We only want to do this for existing customers
    if (loggedIn) {
      // Wait to retrieve the customers previous car list
      yield take(FETCH_CONNECTIONS_SUCCESS);
    }
    const preferredCars = yield select(getPreferredCars);
    const carInPreferences = yield call(
      matchingCarInPreferences,
      loggedIn,
      preferredCars,
      registrationNumber,
    );

    const save = yield select((state) => state.quote.save);
    const carData = yield call(car.get, save.uuid, registrationNumber);

    if (carData.value === null) {
      yield put(setLookupField('indicativePriceMissing', true));
      carData.value = '';
    } else {
      yield put(setCarField('value', carData.value));
    }

    yield put(lookupCarSuccess(carData));
    const driverOwner = yield select(getDriverOwner);
    if (carInPreferences && driverOwner !== true) {
      yield put(selectConnection(carInPreferences.uuid));
      yield put(setCarField('isNewCar', false));
      yield put(change('carForm', 'relationship', carInPreferences.relationship));
    } else {
      yield put(setCarField('isNewCar', true));
      yield put(setCarField('mainInsurer', ''));
      yield put(setCarField('savedMainInsurer', null));
    }
  } catch (error) {
    yield put(lookupCarFailure(error.message, error.redirect, error.code));
  }
}

export function* watchLookup() {
  yield takeEvery(types.LOOKUP_ADDRESS_FETCH, fetchAddressSaga);
  yield takeEvery(types.LOOKUP_BILLING_ADDRESS_FETCH, fetchBillingAddressSaga);
  yield takeEvery(types.LOOKUP_CAR_FETCH, fetchCarSaga);
}
