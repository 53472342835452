export enum types {
  LOGIN_REQUEST = 'LOGIN.REQUEST',
  LOGIN_SUCCESS = 'LOGIN.SUCCESS',
  LOGIN_FAILURE = 'LOGIN.FAILURE',
  LOGOUT_REQUEST = 'LOGOUT.REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT.SUCCESS',
}

export interface Login {
  type: typeof types.LOGIN_REQUEST;
  page: string;
}

export interface LoginSuccess {
  type: typeof types.LOGIN_SUCCESS;
}

export interface LoginFailure {
  type: typeof types.LOGIN_FAILURE;
  error: Error;
  origin: string;
}

export interface Logout {
  type: typeof types.LOGOUT_REQUEST;
  redirect: string;
}

export interface LogoutSuccess {
  type: typeof types.LOGOUT_SUCCESS;
}

export const login = (page: string): Login => ({
  type: types.LOGIN_REQUEST,
  page,
});

export const loginSuccess = (): LoginSuccess => ({
  type: types.LOGIN_SUCCESS,
});

export const loginFailure = (error: Error, origin = 'email'): LoginFailure => ({
  type: types.LOGIN_FAILURE,
  error,
  origin,
});

export const logout = (redirect = '/start'): Logout => ({
  type: types.LOGOUT_REQUEST,
  redirect,
});

export const logoutSuccess = (): LogoutSuccess => ({
  type: types.LOGOUT_SUCCESS,
});

export type LoginActiontypes = Login | LoginSuccess | LoginFailure | Logout | LogoutSuccess;
