import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
  getQuickSelectPricesFailure,
  getQuickSelectPricesSuccess,
  quickSelectIndicativePrice,
  types,
} from '@reducer/quote/indicativePrice.actions';
import { getIsQuickSelectDurationSelected, getIsSubscription } from '@reselectors/quote';
import indicativeDurations from '@config/indicativeDurations';
import { getIndicativeDurationsConfig } from '@reselectors/price';
import handleError from '@redux/utils/errorHandler';

import { quote as quoteApi } from '../../services/api';

export const getQuoteUuid = (state) => state.quote.save.uuid;
export const getUserPostcode = (state) => state.quote.driver.address.postcode;

function* updateWithNewPrice(defaultDuration) {
  const selectedDurationValue = yield select((state) => state.quote.duration);
  const endChoiceSelectedValue = selectedDurationValue?.endChoiceSelected?.value;

  if (endChoiceSelectedValue === undefined) {
    return yield put(quickSelectIndicativePrice(defaultDuration));
  }

  const state = yield select();
  const options = getIndicativeDurationsConfig(state);

  const selectedDuration = Object.entries(options).reduce((acc, [key, value]) => {
    if (key === 'default') return acc;

    const foundOption = value.options.find((option) => option.value === endChoiceSelectedValue);
    return foundOption
      ? {
          value: foundOption.value,
          tab: value.value,
          inHours: foundOption.inHours,
        }
      : acc;
  }, null);

  return yield put(quickSelectIndicativePrice(selectedDuration || defaultDuration));
}

// eslint-disable-next-line consistent-return
export function* getQuickSelectPricesSaga(action) {
  try {
    const postcode = yield select(getUserPostcode);
    if (postcode === '') {
      return put(getQuickSelectPricesFailure());
    }

    const uuid = yield select(getQuoteUuid);

    const durations = action.isSubscription
      ? indicativeDurations.getSubs()
      : yield select(getIndicativeDurationsConfig);

    const availableHours = Object.keys(durations)
      .filter((key) => key !== 'default')
      .flatMap((key) => durations[key].options)
      .map((x) => x.inHours);

    const prices = yield call(
      quoteApi.getIndicativePrice,
      uuid,
      availableHours,
      action.isSubscription,
    );

    yield put(getQuickSelectPricesSuccess(prices.prices));

    const isQuickSelectDurationSelected = yield select(getIsQuickSelectDurationSelected);

    if (!isQuickSelectDurationSelected || action.isSubscription) {
      // subs (inHours) duration is a variable (set using now()) so need to use the same value that is sent to pricing
      const defaultDuration = action.isSubscription ? durations.default : action.defaultDuration;

      yield put(quickSelectIndicativePrice(defaultDuration));
    } else {
      yield updateWithNewPrice(action.defaultDuration);
    }
  } catch (e) {
    handleError(e);
    yield put(getQuickSelectPricesFailure());
  }
}

export function* watchIndicativePrice() {
  yield takeEvery([types.QUICK_SELECT_PRICES_REQUEST], getQuickSelectPricesSaga);
}
