import React from 'react';
import styled from 'styled-components';
import colors from '@config/colors';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  height: 19px;
  line-height: 19px;
`;

const Title = styled(Text)`
  color: ${colors.outerSpace};
  text-transform: uppercase;
`;
const Value = styled(Text)`
  color: ${colors.blue.secondary};
  text-align: right;
`;

const TableRow = ({ title, value }) => (
  <Row>
    <Title id={`quote-title-tableRow${title}`}>{title}</Title>
    <Value id={`quote-value-tableRow${title}`}>{value}</Value>
  </Row>
);

export default TableRow;
