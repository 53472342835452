import React from 'react';
import { Field } from 'redux-form';
import { useAppSelector } from '@hooks';

import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import Group from '@atoms/layout/form/Group';

import ReduxFieldValidation from '@services/formValidation';
import normalizeAlphaNumericOrSpace from '@services/formNormalization';
import TextInputField from '@atoms/inputs/TextInputField';
import DateInputField from '@molecules/DateInputField';

const PostcodeAndDob: React.FC = () => {
  const driverBirthdate = useAppSelector((state) => state.quote.driver?.birthdate);
  const hadPostcode = useAppSelector((state) => Boolean(state.account.customer.postcode));
  const prefilled = useAppSelector((state) => Boolean(state.quote.user.prefilledDriver));
  const isQuoteForMyself = useAppSelector((state) => state.account.customer.isQuoteForMyself);

  return (
    <>
      <Group>
        <SectionTitle id='quoteStart-title-temporaryDriverPostcodeText'>
          {isQuoteForMyself ? 'What is your postcode?' : 'What is their postcode?'}
        </SectionTitle>
        <Field
          id='postcode'
          name='postcode'
          placeholder='Postcode'
          component={TextInputField}
          maxLength={8}
          alwaysCapitalised
          validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isCoveredPostcode]}
          normalize={normalizeAlphaNumericOrSpace}
          disabled={hadPostcode && prefilled}
        />
      </Group>
      <Group>
        <SectionTitle id='quoteStart-title-tempDriverDOBText'>
          {isQuoteForMyself ? 'What is the your date of birth?' : 'What is their date of birth?'}
        </SectionTitle>
        <Field
          id='birthdate'
          name='birthdate'
          component={DateInputField}
          validate={[ReduxFieldValidation.isValidDOB, ReduxFieldValidation.isOver16]}
          disabled={!!(driverBirthdate && prefilled)}
        />
      </Group>
    </>
  );
};

export default PostcodeAndDob;
