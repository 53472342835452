export const getQuoteJourneyDuration = (): number => {
  const startTime = sessionStorage.getItem('quoteJourneyStart');

  if (!startTime) return 0;

  const parsedStartTime = parseInt(startTime);
  if (Number.isNaN(parsedStartTime)) return 0;

  const durationInMilliseconds = Date.now() - parsedStartTime;
  return Math.round(durationInMilliseconds / 1000);
};
