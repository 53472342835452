import React from 'react';
import styled from 'styled-components';
import rightArrow from '@assets/right-arrow-icon.svg';
import downArrow from '@assets/down-arrow-icon.svg';
import upArrow from '@assets/up-arrow-icon.svg';
import colors from '@config/colors';

const ArrowContainer = styled.div`
  height: 30px;
  width: 30px;
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 17px;
  background-color: ${colors.white};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  align-items: center;
  justify-content: center;
  display: flex;
  padding: ${(props) => props.padding};
`;

const LinkArrow = ({ direction }) => {
  let src;
  let padding;

  switch (direction) {
    case 'up':
      src = upArrow;
      padding = '0px 0px 2px 0px';
      break;
    case 'down':
      src = downArrow;
      padding = '1px 0 0 0';
      break;
    case 'right':
      src = rightArrow;
      padding = '0px 0px 0px 2px';
      break;
    default:
      break;
  }
  return (
    <ArrowContainer padding={padding}>
      <img src={src} alt='src' />
    </ArrowContainer>
  );
};

export default LinkArrow;
