import React from 'react';
import { ToolTipModal, DesktopPageModal } from '@rentecarlo/component-library';

type Props = {
  closeText: string;
  close: () => void;
  show: boolean;
};

export const sizes = {
  desktopWidth: '1024px',
  desktopWidthInt: 1024,
};

const PageModal: React.FC<Props> = ({ show, close, closeText, children }) => {
  return window.innerWidth >= sizes.desktopWidthInt ? (
    <DesktopPageModal visible={show} onClick={close} closeText={closeText}>
      {children}
    </DesktopPageModal>
  ) : (
    <ToolTipModal visible={show} closeText='Close' onClick={close} styling={false}>
      {children}
    </ToolTipModal>
  );
};

export default PageModal;
