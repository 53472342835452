import { ProductType } from '@utils/types';
import { AlternativeProductActions, types } from './alternativeProducts.actions';

export type AlternativeProductTrueResult = { passedUW: true; price: number };
type AlternativeProductFalseResult = { passedUW: false; price: null };
export type AlternativeProductResult = AlternativeProductTrueResult | AlternativeProductFalseResult;

export type AlternativeProducts = Map<ProductType, AlternativeProductResult>;

// Reducer
export const initialState: AlternativeProducts = new Map();

export default function alternativeProducts(
  state: AlternativeProducts = initialState,
  action = {} as AlternativeProductActions,
): AlternativeProducts {
  switch (action.type) {
    case types.SET_ALTERNATIVE_PRODUCT_RESULT: {
      const newState = new Map(state);
      newState.set(action.productType, action.result);
      return newState;
    }
    default:
      return state;
  }
}
