import { SetStateFieldActionCreator } from '@utils/types';
import { Ancillary } from './ancillary';

export enum types {
  SET_ANCILLARY_FIELD = 'SET_ANCILLARY_FIELD',
  UPDATE_INITIAL_PRICE_WITH_BREAKDOWN_COVER = 'UPDATE_INITIAL_PRICE_WITH_BREAKDOWN_COVER',
}

type SetAncillaryField = ReturnType<typeof setAncillaryField>;

export interface UpdateInitialPriceWithBreakdownCover {
  type: typeof types.UPDATE_INITIAL_PRICE_WITH_BREAKDOWN_COVER;
  price: string | null;
}

export type AncillaryActions = SetAncillaryField | UpdateInitialPriceWithBreakdownCover;

export const setAncillaryField: SetStateFieldActionCreator<
  Ancillary,
  typeof types.SET_ANCILLARY_FIELD
> = (field, value) => ({
  type: types.SET_ANCILLARY_FIELD,
  field,
  value,
});

export const updateInitialPriceWithBreakdownCover = (
  price: string | null,
): UpdateInitialPriceWithBreakdownCover => ({
  type: types.UPDATE_INITIAL_PRICE_WITH_BREAKDOWN_COVER,
  price,
});
