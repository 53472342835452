/* eslint-disable no-shadow */
/*
 * Reselector for a global loading state.
 */
import { createSelector } from 'reselect';

const isConfigLoading = (state) =>
  state.config.alternativeInsurersLoading || state.config.occupationsLoading;
const isLookupLoading = (state) => state.lookup.loading;
const isQuotePaymentLoading = (state) => state.quote.payment.loading;
const isQuoteSaveLoading = (state) => state.quote.save.loading;

export const isLoading = createSelector(
  [isConfigLoading, isLookupLoading, isQuotePaymentLoading, isQuoteSaveLoading],
  (configLoading, lookupLoading, quotePaymentLoading, quoteSaveLoading) =>
    configLoading || lookupLoading || quotePaymentLoading || quoteSaveLoading,
);
