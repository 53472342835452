import quoteApi from '@services/api/quote';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ownerDetails, aboutYou } from '../reducer/pages/actions';
import { partialUpdateSuccess, partialUpdateFailure } from '../reducer/quote/actions';
import {
  types as paymentTypes,
  documentDeliveryPreferencesSet,
} from '../reducer/quote/payment.actions';

const getQuoteUuid = (state) => state.quote.save.uuid;
const getPaymentState = (state) => state.quote.payment;

const ownerJsonMapper = (ownerFormData) => ({
  owner_first_name: ownerFormData.firstName,
  owner_last_name: ownerFormData.surname,
  owner_date_of_birth: ownerFormData.birthdate.format('YYYY-MM-DD'),
  owner_postcode: ownerFormData.postcode,
  user_relationship: ownerFormData.relationship,
  owner_save: ownerFormData.save,
});

const aboutYouJSONMapper = (aboutYouFormData) => ({
  driver_first_name: aboutYouFormData.firstName,
  driver_middle_name: aboutYouFormData.middleNames,
  driver_last_name: aboutYouFormData.lastName,
  driver_title: aboutYouFormData.title,
  driver_occupation_type: aboutYouFormData.occupationType,
  driver_occupation: aboutYouFormData.occupation,
  driver_phone_number: aboutYouFormData.phoneNumber,
  driver_email: aboutYouFormData.email,
  driver_date_of_birth: aboutYouFormData.birthdate.format('YYYY-MM-DD'),
  driver_address_is_manual: aboutYouFormData.address.isManual,
  driver_address_county: aboutYouFormData.address.county,
  driver_address_country: aboutYouFormData.address.country,
  driver_address_flat_no: aboutYouFormData.address.flatNumber,
  driver_address_house_name: aboutYouFormData.address.houseName,
  driver_address_house_no: aboutYouFormData.address.houseNumber,
  driver_address_line1: aboutYouFormData.address.line1,
  driver_address_line2: aboutYouFormData.address.line2,
  driver_address_postcode: aboutYouFormData.address.postcode,
  driver_address_road: aboutYouFormData.address.road,
  driver_address_town: aboutYouFormData.address.town,
  driver_address_lookup_id: aboutYouFormData.address.lookupId,
});

const documentPreferenceJSONMapper = (data) => ({
  document_delivery_preference: data.paperlessDelivery ? 'digital' : data.deliveryPreference,
});

export function* partialUpdateSaga(jsonMapper, action) {
  const quoteUuid = yield select(getQuoteUuid);
  const requestData = jsonMapper(action.payload);
  try {
    yield call(quoteApi.partialUpdate, quoteUuid, requestData);
    yield put(partialUpdateSuccess());
  } catch {
    yield put(partialUpdateFailure());
  }
}

export function* updateDocumentDeliveryPreference() {
  const paymentState = yield select(getPaymentState);
  yield put(documentDeliveryPreferencesSet(paymentState));
}

export function* watchQuote() {
  yield takeEvery(ownerDetails.SUBMITTED, partialUpdateSaga, ownerJsonMapper);
  yield takeEvery(aboutYou.SUBMITTED, partialUpdateSaga, aboutYouJSONMapper);
  yield takeEvery(paymentTypes.SET_PAPERLESS_DELIVERY, updateDocumentDeliveryPreference);
  yield takeEvery(paymentTypes.SET_DELIVERY_PREFERENCE, updateDocumentDeliveryPreference);
  yield takeEvery(
    paymentTypes.DOCUMENT_DELIVERY_PREFERENCES_SET,
    partialUpdateSaga,
    documentPreferenceJSONMapper,
  );
}
