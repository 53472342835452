import React, { ReactNode } from 'react';
import {
  StyledBottomContent,
  StyledBox,
  StyledBoxContent,
  StyledButton,
  StyledButtonContainer,
  StyledCardFooterContainer,
  StyledContainer,
  StyledContent,
  StyledTitle,
  StyledLogo,
  StyledLogoWrapper,
} from './assets/styles';

export interface Props {
  title: string;
  titleCentered?: boolean;
  buttonAction?: () => void;
  buttonText?: string;
  bottomContent?: ReactNode;
  children: ReactNode;
  buttonDisabled?: boolean;
  cardFooterContent?: ReactNode;
}

const Page: React.FC<Props> = ({
  cardFooterContent,
  children,
  title,
  titleCentered = false,
  buttonAction,
  buttonText,
  bottomContent,
  buttonDisabled,
}) => {
  return (
    <StyledContainer>
      <StyledContent id='quote-container-styledContent'>
        <StyledLogoWrapper>
          <StyledLogo />
        </StyledLogoWrapper>
        <StyledBox id='quote-container-styledBox'>
          <StyledTitle id='quote-container-styledTitle' centered={titleCentered}>
            {title}
          </StyledTitle>
          <StyledBoxContent>{children}</StyledBoxContent>
          {buttonText && (
            <StyledButtonContainer>
              <StyledButton
                id='quote-button-continue'
                testId='custom-button'
                disabled={buttonDisabled}
                height={48}
                onClick={buttonAction}
              >
                {buttonText}
              </StyledButton>
            </StyledButtonContainer>
          )}
          {cardFooterContent && (
            <StyledCardFooterContainer id='quote-container-styledCardFooter'>
              {cardFooterContent}
            </StyledCardFooterContainer>
          )}
        </StyledBox>
        {bottomContent && (
          <StyledBottomContent id='quote-container-styledBottomContent'>
            {bottomContent}
          </StyledBottomContent>
        )}
      </StyledContent>
    </StyledContainer>
  );
};

export default Page;
