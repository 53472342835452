import React from 'react';
import { AnimatedLoader } from '@rentecarlo/component-library';
import styled, { css } from 'styled-components';
// import size from '@config/size';

type Props = {
  primaryText?: string;
  secondaryText?: string;
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-bottom: 64px;
`;

const LoadingTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 10%;
`;
const MainLoadingText = styled.p`
  font-weight: 700;
  font-size: 30px;
  font-family: 'proxima-soft';
  ${css`
    @media (max-width: 840px) {
      display: block;
      font-size: 21px;
      align: center;
    }
  `}
`;
const SecondaryLoadingText = styled.p`
  font-size: 20px;
  font-family: 'proxima-soft';
  font-weight: 400;
  ${css`
    @media (max-width: 768px) {
      display: block;
      font-size: 17px;
      align: center;
      font-weight: 400;
    }
  `}
`;

export const RepurchaseLoader: React.FC<Props> = ({ primaryText, secondaryText }) => {
  return (
    <Wrapper>
      <AnimatedLoader width='80%' height='100%' />
      {primaryText && (
        <LoadingTextContainer>
          <MainLoadingText id='quote-text-loaderPrimary'>{primaryText}</MainLoadingText>
          <SecondaryLoadingText id='quote-text-loaderSecondary'>
            {secondaryText}
          </SecondaryLoadingText>
        </LoadingTextContainer>
      )}
    </Wrapper>
  );
};

export default RepurchaseLoader;
