import React from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import ChevronLeft from './chevron-left';
import ChevronRight from './chevron-right';
import './calendar.less';
import {
  formatWeekday,
  formatNativeDate,
  createNativeDateFromDayjs,
  createDayjsFromNativeDate,
} from '../../../services/date';

dayjs.extend(utc);
dayjs.extend(timezone);

interface ComponentProps {
  date: Dayjs | null;
  onChange: (date: Dayjs) => void;
}

const SingleDateCalendar: React.FC<ComponentProps> = ({ date, onChange }) => {
  const currentDate = new Date();
  return (
    <Calendar
      minDetail='month'
      showNeighboringMonth={false}
      selectRange={false}
      prevLabel={<ChevronLeft />}
      nextLabel={<ChevronRight />}
      nextAriaLabel='Move forward to switch to the next month.'
      prevAriaLabel='Move backward to switch to the previous month.'
      prev2Label={null}
      next2Label={null}
      value={createNativeDateFromDayjs(date)}
      maxDate={currentDate}
      formatShortWeekday={(_locale, calendarDate) => formatWeekday(calendarDate)}
      formatLongDate={(_locale, calendarDate) => formatNativeDate(calendarDate)}
      onChange={(value, _event) => {
        if (value instanceof Date) {
          onChange(createDayjsFromNativeDate(value));
        }
      }}
    />
  );
};

export default SingleDateCalendar;
