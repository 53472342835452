import { getExcessForProduct } from '@reselectors/quote';
import {
  getFirst5CharactersOfDLN,
  getNext6CharactersOfDLN,
} from '@pages/quote/DrivingHistory.container';

const parseNonNullToString = (value) =>
  value === undefined || value === null ? null : value.toString();

const getDriverStatus = (isDriverOwner) => {
  return isDriverOwner ? 'owner' : 'driver';
};

const getMainInsurerValues = (insurerValue, insurersList) => {
  const lowerCaseInsurerValue = insurerValue.toLowerCase();
  const isInsurerInList = (insurer) => insurer.value.toLowerCase() === lowerCaseInsurerValue;
  if (insurerValue === '' || insurersList.find(isInsurerInList)) {
    return { mainInsurer: insurerValue };
  }
  return {
    mainInsurer: 'Other',
    alternateInsurer: insurerValue,
  };
};

export default {
  'quote-start': ({ account, lookup, quote: { driver, save } }) => {
    return {
      form: 'quoteStartForm',
      fields: {
        birthdate: account.customer.birthdate || driver.birthdate,
        postcode: account.address[0].postcode || driver.address.postcode,
        status: save.uuid ? getDriverStatus(driver.isDriverOwner) : undefined,
        vehicleRegistration: lookup.registrationNumber,
        email: driver.email,
      },
    };
  },
  'choose-your-cover': ({ quote: { product, tempcover } }) => {
    return {
      form: 'chooseYourCoverForm',
      fields: {
        paymentMethod: product.paymentMethod,
        tempcoverReason: tempcover.reasonForCover,
        tempcoverAltReason: tempcover.otherReasonForCover,
      },
    };
  },
  'driving-profile': ({ quote: { newdriver } }) => {
    return {
      form: 'drivingProfileForm',
      fields: {
        expectedMileage: newdriver.expectedMileage,
        overnightLocation: newdriver.overnightLocation,
        hasAlternateVehicle: newdriver.hasAlternateVehicle,
        typeOfUsage: newdriver.typeOfUsage,
      },
    };
  },
  car: ({ quote: { car, owner, tempcover }, lookup, config: { alternativeInsurers } }) => {
    const derivedValues = getMainInsurerValues(car.mainInsurer, alternativeInsurers);
    return {
      form: 'carForm',
      fields: {
        ...derivedValues,
        carRegistration: lookup.registrationNumber,
        relationship: owner.relationship,
        hasInsurance: car.hasInsurance,
        tempcoverConfirmedIndividualOwner: car.personalTempcoverVehicleConfirmed,
        tempcoverReason: tempcover.reasonForCover,
        tempcoverAltReason: tempcover.otherReasonForCover,
        isNamedDriver: tempcover.isNamedDriver,
      },
    };
  },
  'licence-change': ({ quote: { driver } }) => ({
    form: 'upgradeAccountForm',
    fields: {
      statedTestPassDate: driver.statedTestPassDate,
    },
  }),
  'driving-test': ({ quote: { drivingTest } }) => ({
    form: 'drivingTestForm',
    fields: {
      hasPassedTest: parseNonNullToString(drivingTest.hasPassed),
      drivingTestDate: drivingTest.drivingTestDate,
    },
  }),
  'about-you': ({ quote: { driver, user }, account: { customer } }) => ({
    form: 'aboutYouForm',
    fields: {
      title: driver.title,
      firstName: driver.firstName,
      lastName: driver.lastName,
      birthdate: driver.birthdate,
      status: user.status,
      postcode: driver.address.postcode,
      addressKey: driver.address.key,
      occupation: driver.occupation,
      occupationType: driver.occupationType,
      phoneNumber: driver.phoneNumber,
      email: driver.email,
      source: user.source,
      instructor: customer.instructorId,
    },
  }),
  'driver-details-summary': ({ quote: { driver } }) => ({
    form: 'driverDetailsSummaryForm',
    fields: {
      phoneNumber: driver.phoneNumber,
    },
  }),
  'quote-register': ({ quote: { driver } }) => ({
    form: 'quoteRegisterForm',
    fields: {
      email: driver.email,
    },
  }),
  'driving-history': ({
    quote: {
      claims: { hasClaims, numberOfClaims },
      noClaimsBonus: { haveNCB, level },
      licence: { licenceNumber },
      driver: { lastName, birthdate, title },
    },
    account: {
      login: { loggedIn },
    },
  }) => ({
    form: 'drivingHistoryForm',
    fields: {
      hasClaims: parseNonNullToString(hasClaims),
      licenceNumberSurnamePart: loggedIn
        ? licenceNumber.substring(0, 5)
        : getFirst5CharactersOfDLN(lastName),
      licenceNumberDOBPart: loggedIn
        ? licenceNumber.substring(5, 11)
        : getNext6CharactersOfDLN(birthdate, title),
      licenceNumberFinalPart: licenceNumber.substring(11, 16),
      numberOfClaims,
      ncb: {
        haveNCB: parseNonNullToString(haveNCB),
        level,
      },
    },
  }),
  'product-choice': ({ quote: { product } }) => ({
    form: 'productChoiceForm',
    fields: {
      telematics: product.productType === 'newdriver',
    },
  }),
  'important-information': (state) => ({
    form: 'importantInformationForm',
    fields: {
      excess: getExcessForProduct(state),
      confirmations: false,
      breakdownCover: parseNonNullToString(state.quote.ancillary.breakdownCover),
    },
  }),
  payment: (state) => ({
    form: 'paymentForm',
    fields: {
      paperlessDelivery: state.quote.payment.paperlessDelivery,
      deliveryPreference: state.quote.payment.deliveryPreference,
    },
  }),
  account: () => ({
    form: 'extensionForm',
    fields: {
      paperlessDelivery: true,
      deliveryPreference: 'postal',
    },
  }),
};
