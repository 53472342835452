/* eslint-disable camelcase */
export type SetStateFieldActionCreator<S, T extends string> = <U extends keyof S, V extends S[U]>(
  field: U,
  value: V,
) => {
  type: T;
  field: U;
  value: V;
};

export interface VeygoResponseError {
  message: string;
  code: string;
  [key: string]: string;
}

export interface FinalPriceExcess {
  acriss_code: null;
  duration: number;
  excess: number;
  price: string;
  price_after_discount: number;
  breakdown_price?: number;
  price_with_breakdown?: number;
  price_with_breakdown_after_discount?: number;
}

export enum PaymentMethod {
  oneOff = 'oneOff',
  subscription = 'subscription',
}

export type ProductType = 'newdriver' | 'tc' | 'csi' | 'ldp';
