/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import styled from 'styled-components';

import { Info } from '@rentecarlo/component-library';

import expectedMileageOptions from '@config/expectedMileageOptions';
import overnightLocationOptions from '@config/overnightLocationOptions';

import DropDownField from '@atoms/inputs/DropDownField';
import ReduxFieldValidation from '@services/formValidation';
import QuoteStep from '@templates/QuoteStep';

import AlternateVehicleField from './fields/AlternateVehicleField';
import { ComponentProps } from './DrivingProfile.container';

interface FieldContainerProps {
  marginBottom: number;
}

const FieldContainer = styled.div<FieldContainerProps>`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const DrivingProfile: React.FC<ComponentProps & InjectedFormProps> = ({
  formErrors,
  valid,
  handleSubmit,
  submission,
  form,
  submitFailed,
  isQuoteForMyself,
  isFastRepurchase,
}) => {
  return (
    <QuoteStep
      id='drivingProfile-component-quoteStep'
      stepTitle='Driving profile'
      showButton
      stepSubtitle='Answer the questions below so we can provide you with a more accurate price.'
      nextLabel='Next'
      nextAction={handleSubmit((values: Record<string, string>) => {
        submission(values, isFastRepurchase);
      })}
      formErrors={formErrors}
      form={form}
      submitError={submitFailed && !valid}
    >
      <FieldContainer marginBottom={32}>
        <Field
          name='expectedMileage'
          label='Expected monthly mileage?'
          id='drivingProfile-field-expectedMonthlyMileageDropdown'
          component={DropDownField}
          placeholder='Select expected monthly mileage'
          options={expectedMileageOptions}
          errorText='Expected monthly mileage is required.'
          validate={[ReduxFieldValidation.isRequired]}
        />
        <Info id='drivingProfile-text-estimateMileage'>
          {`This is an estimate of the mileage ${isQuoteForMyself ? 'you' : 'they'} expect ${
            isQuoteForMyself ? 'your' : 'their'
          } car to cover monthly.`}
        </Info>
      </FieldContainer>
      <FieldContainer marginBottom={12}>
        <Field
          name='overnightLocation'
          label='Where will the car be parked overnight?'
          id='drivingProfile-field-overnightLocationDropdown'
          component={DropDownField}
          placeholder='Select where the car will be parked'
          options={overnightLocationOptions}
          errorText='Overnight location is required.'
          validate={[ReduxFieldValidation.isRequired]}
        />
      </FieldContainer>
      <FieldContainer marginBottom={12}>
        <Field
          name='hasAlternateVehicle'
          label={`Do ${isQuoteForMyself ? 'you' : 'they'} have access to another vehicle?`}
          id='drivingProfile-field-hasAlternateVehicle'
          component={AlternateVehicleField}
          errorText='Access to another vehicle is required.'
          validate={[ReduxFieldValidation.isRequired]}
          isQuoteForMyself={isQuoteForMyself}
        />
      </FieldContainer>
    </QuoteStep>
  );
};

export default DrivingProfile;
