import { remoteToLocalAddress } from '@services/transformers/customers';
import { AddressActionTypes, LOOKUP_ADDRESS_FAILURE } from './address.actions';
import {
  CUSTOMER_FETCH_ME_SUCCESS,
  SET_DRIVER_FROM_QUOTE,
  UPDATE_DRIVER_FROM_QUOTE,
  CustomerActions,
} from './customer.actions';
import { types as loginTypes, LoginActiontypes } from './login.actions';
import deepUpdate from '../../../services/deepUpdate';
import { Address } from './addressTypes';

// Reducer
export const initialState: Address[] = [
  {
    isManual: false,
    country: 'GBR',
    county: '',
    flatNumber: '',
    houseName: '',
    houseNumber: '',
    line1: '',
    line2: '',
    postcode: '',
    road: '',
    town: '',
    udprn: '',
    lookupId: '',
    uuid: '',
    lookedUp: false,
    key: '',
  },
];

export default function customerReducer(
  state: Address[] = initialState,
  action: AddressActionTypes | LoginActiontypes | CustomerActions,
): Address[] {
  switch (action.type) {
    case loginTypes.LOGOUT_SUCCESS:
      return initialState;
    case CUSTOMER_FETCH_ME_SUCCESS:
      if (action.data.addresses.length === 0) return initialState;
      return action.data.addresses.map((address) => ({
        ...remoteToLocalAddress(address),
        isManual: false,
        lookedUp: true,
        key: '-1',
      }));
    case LOOKUP_ADDRESS_FAILURE:
      return action.code === 'POSTCODE_NOT_FOUND'
        ? [deepUpdate(state[0], 'isManual', true)]
        : state;
    // /!\ This assumes the user is a _driver_!
    case SET_DRIVER_FROM_QUOTE:
    case UPDATE_DRIVER_FROM_QUOTE:
      return [
        {
          uuid: state[0] ? state[0].uuid : '',
          isManual: action.quote.driver.address.isManual,
          country: action.quote.driver.address.country,
          county: action.quote.driver.address.county,
          flatNumber: action.quote.driver.address.flatNumber,
          houseName: action.quote.driver.address.houseName,
          houseNumber: action.quote.driver.address.houseNumber,
          line1: action.quote.driver.address.line1,
          line2: action.quote.driver.address.line2,
          postcode: action.quote.driver.address.postcode,
          road: action.quote.driver.address.road,
          town: action.quote.driver.address.town,
          lookupId: action.quote.driver.address.lookupId,
          udprn: action.quote.driver.address.udprn,
        },
      ];
    default:
      return state;
  }
}
