import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavBar as NavBar2024 } from '@rentecarlo/component-library';
import ClearDataModal from '@organisms/modals/ClearDataModal';
import { getProgress } from '@molecules/progressBars/ProgressBar/utils';
import { NavBarContainer } from './styles';
import { Props, DispatchProps } from './NavBar.container';

type OwnProps = {
  pathname: string;
};

type ComponentProps = Props & DispatchProps & OwnProps;

const NavBar: React.FC<ComponentProps> = ({ productType, loggedIn, logout, pathname }) => {
  const [dataModalVisible, setDataModalVisible] = useState(false);

  const showPath = [
    'choose-your-cover',
    'indicative-price',
    'duration-of-cover',
    'car',
    'driver-details-summary',
    'about-you',
    'driving-history',
    'important-information',
    'almost-there',
    'final-quote',
    'quote-register',
    'payment',
  ];
  const showModal = showPath.some((el) => pathname.includes(el));
  const history = useHistory();

  return (
    <NavBarContainer>
      <NavBar2024
        loggedIn={loggedIn}
        logout={(): void => {
          logout();
        }}
        onLogoClick={(): void => {
          if (showModal) {
            setDataModalVisible(true);
          } else {
            history.push('/start');
          }
        }}
        progress={getProgress(pathname, productType)}
        accountURL={`${process.env.REACT_APP_ACCOUNT_URL}/profile`}
        purchasesURL={`${process.env.REACT_APP_ACCOUNT_URL}/purchases`}
        loginURL={`${process.env.REACT_APP_LOGIN_URL}/?redirect=${window.location.href}`}
      >
        <ClearDataModal
          path='/start'
          visible={dataModalVisible}
          onClose={(): void => setDataModalVisible(false)}
        />
      </NavBar2024>
    </NavBarContainer>
  );
};
export default NavBar;
