import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { resetDuration } from '@reducer/quote/duration.actions';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { isForbiddenDuration } from '@services/date';
import { RootState } from '@redux/reducer';

import IndicativePrice from './IndicativePrice';

const resetAndGoToDuration = (dispatch: Dispatch) => {
  dispatch(resetDuration());
  dispatch(push('duration-of-cover'));
};

const nextAction = (
  dispatch: Dispatch,
  isFastRepurchase: boolean,
  shouldShowDriverProfile: boolean,
) => {
  if (shouldShowDriverProfile) {
    return dispatch(saveAndGoTo('driving-profile', false));
  }

  if (isFastRepurchase) {
    return dispatch(saveAndGoTo('confirm-details', true));
  }

  return dispatch(saveAndGoTo('car', false));
};

const mapStateToProps = (state: RootState) => {
  const {
    quote: {
      duration: { endDateTime, endChoiceSelected, startDateTime },
      indicativePrice: { loading },
      product: { productType, paymentMethod },
      driver: { birthdate },
      save: { isFastRepurchase },
    },
    account: {
      customer: { isQuoteForMyself },
    },
  } = state;

  const disableQuickDateSelectionButton = isForbiddenDuration(startDateTime, endDateTime);

  return {
    productType,
    paymentMethod,
    loading,
    disableQuickDateSelectionButton,
    endChoiceSelectedValue: endChoiceSelected && endChoiceSelected.value,
    birthdate,
    isQuoteForMyself,
    isFastRepurchase,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetAndGoToDuration: () => resetAndGoToDuration(dispatch),
  nextAction: (isFastRepurchase: boolean, shouldShowDriverProfile: boolean) => {
    nextAction(dispatch, isFastRepurchase, shouldShowDriverProfile);
  },
});

const IndicativePriceContainer = connect(mapStateToProps, mapDispatchToProps)(IndicativePrice);

export default IndicativePriceContainer;
