import React, { ReactElement, useState } from 'react';
import { FieldContainer } from '@rentecarlo/component-library';
import fieldStyleProps from '@services/fieldStyleProps';
import {
  StyledSelector,
  StyledSelectorImg,
  StyledWrapper,
  StyledLeftWrapper,
  StyledRightWrapper,
  StyledUl,
  StyledLi,
  StyledOptionImg,
} from './assets/styles';
import blueArrow from './assets/blue-arrow.svg';
import greenTick from './assets/green-tick.svg';

interface Props {
  id: string;
  options: {
    name: string;
    value: string;
  }[];
  placeholder: string;
  input: {
    onChange: (name: string) => void;
    value: string;
  };
  meta: {
    error?: string;
    active?: boolean;
    submitFailed?: boolean;
    touched?: boolean;
    pristine?: boolean;
    valid?: boolean;
  };
  disabled: boolean;
}

const SelectNew = ({
  id,
  options,
  placeholder,
  input: { onChange, value },
  meta: { error, active, submitFailed, touched, pristine, valid },
  disabled,
}: Props): ReactElement => {
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = (): void => {
    setOpen(!isOpen);
  };
  const onChangeAndToggleDropdown = (name: string): void => {
    onChange(name);
    toggleDropdown();
  };
  const { borderBottomColor } = fieldStyleProps(
    disabled,
    active,
    valid,
    ((touched && !pristine) || submitFailed) && error && !active,
  );
  return (
    <FieldContainer
      id={id}
      showErrorContainer={submitFailed && error}
      showError={((!pristine && touched) || submitFailed) && error && (!active || submitFailed)}
      error={error}
    >
      <StyledSelector
        id={id}
        onClick={(): void => toggleDropdown()}
        placeholder={placeholder}
        value={value}
        isOpen={isOpen}
        borderBottomColor={borderBottomColor}
      >
        <StyledLeftWrapper id='car-text-selectNew'>{value || placeholder}</StyledLeftWrapper>
        <StyledRightWrapper>
          <StyledSelectorImg
            id='car-image-selectNew'
            src={blueArrow}
            isOpen={isOpen}
            alt='blue-arrow'
          />
        </StyledRightWrapper>
      </StyledSelector>
      <StyledWrapper>
        <StyledUl id='car-text-selectNewList' isOpen={isOpen}>
          {options.map((option) => (
            <StyledLi
              id={`car-text-selectNewOption${option.name}`}
              key={option.value}
              onClick={(): void => onChangeAndToggleDropdown(option.name)}
              isSelected={value === option.name}
            >
              <StyledLeftWrapper id='car-container-selectNew'>{option.name}</StyledLeftWrapper>
              <StyledRightWrapper>
                <StyledOptionImg
                  src={greenTick}
                  isVisible={value === option.name}
                  alt='green-tick'
                />
              </StyledRightWrapper>
            </StyledLi>
          ))}
        </StyledUl>
      </StyledWrapper>
    </FieldContainer>
  );
};

SelectNew.defaultProps = {
  options: [],
};

export default SelectNew;
