import React, { useEffect } from 'react';
import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import BillingAddress from '@organisms/inputs/BillingAddress.container';
import StripeLoadingContainer from '@pages/quote/Payment/StripeLoadingContainer';
import StripePaymentForm from '@organisms/forms/CardPaymentForm/StripePaymentForm';

import { ConversionProject } from '@config/constants';

type CardPaymentFormProps = {
  billingAddress: Record<string, never>;
  billingAddressPopulated: boolean;
  cardHolderName: string;
  cardHolderEmail: string;
  paymentError?: string;
  errorCode?: string;
  paymentLoading?: boolean;
  productEmail: string;
  amount: number;
  isSubscription: boolean;
  setCardHolderName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPaymentLoading: (loading: boolean) => void;
  stripeClientSecret: string | null;
  stripeClientStatus: string | null;
  sendGAEventForPaymentFlow: (name: string, value: string) => void;
  resetPaymentError: () => void;
  stateConfirmationToken?: string;
};

const CardPaymentForm: React.FC<CardPaymentFormProps> = ({
  amount,
  billingAddressPopulated,
  isSubscription,
  sendGAEventForPaymentFlow,
  ...otherprops
}) => {
  useEffect(() => {
    sendGAEventForPaymentFlow(ConversionProject.PAYMENT_START, 'Stripe');
  });

  // isSubscription gets set to true if passed in (?) so spreading here instead
  const props = isSubscription ? { ...otherprops, isSubscription } : otherprops;

  return (
    <>
      <Group id='billingAddress'>
        <SectionTitle id='payment-title-cardPaymentBillingAddress'>
          What is your billing address?
        </SectionTitle>
        <BillingAddress />
      </Group>

      {billingAddressPopulated && (
        <StripeLoadingContainer amount={Math.trunc(amount * 100)} isSubscription={isSubscription}>
          {/* Spread the props as they were just a 1-2-1 hand-down */}
          <StripePaymentForm {...props} billingAddressPopulated />
        </StripeLoadingContainer>
      )}
    </>
  );
};

export default CardPaymentForm;
