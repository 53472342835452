import styled from 'styled-components';

import colors from '@config/colors';

export const List = styled.ul`
  list-style-type: disc;
  padding-left: 32px;
  font-size: 16px;
  color: ${colors.outerSpace};
`;

export const Spacer = styled.div`
  height: 32px;
`;
