import React from 'react';
import Sizes from '@config/size';
import MultipleChoiceField from '@molecules/inputs/MultipleChoiceField';
import { FieldContainer } from '@rentecarlo/component-library';
import Select from '@molecules/selects/Select';

const Selector = ({
  id,
  withChange,
  placeholder,
  options,
  input,
  meta,
  selectorType,
  disabled,
  showValidateTick,
  type,
}) => {
  const { value, onChange } = input;
  const { error, touched, active, pristine, submitFailed } = meta;
  return window.innerWidth < parseInt(Sizes.desktopWidth) && options.length > 3 ? (
    <FieldContainer
      id={id}
      showErrorContainer={submitFailed && error}
      showError={((touched && !pristine) || submitFailed) && error && (!active || submitFailed)}
      error={error}
    >
      <Select
        disabled={disabled}
        id={id}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        selectorType={selectorType}
        showValidateTick={showValidateTick}
        type={type}
        value={value}
        meta={meta}
      />
    </FieldContainer>
  ) : (
    <MultipleChoiceField
      id={id}
      input={input}
      withChange={withChange}
      selectorType={selectorType}
      options={options}
      disabled={disabled}
      meta={meta}
    />
  );
};

Selector.defaultProps = {
  type: 'default',
  disabled: false,
  value: '',
  placeholder: '',
  showValidateTick: null,
};

export default Selector;
