export const FETCH_CONNECTIONS_REQUEST = 'FETCH_CONNECTIONS.REQUEST';
export const FETCH_CONNECTIONS_SUCCESS = 'FETCH_CONNECTIONS.SUCCESS';
export const FETCH_CONNECTIONS_FAILURE = 'FETCH_CONNECTIONS.FAILURE';
export const SELECT_CONNECTION = 'SELECT_CONNECTION';

export interface PreferredCar {
  car: {
    uuid?: string;
    registrationNumber?: string;
  };
  mainInsurer?: boolean;
}

export interface FetchConnections {
  type: typeof FETCH_CONNECTIONS_REQUEST;
}

export interface FetchConnectionsSuccess {
  type: typeof FETCH_CONNECTIONS_SUCCESS;
  data: PreferredCar[];
}

export interface FetchConnectionsFailure {
  type: typeof FETCH_CONNECTIONS_FAILURE;
  error: Error;
}

export interface SelectConnection {
  type: typeof SELECT_CONNECTION;
  uuid: string;
}

export const selectConnection = (uuid: string): SelectConnection => ({
  type: SELECT_CONNECTION,
  uuid,
});

export const fetchConnections = (): FetchConnections => ({
  type: FETCH_CONNECTIONS_REQUEST,
});

export const fetchConnectionsSuccess = (data: PreferredCar[]): FetchConnectionsSuccess => ({
  type: FETCH_CONNECTIONS_SUCCESS,
  data,
});

export const fetchConnectionsFailure = (error: Error): FetchConnectionsFailure => ({
  type: FETCH_CONNECTIONS_FAILURE,
  error,
});

export type ConnectionActionTypes =
  | FetchConnections
  | FetchConnectionsSuccess
  | FetchConnectionsFailure
  | SelectConnection;
