export const cardholderTypeMap = {
  owner: 'ow',
  driver: 'dr',
  parent: 'pa',
  friend: 'fr',
  spouse: 'sp',
  other: 'ot',
};

export const licenceTypeMap = {
  uk_manual: 'ukm',
  uk_auto: 'uka',
  uk_prov: 'ukp',
  non_uk: 'nuk',
};

export const remoteToLocalLicenceTypeMap = {
  ukm: 'uk_manual',
  uka: 'uk_auto',
  ukp: 'uk_prov',
  nuk: 'non_uk',
};

export const statusMap = {
  driver: 'd',
  owner: 'o',
};
