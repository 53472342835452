import styled from 'styled-components';

import colors from '@config/colors';
import media from '@config/media';
import fonts from '@config/fonts';

export const Title = styled.h1`
  font-family: ${fonts.title};
  font-weight: bold;
  line-height: 36px;
  font-size: 32px;
  color: ${colors.dark.title};
  ${media.tablet`
    line-height: 32px;
    font-size: 25px;
    letter-spacing: 0.9px;
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const FormContainer = styled.div`
  width: 100%;
`;

export const FormPanelWrapper = styled.div`
  ${media.tablet`
    width: 320px;
    height: 48px;
  `}
  letter-spacing: 0;
  line-height: 18px;
  height: 36px;
  width: 368px;
`;

export const FormPanelText = styled.span`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 14px;
`;
