/* eslint-disable camelcase */
import React, { FC } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import styled from 'styled-components';
import colors from '@config/colors';

import { InformationBox } from '@rentecarlo/component-library';

dayjs.extend(advancedFormat);

const Container = styled.div`
  margin-top: 16px;
`;

export const InfoLink = styled.a`
  color: ${colors.blue.royal};
  font-family: proxima-soft;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  display: inline;
  cursor: pointer;
`;

type Props = {
  currentRestrictedPeriod: [
    {
      name?: string;
      start_date?: string;
      end_date?: string;
      min_duration?: string;
      article_link?: string;
    },
  ];
};

const RestrictedPeriodErrorBox: FC<Props> = ({ currentRestrictedPeriod }) => {
  const restrictedPeriod = currentRestrictedPeriod[0];
  const periodStartDate = dayjs(restrictedPeriod.start_date).format('Do of MMM');
  const periodEndDate = dayjs(restrictedPeriod.end_date).format('Do of MMM');
  return (
    <Container>
      <InformationBox id='durationOfCover-text-restrictedPeriodError' type='error'>
        {`Sorry! You can’t select policies that are under ${restrictedPeriod.min_duration} hours during ${periodStartDate} - ${periodEndDate}. `}
        {restrictedPeriod.article_link && (
          <>
            You can read more information on this
            <InfoLink
              id='restrictedPeriodsArticleLink'
              href={restrictedPeriod.article_link}
              target='_blank'
              rel='noopener noreferrer'
            >
              {' '}
              here
            </InfoLink>
            .
          </>
        )}
      </InformationBox>
    </Container>
  );
};

export default RestrictedPeriodErrorBox;
