import React, { ReactElement } from 'react';
import * as styles from './assets/styles';

interface Props {
  children: React.ReactElement;
  value: string;
}

const SubQuestion = ({ children, value }: Props): ReactElement => (
  <styles.StyledContainer>
    <styles.StyledNotch value={value} />
    {children}
  </styles.StyledContainer>
);

export default SubQuestion;
