export default [
    {
      name: '101 - 200',
      value: '200',
    },
    {
      name: '201 - 400',
      value: '400',
    },
    {
      name: '401 - 600',
      value: '600',
    },
    {
      name: '601 - 800',
      value: '800',
    },
    {
      name: '801 - 1000',
      value: '1000',
    },
    {
      name: '1001 - 1500',
      value: '1500',
    },
    {
      name: '1501 - 2000',
      value: '2000',
    },
    {
      name: '2001 - 2500',
      value: '2500',
    },
    {
      name: '2501 - 3000',
      value: '3000',
    },
    {
      name: '3001 - 4000',
      value: '4000',
    },
    {
      name: '4001 - 5000',
      value: '5000',
    },
    {
      name: '5000+',
      value: '5001',
    },
  ];
