/*
 * Reselectors for links.
 */
import { createSelector } from 'reselect';

const product = (state) => state.quote.product;

export const getFaqUrl = createSelector([product], ({ productType }) => {
  switch (productType) {
    case 'csi':
      return 'https://www.veygo.com/temporary-car-insurance/#faq';
    case 'ldp':
      return 'https://www.veygo.com/learner-driver-insurance/#faq';
    default:
      return 'https://www.veygo.com/temporary-car-insurance/#faq';
  }
});

export const getHelpCentreUrl = createSelector([], () => 'https://www.veygo.com/help');
