import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { types, resetQuote } from '../reducer/quote/actions';
import { resetLookupCar } from '../reducer/lookup/actions';
import { sendEvent } from '../reducer/analytics/actions';

// Saga that resets the quote and lookup reducers when a user clicks the
// "start again" button and then redirects them to the quote-start page
export function* userResetQuoteSaga() {
  yield put(resetQuote());
  yield put(resetLookupCar());
  yield put(push('/start'));
  yield put(
    sendEvent('csiClick', {
      type: 'userResetQuote',
    }),
  );
}

export function* watchResetQuote() {
  yield takeEvery(types.USER_RESET_QUOTE, userResetQuoteSaga);
}
