interface FetchRequestArgs extends RequestInit {
  url: string;
}

const BLOCKED_FETCH_ERROR_MESSAGES = [
  'Failed to fetch', // Chrome
  'Failed to Fetch', // Edge
  'NetworkError when attempting to fetch resource.', // Firefox
  'Load failed', // Safari
];

export const isPossibleBlockedFetchError = (error: Error): boolean => {
  return error.name === 'TypeError' && BLOCKED_FETCH_ERROR_MESSAGES.includes(error.message);
};

export class SuspectedBlockedFetchError extends Error {
  fetchArgs: FetchRequestArgs;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  constructor(fetchArgs: FetchRequestArgs, ...params: Array<any>) {
    super(...params);
    Object.setPrototypeOf(this, SuspectedBlockedFetchError.prototype);

    this.name = 'SuspectedBlockedFetchError';
    this.fetchArgs = fetchArgs;
  }
}
