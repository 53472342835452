import React from 'react';
import styled from 'styled-components';
import colors from '@config/colors';
import dayjs, { Dayjs } from 'dayjs';
import { ComponentType, useComponentId } from '@hooks';

const DateFieldContainer = styled.div`
  display: flex;
  height: 65px;
  margin: 0 -4px 16px;
`;

const DateField = styled.button`
  align-items: center;
  display: flex;
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  flex-grow: 1;
  justify-content: center;
  height: 65px;
  width: 0px;
  margin: 0 4px 0;
  position: relative;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : null)};
  background-color: ${(props) => (props.disabled ? colors.grey.background.disabled : 'inherit')};
`;

const Text = styled.div`
  color: ${colors.outerSpace};
  align-items: center;
  bottom: 0;
  display: flex;
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  text-transform: uppercase;
`;

const DatePickerContainer = styled.div`
  opacity: 0;
  width: 0;
`;

function clickDatePicker(event: React.SyntheticEvent<HTMLButtonElement>, disabled?: boolean) {
  if (disabled) {
    return;
  }
  event.preventDefault();
  const datePicker = document.getElementById('licenceChange-field-datePicker');
  if (datePicker) {
    datePicker.focus();
    datePicker.click();
  }
}

interface Props {
  valid: boolean;
  date: Dayjs;
  prefilled: boolean;
  touched: boolean;
  error: string;
  disabled?: boolean;
  setDate: (date: Dayjs | null) => void;
  onBlur: (date: Dayjs | null) => void;
}

const DateInput: React.FC<Props> = ({ date, disabled, onBlur, setDate }) => {
  const creator = useComponentId();
  const onChange = (value: string): void => {
    const dob = dayjs(value, 'YYYY-MM-DD');
    setDate(dob);
    onBlur(dob);
  };
  return (
    <DateFieldContainer>
      <DatePickerContainer>
        <input
          id={creator(ComponentType.FIELD, 'datePicker')}
          name='datePicker'
          type='date'
          onChange={(event) => onChange(event.target.value)}
        />
      </DatePickerContainer>
      <DateField
        id={creator(ComponentType.FIELD, 'day')}
        onClick={(event) => clickDatePicker(event, disabled)}
        disabled={disabled}
      >
        <Text id={creator(ComponentType.TEXT, 'dayFieldInput')}>{date ? date.date() : 'DD'}</Text>
      </DateField>
      <DateField
        id={creator(ComponentType.FIELD, 'month')}
        onClick={(event) => clickDatePicker(event, disabled)}
        disabled={disabled}
      >
        <Text id={creator(ComponentType.TEXT, 'monthFieldInput')}>
          {date ? date.format('MMM') : 'MM'}
        </Text>
      </DateField>
      <DateField
        id={creator(ComponentType.FIELD, 'year')}
        onClick={(event) => clickDatePicker(event, disabled)}
        disabled={disabled}
      >
        <Text id={creator(ComponentType.TEXT, 'yearFieldInput')}>
          {date ? date.format('YYYY') : 'YYYY'}
        </Text>
      </DateField>
    </DateFieldContainer>
  );
};

export default DateInput;
