import { SetStateFieldActionCreator } from '@utils/types';
import { State } from './legal';

export enum types {
  SET_LEGAL_FIELD = 'SET_LEGAL_FIELD',
  TOGGLE_PRIVACY_POLICY = 'TOGGLE_PRIVACY_POLICY',
}

// Actions
export const setLegalField: SetStateFieldActionCreator<State, typeof types.SET_LEGAL_FIELD> = (
  field,
  value,
) => ({
  type: types.SET_LEGAL_FIELD,
  field,
  value,
});

type SetLegalFieldType = ReturnType<typeof setLegalField>;

export interface TogglePrivacyPolicy {
  type: typeof types.TOGGLE_PRIVACY_POLICY;
}

export const togglePrivacyPolicy = (): TogglePrivacyPolicy => ({
  type: types.TOGGLE_PRIVACY_POLICY,
});

export type LegalTypes = SetLegalFieldType | TogglePrivacyPolicy;
