import React from 'react';
import { connect } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import styled from 'styled-components';
import colors from '@config/colors';

import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import VariableExcessOptions from '@molecules/blocks/VariableExcessOptions';
import { updatePrice } from '@reducer/quote/save.actions';
import { logVariableExcessEvent } from '@services/ecommerceTracking';

import { FinalPriceExcess } from '@utils/types';

interface Props {
  showUnavailableExcess: boolean;
  finalPricesByExcess: FinalPriceExcess[];
  productType: string;
}

const VarExcessDescriptionContainer = styled.div`
  font-size: 13px;
  font-family: 'proxima-soft';
  color: ${colors.grey.text.light};
  margin-bottom: 10px;
`;

const unavailableExcessDescriptionText =
  "Unfortunately we can't offer you a variable excess on this quote. The criteria is always changing, but it could be due to your age or the vehicle you're driving.";

const VariableExcessForm: React.FC<Props & WrappedFieldProps> = ({
  input: { value, onChange },
  showUnavailableExcess,
  finalPricesByExcess,
  productType,
}) => (
  <>
    <SectionTitle id='importantInformation-title-variableExcess'>Select your excess:</SectionTitle>
    {showUnavailableExcess && (
      <VarExcessDescriptionContainer id='importantInformation-text-variableExcess'>
        {unavailableExcessDescriptionText}
      </VarExcessDescriptionContainer>
    )}
    <VariableExcessOptions
      finalPricesByExcess={finalPricesByExcess}
      excessSelected={value}
      showUnavailableExcess={showUnavailableExcess}
      setVariableExcess={(excessValue: number) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newPrice = finalPricesByExcess.find((priceObj) => priceObj.excess === excessValue)
          .price;

        updatePrice(newPrice);
        logVariableExcessEvent(excessValue, productType);
        return onChange(excessValue);
      }}
    />
  </>
);

const mapDispatchToProps = {
  updatePrice,
};

export default connect(null, mapDispatchToProps)(VariableExcessForm);
