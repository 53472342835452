import { CustomerResponseV3Additional } from '@services/transformers/customers';
import { VeygoResponseError } from '@utils/types';
import { Dayjs } from 'dayjs';

import { QuoteState } from '@redux/reducer/quote';

import { types as loginTypes } from './login.actions';
import { QuoteLicence } from './customerTypes';
import { RegisterFailure } from './register.actions';

export const SET_CUSTOMER_FIELD = 'SET_CUSTOMER_FIELD';
export const CUSTOMER_FETCH_ME_REQUEST = 'FETCH_ME.REQUEST';
export const CUSTOMER_FETCH_ME_SUCCESS = 'FETCH_ME.SUCCESS';
export const CUSTOMER_FETCH_ME_FAILURE = 'FETCH_ME.FAILURE';

export const CUSTOMER_SAVE_REQUEST = 'CUSTOMER_SAVE.REQUEST';
export const CUSTOMER_SAVE_SUCCESS = 'CUSTOMER_SAVE.SUCCESS';
export const CUSTOMER_SAVE_FAILURE = 'CUSTOMER_SAVE.FAILURE';

export const CUSTOMER_SAVE_FINISH_REQUEST = 'SAVE_CUSTOMER_FINISH.REQUEST';
export const CUSTOMER_SAVE_FINISH_SUCCESS = 'SAVE_CUSTOMER_FINISH.SUCCESS';
export const CUSTOMER_SAVE_FINISH_FAILURE = 'SAVE_CUSTOMER_FINISH.FAILURE';

export const CUSTOMER_UPGRADE_REQUEST = 'UPGRADE_CUSTOMER.REQUEST';
export const CUSTOMER_UPGRADE_SUCCESS = 'UPGRADE_CUSTOMER.SUCCESS';

export const SET_DRIVER_FROM_QUOTE = 'SET_DRIVER_FROM_QUOTE';
export const UPDATE_DRIVER_FROM_QUOTE = 'UPDATE_DRIVER_FROM_QUOTE';
export const SET_QUOTE_FOR_MYSELF = 'SET_QUOTE_FOR_MYSELF';
export const SET_SHOULD_SHOW_SIDEBAR = 'SET_SHOULD_SHOW_SIDEBAR';

export interface SetCustomerField {
  type: typeof SET_CUSTOMER_FIELD;
  field: string;
  value: boolean | Dayjs;
}

export interface CustomerLoginSuccess {
  type: typeof loginTypes.LOGIN_SUCCESS;
}

export interface CustomerLogout {
  type: typeof loginTypes.LOGOUT_SUCCESS;
}

export interface FetchMe {
  type: typeof CUSTOMER_FETCH_ME_REQUEST;
}

export interface FetchMeSuccess {
  type: typeof CUSTOMER_FETCH_ME_SUCCESS;
  data: CustomerResponseV3Additional;
}

export interface FetchMeFailure {
  type: typeof CUSTOMER_FETCH_ME_FAILURE;
  error: VeygoResponseError;
}

export interface SaveCustomerRequest {
  type: typeof CUSTOMER_SAVE_REQUEST;
}
export interface SaveCustomerSuccess {
  type: typeof CUSTOMER_SAVE_SUCCESS;
  data: CustomerResponseV3Additional;
}

export interface SaveCustomerFailure {
  type: typeof CUSTOMER_SAVE_FAILURE;
  error: VeygoResponseError;
}

export interface SaveCustomerAndGoTo {
  type: typeof CUSTOMER_SAVE_REQUEST;
  page: string;
}

export interface SetDriverFromQuote {
  type: typeof SET_DRIVER_FROM_QUOTE;
  uuid: string;
  quote: QuoteState;
}

export interface UpdateDriverFromQuote {
  type: typeof UPDATE_DRIVER_FROM_QUOTE;
  uuid: string;
  quote: QuoteState;
}

export interface UpgradeCustomerRequest {
  type: typeof CUSTOMER_UPGRADE_REQUEST;
}

export interface UpgradeCustomerSuccess {
  type: typeof CUSTOMER_UPGRADE_SUCCESS;
  data: {
    licence: QuoteLicence;
  };
}

export interface SetShoulShowSideBar {
  type: typeof SET_SHOULD_SHOW_SIDEBAR;
  displaySideBar: boolean;
}

export type HandleSaveFailuresActionTypes = SaveCustomerFailure | RegisterFailure;

export interface SetQuoteForMyself {
  type: typeof SET_QUOTE_FOR_MYSELF;
  isQuoteForMyself: boolean;
}

export type CustomerActions =
  | SetCustomerField
  | FetchMe
  | FetchMeSuccess
  | FetchMeFailure
  | SaveCustomerRequest
  | SaveCustomerSuccess
  | SaveCustomerFailure
  | SaveCustomerAndGoTo
  | SetDriverFromQuote
  | UpdateDriverFromQuote
  | UpgradeCustomerRequest
  | UpgradeCustomerSuccess
  | CustomerLoginSuccess
  | CustomerLogout
  | SetQuoteForMyself
  | SetShoulShowSideBar;

// Actions
export const setCustomerField = (field: string, value: boolean | Dayjs): SetCustomerField => ({
  type: SET_CUSTOMER_FIELD,
  field,
  value,
});

export const fetchMe = (): FetchMe => ({
  type: CUSTOMER_FETCH_ME_REQUEST,
});

export const fetchMeSuccess = (data: CustomerResponseV3Additional): FetchMeSuccess => ({
  type: CUSTOMER_FETCH_ME_SUCCESS,
  data,
});

export const fetchMeFailure = (error: VeygoResponseError): FetchMeFailure => ({
  type: CUSTOMER_FETCH_ME_FAILURE,
  error,
});

export const saveCustomer = (): SaveCustomerRequest => ({
  type: CUSTOMER_SAVE_REQUEST,
});

export const saveCustomerSuccess = (data: CustomerResponseV3Additional): SaveCustomerSuccess => ({
  type: CUSTOMER_SAVE_SUCCESS,
  data,
});

export const saveCustomerFailure = (error: VeygoResponseError): SaveCustomerFailure => ({
  type: CUSTOMER_SAVE_FAILURE,
  error,
});

export const saveCustomerAndGoTo = (page: string): SaveCustomerAndGoTo => ({
  type: CUSTOMER_SAVE_REQUEST,
  page,
});

export const setDriverFromQuote = (uuid: string, quote: QuoteState): SetDriverFromQuote => ({
  type: SET_DRIVER_FROM_QUOTE,
  uuid,
  quote,
});

export const updateDriverFromQuote = (uuid: string, quote: QuoteState): UpdateDriverFromQuote => ({
  type: UPDATE_DRIVER_FROM_QUOTE,
  uuid,
  quote,
});

export const upgradeCustomerRequest = (): UpgradeCustomerRequest => ({
  type: CUSTOMER_UPGRADE_REQUEST,
});

export const upgradeCustomerSuccess = (data: {
  licence: QuoteLicence;
}): UpgradeCustomerSuccess => ({
  type: CUSTOMER_UPGRADE_SUCCESS,
  data,
});

export const setQuoteForMyself = (isQuoteForMyself: boolean): SetQuoteForMyself => ({
  type: SET_QUOTE_FOR_MYSELF,
  isQuoteForMyself,
});

export const setShouldShowSideBar = (displaySideBar: boolean): SetShoulShowSideBar => ({
  type: SET_SHOULD_SHOW_SIDEBAR,
  displaySideBar,
});
