import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { resetQuote } from '../reducer/quote/actions';
import { types as saveTypes, SaveQuoteFailure } from '../reducer/quote/save.actions';

/**
 * rejectionSaga
 *
 * @generator
 * @yields {push} rejection - redirects users window.location
 * @returns {SagaIterator}
 * */
export function* rejectionSaga({ error }: SaveQuoteFailure): SagaIterator {
  if (error) {
    const rejectionReasons = ['CARNOTFOUND'];
    if (rejectionReasons.find((item) => item === error.code)) {
      yield put(push('/rejection'));
    }
  }
}

/**
 * expiredSaga
 *
 * @generator
 * @yields {resetQuote} - dispatches resetQuote action
 * @yields {push} start - redirects users window.location
 * @returns {SagaIterator}
 * */
export function* expiredSaga({ error }: SaveQuoteFailure): SagaIterator {
  if (error && error.code === 'QUOTEEXPIRED') {
    yield put(resetQuote());
    yield put(push('/start?expired=True'));
  }
}

/**
 * watchApp
 *
 * @generator
 * @yields {pollAlerts}
 * @yields {rejectionSaga}
 * @yields {expiredSaga}
 * @returns {SagaIterator}
 * */

export function* watchApp(): SagaIterator {
  yield takeEvery(saveTypes.SAVE_QUOTE_FAILURE, rejectionSaga);
  yield takeEvery(saveTypes.SAVE_QUOTE_FAILURE, expiredSaga);
}
