export default [
  {
    name: '£0 - £999',
    value: '500',
  },
  {
    name: '£1,000 - £1,499',
    value: '1250',
  },
  {
    name: '£1,500 - £1,999',
    value: '1750',
  },
  {
    name: '£2,000 - £2,499',
    value: '2250',
  },
  {
    name: '£2,500 - £2,999',
    value: '2750',
  },
  {
    name: '£3,000 - £3,999',
    value: '3500',
  },
  {
    name: '£4,000 - £4,999',
    value: '4500',
  },
  {
    name: '£5,000 - £7,499',
    value: '6250',
  },
  {
    name: '£7,500 - £9,999',
    value: '8750',
  },
  {
    name: '£10,000 - £14,999',
    value: '12500',
  },
  {
    name: '£15,000 - £19,999',
    value: '17500',
  },
  {
    name: '£20,000 - £29,999',
    value: '25000',
  },
  {
    name: '£30,000 - £39,999',
    value: '35000',
  },
  {
    name: '£40,000 - £49,999',
    value: '45000',
  },
  {
    name: '£50,000 - £59,999',
    value: '55000',
  },
  {
    name: '£60,000 - £79,999',
    value: '70000',
  },
  {
    name: '£80,000 - £99,999',
    value: '90000',
  },
  {
    name: '£100,000 +',
    value: '150000',
  }
];
