import React from 'react';
import styled, { css } from 'styled-components';

import size from '@config/size';
import tick from '@assets/tick.svg';

const TickInputContainer = styled.div`
  position: relative;
`;

const TheTick = styled.img`
  height: 36px;
  width: 31px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  right: ${(props) => props.mobileRightMargin};
  ${css`
    @media (min-width: ${(props) => props.desktopMinWidth}) {
      right: ${(props) => props.desktopRightMargin};
    }
  `}
`;

const GreenTick = ({
  id,
  children,
  showTick,
  tickImgAlt,
  desktopRightMargin,
  desktopMinWidth,
  mobileRightMargin,
}) => (
  <TickInputContainer id={id}>
    {children}
    {showTick && (
      <TheTick
        src={tick}
        alt={tickImgAlt}
        desktopMinWidth={desktopMinWidth}
        mobileRightMargin={mobileRightMargin}
        desktopRightMargin={desktopRightMargin}
      />
    )}
  </TickInputContainer>
);

GreenTick.defaultProps = {
  tickImgAlt: 'Input okay',
  desktopMinWidth: size.desktopWidth,
  mobileRightMargin: '16px',
  desktopRightMargin: `-${31 + 32}px`,
  keepTickAbsolute: true,
};

export default GreenTick;
