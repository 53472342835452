import { CognitoClient } from '@rentecarlo/node-amplify-client';
import { isPossibleBlockedFetchError, SuspectedBlockedFetchError } from '@services/clients/errors';
import { isUserNotLoggedInError } from '@utils/errors';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
interface AdditionalHeaders {
  'Content-Type'?: string;
  accept?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const getCookie = (cookieName: string): string => {
  if (document.cookie) {
    const cookieSuffix = `${cookieName}=`;
    // Split the cookies into an array
    const splitDocumentCookies = document.cookie.split('; ');
    // Filter the array of cookies to find our cookie
    const findCSRFCookie =
      splitDocumentCookies.length > 0
        ? splitDocumentCookies.filter((cookie) => cookie.includes(cookieSuffix))
        : [];
    // If we have a match, set the cookie to the value
    // If filter returns an empty array, then just set the csrftoken to an empty string
    return findCSRFCookie.length > 0 ? findCSRFCookie[0].replace(cookieSuffix, '') : '';
  }

  return '';
};

export const retrieveCognitoBearerToken = async (): Promise<string> => {
  try {
    const { idToken } = await CognitoClient.getCurrentSession();
    return `Bearer ${idToken}`;
  } catch (err) {
    if (isUserNotLoggedInError(err)) return '';

    /* eslint-disable no-console */
    console.error(err);
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleResponse = async (response: Response): Promise<any> => {
  if (response.ok) {
    try {
      const responseBody = await response.json();
      return Promise.resolve(responseBody);
    } catch (error) {
      // The purpose of this catch is to prevent the frontend from completely
      // crashing when a non-json response is returned to the Client.
      return Promise.resolve({});
    }
  }

  try {
    // Nice opportunity to tie in the error payloads
    const errorResponse = await response.json();
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(errorResponse);
  } catch (error) {
    return Promise.reject(response);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleBlobResponse = async (response: Response): Promise<any> => {
  if (response.ok) {
    try {
      const responseBody = await response.blob();
      return Promise.resolve(responseBody);
    } catch (error) {
      // The purpose of this catch is to prevent the frontend from completely
      // crashing when a non-json response is returned to the Client.
      return Promise.resolve({});
    }
  }

  try {
    // Nice opportunity to tie in the error payloads
    const errorResponse = await response.json();
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(errorResponse);
  } catch (error) {
    return Promise.reject(response);
  }
};

class Builder {
  public requestHeaders: { [key: string]: string };

  public requestMethod: string;

  public response: Response | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private requestData: any;

  private requestSecured: boolean;

  private requestUrl: string;

  constructor() {
    const csrftoken = getCookie('csrftoken');
    this.requestMethod = '';
    // default secured to true
    this.requestSecured = true;
    this.requestUrl = '';
    this.requestHeaders = {
      'Content-Type': 'application/json',
      accept: 'application/json',
      CSRF: csrftoken.trim(),
    };
    this.requestData = null;
    this.response = null;
  }

  public get(url: string, baseUrl: string = process.env.REACT_APP_CSI_BASE_URL): Builder {
    this.requestMethod = 'GET';
    this.requestUrl = baseUrl + url;
    return this;
  }

  public put(url: string, baseUrl: string = process.env.REACT_APP_CSI_BASE_URL): Builder {
    this.requestMethod = 'PUT';
    this.requestUrl = baseUrl + url;
    return this;
  }

  public post(url: string, baseUrl: string = process.env.REACT_APP_CSI_BASE_URL): Builder {
    this.requestMethod = 'POST';
    this.requestUrl = baseUrl + url;
    return this;
  }

  public delete(url: string, baseUrl: string = process.env.REACT_APP_CSI_BASE_URL): Builder {
    this.requestMethod = 'DELETE';
    this.requestUrl = baseUrl + url;
    return this;
  }

  public data(data: unknown): Builder {
    this.requestData = data;
    return this;
  }

  public head(url: string, baseUrl: string = process.env.REACT_APP_CSI_BASE_URL): Builder {
    this.requestMethod = 'HEAD';
    this.requestUrl = baseUrl + url;
    return this;
  }

  public unsecured(): Builder {
    this.requestSecured = false;
    return this;
  }

  public additionalHeaders(headers: AdditionalHeaders): Builder {
    this.requestHeaders = {
      ...this.requestHeaders,
      ...headers,
    };
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public execute = async (): Promise<any> => {
    if (this.requestSecured) {
      this.requestHeaders = {
        ...this.requestHeaders,
        Authorization: await retrieveCognitoBearerToken(),
      };
    }

    const fetchArgs = {
      body: this.requestData ? JSON.stringify(this.requestData) : undefined,
      credentials: 'include',
      headers: this.requestHeaders,
      method: this.requestMethod,
    };

    try {
      this.response = await fetch(`${this.requestUrl}`, fetchArgs);
    } catch (err) {
      let fetchError = err;

      // Does the error resemble what happens if the request is blocked?
      if (isPossibleBlockedFetchError(err)) {
        fetchError = new SuspectedBlockedFetchError(
          {
            url: `${this.requestUrl}`,
            ...fetchArgs,
          },
          'fetch error suspected of being blocked',
          { cause: err },
        );
      }

      return Promise.reject(fetchError);
    }

    return handleResponse(this.response);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public executeBlobResp = async (): Promise<any> => {
    if (this.requestSecured) {
      this.requestHeaders = {
        ...this.requestHeaders,
        Authorization: await retrieveCognitoBearerToken(),
      };
    }

    const response: Response = await fetch(`${this.requestUrl}`, {
      body: this.requestData,
      credentials: 'include',
      headers: this.requestHeaders,
      method: this.requestMethod,
    });

    return handleBlobResponse(response);
  };
}

export default {
  Builder,
};
