import { connect } from 'react-redux';
import React from 'react';
import styled from 'styled-components';
import { Button, FormErrorBanner } from '@rentecarlo/component-library';

import colors from '@config/colors';
import StepTitle from '@atoms/titles/pageTitles/StepTitle';
import { capitaliseFirstLetter } from '@services/formatString';
import { ComponentType, useComponentId } from '@hooks';

const QuoteStepContainer = styled.div`
  position: relative;
  background-color: ${colors.grey.background.lighter};
  ${(props) => (props.height ? `height:${props.height}px;` : '')}
  ${(props) =>
    props.paddingBottom ? `padding-bottom:${props.paddingBottom}px;` : 'padding-bottom: 64px'}
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 0px 0px 0px;
`;

const ButtonContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const QuoteStep = (props) => {
  const creator = useComponentId();
  const {
    children,
    height,
    form,
    nextAction,
    nextDisabled,
    nextLabel,
    paddingBottom,
    stepSubtitle,
    stepTitle,
    paddingTop,
    triggerGAEvent,
    showButton,
    submitError,
    formErrors,
    titleMarginBottom,
  } = props;
  const [, product, step] = window.location.pathname.split('/');
  const primaryButtonLabel = !submitError ? capitaliseFirstLetter(nextLabel) : 'Errors on page';
  const handleClick = (event) => {
    event.preventDefault();
    if (triggerGAEvent) {
      triggerGAEvent();
    }
    return nextAction();
  };
  return (
    <QuoteStepContainer
      height={height}
      paddingBottom={paddingBottom}
      id={creator(ComponentType.CONTAINER, 'main')}
    >
      <StepTitle
        id='quote-component-title'
        title={stepTitle}
        subtitle={stepSubtitle}
        paddingTop={paddingTop}
        marginBottom={titleMarginBottom}
      />
      {children}
      {nextAction && (
        <FooterRow id='quote-component-footerRow'>
          {showButton && (
            <ButtonContainer>
              <Button
                id='quote-button-next'
                onClick={handleClick}
                disabled={nextDisabled}
                borderColor={submitError && colors.blazeOrange}
                backgroundColor={submitError && colors.blazeOrange}
                backgroundColorHover={submitError && colors.blazeOrange}
                height={64}
                hoverShadow={submitError && 'none'}
              >
                {primaryButtonLabel}
              </Button>
            </ButtonContainer>
          )}
        </FooterRow>
      )}
      {form && (
        <FormErrorBanner
          id='quote-component-formErrorBanner'
          show={submitError}
          formErrors={formErrors}
        />
      )}
    </QuoteStepContainer>
  );
};

QuoteStep.defaultProps = {
  height: '',
  form: false,
  includeStepPrefix: true,
  nextDisabled: false,
  nextLabel: 'NEXT',
  stepSubtitle: '',
  showButton: true,
  triggerGAEvent: false,
};

export default QuoteStep;
