import { connect } from 'react-redux';
import MainChoicePricingElement from '@molecules/blocks/MainChoicePricingElement';
import { RootState } from '@redux/reducer';
import { Dayjs } from 'dayjs';

const mapStateToProps = (state: RootState) => {
  const {
    quote: { driver, duration, save, car, product, ancillary },
  } = state;

  return {
    duration: duration as { startDateTime: Dayjs; immediateStart: boolean },
    validDiscountCode: save.validDiscountCode,
    carDetails: `${car.make} ${car.model}`,
    policyHolderName: `${driver.firstName} ${driver.lastName}`,
    isSubscription: product.isSubscription,
    breakdownCover: ancillary.breakdownCover,
  };
};

const ConnectedPricingElement = connect(mapStateToProps)(MainChoicePricingElement);

export default ConnectedPricingElement;
