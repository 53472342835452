/* eslint-disable @typescript-eslint/no-explicit-any */
import { OptimizelyOverrideAttributes } from '@redux/reselectors/optimizely';
import { decideOptimizelyProduct } from '@utils/optimizelyProduct';
import { optimizelyClient } from './clients/optimizely';

export default function sendOptimizelyEvent(
  eventKey: string,
  attributes: OptimizelyOverrideAttributes,
  eventTags?: { [key: string]: any },
): void {
  const optimizelyProduct = decideOptimizelyProduct();

  const payload = {
    ...attributes,
    optimizely_product: optimizelyProduct,
  };

  optimizelyClient.track(eventKey, attributes.user_identity, payload, eventTags);

  window.optimizely = window.optimizely || [];
  window.optimizely.push({
    type: 'user',
    attributes: { ...attributes, optimizely_product: optimizelyProduct },
  });

  window.optimizely.push({
    type: 'event',
    eventName: eventKey,
    ...(eventTags && { tags: eventTags }),
  });
}
