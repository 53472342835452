import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setProduct } from '@redux/reducer/quote/product.actions';
import { setUserField } from '@redux/reducer/quote/user.actions';
import { RootState } from '@redux/reducer';
import ReasonForCover, { DispatchProps, ValueProps } from './ReasonForCoverField';

const mapStateToProps = (state: RootState): ValueProps => ({
  isNewCar: state.quote.car.isNewCar,
  tempcoverModalOpen: state.quote.user.tempcoverModalOpen,
  productType: state.quote.product.productType,
  isQuoteForMyself: state.account.customer.isQuoteForMyself,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  changeToCsi: (): void => {
    dispatch(setUserField('tempcoverModalOpen', false));
    dispatch(setProduct('csi'));
  },
  openTempcoverReasonModal: (e: React.SyntheticEvent): void => {
    e.preventDefault();
    dispatch(setUserField('tempcoverModalOpen', true));
  },
  closeTempcoverReasonModal: (): void => dispatch(setUserField('tempcoverModalOpen', false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReasonForCover);
