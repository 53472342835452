import React from 'react';
import styled from 'styled-components';

import RadioButton from '@atoms/inputs/RadioButton';

const ToggleButtonContainer = styled.div`
  display: flex;
  margin: 0 -4px 16px;
`;

const ToggleButtons = ({
  value,
  onSelect,
  leftLabel,
  rightLabel,
  groupName,
  displayLeftError,
  displayRightError,
  page,
}) => (
  <ToggleButtonContainer>
    <RadioButton
      id={`${page}-button-${groupName}Yes`}
      checked={value === true}
      onSelect={onSelect}
      text={leftLabel}
      value='true'
      displayError={displayLeftError}
    />
    <RadioButton
      id={`${page}-button-${groupName}No`}
      checked={value === false}
      onSelect={onSelect}
      text={rightLabel}
      value='false'
      displayError={displayRightError}
    />
  </ToggleButtonContainer>
);

ToggleButtons.defaultProps = {
  leftLabel: 'YES',
  rightLabel: 'NO',
  displayLeftError: false,
  displayRightError: false,
};

export default ToggleButtons;
