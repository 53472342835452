import React, { ReactElement } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withStripeHook = (Component) => {
  return (props: Record<string, unknown>): ReactElement => {
    const stripe = useStripe();
    const elements = useElements();

    return <Component stripe={stripe} elements={elements} {...props} />;
  };
};
