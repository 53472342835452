import styled from 'styled-components';

interface ContainerProps {
  onClick?: () => void;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: ${({ onClick }: ContainerProps): string => (onClick ? 'pointer' : 'auto')};
`;

export const Title = styled.div`
  font-family: proxima-soft;
  font-size: 14px;
  font-weight: 700;
  color: #364344;
`;

export const Content = styled.div`
  font-family: proxima-soft;
  font-size: 16px;
  color: #364344;
  line-height: 20px;
  font-weight: 400;
`;

export const Separator = styled.div`
  margin-top: 26px;
  margin-bottom: 24px;
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
`;
