export default [
    {
      name: 'Garage',
      value: 'garage',
    },
    {
      name: 'Driveway',
      value: 'driveway',
    },
    {
      name: 'Street',
      value: 'street',
    },
    {
      name: 'Residential parking area',
      value: 'residential',
    },
    {
      name: 'Car park',
      value: 'carpark',
    },
    {
      name: 'Railway car park',
      value: 'rail_carpark',
    },
    {
      name: 'Work car park',
      value: 'work_carpark',
    },
  ];
