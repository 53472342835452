import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { FieldContainer } from '@rentecarlo/component-library';
import TextInput from '@atoms/inputs/TextInput';

interface Props {
  alwaysCapitalised?: boolean;
  alwaysLowerCase?: boolean;
  disabled?: boolean;
  id: string;
  optional?: boolean;
  pasteDisabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  placeholderStyle?: string;
  selectorType: string;
  textStyle?: string;
  type?: string;
  showInitialError?: boolean;
}
const TextInputField: React.FC<Props & WrappedFieldProps> = ({
  input: { onChange, onBlur, onFocus, value },
  meta: { valid, error, touched, active, pristine, submitFailed },
  id,
  placeholder = '',
  maxLength = 100,
  disabled = false,
  type = 'text',
  pasteDisabled,
  placeholderStyle = '',
  textStyle = '',
  selectorType,
  alwaysCapitalised = false,
  alwaysLowerCase = false,
  optional = false,
  showInitialError = false,
}) => (
  <FieldContainer
    id={id}
    showErrorContainer={submitFailed && error}
    showError={
      ((touched && !pristine) || submitFailed || showInitialError) &&
      error &&
      (!active || submitFailed)
    }
    error={error}
  >
    <TextInput
      id={id}
      value={value}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      isValid={optional ? false : valid}
      showError={((touched && !pristine) || submitFailed || showInitialError) && error && !active}
      alwaysCapitalised={alwaysCapitalised}
      alwaysLowerCase={alwaysLowerCase}
      disabled={disabled}
      type={type}
      pasteDisabled={pasteDisabled}
      placeholderStyle={placeholderStyle}
      textStyle={textStyle}
      selectorType={selectorType}
      active={active}
    />
  </FieldContainer>
);

export default TextInputField;
