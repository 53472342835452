import React from 'react';
import styled from 'styled-components';

import Modal from '@atoms/layout/modal/Modal';
import ModalTitle from '@atoms/titles/pageTitles/ModalTitle';

import size from '@config/size';
import colors from '@config/colors';
import ArrowIcon from '@assets/back.svg';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.grey.background.lighter};
  overflow: auto;
  padding: ${(props) => (props.fullscreen ? '0' : '24px')};
`;

const BackButtonContainer = styled.div`
  align-items: center;
  color: ${colors.blue.steel};
  cursor: pointer;
  font-family: 'proxima-soft';
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 24px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  align-items: center;
`;

const ArrowIconContainer = styled.img`
  margin-right: 8px;
`;

const PageModal = (props) => {
  const {
    display,
    fullscreen,
    fullscreenOnDesktop,
    backButtonAction,
    showBackButton,
    isUpperCase,
    title,
    children,
  } = props;
  const desktopMinWidth = size.desktopWidth;
  return (
    <Modal
      display={display}
      desktopMinWidth={desktopMinWidth}
      fullscreenOnDesktop={fullscreenOnDesktop}
    >
      <Container desktopMinWidth={desktopMinWidth} fullscreen={fullscreen}>
        {showBackButton && (
          <BackButtonContainer
            id='quote-button-modalBack'
            desktopMinWidth={desktopMinWidth}
            onClick={backButtonAction}
          >
            <ArrowIconContainer src={ArrowIcon} />
            Back
          </BackButtonContainer>
        )}
        <ModalTitle id='quote-title-modal' isUpperCase={isUpperCase}>
          {title}
        </ModalTitle>
        {children}
      </Container>
    </Modal>
  );
};

PageModal.defaultProps = {
  display: '',
  fullscreenOnDesktop: false,
  backButtonAction: () => {},
  showBackButton: true,
  fullscreen: false,
};

export default PageModal;
