import React, { FC } from 'react';
import { Container, Title, Content, Separator } from './assets/QuoteSummaryDetail';

type Props = {
  title: string;
  showSeparator?: boolean;
  id?: string;
  onClick?: () => void;
};

const QuoteSummaryDetail: FC<Props> = ({ title, onClick, children, showSeparator = false, id }) => (
  <Container id={id} onClick={onClick}>
    <Title id='quote-title-quoteSummaryDetail'>{title}</Title>
    <Content>{children}</Content>
    {showSeparator && <Separator />}
  </Container>
);

export default QuoteSummaryDetail;
