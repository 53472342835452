import React from 'react';
import styled, { css } from 'styled-components';
import colors from '@config/colors';
import TickIcon from '@assets/tick-radio-button.svg';
import ErrorIcon from '@assets/error-triangle.svg';
import LockIcon from '@assets/lock.svg';

import { capitaliseWords } from '@services/formatString';

const getColor = (props, normalColor, selectedColor, selectedErrorColor, disabledColor) => {
  if (props.selectedStyle) {
    return props.displayError ? selectedErrorColor : selectedColor;
  }
  return props.disabled ? disabledColor : normalColor;
};

const getBorder = (props) =>
  getColor(
    props,
    colors.grey.lines.light,
    colors.grey.lines.light,
    colors.orange,
    colors.grey.lines.light,
  );

const RadioContainer = styled.div`
  margin: 0 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    getColor(
      props,
      colors.white,
      colors.green.selected,
      colors.orangeLight,
      colors.grey.background.disabled,
    )};
  border: 1px solid ${(props) => getBorder(props)};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  color: ${(props) => getColor(props, colors.outerSpace, colors.white, colors.orange)};
  flex-grow: 1;
  height: 64px;
  position: relative;

  ${(props) => css`
    &:hover {
      background-color: ${!props.selectedStyle && !props.disabled && colors.grey.background.hover};
    }
  `}

  ${(props) =>
    props.squashed
      ? ` 
    height: 40px;
    border-radius: 0;
    margin: 0 0px;
    border-left-width: 0px;
    ${css`
      :first-child {
        border-left-width: 1px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      :last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    `}`
      : null};
`;

const StyledRadio = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
`;

const LockContainer = styled.img`
  margin-right: 8px;
`;

const IconContainer = styled.img`
  height: ${(props) => props.tickSize};
  position: absolute;
  right: ${(props) => props.tickPosition};
  top: ${(props) => props.tickPosition};
  width: ${(props) => props.tickSize};
`;

const StyledLabel = styled.label`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const RadioButton = ({
  checked,
  displayError,
  disabled,
  locked,
  squashed,
  id,
  selectorType,
  text,
  tickSize,
  tickPosition,
  value,
  onSelect,
}) => (
  <RadioContainer
    selectedStyle={checked}
    displayError={displayError}
    disabled={disabled || locked}
    squashed={squashed}
    id={id}
  >
    <StyledRadio
      id={`${id}Radio`}
      data-test={selectorType}
      type='radio'
      checked={checked}
      value={value}
      onChange={(data) => (disabled || locked ? null : onSelect(data))}
    />
    {locked && <LockContainer src={LockIcon} />}
    <StyledLabel id={`${id}Label`} htmlFor={id} disabled={disabled || locked}>
      {capitaliseWords(text)}
    </StyledLabel>
    {checked && !squashed && (
      <IconContainer
        tickPosition={tickPosition}
        tickSize={tickSize}
        src={displayError ? ErrorIcon : TickIcon}
      />
    )}
  </RadioContainer>
);

RadioButton.defaultProps = {
  checked: null,
  disabled: false,
  displayError: false,
  tickPosition: '10px',
  squashed: false,
  tickSize: '15px',
  locked: false,
};

export default RadioButton;
