import * as React from 'react';
import styled from 'styled-components';
import Page from '@templates/Page';
import ConfusedVeyPal from '@assets/confused-veypal.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  flex: 1;
`;

const Text = styled.div`
  margin-top: 24px;
  font-family: proxima-soft;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #364344;
`;

const ProcessingError: React.FC = () => {
  return (
    <Page title="We've hit a bump in the road..." titleCentered>
      <Container>
        <Image src={ConfusedVeyPal} />
      </Container>
      <Text id='processingError-text-paymentTaken'>
        We’re really sorry, we’ve taken payment, but we’ve been unable to process your policy.
        <br />
        <br />
        <strong id='processingError-text-paymentTakenStrong'>You are not insured</strong> by us at
        the moment, so you can't drive without getting alternative insurance first.
        <br />
        <br />
        We've alerted our Customer Support team who will issue the refund within one working day.
        This will take 3 – 10 days to go back to your account. If you would like a credit note
        instead, so you don't have to wait for the refund, please use the contact form that can be
        found{' '}
        <a href='https://www.veygo.com/contact/' target='_blank' rel='noopener noreferrer'>
          <strong id='processingError-link-contact'>here</strong>
        </a>
        .
      </Text>
    </Page>
  );
};

export default ProcessingError;
