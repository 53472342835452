import React from 'react';
import { reduxForm } from 'redux-form';

import QuoteStep from '@templates/QuoteStep';
import { ComponentType, useComponentId, useOptimizelyFlag } from '@hooks';
import driverdetailsIcon from '@assets/manual.svg';
import carIcon from '@assets/car-front.svg';
import receiptIcon from '@assets/receipt.svg';
import certificateIcon from '@assets/certificate.svg';
import { InformationBox } from '@rentecarlo/component-library';
import ModalComponent from '@molecules/modals/ModalComponent';

import FastRepurchaseSummary from './FastRepurchaseSummary';
import { ComponentProps } from './ConfirmFastRepurchaseDetails.container';

const ConfirmFastRepurchaseDetails: React.FC<ComponentProps> = (props) => {
  const removeCanEditRestrictions = useOptimizelyFlag('TEMP_REMOVE_CAN_EDIT_RESTRICTIONS').enabled;

  const creator = useComponentId();
  const {
    driverDetailsData,
    vehicleInformationData,
    payableExcessData,
    drivingHistoryData,
    underwriting,
    driverDetailsEdit,
    vehicleInformationEdit,
    payableExcessEdit,
    drivingHistoryEdit,
    submission,
    onAccept,
    onCancel,
  } = props;

  let { canEditDriver, display30DayEditModal } = props;

  if (removeCanEditRestrictions) {
    canEditDriver = true;
    display30DayEditModal = false;
  }

  return (
    <>
      <ModalComponent
        show={display30DayEditModal}
        okLabel='Continue'
        onAccept={() => onAccept()}
        onCancel={() => onCancel()}
        id={creator(ComponentType.COMPONENT, '30DayPopUp')}
      >
        Please note that you can only change your profile information once every 30 days, so it is
        important that you have submitted the correct information on these pages
      </ModalComponent>
      <QuoteStep
        id={creator(ComponentType.COMPONENT, 'quoteStep')}
        includeStepPrefix={false}
        nextAction={submission}
        nextLabel='Confirm'
        stepTitle='Confirm details'
        stepSubtitle='Please check these details are correct'
        form
        formErrors={{}}
      >
        <FastRepurchaseSummary
          title='Driver details'
          pillButtonAction={driverDetailsEdit}
          hidePillButton={!canEditDriver}
          icon={driverdetailsIcon}
          data={driverDetailsData}
        />
        <FastRepurchaseSummary
          title='Vehicle information'
          pillButtonAction={vehicleInformationEdit}
          hidePillButton={false}
          icon={carIcon}
          data={vehicleInformationData}
        />
        <FastRepurchaseSummary
          title='Driving history'
          pillButtonAction={drivingHistoryEdit}
          hidePillButton={false}
          icon={certificateIcon}
          data={drivingHistoryData}
        />
        <FastRepurchaseSummary
          title='Payable excess'
          pillButtonAction={payableExcessEdit}
          hidePillButton={!underwriting}
          icon={receiptIcon}
          data={payableExcessData}
        >
          <InformationBox
            id={creator(ComponentType.FIELD, 'mobileImportantInformation')}
            type='important'
          >
            The excess is the amount you pay in the event of any claim on this policy, regardless of
            who is responsible for the incident.
          </InformationBox>
        </FastRepurchaseSummary>
      </QuoteStep>
    </>
  );
};

export default reduxForm({
  form: 'confirmFastRepurchaseDetailsForm',
})(ConfirmFastRepurchaseDetails);
