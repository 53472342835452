import React from 'react';

import PillButton from '@atoms/buttons/PillButton';
import { ComponentType, useComponentId } from '@hooks';

import {
  SlideContainer,
  InnerContainer,
  TopContainer,
  ButtonContainer,
  ContentContainer,
  Title,
  Backdrop,
} from './styles';

import { Props } from './types';

const ModalSlide: React.FC<Props> = ({
  children,
  actionLabel = 'Back',
  onClick,
  visible,
  title,
}) => {
  const creator = useComponentId();
  return (
    <>
      <SlideContainer visible={visible}>
        <InnerContainer>
          <TopContainer>
            <ButtonContainer>
              <PillButton id='modal-slide-close-button' onClick={onClick}>
                {actionLabel}
              </PillButton>
            </ButtonContainer>
          </TopContainer>
          <ContentContainer>
            <Title id={creator(ComponentType.TITLE, 'modalSlide')}>{title}</Title>
            {children}
          </ContentContainer>
        </InnerContainer>
      </SlideContainer>
      <Backdrop onClick={onClick} visible={visible} />
    </>
  );
};

export default ModalSlide;
