import React from 'react';

import './Page.less';
import colors from '@config/colors';

interface ComponentProps {
  borderTop: boolean;
  children: any;
}

const Page: React.FC<ComponentProps> = ({ borderTop = true, children }) => {
  const divStyle = {
    backgroundColor: `${colors.grey.background.lighter}`,
  };

  let classes = 'content-main';
  if (borderTop) classes = `${classes} border-top`;
  return (
    <div className={classes} style={divStyle}>
      {children}
    </div>
  );
};

export default Page;
