import styled from 'styled-components';

import VeygoLogo from '@assets/logo.svg';

export const StyledContainer = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: #fbfbfc;
`;

export const StyledContent = styled.div`
  margin: 0 auto;
  padding-top: 56px;
  min-width: 320px;
  max-width: 560px;
  display: flex;
  flex-direction: column;
`;

export const StyledBox = styled.div`
  margin: 32px 40px 40px 40px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  max-width: 560px;
  @media only screen and (max-width: 415px) {
    margin: 32px 8px 40px 8px;
    padding: 16px;
  }
`;

export interface StyledTitleProps {
  centered: boolean;
}

export const StyledTitle = styled.h1`
  color: #364344;
  font-family: Graphie;
  font-size: 32px;
  font-weight: bold;
  text-align: ${({ centered }: StyledTitleProps): string => (centered ? 'center' : 'left')};
  @media only screen and (max-width: 415px) {
    text-align: center;
  }
`;

export const StyledBoxContent = styled.div`
  margin: 32px 0px 40px 0px;
`;

export const StyledButtonContainer = styled.div`
  justify-self: flex-end;
`;

export const StyledBottomContent = styled.div`
  margin: 0px 40px;
  @media only screen and (max-width: 415px) {
    margin: 0px 16px;
  }
`;

export const StyledLogoWrapper = styled.a.attrs(() => ({
  href: process.env.REACT_APP_CMS_URL,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLogo = styled.img.attrs(() => ({
  src: VeygoLogo,
  alt: 'Veygo logo',
  rel: 'noopener noreferrer',
}))`
  cursor: pointer;
`;

export const StyledCardFooterContainer = styled.div`
  margin-top: 24px;
`;
