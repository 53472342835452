export default [
  {
    text: 'MR',
    value: 'mr',
    id: 'MR',
    selectorType : 'MR',
    name: 'Mr',
  },
  {
    text: 'MRS',
    value: 'mrs',
    id: 'MRS',
    selectorType : 'MRS',
    name: 'Mrs',
  },
  {
    text: 'MISS',
    value: 'miss',
    id: 'MISS',
    selectorType : 'MISS',
    name: 'Miss',
  },
  {
    text: 'MS',
    value: 'ms',
    id: 'MS',
    selectorType : 'MS',
    name: 'Ms',
  },
  {
    text: 'MX',
    value: 'mx',
    id: 'MX',
    selectorType : 'MX',
    name: 'Mx',
  }
];
