import styled from 'styled-components';

export default styled.h3`
  color: #364344;
  font-family: 'gotham-rounded-book';
  font-size: 25px;
  font-weight: normal;
  line-height: 30px;
  margin-bottom: 48px;
  text-transform: uppercase;
  text-align: center;
`;
