import { SetStateFieldActionCreator } from '@utils/types';
import { formatAddressesFromLookup } from '../../../services/addressLookup';
import { AddressData, Lookup, CarData } from './lookupTypes';

// Types
export enum types {
  SET_LOOKUP_FIELD = 'SET_LOOKUP_FIELD',
  RESET_QUOTE = 'RESET_QUOTE',
  LOOKUP_ADDRESS_FETCH = 'LOOKUP_ADDRESS.FETCH',
  LOOKUP_ADDRESS_SUCCESS = 'LOOKUP_ADDRESS.SUCCESS',
  LOOKUP_ADDRESS_FAILURE = 'LOOKUP_ADDRESS.FAILURE',
  LOOKUP_BILLING_ADDRESS_FETCH = 'LOOKUP_BILLING_ADDRESS.FETCH',
  LOOKUP_BILLING_ADDRESS_SUCCESS = 'LOOKUP_BILLING_ADDRESS.SUCCESS',
  LOOKUP_BILLING_ADDRESS_FAILURE = 'LOOKUP_BILLING_ADDRESS.FAILURE',
  LOOKUP_CAR_FETCH = 'LOOKUP_CAR.FETCH',
  LOOKUP_CAR_SUCCESS = 'LOOKUP_CAR.SUCCESS',
  LOOKUP_CAR_FAILURE = 'LOOKUP_CAR.FAILURE',
  LOOKUP_CAR_RESET = 'LOOKUP_CAR.RESET',
  LOOKUP_CAR_ERROR_RESET = 'LOOKUP_CAR_ERROR.RESET',
}

export interface LookupAddress {
  type: typeof types.LOOKUP_ADDRESS_FETCH;
  postcode: string;
}

export interface LookupAddressSuccess {
  type: typeof types.LOOKUP_ADDRESS_SUCCESS;
  addressData: AddressData[];
}

export interface LookupAddressFailure {
  type: typeof types.LOOKUP_ADDRESS_FAILURE;
  error: string;
  reject: boolean;
  code: string;
}

export interface LookupBillingAddress {
  type: typeof types.LOOKUP_BILLING_ADDRESS_FETCH;
  postcode: string;
}

export interface LookupBillingAddressSuccess {
  type: typeof types.LOOKUP_BILLING_ADDRESS_SUCCESS;
  addressData: AddressData[];
}

export interface LookupBillingAddressFailure {
  type: typeof types.LOOKUP_BILLING_ADDRESS_FAILURE;
  error: string;
  reject: boolean;
  code: string;
}

export interface LookupCar {
  type: typeof types.LOOKUP_CAR_FETCH;
  registrationNumber: string;
}

export interface LookupCarSuccess {
  type: typeof types.LOOKUP_CAR_SUCCESS;
  carData: CarData;
}

export interface LookupCarFailure {
  type: typeof types.LOOKUP_CAR_FAILURE;
  error: string;
  reject: boolean;
  code: string;
}

export interface ResetLookupCar {
  type: typeof types.LOOKUP_CAR_RESET;
}

export interface ResetLookupCarErrors {
  type: typeof types.LOOKUP_CAR_ERROR_RESET;
}

export interface ResetQuote {
  type: typeof types.RESET_QUOTE;
}

export type SetLookupField = ReturnType<typeof setLookupField>;

export type LookupActions =
  | LookupAddress
  | LookupAddressSuccess
  | LookupAddressFailure
  | LookupBillingAddress
  | LookupBillingAddressSuccess
  | LookupBillingAddressFailure
  | LookupCar
  | LookupCarSuccess
  | LookupCarFailure
  | ResetLookupCar
  | ResetLookupCarErrors
  | SetLookupField
  | ResetQuote;

// Address Actions
export const lookupAddress = (postcode: string): LookupAddress => ({
  type: types.LOOKUP_ADDRESS_FETCH,
  postcode,
});

export const lookupAddressSuccess = (addressData: AddressData[]): LookupAddressSuccess => ({
  type: types.LOOKUP_ADDRESS_SUCCESS,
  addressData: formatAddressesFromLookup(addressData),
});

export const lookupAddressFailure = (
  error: string,
  reject = false,
  code: string,
): LookupAddressFailure => ({
  type: types.LOOKUP_ADDRESS_FAILURE,
  error,
  reject,
  code,
});

// Billing Address Actions
export const lookupBillingAddress = (postcode: string): LookupBillingAddress => ({
  type: types.LOOKUP_BILLING_ADDRESS_FETCH,
  postcode,
});

export const lookupBillingAddressSuccess = (
  addressData: AddressData[],
): LookupBillingAddressSuccess => ({
  type: types.LOOKUP_BILLING_ADDRESS_SUCCESS,
  addressData: formatAddressesFromLookup(addressData),
});

export const lookupBillingAddressFailure = (
  error: string,
  reject = false,
  code: string,
): LookupBillingAddressFailure => ({
  type: types.LOOKUP_BILLING_ADDRESS_FAILURE,
  error,
  reject,
  code,
});

// Car Actions
export const lookupCar = (registrationNumber: string): LookupCar => ({
  type: types.LOOKUP_CAR_FETCH,
  registrationNumber,
});

export const lookupCarSuccess = (carData: CarData): LookupCarSuccess => ({
  type: types.LOOKUP_CAR_SUCCESS,
  carData,
});

export const lookupCarFailure = (error: string, reject = true, code: string): LookupCarFailure => ({
  type: types.LOOKUP_CAR_FAILURE,
  error,
  reject,
  code,
});

export const setLookupField: SetStateFieldActionCreator<Lookup, typeof types.SET_LOOKUP_FIELD> = (
  field,
  value,
) => ({
  type: types.SET_LOOKUP_FIELD,
  field,
  value,
});

export const resetLookupCar = (): ResetLookupCar => ({
  type: types.LOOKUP_CAR_RESET,
});

export const resetLookupCarErrors = (): ResetLookupCarErrors => ({
  type: types.LOOKUP_CAR_ERROR_RESET,
});
