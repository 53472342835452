import { ProductType } from '@utils/types';
import { AlternativeProductResult } from './alternativeProducts';

export enum types {
  SET_ALTERNATIVE_PRODUCT_RESULT = 'SET_ALTERNATIVE_PRODUCT_RESULT',
  MAKE_ALTERNATIVE_PRODUCT_CHECK = 'MAKE_ALTERNATIVE_PRODUCT_CHECK',
}

export interface SetAlternativeProduct {
  type: types.SET_ALTERNATIVE_PRODUCT_RESULT;
  productType: ProductType;
  result: AlternativeProductResult;
}
export interface MakeAlternativeProductCheck {
  type: types.MAKE_ALTERNATIVE_PRODUCT_CHECK;
  productType: ProductType;
}

export type AlternativeProductActions = SetAlternativeProduct;

export const setAlternativeProduct = (
  productType: ProductType,
  result: AlternativeProductResult,
): SetAlternativeProduct => ({
  type: types.SET_ALTERNATIVE_PRODUCT_RESULT,
  productType,
  result,
});

export const makeAlternativeProductCheck = (
  productType: ProductType,
): MakeAlternativeProductCheck => ({
  type: types.MAKE_ALTERNATIVE_PRODUCT_CHECK,
  productType,
});
