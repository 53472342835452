const getAffiliate = (): string => {
  // eslint-disable-next-line prefer-regex-literals
  const affiliateIdMatch = document.cookie.match(new RegExp('affiliateId=([^;]+)'));
  if (!affiliateIdMatch) {
    return '';
  }
  const affiliateId = affiliateIdMatch[1];
  return affiliateId;
};

export default getAffiliate;
