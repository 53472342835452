import { connect } from 'react-redux';
import { getFaqUrl } from '@reselectors/links';
import RejectionFooter from './RejectionFooter';

const mapStateToProps = (state) => ({
  faqUrl: getFaqUrl(state),
});

const RejectionFooterContainer = connect(mapStateToProps)(RejectionFooter);

export default RejectionFooterContainer;
