import styled from 'styled-components';

import colors from '@config/colors';
import fonts from '@config/fonts';

export const Subtitle = styled.h2`
  font-size: 18px;
  font-family: ${fonts.title};
  color: ${colors.dark.title};
  line-height: 36px;
`;

export const InfoText = styled.p`
  font-size: 14px;
  font-family: ${fonts.standard};
  color: ${colors.outerSpace};
  line-height: 24px;
  margin-bottom: 24px;
`;
