const discount = {
  displayDiscount(props) {
    return (
      !props.crossSaleInitialPrice &&
      props.initialPrice != null &&
      parseFloat(props.price) < parseFloat(props.initialPrice)
    );
  },
};

export default discount;
