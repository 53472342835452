import styled from 'styled-components';

import { Button, ThemeUtils } from '@rentecarlo/component-library';

// The && generates extra CSS class names, which then makes this CSS
// more specific than the styles generated in the component library.
const OutlineButton = styled(Button).withConfig({
  displayName: 'OutlineButton',
})`
  && {
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.actionButton.backgroundColor};
    color: ${({ theme }) => theme.actionButton.backgroundColor};
    height: 64px;

    &:hover {
      color: ${({ theme }) => theme.actionButton.hover.backgroundColor};
    }
  }
`;

OutlineButton.defaultProps = {
  theme: ThemeUtils.GenericTheme,
};

export default OutlineButton;
