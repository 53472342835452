export const getCookie = (cookieName: string): string | undefined => {
  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
  if (match) {
    return match[2];
  }

  return undefined;
};

export const getDomain = (): string => {
  const hostname = window.location.hostname?.replace('www', '');
  const fullDomain = hostname.split('.');
  const hostDomain = fullDomain.slice(fullDomain.length - 2).join('.');
  return `.${hostDomain}`;
};

export const setSessionCookie = (key: string, value: string): void => {
  const domainName = getDomain();
  document.cookie = `${key}=${value};path=/;domain=${domainName}`;
};

export function eraseCookie(name: string): void {
  const domainName = getDomain();
  document.cookie = `${name}=; Max-Age=-99999999;domain=${domainName}; path = /;`;
}
