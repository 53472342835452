import styled from 'styled-components';

import { sizes } from '@organisms/modals/PageModal';

import colors from '@config/colors';
import media from '@config/media';
import fonts from '@config/fonts';

const buildDesktopContainerWidth = (): string => {
  // If we're mobile
  if (window.innerWidth <= sizes.desktopWidthInt) {
    return '100%';
  }

  let windowPadding = '0px';
  if (window.innerWidth <= 1500) {
    windowPadding = '235px';
  }

  return `calc(((100% - (320px + 10%)) / 2) + ${windowPadding})`;
};

type StyledBackdropProps = {
  visible: boolean;
};

export const SlideContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 0.5s;
  width: ${({ visible }): string => (visible ? buildDesktopContainerWidth() : '0px')};
  overflow: auto;
  background-color: ${colors.white};
  box-shadow: 0 1px 3px 0 ${colors.shadow};
  z-index: 2;

  ${media.tablet`
    transition: width 0.2s;
  `};
`;

export const Backdrop = styled.div<StyledBackdropProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: blur(3px);
  background-color: ${colors.backdrop};
  ${({ visible }): string => !visible && 'display: none;'};
`;

export const InnerContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${media.tablet`
    align-items: center;
  `};
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: ${colors.white};
  z-index: 3;
`;

export const ButtonContainer = styled.div`
  padding: 24px 21px 20px;
`;

export const ContentContainer = styled.div`
  width: 400px;
  margin: 0px 56px 0 0;

  ${media.tablet`
    width: 320px;
    margin: 0px;
  `};
`;

export const Title = styled.h1`
  font-family: ${fonts.title};
  font-weight: bold;
  line-height: 36px;
  font-size: 32px;
  color: ${colors.dark.title};
  ${media.tablet`
    line-height: 32px;
    font-size: 25px;
    letter-spacing: 0.9px;
  `}
  margin-bottom: 48px;

  ${media.tablet`
    margin-bottom: 16px;
  `};
`;
