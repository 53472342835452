import React, { KeyboardEvent } from 'react';
import { FieldProps } from 'redux-form';

import PillButton from '@atoms/buttons/PillButton';

import {
  RegInputContainer,
  Divider,
  DividerWrapper,
  RegistrationInput,
  RegistrationInputWrapper,
  LicenceIcon,
  LicenceIconWrapper,
  SearchButtonWrapper,
  SearchButtonIcon,
  SearchButtonText,
  SearchWrapper,
} from './styles';

interface Props {
  onSearch: (reg: string) => void;
}

const CarRegistrationSearch: React.FC<Props & FieldProps> = ({ input, meta, onSearch }) => {
  const { onChange, onBlur, value } = input;
  const { error } = meta;
  const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, '');
  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !error) {
      onSearch(cleanedValue.toUpperCase());
    }
  };
  return (
    <RegInputContainer hasError={error}>
      <LicenceIconWrapper>
        <LicenceIcon />
      </LicenceIconWrapper>
      <RegistrationInputWrapper>
        <RegistrationInput
          id='quoteStart-input-carFormCarDetails'
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder='CAR REG'
          maxLength={8}
          type='text'
          onKeyPress={handleEnter}
        />
      </RegistrationInputWrapper>
      <SearchWrapper>
        <DividerWrapper>
          <Divider />
        </DividerWrapper>
        <SearchButtonWrapper>
          <PillButton
            disabled={error}
            id='quoteStart-button-carRegSearch'
            onClick={(): void => onSearch(cleanedValue.toUpperCase())}
          >
            <SearchButtonText id='quoteStart-button-search'>Search</SearchButtonText>
            <SearchButtonIcon />
          </PillButton>
        </SearchButtonWrapper>
      </SearchWrapper>
    </RegInputContainer>
  );
};

export default CarRegistrationSearch;
