import React, { SyntheticEvent } from 'react';

import { WrappedFieldProps } from 'redux-form';

import { PostcodeSearchBox as DsmPostcodeSearchBox } from '@rentecarlo/component-library';

type Props = {
  buttonId: string;
  id: string;
  isValid: boolean;
  onChange: (event: SyntheticEvent) => void;
  onSearch: () => void;
  value: string;
  postcodeNotFound: string;
};

const PostcodeSearchBox: React.FC<Props & WrappedFieldProps> = ({
  id,
  input,
  meta,
  isValid,
  onSearch,
  postcodeNotFound,
}) => {
  const getFormErrors = (): string | JSX.Element => {
    const postcodeNotFoundError = (
      <div id='aboutYou-text-postcodeSearchBoxInvalidPostCodeError'>
        Oops, we don't recognise that postcode!{' '}
        <a
          id='aboutYou-link-postcodeSearchBoxInvalidPostCodeError'
          href='https://www.veygo.com/contact/'
        >
          Contact us
        </a>{' '}
        and we'll do our best to help
      </div>
    );
    return postcodeNotFound ? postcodeNotFoundError : meta.error;
  };
  return (
    <DsmPostcodeSearchBox
      id={id}
      isValid={isValid}
      onSearch={onSearch}
      postcodeNotFound={postcodeNotFound}
      value={input.value}
      touched={meta.touched}
      active={meta.active}
      error={meta.error}
      pristine={meta.pristine}
      submitFailed={meta.submitFailed}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onChange={input.onChange}
      errorMessage={getFormErrors()}
    />
  );
};

export default PostcodeSearchBox;
