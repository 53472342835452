import HttpClient from './httpClient';

const webLog = {
  sendError: (message, args) => {
    return new HttpClient.Builder()
      .post('/web-log/error')
      .data({ event_message: message, attributes: { ...args } })
      .unsecured()
      .execute();
  },
};

export default webLog;
