import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import colors from '@config/colors';

import { Button } from '@rentecarlo/component-library';

import PageModal from '@atoms/layout/modal/PageModal';
import QuestionItem from '@atoms/blocks/QuestionItem';

const QuestionList = styled.ul`
  margin-top: 24px;
  margin-bottom: 60px;
  ${css`
    li {
      border-bottom: 1px solid ${colors.grey.lines.light};
    }
    li:last-child {
      border-bottom: none;
    }
  `}
`;

const QuestionModal = (props) => {
  const [currentSelectionQuestion, setSelectedQuestion] = useState('');
  const { persistedSelectedQuestion } = props;

  useEffect(() => {
    setSelectedQuestion(persistedSelectedQuestion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (persistedSelectedQuestion !== currentSelectionQuestion) {
      setSelectedQuestion(persistedSelectedQuestion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedSelectedQuestion]);

  const closeQuestionModal = () => {
    const { onClose } = props;
    setSelectedQuestion('');
    onClose();
  };

  const { title, questions, onSelectedQuesiton, display, onClose } = props;
  return (
    <PageModal backButtonAction={closeQuestionModal} display={display} title={title}>
      <QuestionList id='quote-title-questionModalQuestions'>
        {questions.map((question) => (
          <QuestionItem
            key={question.value}
            title={question.title}
            description={question.description}
            value={question.value}
            isSelected={currentSelectionQuestion === question.value}
            applyTransparency={
              !(currentSelectionQuestion === '' || currentSelectionQuestion === question.value)
            }
            selectQuestion={(value) => setSelectedQuestion(value)}
            id={question.id}
          />
        ))}
      </QuestionList>
      <Button
        id='quote-button-questionModalDone'
        borderWidth={0}
        disabled={currentSelectionQuestion === ''}
        height={65}
        onClick={(event) => {
          event.preventDefault();
          onSelectedQuesiton(currentSelectionQuestion);
          onClose();
        }}
      >
        Done
      </Button>
    </PageModal>
  );
};

export default QuestionModal;
