import { VeygoResponseError } from '@utils/types';
import { InsurerData, OccupationData, RestrictedPeriodsData } from './types';

// Types
export enum types {
  CONFIG_OCCUPATION_REQUEST = 'CONFIG_OCCUPATION.REQUEST',
  CONFIG_OCCUPATION_SUCCESS = 'CONFIG_OCCUPATION.SUCCESS',
  CONFIG_OCCUPATION_FAILURE = 'CONFIG_OCCUPATION.FAILURE',
  CONFIG_ALTERNATIVE_INSURERS_REQUEST = 'CONFIG_ALTERNATIVE_INSURERS.REQUEST',
  CONFIG_ALTERNATIVE_INSURERS_SUCCESS = 'CONFIG_ALTERNATIVE_INSURERS.SUCCESS',
  CONFIG_ALTERNATIVE_INSURERS_FAILURE = 'CONFIG_ALTERNATIVE_INSURERS.FAILURE',
  RESTRICTED_PERIODS_REQUEST = 'restricted_periods/request',
  RESTRICTED_PERIODS_SUCCESS = 'restricted_periods/success',
  RESTRICTED_PERIODS_FAILURE = 'restricted_periods/failure',
  FEATURE_FLAGS_REQUEST_SINGLE = 'FEATURE_FLAGS.REQUEST_SINGLE',
  FEATURE_FLAGS_SUCCESS_SINGLE = 'FEATURE_FLAGS.SUCCESS_SINGLE',
  FEATURE_FLAGS_SUCCESS = 'FEATURE_FLAGS.SUCCESS',
  AMPLIFY_LOADED = 'AMPLIFY_LOADED',
  SET_OPTIMIZELY_ATTRIBUTE = 'SET_OPTIMIZELY_ATTRIBUTE',
}

export interface FetchOccupations {
  type: typeof types.CONFIG_OCCUPATION_REQUEST;
}

export interface FetchOccupationsSuccess {
  type: typeof types.CONFIG_OCCUPATION_SUCCESS;
  occupationsList: OccupationData;
}

export interface FetchOccupationsFailure {
  type: typeof types.CONFIG_OCCUPATION_FAILURE;
  error: VeygoResponseError;
}

export interface FetchAlternativeInsurers {
  type: typeof types.CONFIG_ALTERNATIVE_INSURERS_REQUEST;
}

export interface FetchAlternativeInsurersSuccess {
  type: typeof types.CONFIG_ALTERNATIVE_INSURERS_SUCCESS;
  alternativeInsurers: InsurerData[];
}

export interface FetchAlternativeInsurersFailure {
  type: typeof types.CONFIG_ALTERNATIVE_INSURERS_FAILURE;
  error: VeygoResponseError;
}

export interface FetchRestrictedPeriodsRequest {
  type: typeof types.RESTRICTED_PERIODS_REQUEST;
}

export interface FetchRestrictedPeriodsSuccess {
  type: typeof types.RESTRICTED_PERIODS_SUCCESS;
  data: RestrictedPeriodsData[];
}

export interface FetchRestrictedPeriodsFailure {
  type: typeof types.RESTRICTED_PERIODS_FAILURE;
  error: VeygoResponseError;
}

export interface AmplifyLoaded {
  type: typeof types.AMPLIFY_LOADED;
}

export interface SetOptimizelyAttribute {
  type: typeof types.SET_OPTIMIZELY_ATTRIBUTE;
  key: string;
  value: string;
}

export type ConfigActions =
  | FetchOccupations
  | FetchOccupationsSuccess
  | FetchOccupationsFailure
  | FetchAlternativeInsurers
  | FetchAlternativeInsurersSuccess
  | FetchAlternativeInsurersFailure
  | FetchRestrictedPeriodsRequest
  | FetchRestrictedPeriodsSuccess
  | FetchRestrictedPeriodsFailure
  | AmplifyLoaded
  | SetOptimizelyAttribute;

// Occupation actions
export const fetchOccupations = (): FetchOccupations => ({
  type: types.CONFIG_OCCUPATION_REQUEST,
});

export const fetchOccupationsSuccess = (
  occupationsList: OccupationData,
): FetchOccupationsSuccess => ({
  type: types.CONFIG_OCCUPATION_SUCCESS,
  occupationsList,
});

export const fetchOccupationsFailure = (error: VeygoResponseError): FetchOccupationsFailure => ({
  type: types.CONFIG_OCCUPATION_FAILURE,
  error,
});

// Alternative insurer actions
export const fetchAlternativeInsurers = (): FetchAlternativeInsurers => ({
  type: types.CONFIG_ALTERNATIVE_INSURERS_REQUEST,
});

export const fetchAlternativeInsurersSuccess = (
  alternativeInsurers: InsurerData[],
): FetchAlternativeInsurersSuccess => ({
  type: types.CONFIG_ALTERNATIVE_INSURERS_SUCCESS,
  alternativeInsurers,
});

export const fetchAlternativeInsurersFailure = (
  error: VeygoResponseError,
): FetchAlternativeInsurersFailure => ({
  type: types.CONFIG_ALTERNATIVE_INSURERS_FAILURE,
  error,
});

// Restricted Periods
export const fetchRestrictedPeriodsRequest = (): FetchRestrictedPeriodsRequest => ({
  type: types.RESTRICTED_PERIODS_REQUEST,
});

export const fetchRestrictedPeriodsSuccess = (
  data: RestrictedPeriodsData[],
): FetchRestrictedPeriodsSuccess => ({
  type: types.RESTRICTED_PERIODS_SUCCESS,
  data,
});

export const fetchRestrictedPeriodsFailure = (
  error: VeygoResponseError,
): FetchRestrictedPeriodsFailure => ({
  type: types.RESTRICTED_PERIODS_FAILURE,
  error,
});

export const amplifyLoaded = (): AmplifyLoaded => ({
  type: types.AMPLIFY_LOADED,
});

export const setOptimiselyAttribute = (key: string, value: string): SetOptimizelyAttribute => ({
  type: types.SET_OPTIMIZELY_ATTRIBUTE,
  key,
  value,
});
