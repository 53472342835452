/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Field, InjectedFormProps, DecoratedFormProps } from 'redux-form';
import styled from 'styled-components';
import { InformationBox, LinkButton } from '@rentecarlo/component-library';
import InfoTag from '@atoms/paragraphs/InfoTag';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Dispatch } from 'redux';

import titles from '@config/titles';
import occupationTypes from '@config/occupationTypes';
import ReduxFieldValidation from '@services/formValidation';

import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import SectionSubTitle from '@atoms/titles/componentTitles/SectionSubTitle';
import DateInputField from '@molecules/DateInputField';
import AddressSearch from '@molecules/AddressSearch';

import QuoteStep from '@templates/QuoteStep';

import MultipleChoiceField from '@molecules/inputs/MultipleChoiceField';
import TextInput from '@atoms/inputs/TextInputField';
import OccupationSelectField from '@molecules/selects/OccupationSelectField';
import Selector from '@organisms/Selector/Selector';

import { OccupationData } from '@reducer/config/types';
import { Address } from '@reducer/account/addressTypes';
import { DriverFields } from '@reducer/quote/driverTypes';

import accountApi from '@services/api/customer';

import EmailAlreadyExistsModal from '@organisms/modals/EmailAlreadyExistsModal';

import { useAppSelector } from '@hooks';
import { PaymentMethod } from '@utils/types';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Europe/London');

const InfoTagContainer = styled.div`
  margin: -7px 16px 0 16px;
`;

const getSubtitle = (isLoggedIn: boolean, goToLogin: () => void) => {
  if (isLoggedIn) return 'Tell us about yourself.';
  return (
    <span id='aboutYou-text-driverTitle'>
      Please provide the following information for the person who will drive the vehicle on this
      policy. Purchased before?{' '}
      <LinkButton id='aboutYou-button-goToLogin' action={goToLogin} large>
        Log in to save time
      </LinkButton>
    </span>
  );
};

const renderTitles = (title: string, productType: string, isQuoteForMyself: boolean) => {
  const titlesConfig: { [key: string]: string } = {
    driversName: `What is ${isQuoteForMyself ? 'your' : 'their'} full name?`,
    dob: `What is ${isQuoteForMyself ? 'your' : 'their'} date of birth?`,
    address: `What is ${isQuoteForMyself ? 'your' : 'their'} address?`,
    occupation: `What is ${isQuoteForMyself ? 'your' : 'their'} occupation?`,
    contactNumber: `What is ${isQuoteForMyself ? 'your' : 'their'} phone number?`,
    emailAddress: `What is ${isQuoteForMyself ? 'your' : 'their'} email address?`,
    emailAddressInfo: `This email address is really important - it's where we'll send ${
      isQuoteForMyself ? 'your' : 'their'
    } policy documents and also the magic link to recover ${
      isQuoteForMyself ? 'your' : 'their'
    } account if ${isQuoteForMyself ? 'you' : 'they'} forget ${
      isQuoteForMyself ? 'your' : 'their'
    } password. We'll send marketing updates here too. You can change ${
      isQuoteForMyself ? 'your' : 'their'
    } preferences at any time in ${
      isQuoteForMyself ? 'your' : 'their'
    } account or by clicking the unsubscribe link in marketing emails.`,
    driversNameInfo: `Please complete the below with the details as they appear on ${
      isQuoteForMyself ? 'your' : 'their'
    } driving licence.`,
  };

  return titlesConfig[title];
};

export interface AboutYouProps {
  initialValues: DriverFields;
  initialPostcode?: string;
  occupations: OccupationData;
  occupationType: string;
  prefilled: boolean;
  resetOccupation: () => void;
  valid: boolean;
  submitFailed: boolean;
  formErrors: Record<string, unknown>;
  postcodeNotFound: boolean;
  lookupAddress: (postcode: string) => void;
  addresses: Address[];
  setAddressFields: () => void;
  postcode: string;
  isLoggedIn: boolean;
  quoteStart: Dayjs;
  goToLogin: () => void;
  productType: string;
  paymentMethod: PaymentMethod;
  email: string;
  isFastRepurchase: boolean;
  clearEmailField: () => void;
  submission: (values: Record<string, string>, props: AboutYouProps) => void;
  userIdentity: string;
}

const AboutYou: React.FC<AboutYouProps & InjectedFormProps> = ({
  handleSubmit,
  initialValues = {} as DriverFields,
  occupations,
  occupationType,
  prefilled,
  resetOccupation,
  valid,
  submitFailed,
  formErrors,
  postcodeNotFound,
  lookupAddress,
  addresses,
  setAddressFields,
  postcode,
  isLoggedIn,
  goToLogin,
  productType,
  email,
  clearEmailField,
  submission,
}) => {
  const [showEmailExistsModal, toggleEmailExistsModal] = useState(false);
  const isQuoteForMyself = useAppSelector((state) => state.account.customer.isQuoteForMyself);

  const checkEmailExists = async (): Promise<void> => {
    try {
      if (!formErrors.email) {
        await accountApi.checkEmail(email);

        // Hide any onscreen options/keyboards if user has focused another field after inputting email
        (document.activeElement as HTMLElement).blur();

        toggleEmailExistsModal(true);
      }
    } catch (exception) {
      // Email wasn't found
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(
          (values: Record<string, string>, _dispatch: Dispatch, props: DecoratedFormProps) => {
            submission(values, props as AboutYouProps);
          },
        )}
      >
        <QuoteStep
          id='aboutYou-component-quoteStep'
          form
          stepTitle='About you'
          stepSubtitle={getSubtitle(isLoggedIn, goToLogin)}
          nextAction={handleSubmit(
            (values: Record<string, string>, _dispatch: Dispatch, props: DecoratedFormProps) => {
              submission(values, props as AboutYouProps);
            },
          )}
          submitError={submitFailed && (!valid || postcodeNotFound)}
          formErrors={formErrors}
        >
          <Group>
            <SectionTitle id='aboutYou-title-email'>
              {renderTitles('emailAddress', productType, isQuoteForMyself)}
            </SectionTitle>
            <Field
              id='aboutYou-field-email'
              name='email'
              type='email'
              placeholder='Email address'
              maxLength={254}
              component={TextInput}
              validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isEmail]}
              disabled={isLoggedIn}
              onBlur={() => checkEmailExists()}
            />
            {!isLoggedIn && (
              <InfoTagContainer>
                <InfoTag
                  id='aboutYou-text-infoTag'
                  text={renderTitles('emailAddressInfo', productType, isQuoteForMyself)}
                />
              </InfoTagContainer>
            )}
          </Group>
          <Group>
            <SectionTitle id='aboutYou-title-name'>
              {renderTitles('driversName', productType, isQuoteForMyself)}
            </SectionTitle>
            <SectionSubTitle id='aboutYou-subtitle-name'>
              {renderTitles('driversNameInfo', productType, isQuoteForMyself)}
            </SectionSubTitle>
            <Field
              id='aboutYou-field-title'
              name='title'
              component={Selector}
              placeholder='Select a title'
              options={titles}
              validate={[ReduxFieldValidation.isRequired]}
              disabled={(initialValues.title && prefilled) || isLoggedIn}
            />
            <Field
              id='aboutYou-field-firstName'
              name='firstName'
              placeholder='First name'
              component={TextInput}
              validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isName]}
              disabled={!!(initialValues.firstName && prefilled) || isLoggedIn}
            />
            <Field
              id='aboutYou-field-lastName'
              name='lastName'
              placeholder='Last name'
              component={TextInput}
              validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isName]}
              disabled={!!(initialValues.lastName && prefilled) || isLoggedIn}
            />
          </Group>
          <Group>
            <SectionTitle id='aboutYou-title-DOB'>
              {renderTitles('dob', productType, isQuoteForMyself)}
            </SectionTitle>
            <Field
              id='aboutYou-field-birthdate'
              name='birthdate'
              component={DateInputField}
              validate={[ReduxFieldValidation.isValidDOB]}
              disabled={(initialValues.birthdate && prefilled) || isLoggedIn}
            />
          </Group>
          <Group>
            <SectionTitle id='aboutYou-title-address'>
              {renderTitles('address', productType, isQuoteForMyself)}
            </SectionTitle>
            <Field
              id='aboutYou-field-addressKey'
              name='addressKey'
              component={AddressSearch}
              validate={[ReduxFieldValidation.isRequired]}
              addresses={addresses}
              setAddressFields={setAddressFields}
              lookupAddress={lookupAddress}
              isPostcodeValid={ReduxFieldValidation.isCoveredPostcode}
              postcode={postcode}
              postcodeNotFound={postcodeNotFound}
              isQuoteForMyself={isQuoteForMyself}
            />
          </Group>
          <Group>
            <SectionTitle id='aboutYou-title-occupation'>
              {renderTitles('occupation', productType, isQuoteForMyself)}
            </SectionTitle>
            <Field
              id='aboutYou-field-occupationType'
              name='occupationType'
              component={MultipleChoiceField}
              options={occupationTypes}
              withChange={resetOccupation}
              validate={[ReduxFieldValidation.isRequired]}
            />
            <Field
              id='aboutYou-field-occupation'
              name='occupation'
              component={OccupationSelectField}
              options={occupationTypes}
              occupations={occupations}
              occupationType={occupationType}
              validate={[ReduxFieldValidation.isRequired]}
              isQuoteForMyself={isQuoteForMyself}
            />
          </Group>
          {productType === 'newdriver' ? (
            <Group>
              <SectionTitle id='aboutYou-title-phoneNumberND'>
                Driver’s mobile phone number
              </SectionTitle>
              <Field
                id='aboutYou-field-phoneNumber'
                name='phoneNumber'
                type='tel'
                placeholder='e.g. 07123456789'
                component={TextInput}
                validate={[ReduxFieldValidation.isMobileNumber]}
              />
              <InformationBox id='aboutYou-text-checkPhoneNumberND' type='important'>
                Please make sure that the driver’s mobile number is correct as we will use this to
                activate the New Driver app.
              </InformationBox>
            </Group>
          ) : (
            <Group>
              <SectionTitle id='aboutYou-title-phoneNumber'>
                {renderTitles('contactNumber', productType, isQuoteForMyself)}
              </SectionTitle>
              <Field
                id='aboutYou-field-phoneNumber'
                name='phoneNumber'
                type='tel'
                placeholder='e.g. 07123456789'
                component={TextInput}
                validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isPhoneNumber]}
              />
              <div style={{ padding: '16px' }}>
                <InfoTag
                  id='aboutYou-text-infoMarketingPreferences'
                  text='We need your number in case we need to speak with you regarding your insurance. We may also share marketing updates via SMS. If you do not wish for us to send you marketing updates you can change your account preferences at any time in your account.'
                />
              </div>
            </Group>
          )}
        </QuoteStep>
      </form>

      <EmailAlreadyExistsModal
        visible={showEmailExistsModal}
        close={() => {
          clearEmailField();
          toggleEmailExistsModal(false);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }}
        email={email}
      />
    </>
  );
};

export default AboutYou;
