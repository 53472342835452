import React, { FC } from 'react';
import loader from '@assets/loading.gif';
import discount from '@services/discount';
import SubscriptionIcon from '@assets/subscription.svg';
import TelematicsIcon from '@assets/telematics.svg';

import { Chip } from '@rentecarlo/component-library';

import {
  QuoteSummaryContainer,
  QuoteValueHeader,
  QuoteValueWrapper,
  QuoteValue,
  OldValue,
  QuoteValueFooter,
  QuoteValueLoader,
  RowContainer,
  Text,
  Footer,
} from './assets/QuoteSummary';

const QuoteChips = ({
  isSubscription,
  isTelematics,
}: {
  isSubscription: boolean;
  isTelematics: boolean;
}) => (
  <div style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
    {isSubscription && <Chip icon={SubscriptionIcon} text='Subscription Policy' />}
    {isTelematics && <Chip icon={TelematicsIcon} text='Telematics' />}
  </div>
);

type Props = {
  header?: string | JSX.Element;
  loading?: boolean;
  price?: string;
  initialPrice?: string;
  crossSaleInitialPrice?: string | null;
  footer?: string | JSX.Element;
  isSubscription?: boolean;
  subscriptionText?: string;
  isTelematics?: boolean;
};

const QuoteSummary: FC<Props> = ({
  header,
  loading = false,
  price,
  initialPrice,
  crossSaleInitialPrice = null,
  footer,
  isSubscription = false,
  subscriptionText = 'a month',
  isTelematics = false,
}) => {
  return (
    <QuoteSummaryContainer>
      <QuoteValueHeader id='quote-title-quoteSummary'>{header}</QuoteValueHeader>
      {loading && <QuoteValueLoader src={loader} />}
      <RowContainer>
        <QuoteValueWrapper>
          {!loading && (
            <QuoteValue id='quote-text-quoteSummaryNewQuotePrice' className='quote-price-analytics'>
              £{price}
            </QuoteValue>
          )}
          {discount.displayDiscount({ initialPrice, crossSaleInitialPrice, price }) && (
            <OldValue id='quote-text-quoteSummaryOldQuotePrice'>£{initialPrice}</OldValue>
          )}
          {crossSaleInitialPrice && (
            <OldValue id='quote-text-quoteSummaryCrossSaleInitialPrice'>
              £{crossSaleInitialPrice}
            </OldValue>
          )}
        </QuoteValueWrapper>
        {isSubscription && (
          <Text id='quote-text-quoteSummarySubscriptionText'>{subscriptionText}</Text>
        )}
      </RowContainer>
      <Footer>
        {(isSubscription || isTelematics) && (
          <QuoteChips isSubscription={isSubscription} isTelematics={isTelematics} />
        )}
        {footer && <QuoteValueFooter id='quote-text-quoteSummaryFooter'>{footer}</QuoteValueFooter>}
      </Footer>
    </QuoteSummaryContainer>
  );
};

export default QuoteSummary;
