export const Currency = {
  GBP: 'gbp',
};

export const Country = {
  GB: 'GB',
};

/**
 * This constant will be used for Conversion project exclusively.
 */
export const ConversionProject = {
  ConversionOptimization: 'ConversionOptimizationProject',
  PAYMENT_START: 'PAYMENT_START',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
  THIRD_PARTY_ERROR: 'THIRD_PARTY_ERROR',
};
