import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { RootState } from '@redux/reducer';
import type { Action } from '@redux/types';
import { setPaperlessDelivery, setDeliveryPreference } from '@reducer/quote/payment.actions';
import DeliveryPreferenceForm from './DeliveryPreferenceForm';

const mapStateToProps = (state: RootState) => {
  const {
    quote: { payment, product },
  } = state;
  return {
    paperlessDelivery: payment.paperlessDelivery,
    deliveryPreference: payment.deliveryPreference,
    product: product.productType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setPaperlessDelivery: (value: boolean) => dispatch(setPaperlessDelivery(value)),
  setDeliveryPreference: (value: string) => dispatch(setDeliveryPreference(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPreferenceForm);
