import { AmplifyConfig, AmplifyService } from '@rentecarlo/node-amplify-client';
import process from 'process';

function isDev(): boolean {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function initialise(): Promise<void> {
  const appClientId = process.env.REACT_APP_COGNITO_AUDIENCE;
  const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL;
  const region = process.env.REACT_APP_COGNITO_AWS_REGION;
  const domain = process.env.REACT_APP_VEYGO_DOMAIN;
  const config: AmplifyConfig = {
    region,
    userPoolId,
    appClientId,
    mandatorySignIn: false,
    domain,
    path: '/',
    expires: 365,
    secure: !isDev(),
  };

  return AmplifyService.initialise(config);
}
