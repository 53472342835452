import React from 'react';
import { InformationBox, PostalButtonGroup } from '@rentecarlo/component-library';
import styled from 'styled-components';

const InformationBoxContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const DescriptionText = styled.div`
  font-family: 'proxima-soft';
  font-size: 14px;
  color: ${({ theme }): string => theme.textPrimary};
  line-height: 16px;
  margin-bottom: 0;

  p:first-of-type,
  ul {
    margin-bottom: 16px;
  }

  ul {
    padding-left: 24px;
    list-style-type: disc;
  }
`;

const postalItems = [
  {
    title: 'Key Documents',
    text: (
      <DescriptionText>
        <p id='payment-paragraph-deliveryPreferencePost'>
          I only want to receive the following documents by post:
        </p>
        <ul>
          <li id='payment-text-deliveryPreferenceFormMotor'>Motor proposal confirmation</li>
          <li id='payment-text-deliveryPreferenceFormCertificate'>Certificate of insurance</li>
          <li id='payment-text-deliveryPreferenceFormSchedule'>Policy schedule</li>
        </ul>
        <p id='payment-paragraph-deliveryPreferenceFormOnline'>
          I am happy to access the rest of my documents online.
        </p>
      </DescriptionText>
    ),
    value: 'postal',
  },
  {
    title: 'All Documents',
    text: (
      <DescriptionText id='payment-text-deliveryPreferenceForm'>
        I’d like to receive all documents in the post including the the policy booklet (35-40
        pages).
      </DescriptionText>
    ),
    value: 'postal_full',
  },
];

interface ComponentProps {
  paperlessDelivery: boolean;
  deliveryPreference: string;
  setPaperlessDelivery: (value: boolean) => void;
  setDeliveryPreference: (value: string) => void;
}

const DeliveryPreferenceForm: React.FC<ComponentProps> = ({
  paperlessDelivery,
  setPaperlessDelivery,
  deliveryPreference,
  setDeliveryPreference,
}) => {
  const handleFormSubmit = (values: string): void => {
    setDeliveryPreference(values);
  };

  return (
    <>
      <PostalButtonGroup
        groupName='paperless-delivery-toggle'
        onSelect={(bool) => setPaperlessDelivery(bool)}
        value={paperlessDelivery === true ? 'yes' : 'no'}
        trueOptionText="Yes, I'd like to go paperless"
        falseOptionText="No, I'd prefer them printed"
        postalItems={postalItems}
        onSubmit={handleFormSubmit}
        deliveryPreference={deliveryPreference}
      />

      {!paperlessDelivery && (
        <InformationBoxContainer>
          <InformationBox id='payment-text-deliveryPreferenceFormDeliveryTime' type='important'>
            Documents may take 5-7 working days to reach you by post.
          </InformationBox>
        </InformationBoxContainer>
      )}
    </>
  );
};

export default DeliveryPreferenceForm;
