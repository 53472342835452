import React from 'react';
import { Link } from 'react-router-dom';
import { ModalPortal, PillButton } from '@rentecarlo/component-library';
import TimeoutIcon from '@assets/timeout-icon.svg';
import {
  Container,
  ModalContainer,
  Modal,
  ButtonContainer,
  ImageContainer,
  Image,
  Title,
  Label,
} from './styles';

type Props = {
  visible: boolean;
  onConfirm: () => void;
};

const DurationPriceChangeModal: React.FC<Props> = ({ onConfirm }) => (
  <ModalPortal preventScroll>
    <Container visible onClick={(): boolean => false}>
      <ModalContainer>
        <Modal onClick={(event: React.SyntheticEvent): void => event.stopPropagation()}>
          <ImageContainer>
            <Image src={TimeoutIcon} />
          </ImageContainer>
          <Title id='payment-title-durationPriceChangeModal'>Oh snap!</Title>
          <Label id='payment-text-durationPriceChangeModal'>
            It looks like your duration has changed while completing your quote. Please click the
            button below to update your duration and get a new price.
          </Label>
          <ButtonContainer>
            <Link to='/duration-of-cover'>
              <PillButton
                id='payment-button-durationPriceChangeModal'
                width='100%'
                onClick={onConfirm}
              >
                Update my duration
              </PillButton>
            </Link>
          </ButtonContainer>
        </Modal>
      </ModalContainer>
    </Container>
  </ModalPortal>
);

export default DurationPriceChangeModal;
