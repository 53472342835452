import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { formValueSelector } from 'redux-form';
import { setUserField } from '@reducer/quote/user.actions';
import { driverIsDefined, ownerIsDefined, carIsSelected } from '@reselectors/sidebar';
import { crossSaleInitialPriceSelector } from '@reselectors/quote';
import {
  getPrice,
  getInitialPrice,
  PriceDataObjectProps,
  InitialPriceDataObjectProps,
  UtilDataObjectProps,
} from '@services/variableExcess';
import { Dayjs } from 'dayjs';
import { RootState } from '@redux/reducer';
import QuoteSummarySidebar from './QuoteSummarySidebar.component';

type QuoteDuration = {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
};

export interface StateProps {
  duration: QuoteDuration;
  pathname: string;
  car: {
    make: string;
    model: string;
    registrationNumber: string;
  };
  carIsSelected: boolean;
  displayDriverName: boolean;
  driver: {
    firstName: string;
    lastName: string;
    title: string;
  };
  driverIsDefined: boolean;
  loggedIn: boolean;
  displayOwnerName: boolean;
  productType: string;
  owner: {
    firstName: string;
    surname: string;
  };
  ownerIsDefined: boolean;
  price: string;
  initialPrice: string;
  isFinalPrice: boolean;
  crossSaleInitialPrice: string;
  validDiscountCode: boolean;
  isSubscription: boolean;
}

export interface DispatchProps {
  goToCar: () => void;
  goToOwnerDetails: () => void;
  goToDuration: (location: string) => void;
  goToAboutTheDriver: (loggedIn: boolean) => void;
}

export interface OwnProps {
  pathname: string;
}

const importantInformationForm = formValueSelector('importantInformationForm');

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const {
    account: {
      login: { loggedIn },
    },
    lookup,
    quote: {
      duration,
      driver,
      owner,
      car,
      save,
      product,
      importantInformation,
      indicativePrice,
      ancillary,
    },
  } = state;

  const { pathname } = ownProps;
  const isBreakdownCoverSelected = () => {
    if (pathname.includes('important-information')) {
      return importantInformationForm(state, 'breakdownCover') === 'true';
    }
    return ancillary?.breakdownCover;
  };
  const utilDataObject: UtilDataObjectProps = {
    useExcessPrice: pathname.includes('important-information'),
    selectedExcess: importantInformationForm(state, 'excess'),
    finalPricesByExcess: importantInformation.finalPricesByExcess,
    isBreakdownCoverSelected: isBreakdownCoverSelected(),
  };

  const priceDataObject: PriceDataObjectProps = {
    ...utilDataObject,
    savedPrice: save.price,
    indicativePrice: indicativePrice?.price,
  };

  const initialPriceDataObject: InitialPriceDataObjectProps = {
    ...utilDataObject,
    initialPriceWithBreakdownCover: ancillary?.initialPriceWithBreakdownCover,
    savedInitialPrice: save.initialPrice,
  };
  const initialPrice = getInitialPrice(initialPriceDataObject);
  const price = getPrice(priceDataObject);

  return {
    car: {
      ...car,
      registrationNumber: car.registrationNumber || lookup.registrationNumber,
    },
    duration,
    driver,
    owner,
    displayOwnerName: ownerIsDefined(state),
    displayDriverName: driverIsDefined(state) && pathname !== '/about-you',
    driverIsDefined: driverIsDefined(state),
    ownerIsDefined: ownerIsDefined(state),
    carIsSelected: carIsSelected(state),
    isFinalPrice: !!save.price,
    price,
    initialPrice,
    loggedIn,
    validDiscountCode: save.validDiscountCode,
    pathname,
    crossSaleInitialPrice:
      crossSaleInitialPriceSelector(state, initialPrice, price, isBreakdownCoverSelected()) ?? '',
    productType: product.productType,
    isSubscription: product.isSubscription,
  };
};

const closeMenuAndGoTo = (dispatch: Dispatch, page: string): void => {
  dispatch(push(page));
  dispatch(setUserField('quoteSummaryModalOpen', false));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: Dispatch, ownProps: any): DispatchProps => {
  const handleNavigation = (redirect: () => void): void => {
    redirect();
    if (ownProps.displayMobileSummary) {
      ownProps.onHandleSummaryClose();
    }
  };
  return {
    goToCar: (): void => handleNavigation(() => closeMenuAndGoTo(dispatch, '/car')),
    goToOwnerDetails: (): void => {
      const { router } = ownProps;
      handleNavigation(() =>
        router.push({
          pathname: '/car',
          state: {
            scrollToOwner: true,
          },
        }),
      );
    },
    goToDuration: (location: string): void => {
      handleNavigation(() => closeMenuAndGoTo(dispatch, location));
    },
    goToAboutTheDriver: (loggedIn: boolean): void =>
      handleNavigation(() =>
        closeMenuAndGoTo(dispatch, loggedIn ? '/driver-details-summary' : '/about-you'),
      ),
  };
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => ({
  ...stateProps,
  goToCar: () => {
    dispatchProps.goToCar();
  },
  goToDuration: (location: string) => dispatchProps.goToDuration(location),
  goToAboutTheDriver: (loggedIn: boolean) => {
    dispatchProps.goToAboutTheDriver(loggedIn);
  },
});

const QuoteSummarySidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(QuoteSummarySidebar);

export default QuoteSummarySidebarContainer;
