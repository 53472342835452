import { SetStateFieldActionCreator } from '@utils/types';
import { Car, CarData } from './carTypes';

import { ResetQuote } from './actions';

export enum types {
  LOAD_FROM_LOOKUP = 'LOAD_FROM_LOOKUP',
  SET_CAR_FIELD = 'SET_CAR_FIELD',
  TOGGLE_HAS_INSURANCE = 'TOGGLE_HAS_INSURANCE',
  TOGGLE_TERMS_OF_COVER = 'TOGGLE_TERMS_OF_COVER',
  TOGGLE_TEMPCOVER_PERSONAL_VEHICLE = 'TOGGLE_TEMPCOVER_PERSONAL_VEHICLE',
}

export interface LoadFromLookup {
  type: typeof types.LOAD_FROM_LOOKUP;
  carData: CarData;
}

export interface ToggleHasInsurance {
  type: typeof types.TOGGLE_HAS_INSURANCE;
  value: string;
}

export interface ToggleTermsOfCover {
  type: typeof types.TOGGLE_TERMS_OF_COVER;
}

export interface ToggleTempcoverPersonalVehicle {
  type: typeof types.TOGGLE_TEMPCOVER_PERSONAL_VEHICLE;
}

export type SetCarField = ReturnType<typeof setCarField>;

export type CarActions =
  | LoadFromLookup
  | SetCarField
  | ToggleHasInsurance
  | ToggleTermsOfCover
  | ToggleTempcoverPersonalVehicle
  | ResetQuote;

// Actions
export const loadFromLookup = (carData: CarData): LoadFromLookup => ({
  type: types.LOAD_FROM_LOOKUP,
  carData,
});

export const setCarField: SetStateFieldActionCreator<Car, typeof types.SET_CAR_FIELD> = (
  field,
  value,
) => ({
  type: types.SET_CAR_FIELD,
  field,
  value,
});

export const toggleHasInsurance = (value: string): ToggleHasInsurance => ({
  type: types.TOGGLE_HAS_INSURANCE,
  value,
});

export const toggleTermsOfCover = (): ToggleTermsOfCover => ({
  type: types.TOGGLE_TERMS_OF_COVER,
});

export const toggleTempcoverPersonalVehicle = (): ToggleTempcoverPersonalVehicle => ({
  type: types.TOGGLE_TEMPCOVER_PERSONAL_VEHICLE,
});
