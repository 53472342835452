import { AnalyticsActionTypes, ANALYTICS_SEND_EVENT } from './actions';
import { AnalyticsState } from './analyticTypes';

// Reducer
export const initialState: AnalyticsState = {
  quoteReference: '',
  type: '',
  quote: '',
  customer: '',
};

export default function analytics(
  state = initialState,
  action: AnalyticsActionTypes,
): AnalyticsState {
  switch (action.type) {
    case ANALYTICS_SEND_EVENT:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
