import styled from 'styled-components';

export const TooltipText = styled.div`
  font-family: proxima-soft;
  font-size: 14px;
  margin-top: 8px;
  line-height: 16px;
`;

export const ElementContainer = styled.div`
  margin-top: 16px;
`;

export const FlatButtonContainer = styled.div`
  margin-top: 15px;
`;

interface SpacerProps {
  space?: string;
}

export const Spacer = styled.div`
  margin-top: ${({ space }: SpacerProps): string => space || '10px'};
`;
