import styled from 'styled-components';
import colors from '@config/colors';

export const UpsellCard = styled.div`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid ${colors.robinsEggBlue};
  background: #ccf8f5;
  margin-top: 16px;
`;

export const UpSellText = styled.div`
  font-family: 'proxima-soft';
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  width: 100px;
  border-radius: 8px;

  &:hover img {
    filter: invert(1);
  }
`;
export const Icon = styled.img``;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;
