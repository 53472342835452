const getCurrentPath = (): string => {
  const pathArray = window.location.pathname.split('/');
  pathArray.splice(1, 1);
  return pathArray.join('/');
};

const getQueryParams = (): Record<string, string> => {
  const searchParams: Record<string, string> = {};
  if (window.location.search !== '') {
    window.location.search
      .substr(1)
      .split('&')
      .forEach((value) => {
        // eslint-disable-next-line prefer-destructuring
        searchParams[value.split('=')[0]] = value.split('=')[1];
      });
  }
  return searchParams;
};

export { getCurrentPath, getQueryParams };
