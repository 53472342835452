import React from 'react';
import { useStore } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { useAppSelector } from '@hooks';
import { getPrice, PriceDataObjectProps } from '@services/variableExcess';

import {
  SummaryContainer,
  IconWrapper,
  ArrowIcon,
  LabelWrapper,
  Label,
  PriceContainer,
  CurrentPriceLabel,
  CurrentPriceValue,
} from './styles';

const importantInformationForm = formValueSelector('importantInformationForm');
export interface Props {
  onClick: () => void;
  visible: boolean;
  useExcessPrice?: boolean;
}

const ViewQuoteSummaryBanner: React.FC<Props> = ({ onClick, visible, useExcessPrice = false }) => {
  const { pathname } = window.location;
  const hidePrice = ['/start', '/quote-start', '/choose-your-cover'].includes(pathname);

  const getState = useStore().getState();

  const priceDataObject: PriceDataObjectProps = {
    useExcessPrice,
    selectedExcess: importantInformationForm(getState, 'excess'),
    finalPricesByExcess: useAppSelector(
      (state) => state.quote.importantInformation.finalPricesByExcess,
    ),
    isBreakdownCoverSelected: importantInformationForm(getState, 'breakdownCover') === 'true',
    savedPrice: useAppSelector((state) => state.quote.save.price),
    indicativePrice: useAppSelector((state) => state.quote.indicativePrice?.price),
  };
  const price = getPrice(priceDataObject);

  const showPrice = price && !hidePrice;

  return (
    <SummaryContainer id='quote-container-summaryBanner' onClick={onClick}>
      <LabelWrapper>
        <Label id='quote-title-summaryBanner'>View quote summary</Label>
      </LabelWrapper>
      <IconWrapper>
        <ArrowIcon visible={visible} />
      </IconWrapper>
      {showPrice && (
        <PriceContainer id='quote-container-summaryBannerPrice'>
          <CurrentPriceLabel id='quote-text-quoteSummaryBannerPrice'>
            Current price
          </CurrentPriceLabel>
          <CurrentPriceValue id='quote-text-quoteSummaryBannerPriceValue'>{`£${price}`}</CurrentPriceValue>
        </PriceContainer>
      )}
    </SummaryContainer>
  );
};

export default ViewQuoteSummaryBanner;
