import { CustomerResponseV3Additional } from '@services/transformers/customers';
import { VeygoResponseError } from '@utils/types';
import { RegisterFieldObject } from './registerTypes';

export enum types {
  SET_REGISTER_FIELDS = 'SET_REGISTER_FIELDS',
  REGISTER_REQUEST = 'REGISTER.REQUEST',
  REGISTER_SUCCESS = 'REGISTER.SUCCESS',
  REGISTER_FAILURE = 'REGISTER.FAILURE',
}

export interface SetRegisterFields {
  type: typeof types.SET_REGISTER_FIELDS;
  obj: RegisterFieldObject;
}

export interface RegisterRequest {
  type: typeof types.REGISTER_REQUEST;
  nextPage: string;
}

export interface RegisterSuccess {
  type: typeof types.REGISTER_SUCCESS;
  data: CustomerResponseV3Additional;
  token: string;
}

export interface RegisterFailure {
  type: typeof types.REGISTER_FAILURE;
  error: VeygoResponseError;
}

export const setRegisterFields = (obj: RegisterFieldObject): SetRegisterFields => ({
  type: types.SET_REGISTER_FIELDS,
  obj,
});

export const registerRequest = (nextPage: string): RegisterRequest => ({
  type: types.REGISTER_REQUEST,
  nextPage,
});

export const registerSuccess = (
  data: CustomerResponseV3Additional,
  token: string,
): RegisterSuccess => ({
  type: types.REGISTER_SUCCESS,
  data,
  token,
});

export const registerFailure = (error: VeygoResponseError): RegisterFailure => ({
  type: types.REGISTER_FAILURE,
  error,
});

export type RegisterActiontypes =
  | SetRegisterFields
  | RegisterRequest
  | RegisterSuccess
  | RegisterFailure;
