import styled from 'styled-components';

export const QuestionList = styled.ul`
  margin-top: 48px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 64px;
`;

export const ContentWrapper = styled.div`
  padding-bottom: 64px;
`;
