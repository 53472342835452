import { IndicativePrices } from '@redux/types';
import { IndicativePriceActions, types } from './indicativePrice.actions';
import { types as quoteTypes, QuoteActions } from './actions';
import { SaveActions, types as saveTypes } from './save.actions';
import { ProductActions, SET_PAYMENT_METHOD } from './product.actions';

interface IndicativePriceState {
  prices: IndicativePrices;
  subscriptionPrices: IndicativePrices;
  price?: string;
  error?: string;
  loading: boolean;
}

export const initialState: IndicativePriceState = {
  prices: {},
  subscriptionPrices: {},
  price: undefined,
  error: undefined,
  loading: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IndicativePriceActionTypes =
  | IndicativePriceActions
  | QuoteActions
  | SaveActions
  | ProductActions;
export default function indicativePrice(
  state = initialState,
  action: IndicativePriceActionTypes,
): IndicativePriceState {
  switch (action.type) {
    case saveTypes.SAVE_QUOTE_SUCCESS:
      return {
        ...state,
        price: action.data.indicative_price ? action.data.indicative_price : state.price,
      };
    case types.QUICK_SELECT_PRICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SUBSCRIPTION_PRICES_SUCCESS:
      return {
        ...state,
        subscriptionPrices: action.subscriptionPrices,
        error: undefined,
        loading: false,
      };
    case types.SUBSCRIPTION_PRICES_FAILURE:
      return {
        ...state,
        subscriptionPrices: {},
        price: undefined,
        loading: false,
      };
    case types.QUICK_SELECT_PRICES_SUCCESS:
      return {
        ...state,
        prices: action.prices,
        error: undefined,
        loading: false,
      };
    case SET_PAYMENT_METHOD:
    case quoteTypes.RESET_QUOTE:
    case types.QUICK_SELECT_PRICES_FAILURE:
      return {
        ...state,
        prices: {},
        price: undefined,
        loading: false,
      };
    case types.INDICATIVE_PRICE_QUICK_SELECT:
      return {
        ...state,
        price: state.prices[action.duration.inHours]?.price,
      };
    case types.INDICATIVE_PRICE_SET_SUBS_PRICE:
      return {
        ...state,
        subsPrice: state.subscriptionPrices[action.duration.inHours].price,
      };
    default:
      return state;
  }
}
