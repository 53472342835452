import React from 'react';
import styled, { css } from 'styled-components';

import size from '@config/size';
import Plus from '@assets/add-icn.svg';
import Minus from '@assets/minus.svg';

const PlusInputContainer = styled.div`
  position: relative;
`;

const ThePlus = styled.img`
  height: 20px;
  width: 20px;
  top: 0;
  position: absolute;
  right: 5px;
  margin-top: 7px;
  cursor: pointer;
  ${css`
    @media (min-width: ${size.desktopWidth}) {
      right: '-${31 + 32}px';
    }
  `};
`;

const BluePlus = ({ plus, onClick }) => (
  <PlusInputContainer>
    <ThePlus
      data-test='bluePlus'
      src={plus ? Plus : Minus}
      alt={plus ? 'Add voucher' : 'Remove voucher'}
      onClick={onClick}
    />
  </PlusInputContainer>
);

BluePlus.defaultProps = {
  plus: true,
};

export default BluePlus;
