export default function getTempcoverReasonText(reason, isQuoteForMyself) {
  const pronoun = isQuoteForMyself ? 'I am' : 'They are';
  const ownCar = isQuoteForMyself ? 'my' : 'their';

  switch (reason) {
    case 'tc_buying':
      return `${pronoun} buying a car`;
    case 'tc_selling':
      return `${pronoun} selling ${ownCar} car`;
    case 'tc_personal':
      return 'Personal / social use';
    case 'tc_borrowing':
      return `Borrowing ${ownCar} own car`;
    case 'tc_shopforannual':
      return 'Shopping for annual insurance';
    case 'tc_impound':
      return 'Releasing from impound';
    case 'tc_other':
      return 'Other';
    default:
      return 'Select a reason';
  }
}
