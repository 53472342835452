const objectFieldsHaveChanged = <T>(
  fields: string[],
  obj1: Record<string, T>,
  obj2: Record<string, T>,
): boolean => {
  const anyChanges = fields.reduce((acc, field) => acc || obj1[field] !== obj2[field], false);
  return anyChanges;
};

export default objectFieldsHaveChanged;
