import { call, put, takeEvery, select } from 'redux-saga/effects';

import { customerPreferredCar as customerPreferredCarApi } from '../../services/api';

import { setCarField } from '../reducer/quote/car.actions';
import { setUserField } from '../reducer/quote/user.actions';
import { lookupCar, types as lookupTypes } from '../reducer/lookup/actions';

import {
  SELECT_CONNECTION,
  FETCH_CONNECTIONS_REQUEST,
  fetchConnectionsSuccess,
  fetchConnectionsFailure,
  deleteConnectionSuccess,
  deleteConnectionFailure,
  saveConnectionSuccess,
  saveConnectionFailure,
} from '../reducer/account/connections.actions';

import { CUSTOMER_FETCH_ME_REQUEST } from '../reducer/account/customer.actions';
import { types as quoteTypes } from '../reducer/quote/actions';

export const getPreferredCar = (state, uuid) =>
  state.account.connections.preferredCars.find((elem) => elem.uuid === uuid);
export const getSelectedConnection = (state) => state.quote.user.connection;
export const getRegistrationNumber = (state) => state.quote.car.registrationNumber;
export const getLoginStatus = (state) => state.account.login.loggedIn;

export function* quoteFromConnectionSaga({ connectionUuid, registrationNumber }) {
  yield put(setUserField('connection', connectionUuid));
  yield put(setCarField('registrationNumber', registrationNumber));
  yield put(lookupCar(registrationNumber));
}

export function* fetchConnectionsSaga() {
  try {
    const loggedIn = yield select(getLoginStatus);
    if (loggedIn) {
      const data = yield call(customerPreferredCarApi.list);
      yield put(fetchConnectionsSuccess(data));
    }
  } catch (error) {
    yield put(fetchConnectionsFailure(error));
  }
}

export function* selectConnectionSaga(action) {
  const newConnection = action.uuid;
  const selectedConnection = yield select(getSelectedConnection);
  const quoteRegistrationNumber = yield select(getRegistrationNumber);
  const alreadySelected =
    selectedConnection === newConnection && newConnection === quoteRegistrationNumber;

  if (!!newConnection && !alreadySelected) {
    const preferredCar = yield select(getPreferredCar, newConnection);
    yield put(setUserField('connection', newConnection));
    yield put(setCarField('registrationNumber', preferredCar.car.registrationNumber));
    yield put(setCarField('savedMainInsurer', preferredCar.mainInsurer));
  }
}

export function* watchConnections() {
  yield takeEvery(
    [CUSTOMER_FETCH_ME_REQUEST, lookupTypes.LOOKUP_CAR_FETCH, FETCH_CONNECTIONS_REQUEST],
    fetchConnectionsSaga,
  );
  yield takeEvery(quoteTypes.QUOTE_FROM_CONNECTION, quoteFromConnectionSaga);
  yield takeEvery(SELECT_CONNECTION, selectConnectionSaga);
}
