import {
  MARK_PAGE_AS_COMPLETE_SUCCESS,
  MARK_PAGE_AS_COMPLETE_FAILURE,
  MARK_PAGE_AS_RESET_SUCCESS,
  PageActions,
  ownerDetailsPayload,
  aboutYouPayload,
  OwnerDetailsPageSubmitted,
  AboutYouPageSubmitted,
} from './types';

export enum ownerDetails {
  'SUBMITTED' = 'pages/ownerDetails.submitted',
}

export enum aboutYou {
  'SUBMITTED' = 'pages/aboutYou.submitted',
}

export const markPageAsComplete = (pageName: string): PageActions => ({
  type: MARK_PAGE_AS_COMPLETE_SUCCESS,
  pageName,
});

export const markPageAsCompleteFailure = (pageName: string, error: string): PageActions => ({
  type: MARK_PAGE_AS_COMPLETE_FAILURE,
  pageName,
  error,
});

export const markPageAsReset = (pageName: string): PageActions => ({
  type: MARK_PAGE_AS_RESET_SUCCESS,
  pageName,
});

export const ownerDetailsPageSubmitted = (
  ownerDetailsFormData: ownerDetailsPayload,
): OwnerDetailsPageSubmitted => ({
  type: ownerDetails.SUBMITTED,
  payload: ownerDetailsFormData,
});

export const aboutYouPageSubmitted = (
  aboutYouFormData: aboutYouPayload,
): AboutYouPageSubmitted => ({
  type: aboutYou.SUBMITTED,
  payload: aboutYouFormData,
});

export default {
  ownerDetailsPageSubmitted,
  aboutYouPageSubmitted,
};
