import React from 'react';
import { PageModal } from '@rentecarlo/component-library';
import { Title, TitleSpacer, Wrapper } from './styles';

interface Props {
  show: boolean;
  close: () => void;
  title: string;
  children?: React.ReactNode;
}

const TextModal: React.FC<Props> = ({ show, close, children, title }) => {
  const closeText = 'Back';

  return (
    <PageModal close={close} closeText={closeText} show={show}>
      <Wrapper>
        <Title id='payment-title-textModal'>{title}</Title>
        <TitleSpacer />
        {children}
      </Wrapper>
    </PageModal>
  );
};

export default TextModal;
