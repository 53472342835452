import React from 'react';

import { useSelector } from 'react-redux';

import { CardModal, Button, Clipboard } from '@rentecarlo/component-library';

import { ComponentType, useComponentId, useOptimizelyTrack } from '@hooks';
import { RootState } from '@redux/reducer';

import {
  Container,
  Title,
  Text,
  Image,
  ButtonContainer,
  BottomText,
  Link,
  IconContainer,
  Icon,
  IconText,
  ClipboardContainer,
} from './assets/styles';

import AppImage from './assets/app.png';
import QrCodeImage from './assets/qr-code.png';

const appLink = 'https://onelink.to/veygo-new-driver';

interface Props {
  visible: boolean;
  close: () => void;
}

const TelematicsAppDownloadModal: React.FC<Props> = ({ visible, close }) => {
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const idCreator = useComponentId();

  const sendEvent = useOptimizelyTrack();

  const eventHandler = (event: string) => {
    sendEvent(`newdriver_app_modal_${event}`);
  };

  return (
    <CardModal
      visible={visible}
      maxWidth='400px'
      close={() => {
        eventHandler('close');
        close();
      }}
    >
      <Container id={idCreator(ComponentType.CONTAINER, 'TelematicsAppDownload')}>
        <IconContainer id={idCreator(ComponentType.ICON, 'TelematicsAppDownload')}>
          <Icon />
          <IconText id={idCreator(ComponentType.TEXT, 'TelematicsAppDownloadIconText')}>
            Payment Complete
          </IconText>
        </IconContainer>
        <Title id={idCreator(ComponentType.TITLE, 'TelematicsAppDownload')}>One last step!</Title>
        <Text id={idCreator(ComponentType.TEXT, 'TelematicsAppDownload')}>
          You{' '}
          <span
            id={idCreator(ComponentType.TEXT, 'TelematicsAppDownloadUnderline')}
            style={{ textDecoration: 'underline' }}
          >
            must
          </span>{' '}
          register on the New Driver app to activate your policy!
        </Text>
        {isMobile ? (
          <>
            <Image id={idCreator(ComponentType.IMAGE, 'TelematicsApp')} src={AppImage} />
            <a
              href={appLink}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                eventHandler('download');
              }}
            >
              <ButtonContainer>
                <Button id={idCreator(ComponentType.BUTTON, 'TelematicsAppDownload')}>
                  Download the New Driver app
                </Button>
              </ButtonContainer>
            </a>
          </>
        ) : (
          <Image id={idCreator(ComponentType.IMAGE, 'TelematicsAppQrCode')} src={QrCodeImage} />
        )}
        <ClipboardContainer
          onClick={() => {
            eventHandler('copy');
          }}
        >
          <Clipboard
            id={idCreator(ComponentType.FIELD, 'TelematicsAppLinkClipboard')}
            value={appLink}
            buttonText='Copy link'
          />
        </ClipboardContainer>
        <BottomText id={idCreator(ComponentType.TEXT, 'TelematicsAppHelp')}>
          If you need any help visit our{' '}
          <Link
            id={idCreator(ComponentType.LINK, 'TelematicsAppHelpIos')}
            href='https://www.veygo.com/help-centre/veygo-new-driver-ios-install-guide/'
            onClick={() => {
              eventHandler('ios_guide');
            }}
          >
            iOS
          </Link>{' '}
          and{' '}
          <Link
            id={idCreator(ComponentType.LINK, 'TelematicsAppHelpAndroid')}
            href='https://www.veygo.com/help-centre/veygo-new-driver-android-install-guide/'
            onClick={() => {
              eventHandler('android_guide');
            }}
          >
            Android
          </Link>{' '}
          set up guides
        </BottomText>
      </Container>
    </CardModal>
  );
};

export default TelematicsAppDownloadModal;
