import React, { useEffect } from 'react';
import { ComponentProps } from './Start.container';
import LoggedInStart from './LoggedInStart';
import LoggedOutStart from './LoggedOutStart';

const Start: React.FC<ComponentProps> = ({
  firstName,
  licenceType,
  loggedIn,
  productType,
  login,
  resetQuote,
  submission,
  updateLicenceType,
  amplifyLoaded,
  setForMyself,
  isQuoteForMyself,
}) => {
  useEffect(() => {
    resetQuote();
  }, [loggedIn, resetQuote]);

  return !loggedIn ? (
    <LoggedOutStart
      licenceType={licenceType}
      productType={productType}
      submission={submission}
      updateLicenceType={updateLicenceType}
      setForMyself={setForMyself}
      isQuoteForMyself={isQuoteForMyself}
      resetQuote={resetQuote}
      amplifyLoaded={amplifyLoaded}
      loggedIn={loggedIn}
      login={login}
    />
  ) : (
    <LoggedInStart
      firstName={firstName}
      licenceType={licenceType}
      productType={productType}
      submission={submission}
    />
  );
};

export default Start;
