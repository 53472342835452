import { SagaIterator } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';
import { RootState } from '@redux/reducer';
import { BillingAddress } from '@redux/reducer/quote/paymentTypes';
import { DriverAddress } from '@redux/reducer/quote/driverTypes';
import { types, setPaymentField } from '../reducer/quote/payment.actions';
import { selectLocation } from './tools';

export const getDriverAddress = (state: RootState): DriverAddress => state.quote.driver.address;

export const getBillingAddress = (state: RootState): BillingAddress =>
  state.quote.payment.billingAddress;

export function* billingAddressSaga(): SagaIterator {
  const billingAddress: BillingAddress = yield select(getBillingAddress);
  if (billingAddress.reuseDriverAddress) {
    const driverAddress: DriverAddress = yield select(getDriverAddress);
    billingAddress.isManual = driverAddress.isManual;
    billingAddress.country = driverAddress.country;
    billingAddress.county = driverAddress.county;
    billingAddress.flatNumber = driverAddress.flatNumber;
    billingAddress.houseName = driverAddress.houseName;
    billingAddress.houseNumber = driverAddress.houseNumber;
    billingAddress.line1 = driverAddress.line1;
    billingAddress.line2 = driverAddress.line2;
    billingAddress.postcode = driverAddress.postcode;
    billingAddress.road = driverAddress.road;
    billingAddress.town = driverAddress.town;
    billingAddress.lookupId = driverAddress.lookupId;
    billingAddress.udprn = driverAddress.udprn;
    billingAddress.lookedUp = driverAddress.lookedUp;
    billingAddress.key = driverAddress.key;
    yield put(setPaymentField('billingAddress', billingAddress));
  } else {
    billingAddress.isManual = false;
    billingAddress.country = '';
    billingAddress.county = '';
    billingAddress.flatNumber = '';
    billingAddress.houseName = '';
    billingAddress.houseNumber = '';
    billingAddress.line1 = '';
    billingAddress.line2 = '';
    billingAddress.postcode = '';
    billingAddress.road = '';
    billingAddress.town = '';
    billingAddress.lookupId = '';
    billingAddress.udprn = '';
    billingAddress.lookedUp = false;
    billingAddress.key = '';
    yield put(setPaymentField('billingAddress', billingAddress));
  }
}

export function* watchBillingAddress(): SagaIterator {
  yield takeEvery(types.SET_REUSE_DRIVER_ADDRESS, billingAddressSaga);
  yield takeEvery(selectLocation('/payment-address'), billingAddressSaga);
}
