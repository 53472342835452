import React from 'react';
import { RegContainer, LicenceIconWrapper, LicenceIcon, LicenceText } from './assets/styles';

interface Props {
  reg: string;
  marginTop?: string;
  className?: string;
}

const CarReg: React.FC<Props> = ({ reg, marginTop, className }) => {
  return (
    <RegContainer marginTop={marginTop}>
      <LicenceIconWrapper>
        <LicenceIcon />
      </LicenceIconWrapper>
      <LicenceText id='quoteStart-text-carReg' className={className}>
        {reg}
      </LicenceText>
    </RegContainer>
  );
};

export default CarReg;
