import React, { ReactElement, useState } from 'react';
import { Field } from 'redux-form';
import { Button, FieldContainer } from '@rentecarlo/component-library';
import ReduxFieldValidation from '@services/formValidation';

import { Car } from '@pages/quote/CarDetails/CarDetails.container';
import { ButtonWrapper } from './styles';
import VehicleLookupSearch from './VehicleLookupFormInput.component';

interface Props {
  car: Car | null;
  loading: boolean;
  formErrors: Record<string, unknown>;
  formQuoteStartErrors: Record<string, unknown>;
  errorMessage?: string | null;
  errorCode?: string;
  submitFailed: boolean;
  handleErrorCode: (errorCode: string, errorMessage: string) => void;
  lookupCar: (reg: string) => void;
  skipValidation?: boolean;
  resetLookupCar: () => void;
  vehicleRegistration: string;
}

export const cleanedValue = (value: string): string => value.replace(/[^a-zA-Z0-9]/g, '');

const requireRegUpdateErrors = [
  'CARWEB_ERROR_1000',
  'VEHICLE_NOT_ALLOWED',
  'CARWEB_MISSING_DATA',
  'VEHICLE_TOO_OLD',
  'VEHICLE_IS_IMPORTED',
];

const VehicleLookupForm = ({
  car,
  loading,
  formErrors,
  errorMessage = '',
  errorCode = '',
  submitFailed,
  handleErrorCode,
  lookupCar,
  skipValidation,
  resetLookupCar,
  formQuoteStartErrors,
  vehicleRegistration,
}: Props): ReactElement => {
  const [lookupCarButton, setLookupCarButton] = useState(false);
  const [regUpdated, setRegUpdated] = useState(false);

  const handleLookupCar = (vehReg: string) => {
    if (vehReg) {
      lookupCar(cleanedValue(vehReg?.toUpperCase()));
    }
    setLookupCarButton(true);
    setRegUpdated(false);
  };

  const isError: boolean =
    (submitFailed && formErrors && formErrors.carRegistration && !regUpdated) ||
    (errorMessage && !regUpdated) ||
    (lookupCarButton && formQuoteStartErrors?.vehicleRegistration && !regUpdated);

  return (
    <>
      <FieldContainer
        id='carRegistration'
        data-testid='car_registration_search'
        showErrorContainer={isError}
        showError={isError}
        error={
          (formErrors && formErrors.carRegistration) ||
          handleErrorCode(
            errorCode,
            errorMessage || (lookupCarButton && formQuoteStartErrors?.vehicleRegistration),
          )
        }
        showErrorImage={
          !['CARWEB_ERROR_1000', 'VEHICLE_TOO_OLD', 'VEHICLE_IS_IMPORTED'].includes(errorCode)
        }
      >
        <Field
          id='vehicleRegistrationField'
          name='vehicleRegistration'
          component={VehicleLookupSearch}
          props={{
            onSearch: (reg: string): void => handleLookupCar(reg),
            car,
            resetLookupCar,
            onRegUpdate: (): void => setRegUpdated(true),
            disabled:
              !!formQuoteStartErrors?.vehicleRegistration ||
              (!regUpdated && requireRegUpdateErrors.includes(errorCode)),
          }}
          validate={skipValidation ? [] : [ReduxFieldValidation.isValidCarReg]}
        />
      </FieldContainer>
      <ButtonWrapper visible={!car}>
        <Button
          isLoading={lookupCarButton && loading}
          id='carRegSearchButton'
          height={64}
          data-testid='car-reg-search-car'
          borderWidth={0}
          circleLoader
          onClick={() => handleLookupCar(vehicleRegistration)}
          disabled={
            !!formQuoteStartErrors?.vehicleRegistration ||
            (!regUpdated && requireRegUpdateErrors.includes(errorCode))
          }
        >
          {lookupCarButton && loading ? 'Looking up car' : 'Lookup car'}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default VehicleLookupForm;
