import React, { ReactNode } from 'react';

import { ComponentType, useComponentId } from '@hooks';
import {
  StyledBottomContent,
  StyledBox,
  StyledBoxContent,
  StyledCardFooterContainer,
  StyledContainer,
  StyledContent,
  StyledTitle,
  StyledLogo,
  StyledLogoWrapper,
} from './assets/styles';

export interface Props {
  title: string;
  titleCentered?: boolean;
  buttonAction?: () => void;
  buttonText?: string;
  bottomContent?: ReactNode;
  children: ReactNode;
  buttonDisabled?: boolean;
  cardFooterContent?: ReactNode;
}

const Page: React.FC<Props> = ({
  cardFooterContent,
  children,
  title,
  titleCentered = false,
  bottomContent,
}) => {
  const creator = useComponentId();
  return (
    <StyledContainer>
      <StyledContent id={creator(ComponentType.CONTAINER, 'styledContent')}>
        <StyledLogoWrapper>
          <StyledLogo />
        </StyledLogoWrapper>
        <StyledBox id={creator(ComponentType.CONTAINER, 'styledBox')}>
          <StyledTitle
            id={creator(ComponentType.CONTAINER, 'styledTitle')}
            centered={titleCentered}
          >
            {title}
          </StyledTitle>
          <StyledBoxContent id={creator(ComponentType.CONTAINER, 'styledBoxContainer')}>
            {children}
          </StyledBoxContent>
          {cardFooterContent && (
            <StyledCardFooterContainer
              id={creator(ComponentType.CONTAINER, 'styledCardFooterContainer')}
            >
              {cardFooterContent}
            </StyledCardFooterContainer>
          )}
        </StyledBox>
        {bottomContent && (
          <StyledBottomContent id={creator(ComponentType.TEXT, 'styledBottomContent')}>
            {bottomContent}
          </StyledBottomContent>
        )}
      </StyledContent>
    </StyledContainer>
  );
};

export default Page;
