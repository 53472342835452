import React from 'react';
import styled from 'styled-components';
import { RoundedGreyBox, TextLink } from '@rentecarlo/component-library';

import links from '@config/links';
import QuoteStep from '@templates/QuoteStep';
import LicenceImg from '@assets/licence-conflict.svg';
import { isCassieProvLicence } from '@services/licence';

import { ComponentProps } from './LicenceError.container';

const BrokenLicenceContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const InfoBody = styled.div`
  color: #364344;
  font-family: 'Proxima-Soft';
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

const InfoBold = styled.span`
  font-weight: bold;
`;

const renderBody = (licenceType: string) => {
  if (isCassieProvLicence(licenceType)) {
    return (
      <InfoBody id='licenceChange-text-errorInfoNoLicence'>
        <p id='licenceChange-paragraph-errorInfoWrongLicence'>
          It can take up to{' '}
          <InfoBold id='licenceChange-paragraph-errorInfoWrongLicenceThreeWeeks'>3 weeks </InfoBold>
          for the{' '}
          <TextLink id='licenceChange-link-errorInfoWrongLicence' href={links.DVLA}>
            DVLA{' '}
          </TextLink>
          to send and update your licence.
        </p>
        Until the DVLA sort this out for you, you can{' '}
        <InfoBold id='licenceChange-text-errorInfoNoLicenceBoldExit'>exit this quote </InfoBold>and{' '}
        <InfoBold id='licenceChange-text-errorInfoNoLicenceBoldStart'>start again </InfoBold>
        when you’re ready.
      </InfoBody>
    );
  }

  return (
    <InfoBody id='licenceChange-text-errorInfoWrongLicence'>
      It looks like you are trying to buy Learner Driver cover, but your details don't fit this
      product. Click Continue to quote for Temporary Insurance on your own car, or Car Sharing
      Insurance to borrow someone else's car.
    </InfoBody>
  );
};

const LicenceError: React.FC<ComponentProps> = ({
  cassieLicenceType,
  onExitQuote,
  productType,
}) => (
  <QuoteStep
    id='licenceChange-component-quoteStep'
    stepTitle='OOH SNAP!'
    stepSubtitle="Well, not really, it looks like your driving licence doesn't match the Learner Driver product!"
    includeStepPrefix={false}
    nextLabel={isCassieProvLicence(cassieLicenceType) ? 'EXIT QUOTE' : 'CONTINUE'}
    nextAction={() => onExitQuote(cassieLicenceType, productType)}
    displaySummaryFooter={false}
  >
    <BrokenLicenceContainer>
      <img src={LicenceImg} alt='licence-img' />
    </BrokenLicenceContainer>
    <RoundedGreyBox id='licenceChange-container-error' padding={[16, 16, 16, 16]}>
      {renderBody(cassieLicenceType)}
    </RoundedGreyBox>
    <br />
  </QuoteStep>
);

export default LicenceError;
