import styled from 'styled-components';

import colors from '@config/colors';

import CompleteIcon from '@assets/complete-icon.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 8px 8px;
  margin-top: -28px;
`;

export const Title = styled.h2`
  font-family: Graphie;
  color: ${({ theme }) => theme.textPrimaryAlt};
  font-size: 16px;
  font-weight: 700;
  align-self: center;

  padding-top: 8px;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.textPrimary};
  font-family: Graphie;
  font-size: 28px;
  font-weight: 700;
  line-height: 37px;
  text-align: center;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  align-self: center;

  background-color: ${colors.whiteIce};
  border: 1px solid ${colors.robinsEggBlue};
  border-radius: 4px;

  padding: 4px 8px;
`;

export const Icon = styled.img.attrs({ src: CompleteIcon })``;

export const IconText = styled.p`
  color: ${({ theme }) => theme.textPrimaryAlt};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

export const ClipboardContainer = styled.div`
  display: flex;

  width: 100%;
`;

export const Image = styled.img`
  padding: 16px 0;

  align-self: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`;

export const BottomText = styled.p`
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  padding: 16px 16px 0;
`;

export const Link = styled.a.attrs({ target: '_blank', rel: 'noopener noreferrer' })`
  color: ${({ theme }) => theme.linkPrimary};
`;
