import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import Config from 'Config';

interface Props {
  voucher_code: String;
  order_subtotal: Number;
  order_ref: String;
  commission_group: String;
  product: String;
}

interface AwinParams extends Props {
  currency_code: String;
  sale_amount: Number;
  test_mode: any;
}

const getProductDetails = (product: String) => {
  switch (product) {
    case 'csi':
      return { id: 'CSI', name: 'Car Sharing Insurance' };
    case 'ldp':
      return { id: 'LDP', name: 'Learner Driver Insurance' };
    default:
      return { id: 'XXX', name: 'INVALID_PRODUCT' };
  }
};

const AwinTrackingTags: React.FC<Props> = (props) => {
  const awinParams = {
    ...props,
    currency_code: 'GBP',
    // sale_amount equal to order subtotal as only 1 item in basket. If in future we can
    // purchase several policies at once, there would be a sale_amount for each policy.
    sale_amount: props.order_subtotal,
    test_mode: process.env.REACT_APP_AFFILIATE_TEST_MODE, // Test mode is set in the webpack config
  };

  return (
    <div>
      <AwinImagePixelTrackingTag awinParams={awinParams} />
      <AwinJavascriptTrackingTag awinParams={awinParams} />
      <AwinProductLevelTrackingTag awinParams={awinParams} />
    </div>
  );
};

export const AwinImagePixelTrackingTag: React.FC<{ awinParams: AwinParams }> = (props) => {
  const { awinParams } = props;
  return (
    <img
      id='awin-image-pixel-tag'
      src={`https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=16041\
&amount=${awinParams.order_subtotal}\
&cr=${awinParams.currency_code}\
&ref=${awinParams.order_ref}\
&parts=${awinParams.commission_group}:${awinParams.sale_amount}\
&vc=${awinParams.voucher_code}\
&ch=aw\
&testmode=${props.awinParams.test_mode}\
`}
      width='0'
      height='0'
    />
  );
};

export const AwinJavascriptTrackingTag: React.FC<{ awinParams: AwinParams }> = (props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'awin-javascript-tag';

    const { awinParams } = props;

    const scriptText = document.createTextNode(`
          //<![CDATA[/*** Do not change ***/
          var AWIN = {};
          AWIN.Tracking = {};
          AWIN.Tracking.Sale = {};
    
          /*** Set your transaction parameters ***/
          AWIN.Tracking.Sale.amount = '${awinParams.order_subtotal}';
          AWIN.Tracking.Sale.orderRef = '${awinParams.order_ref}';
          AWIN.Tracking.Sale.parts = '${awinParams.commission_group}:${awinParams.sale_amount}';
          AWIN.Tracking.Sale.voucher = '${awinParams.voucher_code}';
          AWIN.Tracking.Sale.currency = '${awinParams.currency_code}';
          AWIN.Tracking.Sale.test = '${awinParams.test_mode}';
          AWIN.Tracking.Sale.channel = 'aw';
          //]]>
        `);

    script.appendChild(scriptText);
    document.body.appendChild(script);
  }, []);

  return null;
};

export const AwinProductLevelTrackingTag: React.FC<{
  awinParams: AwinParams;
}> = (props) => {
  // The Image Pixel Tracking tag used by AWIN.

  // Note: there are two extra parameters that can be passed into this tag, which
  // are currently not wanted by marketing: 'sku' and 'category'. Currently sku is
  // hardcoded to product_id. They can be added by including them in the textarea
  // either side of commission_group: i.e. |${sku}|${commission_group}|${category}
  const { awinParams } = props;
  const { order_ref } = awinParams;
  const product = getProductDetails(awinParams.product);
  const unit_price = awinParams.order_subtotal;
  const quantity = 1;
  const sku = product.id;
  const { commission_group } = awinParams;

  return (
    <form id='awin-product-tag' style={{ display: 'none' }} name='aw_basket_form'>
      <textarea
        wrap='physical'
        id='aw_basket'
        value={`

AW:P|16041|${order_ref}|${product.id}|${product.name}|${unit_price}|${quantity}|${sku}|${commission_group}

`}
      />
    </form>
  );
};

export default AwinTrackingTags;
