import React from 'react';

import CardOwnerIcon from '@assets/owner-icn-tc.svg';
import { capitaliseFirstLetter } from '@services/formatString';
import { Card, CardKeyValueLabel, CardUtils } from '@rentecarlo/component-library';
import PillButton from '@atoms/buttons/PillButton';

type Props = {
  firstName: string;
  surname: string;
  ownerEmail: string;
  ownerRelationship: string;
  ownerBirthdate: string;
  onClick: () => void;
  showBirthdate?: boolean;
  isMobile: boolean;
};

const OwnerDetailsCard: React.FC<Props> = (props: Props) => {
  const {
    firstName,
    surname,
    ownerEmail,
    ownerRelationship,
    showBirthdate,
    ownerBirthdate,
    isMobile,
    onClick,
  } = props;
  return (
    <Card>
      <CardUtils.CardWrapper>
        <CardUtils.StyledCard>
          <CardUtils.Wrapper>
            <CardUtils.CardTitleWrapper>
              <CardUtils.CardTitleIcon icon={CardOwnerIcon} />
              <CardUtils.CardTitleTitle id='quote-title-driverDetailsSummaryOwner' fontSize={28}>
                {isMobile ? 'Owner' : 'Owner details'}
              </CardUtils.CardTitleTitle>
            </CardUtils.CardTitleWrapper>

            <CardUtils.PillButtonWrapper>
              <PillButton id='quote-button-driverDetailsSummaryEditOwner' onClick={onClick}>
                Edit
              </PillButton>
            </CardUtils.PillButtonWrapper>
          </CardUtils.Wrapper>
          <CardUtils.ChildrenWrapper>
            <CardKeyValueLabel id='quote-text-driverDetailsSummaryOwnerName' label='Owner name:'>
              {`${capitaliseFirstLetter(firstName)} ${capitaliseFirstLetter(surname)}`}
            </CardKeyValueLabel>
            {showBirthdate && (
              <CardKeyValueLabel
                id='quote-text-driverDetailsSummaryOwnerDateOfBirth'
                label='Date of birth:'
              >
                {ownerBirthdate}
              </CardKeyValueLabel>
            )}
            <CardKeyValueLabel id='quote-text-driverDetailsSummaryOwnerEmail' label='Email:'>
              {ownerEmail}
            </CardKeyValueLabel>
            <CardKeyValueLabel
              id='quote-text-driverDetailsSummaryOwnerRelationship'
              label='Relationship to you:'
            >
              {ownerRelationship}
            </CardKeyValueLabel>
          </CardUtils.ChildrenWrapper>
        </CardUtils.StyledCard>
      </CardUtils.CardWrapper>
    </Card>
  );
};

export default OwnerDetailsCard;
