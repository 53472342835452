import React from 'react';

import loader from '@assets/loading.gif';
import { Box, PriceInner, Loader, Price, Pound, MonthText } from './assets/styles';

type Props = {
  isLoading?: boolean;
  price?: string | null;
  isSubscription: boolean;
};

const IndicativePriceBox: React.FC<Props> = ({
  isLoading = false,
  price = null,
  isSubscription,
}) => (
  <Box id='indicativePriceHolder'>
    <PriceInner id='indicativePrice-container-price'>
      <Pound id='indicativePrice-text-priceSymbol'>£</Pound>
      {isLoading ? (
        <Loader src={loader} id='loadingImg' />
      ) : (
        <>
          <Price id='indicativePriceText'>{price}</Price>
          {price && isSubscription && (
            <MonthText id='indicativePrice-text-month'>per month</MonthText>
          )}
        </>
      )}
    </PriceInner>
  </Box>
);

export default IndicativePriceBox;
