import { SetStateFieldActionCreator } from '@utils/types';

import { Owner, PreferredCar, State } from './ownerTypes';
import { types as userTypes } from './user.actions';

export enum types {
  SET_OWNER_FIELD = 'SET_OWNER_FIELD',
  SET_OWNER_FIELDS = 'SET_OWNER_FIELDS',
}

export interface SetOwnerFields {
  type: typeof types.SET_OWNER_FIELDS;
  payload: State;
  featureToggled: boolean;
}

export interface OwnerPrefill {
  type: typeof userTypes.PREFILL_OWNER;
  data: {
    customer: Owner;
  };
}
export interface OwnerPrefillOnEdit {
  type: typeof userTypes.PREFILL_OWNER_ON_EDIT;
  data: {
    customer: Owner;
  };
}

export interface PrefillConnection {
  type: typeof userTypes.PREFILL_CONNECTION;
  preferredCar: PreferredCar;
}

// Actions
export const setOwnerField: SetStateFieldActionCreator<State, typeof types.SET_OWNER_FIELD> = (
  field,
  value,
) => ({
  type: types.SET_OWNER_FIELD,
  field,
  value,
});

export const setOwnerFields = (payload: State, featureToggled: boolean): SetOwnerFields => ({
  type: types.SET_OWNER_FIELDS,
  payload,
  featureToggled,
});

type SetOwnerField = ReturnType<typeof setOwnerField>;

export type OwnerActions =
  | SetOwnerField
  | SetOwnerFields
  | OwnerPrefill
  | OwnerPrefillOnEdit
  | PrefillConnection;
