/* eslint-disable */

// Stripe api is only available by sourcing from a script tag

let Stripe;
var stripeLoader = setInterval(() => {
  if (window.Stripe) {
    Stripe = window.Stripe;
    clearInterval(stripeLoader);
  }
});

export default Stripe
