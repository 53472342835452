import React, { useState } from 'react';
import { DurationOptions, Durations } from '@config/indicativeDurations';
import { Button } from '@rentecarlo/component-library';
import LightningBoltIcon from '@assets/lightning-bolt-icn.svg';
import BlackPLus from '@assets/black-plus.svg';
import { useOptimizelyTrack } from '@hooks';
import { UseUpSellComponentHook } from './UpSellComponent.hook';
import {
  UpsellCard,
  TextContainer,
  Icon,
  ButtonContainer,
  UpSellText,
} from './assets/UpSellComponent';

interface Props {
  durations: Durations;
  updateQuote: (option: DurationOptions) => void;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const UpSellComponent = ({
  selectedTab,
  durations,
  updateQuote,
  setSelectedTab,
}: Props): JSX.Element => {
  const [hasSeenUpSell, setHasSeenUpSell] = useState(false);
  const sendEvent = useOptimizelyTrack();
  const { displayPrice, displayHours, durationToUpSell, displayUpSell } = UseUpSellComponentHook(
    durations,
    selectedTab,
  );

  if (hasSeenUpSell || selectedTab !== 'hours' || !displayUpSell || !durationToUpSell) {
    return <div />;
  }

  const handleUpSellClick = () => {
    setHasSeenUpSell(true);
    updateQuote(durationToUpSell);
    if (durationToUpSell.sub === 'DAY') setSelectedTab('days');
    sendEvent('indic_price_up_sell_click');
  };

  return (
    <UpsellCard data-testid='upsell-container'>
      <TextContainer>
        <Icon src={LightningBoltIcon} />
        <UpSellText id='duration-text-upSellHoursValue'>
          <b>{`Add ${displayHours} extra hours`}</b>
          <br />
          {`Estimated price £${displayPrice}`}
        </UpSellText>
      </TextContainer>
      <ButtonContainer>
        <Button
          height={38}
          borderWidth={2}
          borderColor='black'
          id='duration-button-hoursUpSellUpdateMyQuote'
          onClick={handleUpSellClick}
          buttonType='outline'
        >
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}
            id='duration-button-updateMyQuote'
          >
            <Icon src={BlackPLus} alt='Add' />
            Add
          </div>
        </Button>
      </ButtonContainer>
    </UpsellCard>
  );
};

export default UpSellComponent;
