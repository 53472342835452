import { SetStateFieldActionCreator } from '@utils/types';
import { State } from './noClaimsBonus';

export const SET_NCB_FIELD = 'SET_NCB_FIELD';

// Actions
export const setNCBField: SetStateFieldActionCreator<State, typeof SET_NCB_FIELD> = (
  field,
  value,
) => ({
  type: SET_NCB_FIELD,
  field,
  value,
});

export type NCBActionType = ReturnType<typeof setNCBField>;
