import { connect } from 'react-redux';
import { productEmail } from '@reselectors/quote';
import Error from './Error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => ({
  isMobile: state.app.isMobile,
  productType: state.quote.product.productType,
  productEmail,
});

export default connect(mapStateToProps)(Error);
