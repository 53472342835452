import React from 'react';
import { Card, CardUtils } from '@rentecarlo/component-library';
import CardOwnerIcon from '@assets/owner-icn-tc.svg';
import PillButton from '@atoms/buttons/PillButton';
import PlusIcon from '@assets/plus-icn-blue.svg';
import OwnerDetailsCard from '@molecules/OwnerDetailsCard';
import { ComponentType, useComponentId } from '@hooks';
import { Subtitle, Container } from './styles';

interface StateProps {
  setOwnerModalVisibility: () => void;
  hasOwner: boolean;
  firstName: string;
  surname: string;
  ownerEmail: string;
  ownerRelationship: string;
}

const OwnerSummary: React.FC<StateProps> = ({ setOwnerModalVisibility, hasOwner }) => {
  const creator = useComponentId();
  return (
    <>
      {!hasOwner && (
        <Card>
          <CardUtils.CardWrapper onClick={setOwnerModalVisibility}>
            <CardUtils.StyledCard>
              <CardUtils.Wrapper>
                <CardUtils.CardTitleWrapper>
                  <CardUtils.CardTitleIcon icon={CardOwnerIcon} />
                  <CardUtils.CardTitleTitle
                    id={creator(ComponentType.TITLE, 'ownerSummaryAddOwner')}
                    fontSize={18}
                  >
                    Add the car’s owner
                  </CardUtils.CardTitleTitle>
                </CardUtils.CardTitleWrapper>
              </CardUtils.Wrapper>

              <CardUtils.CardSubtitleWrapper>
                <Subtitle id={creator(ComponentType.SUBTITLE, 'ownerSummaryOwnerDetails')}>
                  When borrowing someone else’s car, we need a few details of the owner.
                </Subtitle>
              </CardUtils.CardSubtitleWrapper>

              <CardUtils.ChildrenWrapper>
                <Container>
                  <PillButton
                    icon={PlusIcon}
                    id={creator(ComponentType.BUTTON, 'ownerSummaryAddOwner')}
                    onClick={setOwnerModalVisibility}
                  >
                    Add car owner
                  </PillButton>
                </Container>
              </CardUtils.ChildrenWrapper>
            </CardUtils.StyledCard>
          </CardUtils.CardWrapper>
        </Card>
      )}
      {hasOwner && <OwnerDetailsCard onClick={setOwnerModalVisibility} />}
    </>
  );
};

export default OwnerSummary;
