import React, { FC } from 'react';
import styled from 'styled-components';

const AddressContainer = styled.div`
  font-size: '20px';
  font-family: proxima-soft;
  line-height: '24px'
  text-transform: capitalize;
`;

const AddressLine = styled.span`
  :last-child {
    text-transform: uppercase;
  }
`;

type Props = {
  address: Array<string>;
};

const Address: FC<Props> = ({ address }) => (
  <AddressContainer id='payment-container-address'>
    {address.map((line) => (
      <AddressLine id={`payment-button-${line.split(' ').join('')}`} key={line}>
        {line.toLowerCase()}
        <br />
      </AddressLine>
    ))}
  </AddressContainer>
);

export default Address;
