import React from 'react';

import { CardModal, H1, P, Button } from '@rentecarlo/component-library';

import OutlineButton from '@atoms/buttons/OutlineButton';
import { Container, TextContainer, Spacer } from './assets/styles';

interface Props {
  visible: boolean;
  close: () => void;
  email: string;
}

const EmailAlreadyExistsModal: React.FC<Props> = ({ visible, close, email }) => {
  return (
    <CardModal visible={visible} maxWidth='310px'>
      <Container>
        <H1 id='aboutYou-title-emailExists'>Welcome back!</H1>
        <TextContainer>
          <P id='aboutYou-paragraph-emailExists' lineHeight={26}>
            It looks like you already have an account with us at{' '}
            <b id='aboutYou-paragraph-emailExistsBold'>{email}</b>. <br /> Sign in and we’ll use
            your previous details to save you some time!
          </P>
        </TextContainer>
        <a href={`${process.env.REACT_APP_LOGIN_URL}/?redirect=${window.location.href}`}>
          <Button type='button' id='aboutYou-button-emailExistsGoToLogin' height={64}>
            Sign in
          </Button>
        </a>
        <Spacer />
        <OutlineButton
          id='aboutYou-button-emailExistsUseDifferentEmail'
          type='button'
          onClick={() => {
            close();
          }}
        >
          Use a different email
        </OutlineButton>
      </Container>
    </CardModal>
  );
};

export default EmailAlreadyExistsModal;
