import { createSelector } from 'reselect';

const driver = (state) => state.quote.driver;
const owner = (state) => state.quote.owner;
const car = (state) => state.quote.car;

export const driverIsDefined = createSelector([driver], ({ firstName }) => firstName !== '');

export const ownerIsDefined = createSelector([owner], ({ firstName }) => firstName !== '');

export const carIsSelected = createSelector(
  [car],
  ({ make, model }) => model !== '' && make !== '',
);
