/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';

import { TextLink } from '@rentecarlo/component-library';
import LinkArrow from '@atoms/links/LinkArrow';
import colors from '@config/colors';

const RowWrapper = styled.a`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 78px;
  border-bottom: 1px solid ${colors.grey.lines.light};
  align-items: center;
`;

const LinkContainer = styled.div`
  flex: 1;
  color: ${colors.blue.royal};
  font-family: proxima-soft;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
`;

const HelpfullLinkRow = ({ url, text }) => (
  <RowWrapper href={url}>
    <LinkContainer>
      <TextLink id='rejection-link-helpfulRow' fontSize={14} bold={false} href={url}>
        {text}
      </TextLink>
    </LinkContainer>
    <LinkArrow direction='right' />
  </RowWrapper>
);

export default HelpfullLinkRow;
