import { connect } from 'react-redux';
import { excesses, confirmations } from '@reselectors/quote';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';

import { setVariableExcess } from '@reducer/quote/importantInformation.actions';
import { setAncillaryField } from '@redux/reducer/quote/ancillary.actions';
import { FinalPriceExcess } from '@utils/types';

import ImportantInformation from './ImportantInformation';

const formSelector = formValueSelector('importantInformationForm');

const getBreakdownCoverPrice = (finalPricesByExcess: FinalPriceExcess[]): string => {
  if (finalPricesByExcess.length) {
    // Breakdown cover is the same price across all excesses, so just get the 1st one
    return finalPricesByExcess[0].breakdown_price?.toFixed(2) || '';
  }

  return '';
};

const mapStateToProps = (state) => ({
  confirmations: confirmations(state),
  excesses: excesses(state),
  finalPricesByExcess: state.quote.importantInformation.finalPricesByExcess,
  formExcess: formSelector(state, 'excess'),
  showUnavailableExcess: state.quote.importantInformation.showUnavailableExcess,
  formErrors: getFormSyncErrors('importantInformationForm')(state),
  productType: state.quote.product.productType,
  breakdownCoverPrice: getBreakdownCoverPrice(state.quote.importantInformation.finalPricesByExcess),
  isSubscription: state.quote.product.isSubscription,
  isQuoteForMyself: state.account.customer.isQuoteForMyself,
  isFastRepurchase: state.quote.save.isFastRepurchase,
});

const mapDispatchToProps = (dispatch) => ({
  submission: (
    values: Record<string, unknown>,
    isFastRepurchase: boolean,
    push: (page: string) => void,
  ) => {
    dispatch(setVariableExcess(values.excess));
    dispatch(setAncillaryField('breakdownCover', values.breakdownCover === 'true'));

    if (isFastRepurchase) {
      push('/confirm-details');
      return;
    }

    dispatch(saveAndGoTo('final-quote'));
  },
});

const ImportantInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImportantInformation);

export default reduxForm({
  form: 'importantInformationForm',
})(ImportantInformationContainer);
