import React from 'react';
import styled from 'styled-components';

import StepTitle from '@atoms/titles/pageTitles/StepTitle';
import { MainContainer, Content, InnerContent, SidebarContainer } from '@templates/Main';

import colors from '@config/colors';

import RejectionSidebar from '@molecules/asides/RejectionSidebar.container';
import RejectionImage from '@assets/rejection-footer.svg';
import MobileRejectionFooterImage from '@assets/rejection-footer-mobile.svg';
import { Page } from '../../ui-tools';

const Image = styled.img`
  align-self: center;
  padding-right: 25%;
`;
const MobileImage = styled.img`
  width: 100%;
  margin-top: 30%;
  margin-bottom: 60px;
`;

const AltContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AltSidebarContainer = styled(SidebarContainer)`
  background-color: ${colors.grey.background.light};
`;

interface ComponentProps {
  isMobile: boolean;
  productType: string;
  productEmail: string;
}

const Error: React.FC<ComponentProps> = ({ productEmail, isMobile }) => {
  return (
    <MainContainer>
      <AltContent className='page'>
        <InnerContent>
          <Page id='error-component-page' borderTop={false}>
            <StepTitle includeStepPrefix={false} title='Oops' />
            We're really sorry, but something has gone wrong. If you were in the middle of a
            payment, then please contact our{' '}
            <a id='error-link-customerServices' href={productEmail}>
              customer services team ({productEmail})
            </a>{' '}
            who will be happy to help.
          </Page>
        </InnerContent>
        {!isMobile && <Image src={RejectionImage} />}
      </AltContent>
      {isMobile && <MobileImage src={MobileRejectionFooterImage} />}
      <AltSidebarContainer>
        <RejectionSidebar />
      </AltSidebarContainer>
    </MainContainer>
  );
};

export default Error;
