import { put, select, takeEvery } from 'redux-saga/effects';

import { setUserField } from '../reducer/quote/user.actions';
import { types as initActionTypes } from '../init';

export function* setReferrerSaga() {
  // eslint-disable-next-line prefer-regex-literals
  const referrerMatch = document.cookie.match(new RegExp('referrer=([^;]+)'));
  if (referrerMatch) {
    const referrer = referrerMatch[1];
    yield put(setUserField('referrer', referrer));
  } else {
    yield put(setUserField('referrer', ''));
  }
}

export function* watchRouting() {
  yield takeEvery(initActionTypes.INIT, setReferrerSaga);
}
