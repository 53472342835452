import HttpClient from './httpClient';

const buildError = (message, redirect, code) => ({ message, redirect, code });

const car = {
  async get(quoteUuid, registrationNumber) {
    try {
      const response = await new HttpClient.Builder()
        .get(`/api/v1/quotes/${quoteUuid}/vehicle-lookup/${registrationNumber}`)
        .execute();

      if (response.success === false) {
        throw buildError(response.message, false, response.code);
      }

      return {
        abi_code: response.abiCode,
        engine_size: response.engineSize,
        make: response.make,
        model: response.model,
        transmission: response.transmission,
        year: response.year,
        value: response.value,
      };
    } catch (error) {
      throw buildError(error.message, false, error.code);
    }
  },
};

export default car;
