import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';

import SelectItemButton from '@atoms/buttons/SelectItemButton';
import QuestionRadioItem from '@atoms/blocks/QuestionRadioItem';
import ModalSlide from '@molecules/slides/ModalSlide';

import relationships from '@config/relationships';
import getRelationshipText from '@services/getRelationshipText';

import { Button, FieldContainer } from '@rentecarlo/component-library';
import { ComponentType, useComponentId } from '@hooks';

import { ButtonWrapper, ContentWrapper } from './styles';
import { Props, Relationship } from './types';

const RelationshipSlide: React.FC<Props & WrappedFieldProps> = ({
  disabled,
  productType,
  id,
  input,
  meta,
}) => {
  const creator = useComponentId();
  const { onChange, value } = input;
  const { error, touched, active, pristine, submitFailed } = meta;
  const [visible, setVisibility] = useState(false);

  const questions = relationships.filter(
    (relationship: Relationship) => !(relationship.value === 'co' && productType === 'ldp'),
  );

  return (
    <FieldContainer
      id={id}
      showErrorContainer={submitFailed && error}
      showError={((touched && !pristine) || submitFailed) && error && (!active || submitFailed)}
      error={error}
    >
      <SelectItemButton
        id={id}
        label={getRelationshipText(value)}
        onClick={(): void => setVisibility(true)}
        disabled={disabled}
      />
      <ModalSlide
        title='What’s the relationship between the temporary driver and the owner of the car?'
        onClick={(): void => setVisibility(false)}
        visible={visible}
      >
        <ContentWrapper id={creator(ComponentType.CONTAINER, 'relationshipSlide')}>
          {questions.map((question: Relationship) => (
            <QuestionRadioItem
              key={question.value}
              title={question.title}
              description={question.description}
              value={question.value}
              isSelected={value === question.value}
              applyTransparency={!(value === '' || value === question.value)}
              // eslint-disable-next-line no-shadow
              onSelect={(newValue: string): void => {
                onChange(newValue);
                setVisibility(false);
              }}
              id={question.id}
            />
          ))}
          <ButtonWrapper>
            <Button id='selectRelationship-btn' onClick={(): void => setVisibility(false)}>
              Select relationship
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
      </ModalSlide>
    </FieldContainer>
  );
};

RelationshipSlide.defaultProps = {
  meta: {
    error: '',
  },
};

export default RelationshipSlide;
