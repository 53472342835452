const licenceLabelFromType = (type: 'uk_manual' | 'uk_prov' | 'uk_auto' | 'non_uk'): string => {
  switch (type) {
    case 'uk_manual':
      return 'Full GB Manual';
    case 'uk_prov':
      return 'Provisional Licence';
    case 'uk_auto':
      return 'Full GB Automatic';
    case 'non_uk':
      return 'Issued Outside GB';
    default:
      return 'Full GB Manual';
  }
};

export default licenceLabelFromType;
