import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { types as quoteTypes, QuoteActions } from './actions';

import { SET_HAS_PASSED, SET_DRIVING_TEST_DATE, DrivingTestActions } from './drivingTest.actions';

dayjs.extend(utc);
dayjs.extend(timezone);
export interface DrivingTest {
  hasPassed: boolean | null;
  drivingTestDate: Dayjs | null;
}

export const initialState: DrivingTest = {
  hasPassed: null,
  drivingTestDate: null,
};

export type DrivingTestActionTypes = DrivingTestActions;

export default function reducer(
  state = initialState,
  action = {} as DrivingTestActionTypes | QuoteActions,
): DrivingTest {
  switch (action.type) {
    case SET_HAS_PASSED:
      return {
        ...state,
        hasPassed: action.hasPassed,
      };
    case SET_DRIVING_TEST_DATE:
      return {
        ...state,
        drivingTestDate: action.drivingTestDate,
      };
    case quoteTypes.RESET_QUOTE:
      return initialState;
    default:
      return state;
  }
}
