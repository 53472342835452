import React from 'react';
import styled from 'styled-components';

import colors from '@config/colors';
import dotIcon from '@assets/3-dot-icn.svg';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  border: 1px solid ${colors.grey.lines.light};
  border-radius: 4px;
  background: ${(props) => (props.isDisabled ? colors.grey.background.disabled : colors.white)};
  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  outline: 0;
`;

export const ButtonLabel = styled.span`
  color: ${(props) => (props.isDisabled ? colors.grey.text.light : colors.outerSpace)};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  width: 100%;
  text-align: left;
`;

const ButtonImage = styled.div`
  width: 8px;
  height: 28px;
  margin-right: 20px;
  background-image: url(${dotIcon});
  background-size: contain;
  background-repeat: no-repeat;
`;

const Button = ({ disabled, onClick, id, label }) => (
  <StyledButton
    onClick={disabled ? (event) => event.preventDefault() : onClick}
    isDisabled={disabled}
    id={id}
  >
    <ButtonLabel id={`${id}Label`} isDisabled={disabled}>
      {label}
    </ButtonLabel>
    {!disabled && <ButtonImage />}
  </StyledButton>
);

Button.defaultProps = {
  disabled: false,
};

export default Button;
