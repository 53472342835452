import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { InformationBox } from '@rentecarlo/component-library';
import { push } from 'connected-react-router';

import QuoteStep from '@templates/QuoteStep';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import TextInputField from '@atoms/inputs/TextInputField';
import PasswordInputField from '@atoms/inputs/PasswordInputField/index';
import ReduxFieldValidation from '@services/formValidation';

const ConfirmPasswordWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const InformationBoxText = `
This email address is really important – it’s where we'll send your policy documents, and also the magic link to recover your account if the password is forgotten.
We'll send marketing updates here too. You can change your preferences at any time in your account or by clicking the unsubscribe link in marketing emails.
`;

const Spacer = styled.div`
  margin-top: 32px;
`;

interface Props {
  handleSubmit: (value: unknown) => void;
  submitRegistration: () => void;
  valid: boolean;
  submitFailed: boolean;
  formErrors: Record<string, unknown>;
  submitting: boolean;
  loggedIn: boolean;
}

const QuoteRegister: React.FC<Props> = ({
  handleSubmit,
  submitRegistration,
  valid,
  submitFailed,
  formErrors,
  submitting,
  loggedIn,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      dispatch(push('final-quote'));
    }
  }, [dispatch, loggedIn]);

  return (
    <form>
      <QuoteStep
        id='quoteRegister-component-quoteStep'
        stepTitle='Create your account'
        stepSubtitle=' Please create an account below to continue with your purchase. You will be able to review your policy documentation through your account and purchase more cover using your saved details. '
        nextAction={handleSubmit(submitRegistration)}
        includeStepPrefix={false}
        displaySummaryFooter={false}
        form
        formErrors={formErrors}
        nextDisabled={submitting}
        submitError={submitFailed && !valid}
      >
        <SectionTitle id='quoteRegister-title-driversEmail'>
          The driver's email address
        </SectionTitle>
        <Field
          props={{ pasteDisabled: true }}
          id='quoteRegister-field-email'
          name='email'
          type='email'
          maxLength={254}
          component={TextInputField}
          validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.isEmail]}
        />
        <Spacer />
        <SectionTitle id='quoteRegister-title-confirmDriversEmail'>
          Please confirm the email address
        </SectionTitle>
        <Field
          props={{ pasteDisabled: true }}
          id='quoteRegister-field-emailConfirm'
          name='emailConfirm'
          type='email'
          maxLength={254}
          component={TextInputField}
          validate={[
            ReduxFieldValidation.isRequired,
            ReduxFieldValidation.isEmail,
            ReduxFieldValidation.emailsMatch,
          ]}
        />
        <InformationBox id='quoteRegister-text-importantInformation' type='important'>
          {InformationBoxText}
        </InformationBox>
        <Spacer />
        <SectionTitle id='quoteRegister-title-password'>Password</SectionTitle>
        <Field
          id='quoteRegister-field-password'
          name='password'
          type='password'
          component={PasswordInputField}
          validate={[ReduxFieldValidation.isRequired, ReduxFieldValidation.password]}
        />
        <Spacer />
        <ConfirmPasswordWrapper>
          <SectionTitle id='quoteRegister-title-confirmPassword'>Confirm Password</SectionTitle>
          <Field
            id='quoteRegister-field-confirmPassword'
            name='confirmPassword'
            type='password'
            component={PasswordInputField}
            validate={[
              ReduxFieldValidation.isRequired,
              ReduxFieldValidation.password,
              ReduxFieldValidation.passwordMatch,
            ]}
          />
        </ConfirmPasswordWrapper>
      </QuoteStep>
    </form>
  );
};

export default QuoteRegister;
